import {
  useAuthInfo,
  useHostedPageUrls,
  useLogoutFunction,
} from "@propelauth/react";
import { PlusIcon, ReloadIcon, TrashIcon } from "@radix-ui/react-icons";
import { ChevronDownIcon } from "lucide-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";
import { Layout } from "../../components/Layout";
import { MultiSelectControl } from "../../components/MultiSelectControl";
import { UserContext } from "../../contexts/UserContext";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../shadcn/components/avatar";
import { Button } from "../../shadcn/components/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../shadcn/components/command";
import { Input } from "../../shadcn/components/input";
import { Label } from "../../shadcn/components/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import { Switch } from "../../shadcn/components/switch";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn/components/tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { cn } from "../../shadcn/lib/utils";
import {
  DataSource,
  Department,
  DocType,
  ManagedUser,
  OrganizationProfile,
  USState,
} from "../../types";
import {
  departmentUpdate,
  getDataSources,
  getDocDepartmentAssignments,
  getInternalDocs,
  getUsers,
  refreshDataSource,
  updateDataSources,
  updateUser,
} from "../../utils/apiCalls";
import { TimeAgo } from "../../utils/format";

type DataSwitchProps<T, U> = {
  id: string;
  name: T;
  switchValue: boolean;
  refreshable?: boolean;
  lastUpdatedAt: string | null;
  onCheckedChange: (name: T, newValue: boolean) => void;
  setDataSources?: React.Dispatch<React.SetStateAction<U[]>>;
};

function DataSwitch<T, U extends { doc_type: { id: string } }>(
  props: DataSwitchProps<T, U>
) {
  const authInfo = useAuthInfo();
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(props.switchValue);

  useEffect(() => {
    setIsChecked(props.switchValue);
  }, [props.switchValue]);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    props.onCheckedChange(props.name, !isChecked);
  };

  const handleRefresh = async () => {
    setRefreshLoading(true);
    const response = await refreshDataSource(
      props.id,
      authInfo.accessToken ?? null
    );
    if (response) {
      toast.success("Data source refreshed");
      if (props.setDataSources) {
        props.setDataSources((prev) =>
          prev.map((dataSource) =>
            dataSource.doc_type.id === props.id
              ? {
                  ...dataSource,
                  last_updated_at: new Date().toISOString().slice(0, -1),
                }
              : dataSource
          )
        );
      }
    } else {
      toast.error("Failed to refresh data source");
    }
    setRefreshLoading(false);
  };

  return (
    <div className="flex items-center space-x-2">
      <div className="flex items-center space-x-2">
        <Switch
          id={props.id}
          checked={isChecked}
          onCheckedChange={handleToggle}
        />
        <Label htmlFor={props.id}>{String(props.name)}</Label>
      </div>
      {props.refreshable && (
        <Tooltip>
          <TooltipTrigger>
            <Button variant="ghost" size="icon" onClick={handleRefresh}>
              <ReloadIcon
                className={cn("h-4 w-4", refreshLoading ? "animate-spin" : "")}
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Refresh Data</TooltipContent>
        </Tooltip>
      )}
      {props.lastUpdatedAt && (
        <div className="text-xs text-gray-500">
          Last updated <TimeAgo timestamp={props.lastUpdatedAt} />
        </div>
      )}
    </div>
  );
}

const UserDisplay = (props: {
  firstName: string;
  lastName: string;
  email: string;
  pictureUrl: string;
}) => {
  return (
    <div className="flex items-center space-x-4">
      <Avatar className="h-8 w-8">
        <AvatarImage src={props.pictureUrl} alt="Image" />
        <AvatarFallback>{`${props.firstName?.slice(
          0,
          1
        )}${props.lastName?.slice(0, 1)}`}</AvatarFallback>
      </Avatar>
      <div>
        <p className="text-md font-medium leading-none">{`${props.firstName} ${props.lastName}`}</p>
        <p className="text-md text-muted-foreground">{props.email}</p>
      </div>
    </div>
  );
};

const UserRoleEdit = (props: {
  userToEdit: ManagedUser;
  setUserToEdit: React.Dispatch<React.SetStateAction<ManagedUser>>;
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <div className="flex items-center justify-between w-[600px]">
      <UserDisplay
        firstName={props.userToEdit.first_name}
        lastName={props.userToEdit.last_name}
        email={props.userToEdit.email}
        pictureUrl={props.userToEdit.picture_url}
      />
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger asChild>
          <Button variant="outline" size="sm" className="ml-auto">
            {props.userToEdit.role}
            <ChevronDownIcon className="ml-2 h-4 w-4 text-muted-foreground" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0" align="end">
          <Command>
            <CommandInput placeholder="Select new role..." />
            <CommandList>
              <CommandEmpty>No roles found.</CommandEmpty>
              <CommandGroup>
                <CommandItem
                  className="teamaspace-y-1 flex flex-col items-start px-4 py-2"
                  onSelect={() => {
                    props.setUserToEdit((prev) => ({
                      ...prev,
                      role: "Member",
                    }));
                    setPopoverOpen(false);
                  }}
                >
                  <p>Member</p>
                </CommandItem>
                <CommandItem
                  className="teamaspace-y-1 flex flex-col items-start px-4 py-2"
                  onSelect={() => {
                    props.setUserToEdit((prev) => ({ ...prev, role: "Admin" }));
                    setPopoverOpen(false);
                  }}
                >
                  <p>Admin</p>
                  <p className="text-sm text-muted-foreground">
                    Can view and edit permissions for themself and other users.
                  </p>
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const DataSourceSwitches = (props: {
  dataSources: { doc_type: DocType; enabled: boolean }[];
  editMade: boolean;
  onCheckedChange: (name: string, newValue: boolean) => void;
  handleUpdate: () => void;
  updateLoading: boolean;
  setDataSources?: React.Dispatch<React.SetStateAction<DataSource[]>>;
}) => {
  const { isAgent } = useContext(UserContext);
  const externalDataSources = props.dataSources
    .filter((dataSource) => dataSource.doc_type.external)
    .sort((a, b) => a.doc_type.name.localeCompare(b.doc_type.name));
  const internalDataSources = props.dataSources
    .filter((dataSource) => !dataSource.doc_type.external)
    .sort((a, b) => a.doc_type.name.localeCompare(b.doc_type.name));

  return (
    <>
      <div className="space-y-1">
        <div className="font-bold pb-4">External Data Sources</div>
        <div className="pl-4 space-y-2 items-start">
          {externalDataSources.map((dataSource) => (
            <DataSwitch
              id={dataSource.doc_type.id}
              name={dataSource.doc_type.name}
              switchValue={dataSource.enabled}
              lastUpdatedAt={dataSource.doc_type.last_updated_at}
              onCheckedChange={props.onCheckedChange}
              key={dataSource.doc_type.id}
              refreshable={isAgent}
              setDataSources={props.setDataSources}
            />
          ))}
        </div>
      </div>
      <div className="space-y-1 pt-8">
        <div className="font-bold pb-4">Internal Data Sources</div>
        <div className="pl-4 space-y-2 items-start">
          {internalDataSources.map((dataSource) => (
            <DataSwitch
              id={dataSource.doc_type.id}
              name={dataSource.doc_type.name}
              switchValue={dataSource.enabled}
              onCheckedChange={props.onCheckedChange}
              key={dataSource.doc_type.id}
              refreshable={true}
              lastUpdatedAt={dataSource.doc_type.last_updated_at}
              setDataSources={props.setDataSources}
            />
          ))}
        </div>
        <div className="pt-8">
          <Button onClick={props.handleUpdate} size="sm" className="w-[100px]">
            {props.updateLoading && (
              <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
            )}
            Update
          </Button>
        </div>
      </div>
    </>
  );
};

const EditUserDisplay = (props: {
  user: ManagedUser;
  setUsers: React.Dispatch<React.SetStateAction<ManagedUser[]>>;
}) => {
  const authInfo = useAuthInfo();
  const [userToEdit, setUserToEdit] = useState<ManagedUser>(props.user);
  const editMade = JSON.stringify(userToEdit) !== JSON.stringify(props.user);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    setUserToEdit(props.user);
  }, [props.user]);

  const handleUpdate = async () => {
    setUpdateLoading(true);
    const response = await updateUser(userToEdit, authInfo.accessToken ?? null);
    if (response) {
      toast.success("User updated");
      props.setUsers((prev) => {
        return prev.map((u) => (u.id === userToEdit.id ? userToEdit : u));
      });
    } else {
      toast.error("Failed to update user");
    }
    setUpdateLoading(false);
  };

  const onCheckedChange = (name: string, newValue: boolean) => {
    setUserToEdit((prev) => ({
      ...prev,
      permissions: prev.permissions.map((permission) => {
        if (permission.doc_type.name === name) {
          return { ...permission, enabled: newValue };
        } else {
          return permission;
        }
      }),
    }));
  };

  return (
    <div className="w-full space-y-4">
      <UserRoleEdit userToEdit={userToEdit} setUserToEdit={setUserToEdit} />
      <DataSourceSwitches
        dataSources={userToEdit.permissions}
        onCheckedChange={onCheckedChange}
        editMade={editMade}
        handleUpdate={handleUpdate}
        updateLoading={updateLoading}
      />
    </div>
  );
};

const UserSelector = (props: {
  users: ManagedUser[];
  onSelectUser: (userId: string) => void;
}) => {
  return (
    <Select onValueChange={props.onSelectUser}>
      <SelectTrigger className="w-[600px] bg-white">
        <SelectValue placeholder="Select a User" />
      </SelectTrigger>
      <SelectContent className="max-h-[300px] overflow-y-auto overflow-x-hidden">
        <SelectGroup>
          {props.users.map((user) => {
            const displayName =
              user.first_name || user.last_name
                ? `${user.first_name} ${user.last_name}`.trim()
                : user.email;
            return (
              <SelectItem key={user.id} value={user.id}>
                {displayName}
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

const OrganizationProfileDisplay = (props: {
  orgProfile: OrganizationProfile;
  editRegulatingStates: (
    usState: keyof typeof USState,
    isRegulating: boolean
  ) => void;
  saveRegulatingStates: () => void;
  updateLoading: boolean;
}) => {
  return (
    <div className="opacity-25">
      {/* one day we will undim it.. */}
      <div className="space-y-1">
        <div className="font-bold pb-4">
          Regulating States (feature forthcoming)
        </div>
        <div className="pl-4 space-y-2 items-start">
          <MultiSelectControl
            title="Select States"
            items={Object.keys(USState).map((uss) => ({
              id: uss,
              name: USState[uss as keyof typeof USState],
            }))}
            selectedItems={Object.keys(props.orgProfile.regulating_states)
              .filter(
                (uss) =>
                  props.orgProfile.regulating_states[
                    uss as keyof typeof USState
                  ]
              )
              .map((uss) => ({
                id: uss,
                name: USState[uss as keyof typeof USState],
              }))}
            clearSelectedItems={() =>
              Object.keys(USState).forEach((uss) =>
                props.editRegulatingStates(uss as keyof typeof USState, false)
              )
            }
            selectItem={(item, isSelected) =>
              props.editRegulatingStates(
                item.id as keyof typeof USState,
                isSelected
              )
            }
            selectAll={() =>
              Object.keys(USState).forEach((uss) =>
                props.editRegulatingStates(uss as keyof typeof USState, true)
              )
            }
            selectItemOnly={(item) => {
              Object.keys(USState).forEach((uss) =>
                props.editRegulatingStates(uss as keyof typeof USState, false)
              );
              props.editRegulatingStates(item.id as keyof typeof USState, true);
            }}
          />
        </div>
      </div>
      <div className="space-y-1 pt-8">
        <div className="pt-8">
          <Button
            onClick={props.saveRegulatingStates}
            size="sm"
            className="w-[100px]"
            disabled={true} // TODO: one day we will enable it..
          >
            {props.updateLoading && (
              <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
            )}
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

const DataSourceView = () => {
  const authInfo = useAuthInfo();
  const [dataSources, setDataSources] = useState<DataSource[]>([]);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    getDataSources(authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        setDataSources(response);
      } else {
        toast.error("Failed to fetch data sources");
      }
    });
  }, []);

  const onCheckedChange = (name: string, newValue: boolean) => {
    setDataSources((prev) => {
      return prev.map((dataSource) => {
        if (dataSource.doc_type.name === name) {
          return { ...dataSource, enabled: newValue };
        } else {
          return dataSource;
        }
      });
    });
  };

  const handleUpdate = async () => {
    setUpdateLoading(true);
    const response = await updateDataSources(
      dataSources,
      authInfo.accessToken ?? null
    );
    if (response) {
      toast.success("Data sources updated");
    } else {
      toast.error("Failed to update data sources");
    }
    setUpdateLoading(false);
  };

  return (
    <div className="pl-1">
      <DataSourceSwitches
        dataSources={dataSources}
        onCheckedChange={onCheckedChange}
        handleUpdate={handleUpdate}
        updateLoading={updateLoading}
        editMade={true}
        setDataSources={setDataSources}
      />
    </div>
  );
};

const UserPermissionsView = () => {
  const { isAdmin } = useContext(UserContext);
  const [users, setUsers] = useState<ManagedUser[]>([]);
  const authInfo = useAuthInfo();
  const [selectedUser, setSelectedUser] = useState<ManagedUser | null>(null);

  useEffect(() => {
    if (isAdmin && authInfo.accessToken) {
      getUsers(authInfo.accessToken).then((response) => {
        if (response !== null) {
          setUsers(response);
        } else {
          toast.error("Failed to fetch users");
        }
      });
    }
  }, [isAdmin, authInfo.accessToken]);

  const handleUserSelection = (userId: string) => {
    const user = users.find((u) => u.id === userId);
    setSelectedUser(user ?? null);
  };

  return (
    <div className="pl-1 space-y-4">
      <UserSelector users={users} onSelectUser={handleUserSelection} />
      {selectedUser && (
        <EditUserDisplay user={selectedUser} setUsers={setUsers} />
      )}
    </div>
  );
};

const UserView = () => {
  const authInfo = useAuthInfo();
  const logoutFn = useLogoutFunction();
  const { getAccountPageUrl } = useHostedPageUrls();
  return (
    <div className="space-y-4 pl-1">
      <UserDisplay
        firstName={authInfo.user?.firstName ?? ""}
        lastName={authInfo.user?.lastName ?? ""}
        email={authInfo.user?.email ?? ""}
        pictureUrl={authInfo.user?.pictureUrl ?? ""}
      />
      <div className="flex items-center space-x-2">
        <Button
          variant="default"
          className="w-[100px]"
          onClick={() => window.open(getAccountPageUrl(), "_blank")}
        >
          Account
        </Button>
        <Button
          variant="destructive"
          onClick={() => logoutFn(false)}
          size="sm"
          className="w-[100px]"
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

const DepartmentView = (props: { originalDepartments: Department[] }) => {
  const authInfo = useAuthInfo();
  const { setDepartments } = useContext(UserContext);
  const [departmentsTemp, setDepartmentsTemp] = useState(
    props.originalDepartments
  );
  const [internalDocs, setInternalDocs] = useState<
    { id: string; name: string }[]
  >([]);
  const [docDepartmentAssignments, setDocDepartmentAssignments] = useState<
    { doc_id: string; department_id: string }[]
  >([]);
  const [search, setSearch] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  const departmentsToShow = departmentsTemp.filter((department) =>
    department.name.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    getInternalDocs(authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        setInternalDocs(response);
      } else {
        toast.error("Failed to fetch internal docs");
      }
    });
    getDocDepartmentAssignments(authInfo.accessToken ?? null).then(
      (response) => {
        if (response !== null) {
          setDocDepartmentAssignments(response);
        } else {
          toast.error("Failed to fetch doc department assignments");
        }
      }
    );
  }, []);

  useEffect(() => {
    setDepartmentsTemp(props.originalDepartments);
  }, [props.originalDepartments]);

  const onClickUpdate = async () => {
    setUpdateLoading(true);
    const response = await departmentUpdate(
      departmentsTemp,
      docDepartmentAssignments,
      authInfo.accessToken ?? null
    );
    if (response) {
      setDepartments(departmentsTemp);
      toast.success("Departments updated");
    } else {
      toast.error("Failed to update departments");
    }
    setUpdateLoading(false);
  };

  return (
    <div className="pl-1 space-y-4 w-[600px]">
      <div className="space-y-2">
        <div className="flex items-center justify-between space-x-2">
          <Button
            variant="default"
            size="sm"
            onClick={() => {
              setDepartmentsTemp((prev) => [
                { id: uuidv4(), name: "New Department" },
                ...prev,
              ]);
            }}
          >
            <PlusIcon className="w-4 h-4 mr-2" />
            Add Department
          </Button>
          <Button
            onClick={onClickUpdate}
            variant="default"
            size="sm"
            className="w-[100px]"
          >
            Update
            {updateLoading && (
              <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />
            )}
          </Button>
        </div>
        <Input
          placeholder="Search departments..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="space-y-2">
        {departmentsToShow.map((department) => (
          <div
            key={department.id}
            className="flex items-center justify-between"
          >
            <Input
              value={department.name}
              onChange={(e) => {
                setDepartmentsTemp((prev) =>
                  prev.map((d) =>
                    d.id === department.id ? { ...d, name: e.target.value } : d
                  )
                );
              }}
              className="w-[400px]"
            />
            <div className="flex items-center space-x-2 flex-grow">
              <MultiSelectControl
                title="Documents"
                items={internalDocs}
                selectedItems={docDepartmentAssignments
                  .filter(
                    (assignment) => assignment.department_id === department.id
                  )
                  .map((assignment) => ({
                    id: assignment.doc_id,
                    name:
                      internalDocs.find((doc) => doc.id === assignment.doc_id)
                        ?.name ?? "",
                  }))}
                clearSelectedItems={() => {
                  setDocDepartmentAssignments((prev) =>
                    prev.filter(
                      (assignment) => assignment.department_id !== department.id
                    )
                  );
                }}
                selectItem={(item, isSelected) =>
                  setDocDepartmentAssignments((prev) => {
                    if (isSelected) {
                      return [
                        ...prev,
                        { doc_id: item.id, department_id: department.id },
                      ];
                    }
                    return prev.filter(
                      (assignment) =>
                        assignment.doc_id !== item.id ||
                        assignment.department_id !== department.id
                    );
                  })
                }
              />
              <Button
                variant="destructive"
                onClick={() => {
                  setDepartmentsTemp((prev) =>
                    prev.filter((d) => d.id !== department.id)
                  );
                }}
              >
                <TrashIcon className="w-4 h-4" />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const OrganizationProfileView = () => {
  const authInfo = useAuthInfo();
  const [orgProfile, setOrgProfile] = useState<OrganizationProfile>({
    regulating_states: { CA: true }, // TODO: one day we'll support more than CA..
  });
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    // TODO: read from backend
    authInfo;
  }, []);

  const editRegulatingStates = useCallback(
    (uss: keyof typeof USState, isRegulating: boolean) => {
      const attributes = {
        ...orgProfile,
      };
      attributes.regulating_states[uss] = isRegulating;
      setOrgProfile(attributes);
    },
    [orgProfile, setOrgProfile]
  );

  const saveRegulatingStates = useCallback(async () => {
    setUpdateLoading(true);
    // TODO: write to backend
    setUpdateLoading(false);
  }, [setUpdateLoading]);

  return (
    <div className="pl-1">
      <OrganizationProfileDisplay
        orgProfile={orgProfile}
        editRegulatingStates={editRegulatingStates}
        saveRegulatingStates={saveRegulatingStates}
        updateLoading={updateLoading}
      />
    </div>
  );
};

export const SettingsPage = () => {
  const { isAdmin, departments } = useContext(UserContext);

  return (
    <Layout pageName="Settings">
      <div className="pb-10">
        <Tabs defaultValue="user">
          <TabsList>
            <TabsTrigger value="user">User</TabsTrigger>
            {isAdmin && (
              <>
                <TabsTrigger value="permissions">
                  Document Permissions
                </TabsTrigger>
                <TabsTrigger value="data">Data Sources</TabsTrigger>
                <TabsTrigger value="departments">Departments</TabsTrigger>
                {/* <TabsTrigger value="organization">
                  Organization Profile
                </TabsTrigger> */}
                {/*TODO: Bring this feature live some day..*/}
              </>
            )}
          </TabsList>
          <TabsContent value="user">
            <UserView />
          </TabsContent>
          {isAdmin && (
            <>
              <TabsContent value="permissions">
                <UserPermissionsView />
              </TabsContent>
              <TabsContent value="data">
                <DataSourceView />
              </TabsContent>
              <TabsContent value="departments">
                <DepartmentView originalDepartments={departments} />
              </TabsContent>
              <TabsContent value="organization">
                <OrganizationProfileView />
              </TabsContent>
            </>
          )}
        </Tabs>
      </div>
    </Layout>
  );
};
