import { useAuthInfo } from "@propelauth/react";
import React, { createContext, ReactNode, useContext, useState } from "react";
import {
  getUniqueElements,
  getUniqueStandards,
  sortFactors,
} from "../pages/auditTool/utils";
import {
  AuditTool,
  AuditToolElementReview,
  AuditToolFactorReviewDoc,
  AuditToolPermission,
  AuditToolRelatedDoc,
  Factor,
} from "../types";
import { getAuditTool, getAuditToolRelatedDocs } from "../utils/apiCalls";
import { DocViewerContext } from "./DocViewerContext";

export type AuditFactorReview = {
  review_summary: string;
  review_docs: AuditToolFactorReviewDoc[];
  error?: string | null;
};
type AuditToolContextType = {
  auditTool: AuditTool | null;
  auditToolUserPermissions: AuditToolPermission[];
  setAuditToolUserPermissions: React.Dispatch<
    React.SetStateAction<AuditToolPermission[]>
  >;
  auditToolAllPermissions: AuditToolPermission[];
  setAuditToolAllPermissions: React.Dispatch<
    React.SetStateAction<AuditToolPermission[]>
  >;
  isOwner: boolean;
  setIsOwner: React.Dispatch<React.SetStateAction<boolean>>;
  setAuditTool: React.Dispatch<React.SetStateAction<AuditTool | null>>;
  fetchAuditTool: (auditToolId: string) => Promise<void>;
  activeFactor: Factor | null;
  elementReviews: AuditToolElementReview[];
  setElementReviews: React.Dispatch<
    React.SetStateAction<AuditToolElementReview[]>
  >;
  setActiveFactor: React.Dispatch<React.SetStateAction<Factor | null>>;
  activeDoc: AuditToolRelatedDoc | null;
  setActiveDoc: React.Dispatch<
    React.SetStateAction<AuditToolRelatedDoc | null>
  >;
  auditFactorReview: Record<Factor["id"], AuditFactorReview>;
  setAuditFactorReview: React.Dispatch<
    React.SetStateAction<Record<Factor["id"], AuditFactorReview>>
  >;
  handleAuditFactor: (factor: Factor, doc: AuditToolRelatedDoc) => void;
  findFactorNeighbors: (factor: Factor) => {
    previous: Factor | boolean;
    next: Factor | boolean;
  };
  findElementNeighbors: (
    fa: string,
    std: string,
    el: string
  ) => {
    previous: { fa: string; std: string; el: string } | false;
    next: { fa: string; std: string; el: string } | false;
  };
};

export const AuditToolContext = createContext<AuditToolContextType>({
  auditTool: null,
  auditToolUserPermissions: [],
  setAuditToolUserPermissions: () => {},
  auditToolAllPermissions: [],
  setAuditToolAllPermissions: () => {},
  isOwner: false,
  setIsOwner: () => {},
  setAuditTool: () => {},
  fetchAuditTool: (id: string) => Promise.resolve(),
  elementReviews: [],
  setElementReviews: () => {},
  activeFactor: null,
  setActiveFactor: () => {},
  activeDoc: null,
  setActiveDoc: () => {},
  auditFactorReview: {},
  setAuditFactorReview: () => {},
  handleAuditFactor: () => {},
  findFactorNeighbors: (factor: Factor) => ({
    previous: false,
    next: false,
  }),
  findElementNeighbors: (fa: string, std: string, el: string) => ({
    previous: false,
    next: false,
  }),
});

export const AuditToolProvider = ({ children }: { children: ReactNode }) => {
  const { setDocToView } = useContext(DocViewerContext);
  const authInfo = useAuthInfo();
  const [auditTool, setAuditTool] = useState<AuditTool | null>(null);
  const [elementReviews, setElementReviews] = useState<
    AuditToolElementReview[]
  >([]);
  const [auditToolUserPermissions, setAuditToolUserPermissions] = useState<
    AuditToolPermission[]
  >([]);
  const [auditToolAllPermissions, setAuditToolAllPermissions] = useState<
    AuditToolPermission[]
  >([]);
  const [isOwner, setIsOwner] = useState(false);
  const [activeFactor, setActiveFactor] = useState<Factor | null>(null);
  const [activeDoc, setActiveDoc] = useState<AuditToolRelatedDoc | null>(null);
  const [auditFactorReview, setAuditFactorReview] = useState<
    Record<
      Factor["id"],
      {
        review_summary: string;
        review_docs: AuditToolFactorReviewDoc[];
        error?: string | null;
      }
    >
  >({});
  const fetchAuditTool = async (auditToolId: string) => {
    const auditToolPromise = getAuditTool(
      auditToolId,
      authInfo.accessToken ?? ""
    );
    const relatedDocsPromise = getAuditToolRelatedDocs(
      auditToolId,
      authInfo.accessToken ?? ""
    );
    const [auditTool, relatedDocs] = await Promise.all([
      auditToolPromise,
      relatedDocsPromise,
    ]);
    auditTool.related_docs = relatedDocs;
    setAuditTool(auditTool);
  };

  const findFactorNeighbors = (factor: Factor) => {
    const sortedFactors = sortFactors(
      auditTool?.factors ?? [],
      factor.functional_area
    );
    const index = sortedFactors.findIndex((f) => f.id === factor.id);
    return {
      previous: index === 0 ? false : sortedFactors[index - 1],
      next:
        index === sortedFactors.length - 1 ? false : sortedFactors[index + 1],
    };
  };

  type ElementNeighbor = { fa: string; std: string; el: string } | false;

  const findElementNeighbors = (
    fa: string,
    std: string,
    el: string
  ): {
    previous: { fa: string; std: string; el: string } | false;
    next: { fa: string; std: string; el: string } | false;
  } => {
    const faFactors = auditTool?.factors.filter(
      (f) => f.functional_area === fa
    );
    let sortedElements: { fa: string; std: string; el: string }[] = [];
    const standards = getUniqueStandards(faFactors ?? []);
    for (const standard of standards) {
      const uniqueElements = getUniqueElements(standard.factors);
      sortedElements.push(
        ...uniqueElements.map((e) => ({
          fa: fa,
          std: standard.standard_index,
          el: e.element_index,
        }))
      );
    }
    const index = sortedElements.findIndex((e) => e.el === el && e.std === std);
    const prev: ElementNeighbor =
      index === 0 ? false : sortedElements[index - 1];
    const next: ElementNeighbor =
      index === sortedElements.length - 1 ? false : sortedElements[index + 1];
    const res = {
      previous: prev,
      next,
    };
    return res;
  };

  const handleAuditFactor = async (
    factor: Factor,
    doc: AuditToolRelatedDoc
  ) => {
    setActiveFactor(factor);
    setActiveDoc(doc);
    setDocToView({
      docId: doc.doc_id,
    });
  };
  return (
    <AuditToolContext.Provider
      value={{
        auditTool,
        elementReviews,
        setElementReviews,
        auditToolUserPermissions,
        setAuditToolUserPermissions,
        auditToolAllPermissions,
        setAuditToolAllPermissions,
        isOwner,
        setIsOwner,
        setAuditTool,
        fetchAuditTool,
        activeFactor,
        setActiveFactor,
        activeDoc,
        setActiveDoc,
        auditFactorReview,
        setAuditFactorReview,
        handleAuditFactor,
        findFactorNeighbors,
        findElementNeighbors,
      }}
    >
      {children}
    </AuditToolContext.Provider>
  );
};
