import { Copy } from "lucide-react";
import { useMemo } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { toast } from "sonner";
import { Badge } from "../../shadcn/components/badge";
import { Button } from "../../shadcn/components/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Citation } from "../../types";
import { extractTag } from "../../utils/format";

const rewriteMarkupToMarkdown = (
  markup: string,
  replaceCitationsFormat: (citationId: string, index: number) => string
) => {
  let markdown = markup;

  // citation markup
  extractTag(markup, "citation")
    .flatMap((citationId) => citationId.split(","))
    .filter((citationId) => citationId.length > 0)
    .forEach((citationId, i) => {
      const regex = new RegExp(`(${citationId})(?!~)`, "g");
      markdown = markdown.replace(regex, replaceCitationsFormat(citationId, i));
    });
  markdown = markdown.replaceAll("<citation>", "");
  markdown = markdown.replaceAll("</citation>", "");
  markdown = markdown.replaceAll("~,~", "~ ~");

  // redline markup
  markdown = markdown
    .replace(/<strikethrough>(.*?)<\/strikethrough>/g, "**~~$1~~**")
    .replace(/<underline>(.*?)<\/underline>/g, "**`$1`**");

  return markdown;
};

export type CitationResultViewProps = {
  passage: string;
  citations?: Citation[];
  onClickCitation?: (citationId: string) => void;
};

export const CitationResultView = ({
  passage,
  citations = [],
  onClickCitation = () => null,
}: CitationResultViewProps) => {
  const handleCopy = () => {
    let markdown = rewriteMarkupToMarkdown(passage, (_, i) => `[${i + 1}]`);

    // Remove additional formatting tags for plain text
    markdown = markdown.replace(/<\/?[^>]+(>|$)|\*\*|##+|`/g, "");

    const citationsText = citations
      .filter((citation) => citation.id && passage.includes(citation.id))
      .map(
        (citation, i) =>
          `[${i + 1}] ${citation.doc_name} - Page ${citation.page}`
      )
      .join("\n");

    const textToCopy =
      citationsText.length > 0
        ? `${markdown}\n\nReferences:\n${citationsText}`
        : markdown;
    navigator.clipboard.writeText(textToCopy);
    toast.success("Copied to clipboard");
  };

  const markdown = useMemo(
    () =>
      rewriteMarkupToMarkdown(
        passage,
        (citationId, i) => `~${i + 1}<>${citationId}~`
      ),
    [passage, citations]
  );

  return (
    <div className="relative rounded-lg border bg-card text-card-foreground shadow-sm p-4 pr-10">
      <div className="absolute top-2 right-2">
        <Button variant="ghost" size="icon" onClick={handleCopy}>
          <Copy className="h-4 w-4" />
        </Button>
      </div>
      <Markdown
        className="prose"
        remarkPlugins={[remarkGfm]}
        components={{
          del: ({ node, ...props }) => {
            const [index, cId] = (props.children as string).split("<>");
            if (!cId) {
              return null;
            }

            const citationId = cId.trim();

            const citation = citations.find(
              (citation) => citation.id === citationId
            );
            if (!citation) {
              return null;
            }

            const badge = (
              <Tooltip>
                <TooltipTrigger>
                  <Badge
                    variant="default"
                    className="text-xs hover:bg-gray-100 hover:text-black cursor-pointer"
                    onClick={() => {
                      onClickCitation(citationId);
                    }}
                  >
                    {index}
                  </Badge>
                </TooltipTrigger>
                <TooltipContent>
                  {citation ? (
                    <div className="space-y-2 text-xs w-[200px]">
                      <div className="font-semibold truncate ellipsis">
                        {citation.doc_name}
                      </div>
                      <div className="line-clamp-4">{citation.text}</div>
                    </div>
                  ) : null}
                </TooltipContent>
              </Tooltip>
            );
            return badge;
          },
        }}
      >
        {markdown}
      </Markdown>
    </div>
  );
};
