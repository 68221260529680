import { useMemo } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const rewriteMarkupToMarkdown = (text: string): string => {
  return text
    .replace(/<strikethrough>(.*?)<\/strikethrough>/g, "**~~$1~~**")
    .replace(/<underline>(.*?)<\/underline>/g, "**`$1`**");
};

export type RedlinePassageViewProps = {
  passage: string;
};

export const RedlinedPassageView = ({ passage }: RedlinePassageViewProps) => {
  const markdown = useMemo(() => rewriteMarkupToMarkdown(passage), [passage]);

  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        code: ({ node, ...props }) => {
          return <span className="underline">{props.children}</span>;
        },
      }}
    >
      {markdown}
    </Markdown>
  );
};
