import { useAuthInfo } from "@propelauth/react";
import {
  CrossCircledIcon,
  FileIcon,
  InfoCircledIcon,
  PlusIcon,
  QuestionMarkCircledIcon,
  ReloadIcon,
} from "@radix-ui/react-icons";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { DocViewerCitation } from "../../components/DocViewer";
import { Layout } from "../../components/Layout";
import { MultiSelectControl } from "../../components/MultiSelectControl";
import { PdfCitation } from "../../components/PdfViewer/PdfHighlighter/types";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { UserContext } from "../../contexts/UserContext";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../shadcn/components/alert-dialog";
import { Badge } from "../../shadcn/components/badge";
import { Button } from "../../shadcn/components/button";
import { Input } from "../../shadcn/components/input";
import { Label } from "../../shadcn/components/label";
import { Progress } from "../../shadcn/components/progress";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import { Separator } from "../../shadcn/components/separator";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn/components/tabs";
import { Textarea } from "../../shadcn/components/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { cn } from "../../shadcn/lib/utils";
import {
  checkEntityAssessmentPerms,
  exportEntityAssessment,
  generateEntityAssessment,
  getDocIdsByFilenames,
  getEntityAssessmentDocTypeId,
  getEntityAssessmentMetadata,
  refreshDataSource,
  updateEntityAssessmentMetadata,
  uploadEntityAssessmentFiles,
} from "../../utils/apiCalls";
import { EntityAssessmentHighlightActions } from "./EntityAssessmentHighlightActions";
import {
  dataAccessOptions,
  EntityAssessmentCitation,
  functionOptions,
  LineOfBusinessOption,
  lineOfBusinessOptions,
  MediCalClassificationOption,
  MedicareClassificationOption,
  RiskLevel,
  StandardLineOfBusinessSelector,
  StandardMediCalClassificationSelector,
  StandardMedicareClassificationSelector,
  StandardRiskLevelSelector,
  StandardYesNoSelector,
  YesNoOption,
} from "./EntityAssessmentInput";

const simulateStreaming = async (
  text: string,
  setText: (value: string) => void,
  speed: number = 30
) => {
  let currentText = "";
  for (let i = 0; i < text.length; i++) {
    currentText += text[i];
    setText(currentText);
    await new Promise((resolve) => setTimeout(resolve, speed));
  }
};

const getRiskScore = (riskLevel: RiskLevel): number => {
  switch (riskLevel) {
    case "low":
      return 10;
    case "moderate":
      return 20;
    case "high":
      return 30;
    default:
      return 0;
  }
};

export const EntityAssessmentProjectView = () => {
  const { entityAssessmentId } = useParams<{ entityAssessmentId: string }>();
  const authInfo = useAuthInfo();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [entityAssessmentDocTypeId, setEntityAssessmentDocTypeId] =
    useState<string>("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDocUploaded, setIsDocUploaded] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [docToView, setDocToView] = useState<{ docId: string } | null>(null);
  const [entityName, setEntityName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [contractEffectiveDate, setContractEffectiveDate] = useState("");
  const [servicesProvided, setServicesProvided] = useState("");
  const [selectedFunctions, setSelectedFunctions] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [selectedFunctionsExplanation, setSelectedFunctionsExplanation] =
    useState("");
  const [selectedFunctionsCitations, setSelectedFunctionsCitations] = useState<
    EntityAssessmentCitation[]
  >([]);
  const [selectedOversight, setSelectedOversight] = useState<
    LineOfBusinessOption[]
  >([]);
  const [selectedOversightExplanation, setSelectedOversightExplanation] =
    useState("");
  const [selectedOversightCitations, setSelectedOversightCitations] = useState<
    EntityAssessmentCitation[]
  >([]);
  const [selectedImpactedEnrollee, setSelectedImpactedEnrollee] = useState<
    LineOfBusinessOption[]
  >([]);
  const [
    selectedImpactedEnrolleeExplanation,
    setSelectedImpactedEnrolleeExplanation,
  ] = useState("");
  const [
    selectedImpactedEnrolleeCitations,
    setSelectedImpactedEnrolleeCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [selectedInteractedEnrollee, setSelectedInteractedEnrollee] = useState<
    LineOfBusinessOption[]
  >([]);
  const [
    selectedInteractedEnrolleeExplanation,
    setSelectedInteractedEnrolleeExplanation,
  ] = useState("");
  const [
    selectedInteractedEnrolleeCitations,
    setSelectedInteractedEnrolleeCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [selectedDataAccess, setSelectedDataAccess] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [selectedDataAccessExplanation, setSelectedDataAccessExplanation] =
    useState("");
  const [selectedDataAccessCitations, setSelectedDataAccessCitations] =
    useState<EntityAssessmentCitation[]>([]);
  const [selectedDecisionMakingAuthority, setSelectedDecisionMakingAuthority] =
    useState<YesNoOption>(null);
  const [
    selectedDecisionMakingAuthorityExplanation,
    setSelectedDecisionMakingAuthorityExplanation,
  ] = useState("");
  const [
    selectedDecisionMakingAuthorityCitations,
    setSelectedDecisionMakingAuthorityCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [selectedLeadToFwa, setSelectedLeadToFwa] = useState<YesNoOption>(null);
  const [selectedLeadToFwaExplanation, setSelectedLeadToFwaExplanation] =
    useState("");
  const [selectedLeadToFwaCitations, setSelectedLeadToFwaCitations] = useState<
    EntityAssessmentCitation[]
  >([]);
  const [selectedUseSubcontractor, setSelectedUseSubcontractor] =
    useState<YesNoOption>(null);
  const [
    selectedUseSubcontractorExplanation,
    setSelectedUseSubcontractorExplanation,
  ] = useState("");
  const [
    selectedUseSubcontractorCitations,
    setSelectedUseSubcontractorCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [selectedUseOffshore, setSelectedUseOffshore] =
    useState<YesNoOption>(null);
  const [selectedUseOffshoreExplanation, setSelectedUseOffshoreExplanation] =
    useState("");
  const [selectedUseOffshoreCitations, setSelectedUseOffshoreCitations] =
    useState<EntityAssessmentCitation[]>([]);
  const [selectedOffShoreAssessment, setSelectedOffShoreAssessment] =
    useState<YesNoOption>(null);
  const [
    selectedOffShoreAssessmentExplanation,
    setSelectedOffShoreAssessmentExplanation,
  ] = useState("");
  const [
    selectedOffShoreAssessmentCitations,
    setSelectedOffShoreAssessmentCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [selectedAccessItNetwork, setSelectedAccessItNetwork] =
    useState<YesNoOption>(null);
  const [
    selectedAccessItNetworkExplanation,
    setSelectedAccessItNetworkExplanation,
  ] = useState("");
  const [
    selectedAccessItNetworkCitations,
    setSelectedAccessItNetworkCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [
    selectedMedicareDelegationClassification,
    setSelectedMedicareDelegationClassification,
  ] = useState<MedicareClassificationOption>(null);
  const [
    selectedMedicareDelegationClassificationExplanation,
    setSelectedMedicareDelegationClassificationExplanation,
  ] = useState("");
  const [
    selectedMedicareDelegationClassificationCitations,
    setSelectedMedicareDelegationClassificationCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [
    selectedMediCalDelegationClassification,
    setSelectedMediCalDelegationClassification,
  ] = useState<MediCalClassificationOption>(null);
  const [
    selectedMediCalDelegationClassificationExplanation,
    setSelectedMediCalDelegationClassificationExplanation,
  ] = useState("");
  const [
    selectedMediCalDelegationClassificationCitations,
    setSelectedMediCalDelegationClassificationCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [riskLevelMemberInteraction, setRiskLevelMemberInteraction] =
    useState<RiskLevel>(null);
  const [
    riskLevelMemberInteractionExplanation,
    setRiskLevelMemberInteractionExplanation,
  ] = useState("");
  const [
    riskLevelMemberInteractionCitations,
    setRiskLevelMemberInteractionCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [
    riskLevelDecisionMakingAuthority,
    setRiskLevelDecisionMakingAuthority,
  ] = useState<RiskLevel>(null);
  const [
    riskLevelDecisionMakingAuthorityExplanation,
    setRiskLevelDecisionMakingAuthorityExplanation,
  ] = useState("");
  const [
    riskLevelDecisionMakingAuthorityCitations,
    setRiskLevelDecisionMakingAuthorityCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [
    riskLevelMemberInformationAccess,
    setRiskLevelMemberInformationAccess,
  ] = useState<RiskLevel>(null);
  const [
    riskLevelMemberInformationAccessExplanation,
    setRiskLevelMemberInformationAccessExplanation,
  ] = useState("");
  const [
    riskLevelMemberInformationAccessCitations,
    setRiskLevelMemberInformationAccessCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [riskLevelRegulatoryImpact, setRiskLevelRegulatoryImpact] =
    useState<RiskLevel>(null);
  const [
    riskLevelRegulatoryImpactExplanation,
    setRiskLevelRegulatoryImpactExplanation,
  ] = useState("");
  const [
    riskLevelRegulatoryImpactCitations,
    setRiskLevelRegulatoryImpactCitations,
  ] = useState<EntityAssessmentCitation[]>([]);
  const [overallRiskScore, setOverallRiskScore] = useState<number>(0);

  const {
    setPageNumber,
    setCitations: setDocCitations,
    docMetadata,
    fileSelectorOpen,
  } = useContext(DocViewerContext);

  const [entityAssessmentMetadata, setEntityAssessmentMetadata] =
    useState<Record<string, any> | null>(null);

  const [activeTab, setActiveTab] = useState<string>("chat");

  const { simpleUsers } = useContext(UserContext);
  const [selectedOwnerId, setSelectedOwnerId] = useState<string>("");

  const [isGenerateDialogOpen, setIsGenerateDialogOpen] = useState(false);
  const [currentCitationIndex, setCurrentCitationIndex] = useState<number>(0);

  const handleExportAssessment = async () => {
    setExportLoading(true);
    const response = await exportEntityAssessment(
      entityAssessmentId ?? "",
      authInfo.accessToken ?? null
    );
    if (response) {
      toast.success("Export successful");
    } else {
      toast.error("Export failed");
    }
    setExportLoading(false);
  };

  const handleGenerateAssessment = async () => {
    // Check if there's existing data
    const hasExistingData =
      entityName !== "" ||
      contractEffectiveDate !== "" ||
      servicesProvided !== "" ||
      selectedFunctions.length > 0 ||
      selectedOversight.length > 0 ||
      selectedImpactedEnrollee.length > 0 ||
      selectedInteractedEnrollee.length > 0 ||
      selectedDecisionMakingAuthority !== null ||
      selectedLeadToFwa !== null ||
      selectedUseSubcontractor !== null;

    if (hasExistingData) {
      setIsGenerateDialogOpen(true);
      return;
    }

    // If no existing data, proceed directly
    setGenerating(true);

    // Reset all values before starting generation
    setEntityName("");
    setContractEffectiveDate("");
    setServicesProvided("");
    setSelectedFunctions([]);
    setSelectedFunctionsExplanation("");
    setSelectedOversight([]);
    setSelectedOversightExplanation("");
    setSelectedImpactedEnrollee([]);
    setSelectedImpactedEnrolleeExplanation("");
    setSelectedInteractedEnrollee([]);
    setSelectedInteractedEnrolleeExplanation("");
    setSelectedDecisionMakingAuthority(null);
    setSelectedDecisionMakingAuthorityExplanation("");
    setSelectedLeadToFwa(null);
    setSelectedLeadToFwaExplanation("");
    setSelectedUseSubcontractor(null);
    setSelectedUseSubcontractorExplanation("");
    setSelectedUseOffshore(null);
    setSelectedUseOffshoreExplanation("");
    setSelectedOffShoreAssessment(null);
    setSelectedOffShoreAssessmentExplanation("");
    setSelectedAccessItNetwork(null);
    setSelectedAccessItNetworkExplanation("");
    setSelectedMedicareDelegationClassification(null);
    setSelectedMedicareDelegationClassificationExplanation("");
    setSelectedMediCalDelegationClassification(null);
    setSelectedMediCalDelegationClassificationExplanation("");
    setRiskLevelMemberInteraction(null);
    setRiskLevelMemberInteractionExplanation("");
    setRiskLevelDecisionMakingAuthority(null);
    setRiskLevelDecisionMakingAuthorityExplanation("");
    setRiskLevelMemberInformationAccess(null);
    setRiskLevelMemberInformationAccessExplanation("");
    setRiskLevelRegulatoryImpact(null);
    setRiskLevelRegulatoryImpactExplanation("");

    try {
      const response = await generateEntityAssessment(
        entityAssessmentId ?? "",
        authInfo.accessToken ?? null
      );
      // Process each chunk as it arrives
      let latestMetadata = { ...entityAssessmentMetadata }; // Create outside the loop
      for await (const chunk of response) {
        // Update state based on the fields present in the chunk
        if (chunk.entity_name) setEntityName(chunk.entity_name);
        if (chunk.effective_date)
          setContractEffectiveDate(chunk.effective_date);
        if (chunk.service_provided) setServicesProvided(chunk.service_provided);

        if (chunk.functions_provided) {
          if (
            Array.isArray(chunk.functions_provided) &&
            chunk.functions_provided.length > 0 &&
            typeof chunk.functions_provided[0] === "object"
          ) {
            // If chunk contains full objects, use them directly
            setSelectedFunctions(chunk.functions_provided);
          } else {
            // If chunk contains just IDs, map to full objects
            const functionItems = functionOptions.filter((item) =>
              chunk.functions_provided.includes(item.id)
            );
            setSelectedFunctions(functionItems);
          }
          if (chunk.functions_provided_explanation) {
            setSelectedFunctionsExplanation(
              chunk.functions_provided_explanation
            );
          }
          if (chunk.functions_provided_citations) {
            setSelectedFunctionsCitations(chunk.functions_provided_citations);
          }
        }

        if (chunk.oversight_regulators) {
          const selectedOversightItems = lineOfBusinessOptions.filter(
            (option) => chunk.oversight_regulators.includes(option.id)
          );
          setSelectedOversight(
            selectedOversightItems.map(
              (item) => item.id as LineOfBusinessOption
            )
          );
          setSelectedOversightExplanation(
            chunk.oversight_regulators_explanation
          );
        }

        if (chunk.oversight_regulators_citations) {
          setSelectedOversightCitations(chunk.oversight_regulators_citations);
        }

        if (chunk.enrollee_impact_lines_of_business) {
          const selectedImpactedEnrolleeItems = lineOfBusinessOptions.filter(
            (option) =>
              chunk.enrollee_impact_lines_of_business.includes(option.id)
          );
          setSelectedImpactedEnrollee(
            selectedImpactedEnrolleeItems.map(
              (item) => item.id as LineOfBusinessOption
            )
          );
          setSelectedImpactedEnrolleeExplanation(
            chunk.enrollee_impact_lines_of_business_explanation
          );
          if (chunk.enrollee_impact_lines_of_business_citations) {
            setSelectedImpactedEnrolleeCitations(
              chunk.enrollee_impact_lines_of_business_citations
            );
          }
        }

        if (chunk.enrollee_interaction_lines_of_business) {
          const selectedInteractedEnrolleeItems = lineOfBusinessOptions.filter(
            (option) =>
              chunk.enrollee_interaction_lines_of_business.includes(option.id)
          );
          setSelectedInteractedEnrollee(
            selectedInteractedEnrolleeItems.map(
              (item) => item.id as LineOfBusinessOption
            )
          );
          setSelectedInteractedEnrolleeExplanation(
            chunk.enrollee_interaction_lines_of_business_explanation
          );
          if (chunk.enrollee_interaction_lines_of_business_citations) {
            setSelectedInteractedEnrolleeCitations(
              chunk.enrollee_interaction_lines_of_business_citations
            );
          }
        }

        if (chunk.data_access) {
          if (
            Array.isArray(chunk.data_access) &&
            chunk.data_access.length > 0 &&
            typeof chunk.data_access[0] === "object"
          ) {
            // If metadata contains full objects, use them directly
            setSelectedDataAccess(chunk.data_access);
          } else {
            // If metadata contains just IDs, map to full objects
            const dataAccessItems = dataAccessOptions.filter((item) =>
              chunk.data_access.includes(item.id)
            );
            setSelectedDataAccess(dataAccessItems);
          }
          setSelectedDataAccessExplanation(chunk.data_access_explanation);
        }

        if (chunk.decision_making_authority) {
          setSelectedDecisionMakingAuthority(
            chunk.decision_making_authority as YesNoOption
          );
          setSelectedDecisionMakingAuthorityExplanation(
            chunk.decision_making_authority_explanation
          );
          if (chunk.decision_making_authority_citations) {
            setSelectedDecisionMakingAuthorityCitations(
              chunk.decision_making_authority_citations
            );
          }
        }

        if (chunk.lead_to_fwa) {
          setSelectedLeadToFwa(chunk.lead_to_fwa as YesNoOption);
          setSelectedLeadToFwaExplanation(chunk.lead_to_fwa_explanation);
          if (chunk.lead_to_fwa_citations) {
            setSelectedLeadToFwaCitations(chunk.lead_to_fwa_citations);
          }
        }

        if (chunk.use_subcontractor) {
          setSelectedUseSubcontractor(chunk.use_subcontractor as YesNoOption);
          setSelectedUseSubcontractorExplanation(
            chunk.use_subcontractor_explanation
          );
          if (chunk.use_subcontractor_citations) {
            setSelectedUseSubcontractorCitations(
              chunk.use_subcontractor_citations
            );
          }
        }

        if (chunk.use_offshore) {
          setSelectedUseOffshore(chunk.use_offshore as YesNoOption);
          setSelectedUseOffshoreExplanation(chunk.use_offshore_explanation);
          if (chunk.use_offshore_citations) {
            setSelectedUseOffshoreCitations(chunk.use_offshore_citations);
          }
        }

        if (chunk.off_shore_assessment) {
          setSelectedOffShoreAssessment(
            chunk.off_shore_assessment as YesNoOption
          );
          setSelectedOffShoreAssessmentExplanation(
            chunk.off_shore_assessment_explanation
          );
          if (chunk.off_shore_assessment_citations) {
            setSelectedOffShoreAssessmentCitations(
              chunk.off_shore_assessment_citations
            );
          }
        }

        if (chunk.access_it_network) {
          setSelectedAccessItNetwork(chunk.access_it_network as YesNoOption);
          setSelectedAccessItNetworkExplanation(
            chunk.access_it_network_explanation
          );
          if (chunk.access_it_network_citations) {
            setSelectedAccessItNetworkCitations(
              chunk.access_it_network_citations
            );
          }
        }

        if (chunk.medicare_classification) {
          setSelectedMedicareDelegationClassification(
            chunk.medicare_classification as MedicareClassificationOption
          );
          setSelectedMedicareDelegationClassificationExplanation(
            chunk.medicare_classification_explanation
          );
          if (chunk.medicare_classification_citations) {
            setSelectedMedicareDelegationClassificationCitations(
              chunk.medicare_classification_citations
            );
          }
        }

        if (chunk.medi_cal_classification) {
          setSelectedMediCalDelegationClassification(
            chunk.medi_cal_classification as MediCalClassificationOption
          );
          setSelectedMediCalDelegationClassificationExplanation(
            chunk.medi_cal_classification_explanation
          );
          if (chunk.medi_cal_classification_citations) {
            setSelectedMediCalDelegationClassificationCitations(
              chunk.medi_cal_classification_citations
            );
          }
        }

        if (chunk.risk_member_interaction_impact) {
          setRiskLevelMemberInteraction(
            chunk.risk_member_interaction_impact as RiskLevel
          );
          setRiskLevelMemberInteractionExplanation(
            chunk.risk_member_interaction_impact_explanation
          );
          if (chunk.risk_member_interaction_impact_citations) {
            setRiskLevelMemberInteractionCitations(
              chunk.risk_member_interaction_impact_citations
            );
          }
        }

        if (chunk.risk_decision_making_authority) {
          setRiskLevelDecisionMakingAuthority(
            chunk.risk_decision_making_authority as RiskLevel
          );
          setRiskLevelDecisionMakingAuthorityExplanation(
            chunk.risk_decision_making_authority_explanation
          );
          if (chunk.risk_decision_making_authority_citations) {
            setRiskLevelDecisionMakingAuthorityCitations(
              chunk.risk_decision_making_authority_citations
            );
          }
        }

        if (chunk.risk_member_information_access) {
          setRiskLevelMemberInformationAccess(
            chunk.risk_member_information_access as RiskLevel
          );
          setRiskLevelMemberInformationAccessExplanation(
            chunk.risk_member_information_access_explanation
          );
          if (chunk.risk_member_information_access_citations) {
            setRiskLevelMemberInformationAccessCitations(
              chunk.risk_member_information_access_citations
            );
          }
        }

        if (chunk.risk_regulatory_impact) {
          setRiskLevelRegulatoryImpact(
            chunk.risk_regulatory_impact as RiskLevel
          );
          setRiskLevelRegulatoryImpactExplanation(
            chunk.risk_regulatory_impact_explanation
          );
          if (chunk.risk_regulatory_impact_citations) {
            setRiskLevelRegulatoryImpactCitations(
              chunk.risk_regulatory_impact_citations
            );
          }
        }

        // Update metadata with the new assessment data
        const updatedMetadata = {
          ...latestMetadata, // Use the tracking variable instead
          ...chunk,
          // Basic info
          name: projectName,
          entity_name: chunk.entity_name || entityName,
          effective_date: chunk.effective_date || contractEffectiveDate,
          service_provided: chunk.service_provided || servicesProvided,

          // Functions and oversight
          functions_provided: chunk.functions_provided
            ? functionOptions.filter((item) =>
                chunk.functions_provided.includes(item.id)
              )
            : selectedFunctions,
          functions_provided_explanation:
            chunk.functions_provided_explanation ||
            selectedFunctionsExplanation,
          functions_provided_citations:
            chunk.functions_provided_citations || selectedFunctionsCitations,

          oversight_regulators: chunk.oversight_regulators
            ? lineOfBusinessOptions.filter((option) =>
                chunk.oversight_regulators.includes(option.id)
              )
            : selectedOversight,
          oversight_regulators_explanation:
            chunk.oversight_regulators_explanation ||
            selectedOversightExplanation,
          oversight_regulators_citations:
            chunk.oversight_regulators_citations || selectedOversightCitations,

          // Enrollee impact and interaction
          enrollee_impact_lines_of_business:
            chunk.enrollee_impact_lines_of_business
              ? lineOfBusinessOptions.filter((option) =>
                  chunk.enrollee_impact_lines_of_business.includes(option.id)
                )
              : selectedImpactedEnrollee,
          enrollee_impact_lines_of_business_explanation:
            chunk.enrollee_impact_lines_of_business_explanation ||
            selectedImpactedEnrolleeExplanation,
          enrollee_impact_lines_of_business_citations:
            chunk.enrollee_impact_lines_of_business_citations ||
            selectedImpactedEnrolleeCitations,

          enrollee_interaction_lines_of_business:
            chunk.enrollee_interaction_lines_of_business
              ? lineOfBusinessOptions.filter((option) =>
                  chunk.enrollee_interaction_lines_of_business.includes(
                    option.id
                  )
                )
              : selectedInteractedEnrollee,
          enrollee_interaction_lines_of_business_explanation:
            chunk.enrollee_interaction_lines_of_business_explanation ||
            selectedInteractedEnrolleeExplanation,
          enrollee_interaction_lines_of_business_citations:
            chunk.enrollee_interaction_lines_of_business_citations ||
            selectedInteractedEnrolleeCitations,

          // Data access
          data_access: chunk.data_access
            ? dataAccessOptions.filter((item) =>
                chunk.data_access.includes(item.id)
              )
            : selectedDataAccess,
          data_access_explanation:
            chunk.data_access_explanation || selectedDataAccessExplanation,
          data_access_citations:
            chunk.data_access_citations || selectedDataAccessCitations,

          // Decision making and authority
          decision_making_authority:
            chunk.decision_making_authority || selectedDecisionMakingAuthority,
          decision_making_authority_explanation:
            chunk.decision_making_authority_explanation ||
            selectedDecisionMakingAuthorityExplanation,
          decision_making_authority_citations:
            chunk.decision_making_authority_citations ||
            selectedDecisionMakingAuthorityCitations,

          // FWA and subcontractors
          lead_to_fwa: chunk.lead_to_fwa || selectedLeadToFwa,
          lead_to_fwa_explanation:
            chunk.lead_to_fwa_explanation || selectedLeadToFwaExplanation,
          lead_to_fwa_citations:
            chunk.lead_to_fwa_citations || selectedLeadToFwaCitations,

          use_subcontractor:
            chunk.use_subcontractor || selectedUseSubcontractor,
          use_subcontractor_explanation:
            chunk.use_subcontractor_explanation ||
            selectedUseSubcontractorExplanation,
          use_subcontractor_citations:
            chunk.use_subcontractor_citations ||
            selectedUseSubcontractorCitations,

          use_offshore: chunk.use_offshore || selectedUseOffshore,
          use_offshore_explanation:
            chunk.use_offshore_explanation || selectedUseOffshoreExplanation,
          use_offshore_citations:
            chunk.use_offshore_citations || selectedUseOffshoreCitations,

          off_shore_assessment:
            chunk.off_shore_assessment || selectedOffShoreAssessment,
          off_shore_assessment_explanation:
            chunk.off_shore_assessment_explanation ||
            selectedOffShoreAssessmentExplanation,
          off_shore_assessment_citations:
            chunk.off_shore_assessment_citations ||
            selectedOffShoreAssessmentCitations,

          // IT network access
          access_it_network: chunk.access_it_network || selectedAccessItNetwork,
          access_it_network_explanation:
            chunk.access_it_network_explanation ||
            selectedAccessItNetworkExplanation,
          access_it_network_citations:
            chunk.access_it_network_citations ||
            selectedAccessItNetworkCitations,

          // Classifications
          medicare_classification:
            chunk.medicare_classification ||
            selectedMedicareDelegationClassification,
          medicare_classification_explanation:
            chunk.medicare_classification_explanation ||
            selectedMedicareDelegationClassificationExplanation,
          medicare_classification_citations:
            chunk.medicare_classification_citations ||
            selectedMedicareDelegationClassificationCitations,

          medi_cal_classification:
            chunk.medi_cal_classification ||
            selectedMediCalDelegationClassification,
          medi_cal_classification_explanation:
            chunk.medi_cal_classification_explanation ||
            selectedMediCalDelegationClassificationExplanation,
          medi_cal_classification_citations:
            chunk.medi_cal_classification_citations ||
            selectedMediCalDelegationClassificationCitations,

          // Risk assessments
          risk_member_interaction_impact:
            chunk.risk_member_interaction_impact || riskLevelMemberInteraction,
          risk_member_interaction_impact_explanation:
            chunk.risk_member_interaction_impact_explanation ||
            riskLevelMemberInteractionExplanation,
          risk_member_interaction_impact_citations:
            chunk.risk_member_interaction_impact_citations ||
            riskLevelMemberInteractionCitations,

          risk_decision_making_authority:
            chunk.risk_decision_making_authority ||
            riskLevelDecisionMakingAuthority,
          risk_decision_making_authority_explanation:
            chunk.risk_decision_making_authority_explanation ||
            riskLevelDecisionMakingAuthorityExplanation,
          risk_decision_making_authority_citations:
            chunk.risk_decision_making_authority_citations ||
            riskLevelDecisionMakingAuthorityCitations,

          risk_member_information_access:
            chunk.risk_member_information_access ||
            riskLevelMemberInformationAccess,
          risk_member_information_access_explanation:
            chunk.risk_member_information_access_explanation ||
            riskLevelMemberInformationAccessExplanation,
          risk_member_information_access_citations:
            chunk.risk_member_information_access_citations ||
            riskLevelMemberInformationAccessCitations,

          risk_regulatory_impact:
            chunk.risk_regulatory_impact || riskLevelRegulatoryImpact,
          risk_regulatory_impact_explanation:
            chunk.risk_regulatory_impact_explanation ||
            riskLevelRegulatoryImpactExplanation,
          risk_regulatory_impact_citations:
            chunk.risk_regulatory_impact_citations ||
            riskLevelRegulatoryImpactCitations,
        };

        // Update our tracking variable
        latestMetadata = updatedMetadata;

        setEntityAssessmentMetadata(updatedMetadata);

        // Update the metadata in S3
        await updateEntityAssessmentMetadata(
          entityAssessmentId ?? "",
          updatedMetadata,
          authInfo.accessToken ?? null
        );
      }

      // After all chunks are processed, use the latest tracking variable
      const finalMetadata = {
        ...latestMetadata,
        is_generated: true,
        updated_at: new Date().toISOString(),
      };

      setEntityAssessmentMetadata(finalMetadata);

      await updateEntityAssessmentMetadata(
        entityAssessmentId ?? "",
        finalMetadata,
        authInfo.accessToken ?? null
      );
    } catch (error) {
      toast.error("Failed to generate assessment");
      console.error(error);
    }
    setGenerating(false);
    setGenerated(true);
  };

  const handleConfirmGenerate = () => {
    setIsGenerateDialogOpen(false);
    setGenerating(true);

    // Reset all values before starting generation
    setEntityName("");
    setContractEffectiveDate("");
    setServicesProvided("");
    setSelectedFunctions([]);
    setSelectedFunctionsExplanation("");
    setSelectedFunctionsCitations([]);
    setSelectedOversight([]);
    setSelectedOversightExplanation("");
    setSelectedOversightCitations([]);
    setSelectedImpactedEnrollee([]);
    setSelectedImpactedEnrolleeExplanation("");
    setSelectedImpactedEnrolleeCitations([]);
    setSelectedInteractedEnrollee([]);
    setSelectedInteractedEnrolleeExplanation("");
    setSelectedInteractedEnrolleeCitations([]);
    setSelectedDataAccess([]);
    setSelectedDataAccessExplanation("");
    setSelectedDataAccessCitations([]);
    setSelectedDecisionMakingAuthority(null);
    setSelectedDecisionMakingAuthorityExplanation("");
    setSelectedDecisionMakingAuthorityCitations([]);
    setSelectedLeadToFwa(null);
    setSelectedLeadToFwaExplanation("");
    setSelectedLeadToFwaCitations([]);
    setSelectedUseSubcontractor(null);
    setSelectedUseSubcontractorExplanation("");
    setSelectedUseSubcontractorCitations([]);
    setSelectedUseOffshore(null);
    setSelectedUseOffshoreExplanation("");
    setSelectedUseOffshoreCitations([]);
    setSelectedOffShoreAssessment(null);
    setSelectedOffShoreAssessmentExplanation("");
    setSelectedOffShoreAssessmentCitations([]);
    setSelectedAccessItNetwork(null);
    setSelectedAccessItNetworkExplanation("");
    setSelectedAccessItNetworkCitations([]);
    setSelectedMedicareDelegationClassification(null);
    setSelectedMedicareDelegationClassificationExplanation("");
    setSelectedMedicareDelegationClassificationCitations([]);
    setSelectedMediCalDelegationClassification(null);
    setSelectedMediCalDelegationClassificationExplanation("");
    setSelectedMediCalDelegationClassificationCitations([]);
    setRiskLevelMemberInteraction(null);
    setRiskLevelMemberInteractionExplanation("");
    setRiskLevelMemberInteractionCitations([]);
    setRiskLevelDecisionMakingAuthority(null);
    setRiskLevelDecisionMakingAuthorityExplanation("");
    setRiskLevelDecisionMakingAuthorityCitations([]);
    setRiskLevelMemberInformationAccess(null);
    setRiskLevelMemberInformationAccessExplanation("");
    setRiskLevelMemberInformationAccessCitations([]);
    setRiskLevelRegulatoryImpact(null);
    setRiskLevelRegulatoryImpactExplanation("");

    (async () => {
      try {
        const response = await generateEntityAssessment(
          entityAssessmentId ?? "",
          authInfo.accessToken ?? null
        );

        // Process each chunk as it arrives
        let latestMetadata = { ...entityAssessmentMetadata }; // Create outside the loop
        for await (const chunk of response) {
          // Update state based on the fields present in the chunk
          if (chunk.entity_name) setEntityName(chunk.entity_name);
          if (chunk.effective_date)
            setContractEffectiveDate(chunk.effective_date);
          if (chunk.service_provided)
            setServicesProvided(chunk.service_provided);

          if (chunk.functions_provided) {
            if (
              Array.isArray(chunk.functions_provided) &&
              chunk.functions_provided.length > 0 &&
              typeof chunk.functions_provided[0] === "object"
            ) {
              // If chunk contains full objects, use them directly
              setSelectedFunctions(chunk.functions_provided);
            } else {
              // If chunk contains just IDs, map to full objects
              const functionItems = functionOptions.filter((item) =>
                chunk.functions_provided.includes(item.id)
              );
              setSelectedFunctions(functionItems);
            }
            if (chunk.functions_provided_explanation) {
              setSelectedFunctionsExplanation(
                chunk.functions_provided_explanation
              );
            }
            if (chunk.functions_provided_citations) {
              setSelectedFunctionsCitations(chunk.functions_provided_citations);
            }
          }

          if (chunk.oversight_regulators) {
            const selectedOversightItems = lineOfBusinessOptions.filter(
              (option) => chunk.oversight_regulators.includes(option.id)
            );
            setSelectedOversight(
              selectedOversightItems.map(
                (item) => item.id as LineOfBusinessOption
              )
            );
            setSelectedOversightExplanation(
              chunk.oversight_regulators_explanation
            );
            setSelectedOversightCitations(chunk.oversight_regulators_citations);
          }

          if (chunk.enrollee_impact_lines_of_business) {
            const selectedImpactedEnrolleeItems = lineOfBusinessOptions.filter(
              (option) =>
                chunk.enrollee_impact_lines_of_business.includes(option.id)
            );
            setSelectedImpactedEnrollee(
              selectedImpactedEnrolleeItems.map(
                (item) => item.id as LineOfBusinessOption
              )
            );
            setSelectedImpactedEnrolleeExplanation(
              chunk.enrollee_impact_lines_of_business_explanation
            );
            setSelectedImpactedEnrolleeCitations(
              chunk.enrollee_impact_lines_of_business_citations
            );
          }

          if (chunk.enrollee_interaction_lines_of_business) {
            const selectedInteractedEnrolleeItems =
              lineOfBusinessOptions.filter((option) =>
                chunk.enrollee_interaction_lines_of_business.includes(option.id)
              );
            setSelectedInteractedEnrollee(
              selectedInteractedEnrolleeItems.map(
                (item) => item.id as LineOfBusinessOption
              )
            );
            setSelectedInteractedEnrolleeExplanation(
              chunk.enrollee_interaction_lines_of_business_explanation
            );
            setSelectedInteractedEnrolleeCitations(
              chunk.enrollee_interaction_lines_of_business_citations
            );
          }

          if (chunk.data_access) {
            if (
              Array.isArray(chunk.data_access) &&
              chunk.data_access.length > 0 &&
              typeof chunk.data_access[0] === "object"
            ) {
              // If metadata contains full objects, use them directly
              setSelectedDataAccess(chunk.data_access);
            } else {
              // If metadata contains just IDs, map to full objects
              const dataAccessItems = dataAccessOptions.filter((item) =>
                chunk.data_access.includes(item.id)
              );
              setSelectedDataAccess(dataAccessItems);
            }
            setSelectedDataAccessExplanation(chunk.data_access_explanation);
            setSelectedDataAccessCitations(chunk.data_access_citations);
          }

          if (chunk.decision_making_authority) {
            setSelectedDecisionMakingAuthority(
              chunk.decision_making_authority as YesNoOption
            );
            setSelectedDecisionMakingAuthorityExplanation(
              chunk.decision_making_authority_explanation
            );
            setSelectedDecisionMakingAuthorityCitations(
              chunk.decision_making_authority_citations
            );
          }

          if (chunk.lead_to_fwa) {
            setSelectedLeadToFwa(chunk.lead_to_fwa as YesNoOption);
            setSelectedLeadToFwaExplanation(chunk.lead_to_fwa_explanation);
            setSelectedLeadToFwaCitations(chunk.lead_to_fwa_citations);
          }

          if (chunk.use_subcontractor) {
            setSelectedUseSubcontractor(chunk.use_subcontractor as YesNoOption);
            setSelectedUseSubcontractorExplanation(
              chunk.use_subcontractor_explanation
            );
            setSelectedUseSubcontractorCitations(
              chunk.use_subcontractor_citations
            );
          }

          if (chunk.use_offshore) {
            setSelectedUseOffshore(chunk.use_offshore as YesNoOption);
            setSelectedUseOffshoreExplanation(chunk.use_offshore_explanation);
            setSelectedUseOffshoreCitations(chunk.use_offshore_citations);
          }

          if (chunk.off_shore_assessment) {
            setSelectedOffShoreAssessment(
              chunk.off_shore_assessment as YesNoOption
            );
            setSelectedOffShoreAssessmentExplanation(
              chunk.off_shore_assessment_explanation
            );
            setSelectedOffShoreAssessmentCitations(
              chunk.off_shore_assessment_citations
            );
          }

          if (chunk.access_it_network) {
            setSelectedAccessItNetwork(chunk.access_it_network as YesNoOption);
            setSelectedAccessItNetworkExplanation(
              chunk.access_it_network_explanation
            );
            setSelectedAccessItNetworkCitations(
              chunk.access_it_network_citations
            );
          }

          if (chunk.medicare_classification) {
            setSelectedMedicareDelegationClassification(
              chunk.medicare_classification as MedicareClassificationOption
            );
            setSelectedMedicareDelegationClassificationExplanation(
              chunk.medicare_classification_explanation
            );
            setSelectedMedicareDelegationClassificationCitations(
              chunk.medicare_classification_citations
            );
          }

          if (chunk.medi_cal_classification) {
            setSelectedMediCalDelegationClassification(
              chunk.medi_cal_classification as MediCalClassificationOption
            );
            setSelectedMediCalDelegationClassificationExplanation(
              chunk.medi_cal_classification_explanation
            );
            setSelectedMediCalDelegationClassificationCitations(
              chunk.medi_cal_classification_citations
            );
          }

          if (chunk.risk_member_interaction_impact) {
            setRiskLevelMemberInteraction(
              chunk.risk_member_interaction_impact as RiskLevel
            );
            setRiskLevelMemberInteractionExplanation(
              chunk.risk_member_interaction_impact_explanation
            );
            setRiskLevelMemberInteractionCitations(
              chunk.risk_member_interaction_impact_citations
            );
          }

          if (chunk.risk_decision_making_authority) {
            setRiskLevelDecisionMakingAuthority(
              chunk.risk_decision_making_authority as RiskLevel
            );
            setRiskLevelDecisionMakingAuthorityExplanation(
              chunk.risk_decision_making_authority_explanation
            );
            setRiskLevelDecisionMakingAuthorityCitations(
              chunk.risk_decision_making_authority_citations
            );
          }

          if (chunk.risk_member_information_access) {
            setRiskLevelMemberInformationAccess(
              chunk.risk_member_information_access as RiskLevel
            );
            setRiskLevelMemberInformationAccessExplanation(
              chunk.risk_member_information_access_explanation
            );
            setRiskLevelMemberInformationAccessCitations(
              chunk.risk_member_information_access_citations
            );
          }

          if (chunk.risk_regulatory_impact) {
            setRiskLevelRegulatoryImpact(
              chunk.risk_regulatory_impact as RiskLevel
            );
            setRiskLevelRegulatoryImpactExplanation(
              chunk.risk_regulatory_impact_explanation
            );
            setRiskLevelRegulatoryImpactCitations(
              chunk.risk_regulatory_impact_citations
            );
          }

          // Update metadata with the new assessment data
          const updatedMetadata = {
            ...latestMetadata, // Use the tracking variable instead
            ...chunk,
            // Basic info
            name: projectName,
            entity_name: chunk.entity_name || entityName,
            effective_date: chunk.effective_date || contractEffectiveDate,
            service_provided: chunk.service_provided || servicesProvided,

            // Functions and oversight
            functions_provided: chunk.functions_provided
              ? functionOptions.filter((item) =>
                  chunk.functions_provided.includes(item.id)
                )
              : selectedFunctions,
            functions_provided_explanation:
              chunk.functions_provided_explanation ||
              selectedFunctionsExplanation,
            functions_provided_citations: chunk.functions_provided_citations
              ? chunk.functions_provided_citations
              : selectedFunctionsCitations,

            oversight_regulators: chunk.oversight_regulators
              ? lineOfBusinessOptions.filter((option) =>
                  chunk.oversight_regulators.includes(option.id)
                )
              : selectedOversight,
            oversight_regulators_explanation:
              chunk.oversight_regulators_explanation ||
              selectedOversightExplanation,
            oversight_regulators_citations: chunk.oversight_regulators_citations
              ? chunk.oversight_regulators_citations
              : selectedOversightCitations,

            // Enrollee impact and interaction
            enrollee_impact_lines_of_business:
              chunk.enrollee_impact_lines_of_business
                ? lineOfBusinessOptions.filter((option) =>
                    chunk.enrollee_impact_lines_of_business.includes(option.id)
                  )
                : selectedImpactedEnrollee,
            enrollee_impact_lines_of_business_explanation:
              chunk.enrollee_impact_lines_of_business_explanation ||
              selectedImpactedEnrolleeExplanation,
            enrollee_impact_lines_of_business_citations:
              chunk.enrollee_impact_lines_of_business_citations
                ? chunk.enrollee_impact_lines_of_business_citations
                : selectedImpactedEnrolleeCitations,

            enrollee_interaction_lines_of_business:
              chunk.enrollee_interaction_lines_of_business
                ? lineOfBusinessOptions.filter((option) =>
                    chunk.enrollee_interaction_lines_of_business.includes(
                      option.id
                    )
                  )
                : selectedInteractedEnrollee,
            enrollee_interaction_lines_of_business_explanation:
              chunk.enrollee_interaction_lines_of_business_explanation ||
              selectedInteractedEnrolleeExplanation,
            enrollee_interaction_lines_of_business_citations:
              chunk.enrollee_interaction_lines_of_business_citations
                ? chunk.enrollee_interaction_lines_of_business_citations
                : selectedInteractedEnrolleeCitations,

            // Data access
            data_access: chunk.data_access
              ? dataAccessOptions.filter((item) =>
                  chunk.data_access.includes(item.id)
                )
              : selectedDataAccess,
            data_access_explanation:
              chunk.data_access_explanation || selectedDataAccessExplanation,
            data_access_citations:
              chunk.data_access_citations || selectedDataAccessCitations,

            // Decision making and authority
            decision_making_authority:
              chunk.decision_making_authority ||
              selectedDecisionMakingAuthority,
            decision_making_authority_explanation:
              chunk.decision_making_authority_explanation ||
              selectedDecisionMakingAuthorityExplanation,
            decision_making_authority_citations:
              chunk.decision_making_authority_citations
                ? chunk.decision_making_authority_citations
                : selectedDecisionMakingAuthorityCitations,

            // FWA and subcontractors
            lead_to_fwa: chunk.lead_to_fwa || selectedLeadToFwa,
            lead_to_fwa_explanation:
              chunk.lead_to_fwa_explanation || selectedLeadToFwaExplanation,
            lead_to_fwa_citations: chunk.lead_to_fwa_citations
              ? chunk.lead_to_fwa_citations
              : selectedLeadToFwaCitations,

            use_subcontractor:
              chunk.use_subcontractor || selectedUseSubcontractor,
            use_subcontractor_explanation:
              chunk.use_subcontractor_explanation ||
              selectedUseSubcontractorExplanation,
            use_subcontractor_citations: chunk.use_subcontractor_citations
              ? chunk.use_subcontractor_citations
              : selectedUseSubcontractorCitations,

            use_offshore: chunk.use_offshore || selectedUseOffshore,
            use_offshore_explanation:
              chunk.use_offshore_explanation || selectedUseOffshoreExplanation,
            use_offshore_citations: chunk.use_offshore_citations
              ? chunk.use_offshore_citations
              : selectedUseOffshoreCitations,

            off_shore_assessment:
              chunk.off_shore_assessment || selectedOffShoreAssessment,
            off_shore_assessment_explanation:
              chunk.off_shore_assessment_explanation ||
              selectedOffShoreAssessmentExplanation,
            off_shore_assessment_citations: chunk.off_shore_assessment_citations
              ? chunk.off_shore_assessment_citations
              : selectedOffShoreAssessmentCitations,

            // IT network access
            access_it_network:
              chunk.access_it_network || selectedAccessItNetwork,
            access_it_network_explanation:
              chunk.access_it_network_explanation ||
              selectedAccessItNetworkExplanation,
            access_it_network_citations: chunk.access_it_network_citations
              ? chunk.access_it_network_citations
              : selectedAccessItNetworkCitations,

            // Classifications
            medicare_classification:
              chunk.medicare_classification ||
              selectedMedicareDelegationClassification,
            medicare_classification_explanation:
              chunk.medicare_classification_explanation ||
              selectedMedicareDelegationClassificationExplanation,
            medicare_classification_citations:
              chunk.medicare_classification_citations
                ? chunk.medicare_classification_citations
                : selectedMedicareDelegationClassificationCitations,

            medi_cal_classification:
              chunk.medi_cal_classification ||
              selectedMediCalDelegationClassification,
            medi_cal_classification_explanation:
              chunk.medi_cal_classification_explanation ||
              selectedMediCalDelegationClassificationExplanation,
            medi_cal_classification_citations:
              chunk.medi_cal_classification_citations
                ? chunk.medi_cal_classification_citations
                : selectedMediCalDelegationClassificationCitations,

            // Risk assessments
            risk_member_interaction_impact:
              chunk.risk_member_interaction_impact ||
              riskLevelMemberInteraction,
            risk_member_interaction_impact_explanation:
              chunk.risk_member_interaction_impact_explanation ||
              riskLevelMemberInteractionExplanation,
            risk_member_interaction_impact_citations:
              chunk.risk_member_interaction_impact_citations
                ? chunk.risk_member_interaction_impact_citations
                : riskLevelMemberInteractionCitations,

            risk_decision_making_authority:
              chunk.risk_decision_making_authority ||
              riskLevelDecisionMakingAuthority,
            risk_decision_making_authority_explanation:
              chunk.risk_decision_making_authority_explanation ||
              riskLevelDecisionMakingAuthorityExplanation,
            risk_decision_making_authority_citations:
              chunk.risk_decision_making_authority_citations
                ? chunk.risk_decision_making_authority_citations
                : riskLevelDecisionMakingAuthorityCitations,

            risk_member_information_access:
              chunk.risk_member_information_access ||
              riskLevelMemberInformationAccess,
            risk_member_information_access_explanation:
              chunk.risk_member_information_access_explanation ||
              riskLevelMemberInformationAccessExplanation,
            risk_member_information_access_citations:
              chunk.risk_member_information_access_citations
                ? chunk.risk_member_information_access_citations
                : riskLevelMemberInformationAccessCitations,

            risk_regulatory_impact:
              chunk.risk_regulatory_impact || riskLevelRegulatoryImpact,
            risk_regulatory_impact_explanation:
              chunk.risk_regulatory_impact_explanation ||
              riskLevelRegulatoryImpactExplanation,
            risk_regulatory_impact_citations:
              chunk.risk_regulatory_impact_citations
                ? chunk.risk_regulatory_impact_citations
                : riskLevelRegulatoryImpactCitations,
          };

          // Update our tracking variable
          latestMetadata = updatedMetadata;

          setEntityAssessmentMetadata(updatedMetadata);

          // Update the metadata in S3
          await updateEntityAssessmentMetadata(
            entityAssessmentId ?? "",
            updatedMetadata,
            authInfo.accessToken ?? null
          );
        }

        // After all chunks are processed, use the latest tracking variable
        const finalMetadata = {
          ...latestMetadata,
          is_generated: true,
          updated_at: new Date().toISOString(),
        };

        setEntityAssessmentMetadata(finalMetadata);

        await updateEntityAssessmentMetadata(
          entityAssessmentId ?? "",
          finalMetadata,
          authInfo.accessToken ?? null
        );
      } catch (error) {
        toast.error("Failed to generate assessment");
        console.error(error);
      }
      setGenerating(false);
      setGenerated(true);
    })();
  };

  useEffect(() => {
    const initializeEntityAssessment = async () => {
      const id = await getEntityAssessmentDocTypeId(
        authInfo.accessToken ?? null
      );
      setEntityAssessmentDocTypeId(id);
      await checkEntityAssessmentPerms(id, authInfo.accessToken ?? null);
    };
    initializeEntityAssessment();
  }, [authInfo.accessToken]);

  useEffect(() => {
    const calculateOverallRiskScore = () => {
      const riskLevels = [
        riskLevelMemberInteraction,
        riskLevelDecisionMakingAuthority,
        riskLevelMemberInformationAccess,
        riskLevelRegulatoryImpact,
      ];
      const totalScore = riskLevels.reduce(
        (sum, level) => sum + getRiskScore(level),
        0
      );

      if (totalScore !== overallRiskScore) {
        setOverallRiskScore(totalScore);

        // Update metadata with the overall risk score using snake_case
        if (entityAssessmentId && entityAssessmentMetadata) {
          const updatedMetadata = {
            ...entityAssessmentMetadata,
            overall_risk_score: totalScore, // Use snake_case here
          };
          updateEntityAssessmentMetadata(
            entityAssessmentId,
            updatedMetadata,
            authInfo.accessToken ?? null
          ).catch((error) => {
            console.error("Failed to update metadata:", error);
          });
          setEntityAssessmentMetadata(updatedMetadata);
        }
      }
    };
    calculateOverallRiskScore();
  }, [
    riskLevelMemberInteraction,
    riskLevelDecisionMakingAuthority,
    riskLevelMemberInformationAccess,
    riskLevelRegulatoryImpact,
    entityAssessmentId,
    entityAssessmentMetadata,
    authInfo.accessToken,
    overallRiskScore, // Add overallRiskScore to dependencies
  ]);

  useEffect(() => {
    const fetchMetadata = async () => {
      if (entityAssessmentId) {
        const metadata = await getEntityAssessmentMetadata(
          entityAssessmentId,
          authInfo.accessToken ?? null
        );
        if (metadata) {
          setEntityAssessmentMetadata(metadata);
          setDocToView({
            docId: metadata.doc_ids[0],
          });
          setIsDocUploaded(true);

          // Populate project name from metadata
          if (metadata.name) setProjectName(metadata.name);

          // Populate owner from metadata
          if (metadata.owner_id) setSelectedOwnerId(metadata.owner_id);

          // Populate all form fields from metadata
          if (metadata.entity_name) setEntityName(metadata.entity_name);
          if (metadata.effective_date)
            setContractEffectiveDate(metadata.effective_date);
          if (metadata.service_provided)
            setServicesProvided(metadata.service_provided);

          // Functions and oversight
          if (metadata.functions_provided) {
            if (
              Array.isArray(metadata.functions_provided) &&
              metadata.functions_provided.length > 0 &&
              typeof metadata.functions_provided[0] === "object"
            ) {
              // If metadata contains full objects, use them directly
              setSelectedFunctions(metadata.functions_provided);
            } else {
              // If metadata contains just IDs, map to full objects
              const functionItems = functionOptions.filter((item) =>
                metadata.functions_provided.includes(item.id)
              );
              setSelectedFunctions(functionItems);
            }
          }
          if (metadata.functions_provided_explanation) {
            setSelectedFunctionsExplanation(
              metadata.functions_provided_explanation
            );
          }
          if (metadata.functions_provided_citations) {
            setSelectedFunctionsCitations(
              metadata.functions_provided_citations
            );
          }

          // Oversight regulators
          if (metadata.oversight_regulators) {
            setSelectedOversight(
              metadata.oversight_regulators.map(
                (item: { id: string; name: string }) =>
                  item.id as LineOfBusinessOption
              )
            );
          }
          if (metadata.oversight_regulators_explanation) {
            setSelectedOversightExplanation(
              metadata.oversight_regulators_explanation
            );
          }
          if (metadata.oversight_regulators_citations) {
            setSelectedOversightCitations(
              metadata.oversight_regulators_citations
            );
          }

          // Enrollee impact
          if (metadata.enrollee_impact_lines_of_business) {
            setSelectedImpactedEnrollee(
              metadata.enrollee_impact_lines_of_business.map(
                (item: { id: string; name: string }) =>
                  item.id as LineOfBusinessOption
              )
            );
          }
          if (metadata.enrollee_impact_lines_of_business_explanation) {
            setSelectedImpactedEnrolleeExplanation(
              metadata.enrollee_impact_lines_of_business_explanation
            );
          }
          if (metadata.enrollee_impact_lines_of_business_citations) {
            setSelectedImpactedEnrolleeCitations(
              metadata.enrollee_impact_lines_of_business_citations
            );
          }

          // Enrollee interaction
          if (metadata.enrollee_interaction_lines_of_business) {
            setSelectedInteractedEnrollee(
              metadata.enrollee_interaction_lines_of_business.map(
                (item: { id: string; name: string }) =>
                  item.id as LineOfBusinessOption
              )
            );
          }
          if (metadata.enrollee_interaction_lines_of_business_explanation) {
            setSelectedInteractedEnrolleeExplanation(
              metadata.enrollee_interaction_lines_of_business_explanation
            );
          }
          if (metadata.enrollee_interaction_lines_of_business_citations) {
            setSelectedInteractedEnrolleeCitations(
              metadata.enrollee_interaction_lines_of_business_citations
            );
          }

          // Data access
          if (metadata.data_access) {
            if (
              Array.isArray(metadata.data_access) &&
              metadata.data_access.length > 0 &&
              typeof metadata.data_access[0] === "object"
            ) {
              // If metadata contains full objects, use them directly
              setSelectedDataAccess(metadata.data_access);
            } else {
              // If metadata contains just IDs, map to full objects
              const dataAccessItems = dataAccessOptions.filter((item) =>
                metadata.data_access.includes(item.id)
              );
              setSelectedDataAccess(dataAccessItems);
            }
          }
          if (metadata.data_access_explanation) {
            setSelectedDataAccessExplanation(metadata.data_access_explanation);
          }
          if (metadata.data_access_citations) {
            setSelectedDataAccessCitations(metadata.data_access_citations);
          }

          // Decision making authority
          if (metadata.decision_making_authority) {
            setSelectedDecisionMakingAuthority(
              metadata.decision_making_authority as YesNoOption
            );
          }
          if (metadata.decision_making_authority_explanation) {
            setSelectedDecisionMakingAuthorityExplanation(
              metadata.decision_making_authority_explanation
            );
          }
          if (metadata.decision_making_authority_citations) {
            setSelectedDecisionMakingAuthorityCitations(
              metadata.decision_making_authority_citations
            );
          }

          // FWA
          if (metadata.lead_to_fwa) {
            setSelectedLeadToFwa(metadata.lead_to_fwa as YesNoOption);
          }
          if (metadata.lead_to_fwa_explanation) {
            setSelectedLeadToFwaExplanation(metadata.lead_to_fwa_explanation);
          }
          if (metadata.lead_to_fwa_citations) {
            setSelectedLeadToFwaCitations(metadata.lead_to_fwa_citations);
          }

          // Subcontractor
          if (metadata.use_subcontractor) {
            setSelectedUseSubcontractor(
              metadata.use_subcontractor as YesNoOption
            );
          }
          if (metadata.use_subcontractor_explanation) {
            setSelectedUseSubcontractorExplanation(
              metadata.use_subcontractor_explanation
            );
          }
          if (metadata.use_subcontractor_citations) {
            setSelectedUseSubcontractorCitations(
              metadata.use_subcontractor_citations
            );
          }

          // Offshore
          if (metadata.use_offshore) {
            setSelectedUseOffshore(metadata.use_offshore as YesNoOption);
          }
          if (metadata.use_offshore_explanation) {
            setSelectedUseOffshoreExplanation(
              metadata.use_offshore_explanation
            );
          }

          // Offshore assessment
          if (metadata.off_shore_assessment) {
            setSelectedOffShoreAssessment(
              metadata.off_shore_assessment as YesNoOption
            );
          }
          if (metadata.off_shore_assessment_explanation) {
            setSelectedOffShoreAssessmentExplanation(
              metadata.off_shore_assessment_explanation
            );
          }
          if (metadata.off_shore_assessment_citations) {
            setSelectedOffShoreAssessmentCitations(
              metadata.off_shore_assessment_citations
            );
          }

          // IT network
          if (metadata.access_it_network) {
            setSelectedAccessItNetwork(
              metadata.access_it_network as YesNoOption
            );
          }
          if (metadata.access_it_network_explanation) {
            setSelectedAccessItNetworkExplanation(
              metadata.access_it_network_explanation
            );
          }
          if (metadata.access_it_network_citations) {
            setSelectedAccessItNetworkCitations(
              metadata.access_it_network_citations
            );
          }

          // Medicare classification
          if (metadata.medicare_classification) {
            setSelectedMedicareDelegationClassification(
              metadata.medicare_classification as MedicareClassificationOption
            );
          }
          if (metadata.medicare_classification_explanation) {
            setSelectedMedicareDelegationClassificationExplanation(
              metadata.medicare_classification_explanation
            );
          }

          // Medi-Cal classification
          if (metadata.medi_cal_classification) {
            setSelectedMediCalDelegationClassification(
              metadata.medi_cal_classification as MediCalClassificationOption
            );
          }
          if (metadata.medi_cal_classification_explanation) {
            setSelectedMediCalDelegationClassificationExplanation(
              metadata.medi_cal_classification_explanation
            );
          }
          if (metadata.medi_cal_classification_citations) {
            setSelectedMediCalDelegationClassificationCitations(
              metadata.medi_cal_classification_citations
            );
          }

          // Risk assessments
          if (metadata.risk_member_interaction_impact) {
            setRiskLevelMemberInteraction(
              metadata.risk_member_interaction_impact as RiskLevel
            );
          }
          if (metadata.risk_member_interaction_impact_explanation) {
            setRiskLevelMemberInteractionExplanation(
              metadata.risk_member_interaction_impact_explanation
            );
          }
          if (metadata.risk_member_interaction_impact_citations) {
            setRiskLevelMemberInteractionCitations(
              metadata.risk_member_interaction_impact_citations
            );
          }

          if (metadata.risk_decision_making_authority) {
            setRiskLevelDecisionMakingAuthority(
              metadata.risk_decision_making_authority as RiskLevel
            );
          }
          if (metadata.risk_decision_making_authority_explanation) {
            setRiskLevelDecisionMakingAuthorityExplanation(
              metadata.risk_decision_making_authority_explanation
            );
          }

          if (metadata.risk_member_information_access) {
            setRiskLevelMemberInformationAccess(
              metadata.risk_member_information_access as RiskLevel
            );
          }
          if (metadata.risk_member_information_access_explanation) {
            setRiskLevelMemberInformationAccessExplanation(
              metadata.risk_member_information_access_explanation
            );
          }
          if (metadata.risk_member_information_access_citations) {
            setRiskLevelMemberInformationAccessCitations(
              metadata.risk_member_information_access_citations
            );
          }

          if (metadata.risk_regulatory_impact) {
            setRiskLevelRegulatoryImpact(
              metadata.risk_regulatory_impact as RiskLevel
            );
          }
          if (metadata.risk_regulatory_impact_explanation) {
            setRiskLevelRegulatoryImpactExplanation(
              metadata.risk_regulatory_impact_explanation
            );
          }
          if (metadata.risk_regulatory_impact_citations) {
            setRiskLevelRegulatoryImpactCitations(
              metadata.risk_regulatory_impact_citations
            );
          }
        }
      }
    };
    fetchMetadata();
  }, [entityAssessmentId, authInfo.accessToken]);

  // Add effect to update metadata when owner changes
  useEffect(() => {
    const updateMetadataWithOwner = async () => {
      if (entityAssessmentId && selectedOwnerId && entityAssessmentMetadata) {
        const updatedMetadata = {
          ...entityAssessmentMetadata,
          owner_id: selectedOwnerId,
        };
        await updateEntityAssessmentMetadata(
          entityAssessmentId,
          updatedMetadata,
          authInfo.accessToken ?? null
        );
        setEntityAssessmentMetadata(updatedMetadata);
      }
    };
    updateMetadataWithOwner();
  }, [selectedOwnerId, entityAssessmentId, authInfo.accessToken]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        setSelectedFile(acceptedFiles[0]); // Keep first file for display purposes
        setIsUploading(true);
        try {
          // Upload files first
          const uploadResult = await uploadEntityAssessmentFiles(
            acceptedFiles,
            entityAssessmentId ?? "",
            authInfo.accessToken ?? null
          );

          if (uploadResult.success) {
            await refreshDataSource(
              entityAssessmentDocTypeId,
              authInfo.accessToken ?? null
            );

            // Get document IDs by filenames
            const filenames = acceptedFiles.map((f) => f.name);
            const docIds = await getDocIdsByFilenames(
              filenames,
              authInfo.accessToken ?? null
            );

            // Handle initial upload vs subsequent uploads differently
            if (
              !entityAssessmentMetadata?.doc_ids ||
              entityAssessmentMetadata.doc_ids.length === 0
            ) {
              // Initial upload - set all metadata
              const metadata = {
                id: entityAssessmentId ?? "",
                name: acceptedFiles[0].name, // Use first file name as project name
                filenames: acceptedFiles.map((f) => f.name), // Store filenames as array
                doc_ids: docIds,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                owner_id: authInfo.user?.userId ?? "",
                is_generated: false,
              };

              // Set the project name
              setProjectName(acceptedFiles[0].name);

              // Set the owner
              setSelectedOwnerId(authInfo.user?.userId ?? "");

              setEntityAssessmentMetadata(metadata);

              await updateEntityAssessmentMetadata(
                entityAssessmentId ?? "",
                metadata,
                authInfo.accessToken ?? null
              );

              // Set the first document as the one to view initially
              if (docIds.length > 0) {
                setDocToView({
                  docId: docIds[0],
                });
              }
            } else {
              // Subsequent upload - only append new documents
              const metadata = {
                ...entityAssessmentMetadata,
                filenames: [
                  ...entityAssessmentMetadata.filenames,
                  ...acceptedFiles.map((f) => f.name),
                ],
                doc_ids: [...entityAssessmentMetadata.doc_ids, ...docIds],
                updated_at: new Date().toISOString(),
              };

              setEntityAssessmentMetadata(metadata);

              await updateEntityAssessmentMetadata(
                entityAssessmentId ?? "",
                metadata,
                authInfo.accessToken ?? null
              );

              // Switch to the first of the newly uploaded documents
              if (docIds.length > 0) {
                setDocToView({
                  docId: docIds[0],
                });
              }
            }

            // Show success toast after successful upload
            toast.success("File uploaded successfully");
          } else {
            toast.error("Failed to upload files");
            console.error(uploadResult.error);
          }
        } catch (error) {
          toast.error("Failed to upload files");
          console.error(error);
        } finally {
          setIsUploading(false);
          setIsDocUploaded(true);
        }
      }
    },
    [
      authInfo.accessToken,
      authInfo.user?.userId,
      entityAssessmentDocTypeId,
      entityAssessmentId,
      entityAssessmentMetadata,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    maxFiles: 5,
  });

  useEffect(() => {
    const initializeEntityAssessment = async () => {
      const id = await getEntityAssessmentDocTypeId(
        authInfo.accessToken ?? null
      );
      setEntityAssessmentDocTypeId(id);
    };
    initializeEntityAssessment();
  }, [authInfo.accessToken]);

  // Add effect to update metadata when assessment name changes
  useEffect(() => {
    const updateMetadataWithName = async () => {
      if (entityAssessmentId && projectName && entityAssessmentMetadata) {
        const updatedMetadata = {
          ...entityAssessmentMetadata,
          name: projectName,
        };
        await updateEntityAssessmentMetadata(
          entityAssessmentId,
          updatedMetadata,
          authInfo.accessToken ?? null
        );
        setEntityAssessmentMetadata(updatedMetadata);
      }
    };
    updateMetadataWithName();
  }, [projectName, entityAssessmentId, authInfo.accessToken]);

  const handleDocumentRemoval = async (index: number) => {
    // Prevent document removal if assessment is generated
    if (entityAssessmentMetadata?.is_generated) {
      toast.error("Documents cannot be removed after assessment generation");
      return;
    }

    // Create new arrays without the document at the current index
    const newFilenames =
      entityAssessmentMetadata?.filenames.filter(
        (_: string, i: number) => i !== index
      ) ?? [];
    const newDocIds =
      entityAssessmentMetadata?.doc_ids.filter(
        (_: string, i: number) => i !== index
      ) ?? [];

    // Create updated metadata
    const updatedMetadata = entityAssessmentMetadata
      ? {
          ...entityAssessmentMetadata,
          filenames: newFilenames,
          doc_ids: newDocIds,
          updated_at: new Date().toISOString(),
        }
      : null;

    try {
      if (updatedMetadata) {
        // Update metadata in S3
        await updateEntityAssessmentMetadata(
          entityAssessmentId ?? "",
          updatedMetadata,
          authInfo.accessToken ?? null
        );

        // Update local state
        setEntityAssessmentMetadata(updatedMetadata);

        // Always switch to the first remaining document after removal
        if (newDocIds.length > 0) {
          // Switch to the first remaining document
          setDocToView({
            docId: newDocIds[0],
          });
        } else {
          // No documents left
          setDocToView(null);
          setIsDocUploaded(false);
        }

        toast.success("Document removed successfully");
      }
    } catch (error: unknown) {
      console.error("Failed to remove document:", error);
      toast.error("Failed to remove document");
    }
  };

  const handleSetCitations = (citations: EntityAssessmentCitation[]) => {
    if (citations.length > 0) {
      // Calculate the next citation index (cycling through the array)
      const nextIndex = (currentCitationIndex + 1) % citations.length;
      setCurrentCitationIndex(nextIndex);

      // Set the document and page based on the current citation index
      setDocToView({
        docId: citations[nextIndex].doc_id,
      });
      setPageNumber(citations[nextIndex].page);

      // Set all citations for highlighting in the document
      const pdfCitations: PdfCitation[] = citations.map((citation) => ({
        match: citation.text, // Changed from text to match
        exactMatch: false,
        page: citation.page,
        match_index: citation.match_index,
        id: citation.id || "",
        className: "bg-highlight-default",
        citationTooltip: (
          <EntityAssessmentHighlightActions citationId={citation.id || ""} />
        ),
      }));
      setDocCitations(pdfCitations);
    }
  };

  return (
    <Layout pageName="Entity Assessment">
      <div className="space-y-2">
        <div className="space-y-1">
          <div className="flex items-center space-x-2"></div>
        </div>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={50}
            minSize={40}
            maxSize={70}
            id="doc-panel"
            order={2}
          >
            {docToView && entityAssessmentMetadata?.doc_ids && (
              <Tabs
                value={docToView.docId}
                onValueChange={(value) => {
                  setDocToView({
                    docId: value,
                  });
                }}
                className="w-full pl-4 pr-4"
              >
                <div className="flex items-center gap-4 mb-4">
                  <TabsList className="w-full bg-gray-300">
                    {entityAssessmentMetadata.doc_ids.map(
                      (docId: string, index: number) => (
                        <TabsTrigger
                          key={docId}
                          value={docId}
                          className="flex-1 flex items-center justify-between group"
                        >
                          <span>Document {index + 1}</span>
                          {entityAssessmentMetadata.doc_ids.length === 1 ? (
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <CrossCircledIcon
                                  className="ml-2 h-5 w-5 text-red-300 opacity-0 group-hover:opacity-100 transition-opacity cursor-not-allowed"
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </TooltipTrigger>
                              <TooltipContent>
                                Cannot remove. At least one document is required
                              </TooltipContent>
                            </Tooltip>
                          ) : entityAssessmentMetadata?.is_generated ? (
                            <Tooltip>
                              <TooltipTrigger>
                                <CrossCircledIcon
                                  className="ml-2 h-5 w-5 text-red-300 opacity-0 group-hover:opacity-100 transition-opacity cursor-not-allowed"
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </TooltipTrigger>
                              <TooltipContent>
                                Documents cannot be removed after assessment is
                                generated
                              </TooltipContent>
                            </Tooltip>
                          ) : (
                            <AlertDialog>
                              <AlertDialogTrigger asChild>
                                <CrossCircledIcon
                                  className="ml-2 h-5 w-5 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </AlertDialogTrigger>
                              <AlertDialogContent>
                                <AlertDialogHeader>
                                  <AlertDialogTitle className="flex flex-col gap-3">
                                    <span className="inline-flex items-center gap-2 px-2.5 py-1.5 rounded-md bg-slate-50 text-slate-700 border border-slate-200 text-sm w-fit">
                                      <FileIcon className="w-4 h-4 text-slate-500" />
                                      {
                                        entityAssessmentMetadata?.filenames[
                                          index
                                        ]
                                      }
                                    </span>
                                    Remove this document?
                                  </AlertDialogTitle>
                                  <AlertDialogDescription className="text-slate-600">
                                    This action cannot be undone.
                                  </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                  <AlertDialogCancel
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    Cancel
                                  </AlertDialogCancel>
                                  <AlertDialogAction
                                    onClick={async (e) => {
                                      e.stopPropagation();
                                      await handleDocumentRemoval(index);
                                    }}
                                    className="bg-red-600 hover:bg-red-700 focus:ring-red-600"
                                  >
                                    Remove
                                  </AlertDialogAction>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialog>
                          )}
                        </TabsTrigger>
                      )
                    )}
                  </TabsList>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            disabled={entityAssessmentMetadata.is_generated}
                          />
                          <Button
                            variant="secondary"
                            size="icon"
                            className="h-10 w-12 transition-all hover:bg-gray-400 hover:text-white"
                            disabled={entityAssessmentMetadata.is_generated}
                          >
                            {isUploading ? (
                              <ReloadIcon className="h-4 w-4 animate-spin" />
                            ) : (
                              <PlusIcon className="h-4 w-4" />
                            )}
                          </Button>
                        </div>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      {entityAssessmentMetadata.is_generated
                        ? "Documents cannot be added after assessment is generated"
                        : "Add more documents"}
                    </TooltipContent>
                  </Tooltip>
                </div>
                {entityAssessmentMetadata.doc_ids.map((docId: string) => (
                  <TabsContent key={docId} value={docId}>
                    <DocViewerCitation
                      docId={docId}
                      className="h-[calc(100vh-280px)] mr-4"
                      hideAtlasWidget={false}
                      onCitationsUpdate={(pdfCitation: PdfCitation) => {
                        setDocCitations((prev) => {
                          const newCitation: PdfCitation = {
                            citationTooltip: (
                              <EntityAssessmentHighlightActions
                                citationId={pdfCitation.id || "new-citation"}
                              />
                            ),
                            ...pdfCitation,
                          };
                          let prevCitationExists = false;
                          let citations: PdfCitation[] = prev.map(
                            (citation: PdfCitation) => {
                              if (citation.id === pdfCitation.id) {
                                prevCitationExists = true;
                                return newCitation;
                              } else {
                                return citation;
                              }
                            }
                          );
                          if (!prevCitationExists) {
                            citations.push(newCitation);
                          }
                          return citations;
                        });
                      }}
                    ></DocViewerCitation>
                  </TabsContent>
                ))}
              </Tabs>
            )}
            {!docToView && (
              <div
                {...getRootProps()}
                className={cn(
                  "h-[calc(100vh-130px)] flex items-center justify-center cursor-pointer",
                  "border-2 border-dashed rounded-lg mr-4",
                  isDragActive
                    ? "border-primary bg-primary/10"
                    : "border-gray-300"
                )}
              >
                <input {...getInputProps()} />
                <div className="flex flex-col items-center gap-4">
                  <div className="text-muted-foreground flex items-center gap-2">
                    {isUploading ? (
                      <>Uploading and indexing contracts...</>
                    ) : isDragActive ? (
                      "Drop contracts here..."
                    ) : (
                      <div className="flex flex-col items-center gap-2">
                        Drag & drop entity contracts here, or click to select
                        <div className="text-sm text-muted-foreground mt-1 italic">
                          (up to 5 files or 30 pages)
                        </div>
                      </div>
                    )}
                  </div>
                  {isUploading && (
                    <div className="text-sm text-muted-foreground">
                      <UploadProgressDisplay />
                    </div>
                  )}
                </div>
              </div>
            )}
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel
            defaultSize={50}
            minSize={30}
            maxSize={60}
            id="asessment-panel"
            order={3}
          >
            <div className="flex-column items-center space-y-8 mt-4 ml-6 pl-2 pr-8 h-[calc(100vh-150px)] overflow-y-auto">
              <div className="flex-col items-center space-y-4">
                <div className="flex-col items-center space-y-4">
                  <div className="flex items-start gap-4">
                    <div className="flex-1 items-center space-y-2">
                      <Button
                        className="w-full h-10 px-6"
                        variant="default"
                        onClick={handleGenerateAssessment}
                        disabled={generating || !isDocUploaded}
                      >
                        {generating ? (
                          <>
                            <div className="flex items-center">
                              <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                              Generating...
                            </div>
                          </>
                        ) : (
                          <>✨ Generate Assessment</>
                        )}
                      </Button>
                      <div className="flex items-center w-full">
                        {generating ? (
                          <GenerationProgressDisplay
                            entityName={entityName}
                            contractEffectiveDate={contractEffectiveDate}
                            servicesProvided={servicesProvided}
                            selectedFunctions={selectedFunctions}
                            selectedOversight={selectedOversight}
                            selectedImpactedEnrollee={selectedImpactedEnrollee}
                            selectedInteractedEnrollee={
                              selectedInteractedEnrollee
                            }
                            selectedDecisionMakingAuthority={
                              selectedDecisionMakingAuthority
                            }
                            selectedLeadToFwa={selectedLeadToFwa}
                            selectedUseSubcontractor={selectedUseSubcontractor}
                            selectedUseOffshore={selectedUseOffshore}
                            selectedOffShoreAssessment={
                              selectedOffShoreAssessment
                            }
                            selectedAccessItNetwork={selectedAccessItNetwork}
                            selectedMedicareDelegationClassification={
                              selectedMedicareDelegationClassification
                            }
                            selectedMediCalDelegationClassification={
                              selectedMediCalDelegationClassification
                            }
                            riskLevelMemberInteraction={
                              riskLevelMemberInteraction
                            }
                            riskLevelDecisionMakingAuthority={
                              riskLevelDecisionMakingAuthority
                            }
                            riskLevelMemberInformationAccess={
                              riskLevelMemberInformationAccess
                            }
                            riskLevelRegulatoryImpact={
                              riskLevelRegulatoryImpact
                            }
                          />
                        ) : (
                          <div className="h-[16px] w-full" />
                        )}
                      </div>
                    </div>
                    <Button
                      variant="outline"
                      onClick={handleExportAssessment}
                      className="h-10 px-6 border-2"
                      disabled={exportLoading}
                    >
                      {exportLoading ? (
                        <>
                          <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                          Exporting...
                        </>
                      ) : (
                        <>Export</>
                      )}
                    </Button>
                  </div>
                </div>
                <div className="flex-col items-center space-y-4">
                  <div className="flex items-center space-x-2">
                    <Label>
                      Project Name<span className="text-red-500">*</span>
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Input
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                      className="max-w-[400px]"
                    />
                  </div>
                </div>
                <div className="flex-col items-center space-y-4">
                  <div className="flex items-center space-x-2">
                    <Label>
                      Created By<span className="text-red-500">*</span>
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Select
                      value={selectedOwnerId}
                      onValueChange={setSelectedOwnerId}
                    >
                      <SelectTrigger className="w-[400px] bg-white">
                        <SelectValue placeholder="Select a user" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {simpleUsers
                            .filter(
                              (user) =>
                                !user.first_name?.startsWith("Assistant") &&
                                !user.first_name?.startsWith("Agent")
                            )
                            .sort((a, b) => {
                              // Handle cases where first_name might be undefined
                              const nameA = (a.first_name || "").toLowerCase();
                              const nameB = (b.first_name || "").toLowerCase();
                              return nameA.localeCompare(nameB);
                            })
                            .map((user): JSX.Element => {
                              const displayName =
                                user.first_name || user.last_name
                                  ? `${user.first_name} ${user.last_name}`.trim()
                                  : user.email;
                              return (
                                <SelectItem key={user.id} value={user.id}>
                                  {displayName}
                                </SelectItem>
                              );
                            })}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <Separator />
                <div className="flex-col items-center space-y-4">
                  <div className="flex items-center space-x-2">
                    <Label>
                      Entity Name<span className="text-red-500">*</span>
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Input
                      value={entityName}
                      onChange={(e) => {
                        // Update local state
                        setEntityName(e.target.value);

                        // Update metadata in S3
                        try {
                          const metadata = {
                            entity_name: e.target.value,
                          };
                          updateEntityAssessmentMetadata(
                            entityAssessmentId ?? "",
                            metadata,
                            authInfo.accessToken ?? null
                          ).catch((error) => {
                            console.error("Failed to update metadata:", error);
                          });
                        } catch (error) {
                          console.error("Failed to update metadata:", error);
                        }
                      }}
                      className="max-w-[400px]"
                    />
                  </div>
                </div>
                <div className="flex-col items-center space-y-4">
                  <div className="flex items-center space-x-2">
                    <Label>
                      Effective Date
                      <span className="text-red-500">*</span>
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Input
                      value={contractEffectiveDate}
                      onChange={(e) => {
                        // Update local state
                        setContractEffectiveDate(e.target.value);

                        // Update metadata in S3
                        try {
                          const metadata = {
                            effective_date: e.target.value,
                          };
                          updateEntityAssessmentMetadata(
                            entityAssessmentId ?? "",
                            metadata,
                            authInfo.accessToken ?? null
                          ).catch((error) => {
                            console.error("Failed to update metadata:", error);
                          });
                        } catch (error) {
                          console.error("Failed to update metadata:", error);
                        }
                      }}
                      className="max-w-[400px]"
                    />
                  </div>
                </div>
                <Separator />
                <div className="text-2xl font-semibold text-gray-900">
                  Entity Assessment
                </div>
                <div className="flex-col items-center space-y-4">
                  <div className="flex-col items-center">
                    <Label>
                      What services will be provided by the entity?
                      <span className="text-red-500">*</span>
                    </Label>
                    <p className="text-sm italic text-gray-400">
                      Be specific and comprehensive.
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Textarea
                      value={servicesProvided}
                      onChange={(e) => {
                        // Update local state
                        setServicesProvided(e.target.value);

                        // Update metadata in S3
                        try {
                          const metadata = {
                            service_provided: e.target.value,
                          };
                          updateEntityAssessmentMetadata(
                            entityAssessmentId ?? "",
                            metadata,
                            authInfo.accessToken ?? null
                          ).catch((error) => {
                            console.error("Failed to update metadata:", error);
                          });
                        } catch (error) {
                          console.error("Failed to update metadata:", error);
                        }
                      }}
                      className=" min-h-[200px]"
                      placeholder="Describe the services..."
                    />
                  </div>
                </div>
                <div className="flex-col items-center space-y-12">
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        Will the entity perform one or more of the following
                        functions?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400">
                        Select all that apply. Selection of any of the following
                        automatically requires formal managed care plan
                        oversight.
                      </p>
                    </div>
                    <div className="flex items-center space-x-4">
                      <MultiSelectControl
                        title="Select options"
                        items={functionOptions}
                        selectedItems={selectedFunctions}
                        selectItem={(item, isSelected) => {
                          const newSelectedFunctions = isSelected
                            ? [...selectedFunctions, item]
                            : selectedFunctions.filter((f) => f.id !== item.id);

                          // Update local state
                          setSelectedFunctions(newSelectedFunctions);

                          // Update metadata in S3
                          try {
                            const metadata = {
                              functions_provided: newSelectedFunctions.map(
                                (item) => ({
                                  id: item.id,
                                  name: item.name,
                                })
                              ),
                            };
                            updateEntityAssessmentMetadata(
                              entityAssessmentId ?? "",
                              metadata,
                              authInfo.accessToken ?? null
                            ).catch((error) => {
                              console.error(
                                "Failed to update metadata:",
                                error
                              );
                            });
                          } catch (error) {
                            console.error("Failed to update metadata:", error);
                          }
                        }}
                        clearSelectedItems={() => {
                          // Update local state
                          setSelectedFunctions([]);

                          // Update metadata in S3
                          try {
                            const metadata = {
                              functions_provided: [],
                            };
                            updateEntityAssessmentMetadata(
                              entityAssessmentId ?? "",
                              metadata,
                              authInfo.accessToken ?? null
                            ).catch((error) => {
                              console.error(
                                "Failed to update metadata:",
                                error
                              );
                            });
                          } catch (error) {
                            console.error("Failed to update metadata:", error);
                          }
                        }}
                      />
                      <div className="flex w-5 items-center justify-center">
                        {selectedFunctionsExplanation && (
                          <Tooltip>
                            <TooltipTrigger>
                              <QuestionMarkCircledIcon
                                className="h-5 w-5 text-muted-foreground cursor-pointer"
                                onClick={() =>
                                  selectedFunctionsCitations.length > 0 &&
                                  handleSetCitations(selectedFunctionsCitations)
                                }
                              />
                            </TooltipTrigger>
                            <TooltipContent className="max-w-[500px] text-sm">
                              {selectedFunctionsExplanation}
                            </TooltipContent>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        Will the service provided be under the oversight of any
                        of the following?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400">
                        Select all that apply.
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardLineOfBusinessSelector
                        lineOfBusiness={selectedOversight}
                        handleLineOfBusinessChange={setSelectedOversight}
                        explanation={selectedOversightExplanation}
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="oversight_regulators"
                        accessToken={authInfo.accessToken ?? null}
                        citations={selectedOversightCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        Would the delegated function directly impact an enrollee
                        (e.g. an enrollee's health, safety, welfare, or access
                        to covered services) under any of the following?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400">
                        Select all that apply.
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardLineOfBusinessSelector
                        lineOfBusiness={selectedImpactedEnrollee}
                        handleLineOfBusinessChange={setSelectedImpactedEnrollee}
                        explanation={selectedImpactedEnrolleeExplanation}
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="enrollee_impact_lines_of_business"
                        accessToken={authInfo.accessToken ?? null}
                        citations={selectedImpactedEnrolleeCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        Would the entity interact directly with an enrollee
                        under any of the following?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400">
                        Select all that apply.
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardLineOfBusinessSelector
                        lineOfBusiness={selectedInteractedEnrollee}
                        handleLineOfBusinessChange={
                          setSelectedInteractedEnrollee
                        }
                        explanation={selectedInteractedEnrolleeExplanation}
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="enrollee_interaction_lines_of_business"
                        accessToken={authInfo.accessToken ?? null}
                        citations={selectedInteractedEnrolleeCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        Would the entity have access to any of the following for
                        enrollee's?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400">
                        Select all that apply.
                      </p>
                    </div>
                    <div className="flex items-center space-x-4">
                      <MultiSelectControl
                        title="Select options"
                        items={dataAccessOptions}
                        selectedItems={selectedDataAccess}
                        selectItem={(item, isSelected) => {
                          const newSelectedDataAccess = isSelected
                            ? [...selectedDataAccess, item]
                            : selectedDataAccess.filter(
                                (f) => f.id !== item.id
                              );

                          // Update local state
                          setSelectedDataAccess(newSelectedDataAccess);

                          // Update metadata in S3
                          try {
                            const metadata = {
                              data_access: newSelectedDataAccess.map(
                                (item) => ({
                                  id: item.id,
                                  name: item.name,
                                })
                              ),
                            };
                            updateEntityAssessmentMetadata(
                              entityAssessmentId ?? "",
                              metadata,
                              authInfo.accessToken ?? null
                            ).catch((error) => {
                              console.error(
                                "Failed to update metadata:",
                                error
                              );
                            });
                          } catch (error) {
                            console.error("Failed to update metadata:", error);
                          }
                        }}
                        clearSelectedItems={() => {
                          // Update local state
                          setSelectedDataAccess([]);

                          // Update metadata in S3
                          try {
                            const metadata = {
                              data_access: [],
                            };
                            updateEntityAssessmentMetadata(
                              entityAssessmentId ?? "",
                              metadata,
                              authInfo.accessToken ?? null
                            ).catch((error) => {
                              console.error(
                                "Failed to update metadata:",
                                error
                              );
                            });
                          } catch (error) {
                            console.error("Failed to update metadata:", error);
                          }
                        }}
                      />
                      <div className="flex w-5 items-center justify-center">
                        {selectedDataAccessExplanation && (
                          <Tooltip>
                            <TooltipTrigger>
                              <QuestionMarkCircledIcon
                                className="h-5 w-5 text-muted-foreground cursor-pointer"
                                onClick={() =>
                                  selectedDataAccessCitations.length > 0 &&
                                  handleSetCitations(
                                    selectedDataAccessCitations
                                  )
                                }
                              />
                            </TooltipTrigger>
                            <TooltipContent className="max-w-[500px] text-sm">
                              {selectedDataAccessExplanation}
                            </TooltipContent>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        Would the entity be authorized to make decisions on
                        behalf of the Managed Care Plan?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400">
                        e.g. have the authority to approve or deny health care
                        services, manage provider network selections, determine
                        claims processing rules, or make clinical policy
                        decisions on behalf of the Managed Care Plan?
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardYesNoSelector
                        yesNo={selectedDecisionMakingAuthority}
                        handleYesNoChange={setSelectedDecisionMakingAuthority}
                        explanation={selectedDecisionMakingAuthorityExplanation}
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="decision_making_authority"
                        accessToken={authInfo.accessToken ?? null}
                        citations={selectedDecisionMakingAuthorityCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        Could the entity's services lead to potential fraud,
                        waste or abuse specific to healthcare?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400">
                        e.g. healthcare claims payments and/or managed care plan
                        covered services.
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardYesNoSelector
                        yesNo={selectedLeadToFwa}
                        handleYesNoChange={setSelectedLeadToFwa}
                        explanation={selectedLeadToFwaExplanation}
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="lead_to_fwa"
                        accessToken={authInfo.accessToken ?? null}
                        citations={selectedLeadToFwaCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        Will the entity use subcontractors to support the
                        services performed?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400">
                        If yes, additional information will be required for
                        contracting purposes related to subcontractors.
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardYesNoSelector
                        yesNo={selectedUseSubcontractor}
                        handleYesNoChange={setSelectedUseSubcontractor}
                        explanation={selectedUseSubcontractorExplanation}
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="use_subcontractor"
                        accessToken={authInfo.accessToken ?? null}
                        citations={selectedUseSubcontractorCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        Does the vendor use off-shore subcontractors?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400">
                        If yes, additional information will be required for
                        contracting purposes related to off-shoring.
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardYesNoSelector
                        yesNo={selectedUseOffshore}
                        handleYesNoChange={setSelectedUseOffshore}
                        explanation={selectedUseOffshoreExplanation}
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="use_offshore"
                        accessToken={authInfo.accessToken ?? null}
                        citations={selectedUseOffshoreCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        Has the Off-shore Subcontractor Assessment Form been
                        completed and submitted to Compliance?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400">
                        This form must be completed in advance of contracting.
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardYesNoSelector
                        yesNo={selectedOffShoreAssessment}
                        handleYesNoChange={setSelectedOffShoreAssessment}
                        explanation={selectedOffShoreAssessmentExplanation}
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="off_shore_assessment"
                        accessToken={authInfo.accessToken ?? null}
                        citations={selectedOffShoreAssessmentCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        Will the entity need access to the Managed Care Plan's
                        IT network?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400"></p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardYesNoSelector
                        yesNo={selectedAccessItNetwork}
                        handleYesNoChange={setSelectedAccessItNetwork}
                        explanation={selectedAccessItNetworkExplanation}
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="access_it_network"
                        accessToken={authInfo.accessToken ?? null}
                        citations={selectedAccessItNetworkCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                </div>
                <Separator />
                <div className="text-2xl font-semibold text-gray-900">
                  Delegation Classification
                </div>
                <div className="flex-col items-center space-y-10">
                  <div className="flex-col items-center space-y-4">
                    <div className="flex-col items-center">
                      <Label>
                        What classification do you recommend for the entity
                        under Medicare's oversight?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400"></p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardMedicareClassificationSelector
                        medicareClassification={
                          selectedMedicareDelegationClassification
                        }
                        handleMedicareClassificationChange={(value) =>
                          setSelectedMedicareDelegationClassification(value)
                        }
                        explanation={
                          selectedMedicareDelegationClassificationExplanation
                        }
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="medicare_classification"
                        accessToken={authInfo.accessToken ?? null}
                        citations={
                          selectedMedicareDelegationClassificationCitations
                        }
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-2">
                    <div className="flex-col items-center">
                      <Label>
                        What classification do you recommend for the entity
                        under Medi-Cal's oversight?
                        <span className="text-red-500">*</span>
                      </Label>
                      <p className="text-sm italic text-gray-400"></p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardMediCalClassificationSelector
                        mediCalClassification={
                          selectedMediCalDelegationClassification
                        }
                        handleMediCalClassificationChange={(value) =>
                          setSelectedMediCalDelegationClassification(value)
                        }
                        explanation={
                          selectedMediCalDelegationClassificationExplanation
                        }
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="medi_cal_classification"
                        accessToken={authInfo.accessToken ?? null}
                        citations={
                          selectedMediCalDelegationClassificationCitations
                        }
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                </div>
                <Separator />
                <div className="flex-col items-center space-y-10">
                  <div className="text-2xl font-semibold text-gray-900">
                    Risk Assessment
                  </div>
                  <div className="flex-col items-center space-y-2">
                    <div className="flex-col items-center">
                      <div className="flex items-center gap-2">
                        <Label>
                          Member Interaction / Impact
                          <span className="text-red-500">*</span>
                        </Label>
                        <Tooltip>
                          <TooltipTrigger>
                            <InfoCircledIcon className="h-4 w-4 text-muted-foreground" />
                          </TooltipTrigger>
                          <TooltipContent className="max-w-[500px] text-sm">
                            This risk factor evaluates the amount of interaction
                            the FDR will have directly with members and the
                            amount of the delegated responsibilities will
                            directly affect members. This delegation can expose
                            the member to harm and the company to issues that
                            could result in enforcement actions by the
                            government/regulator and may affect the enrollment,
                            public opinion, and other contracts that could
                            result in adverse publicity.
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardRiskLevelSelector
                        riskLevel={riskLevelMemberInteraction}
                        handleRiskLevelChange={setRiskLevelMemberInteraction}
                        explanation={riskLevelMemberInteractionExplanation}
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="risk_member_interaction_impact"
                        accessToken={authInfo.accessToken ?? null}
                        citations={riskLevelMemberInteractionCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-2">
                    <div className="flex-col items-center">
                      <div className="flex items-center gap-2">
                        <Label>
                          Decision-Making Authority
                          <span className="text-red-500">*</span>
                        </Label>
                        <Tooltip>
                          <TooltipTrigger>
                            <InfoCircledIcon className="h-4 w-4 text-muted-foreground" />
                          </TooltipTrigger>
                          <TooltipContent className="max-w-[500px] text-sm">
                            This risk factor evaluates the extent of
                            decision-making authority granted to the vendor
                            concerning delegated responsibilities on behalf of
                            the company. An increase in the vendor's authority
                            heightens the risk of non-compliance, fraud, waste,
                            and abuse (FWA), which could adversely impact the
                            company. Such risks may lead to enforcement actions
                            by government regulators, resulting in financial
                            penalties and potential damage to the company's
                            public reputation.
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardRiskLevelSelector
                        riskLevel={riskLevelDecisionMakingAuthority}
                        handleRiskLevelChange={
                          setRiskLevelDecisionMakingAuthority
                        }
                        explanation={
                          riskLevelDecisionMakingAuthorityExplanation
                        }
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="risk_decision_making_authority"
                        accessToken={authInfo.accessToken ?? null}
                        citations={riskLevelDecisionMakingAuthorityCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-2">
                    <div className="flex-col items-center">
                      <div className="flex items-center gap-2">
                        <Label>
                          Member Information Access
                          <span className="text-red-500">*</span>
                        </Label>
                        <Tooltip>
                          <TooltipTrigger>
                            <InfoCircledIcon className="h-4 w-4 text-muted-foreground" />
                          </TooltipTrigger>
                          <TooltipContent className="max-w-[500px] text-sm">
                            This risk addresses the amount of member information
                            that the vendor has access to and the nature of the
                            information. Vendors with Protected Health
                            Information (PHI) pose a higher risk of
                            non-compliance or potential FWA, which could result
                            in enforcement actions by government/regulators and
                            could result in financial penalties and negative
                            public opinion.
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardRiskLevelSelector
                        riskLevel={riskLevelMemberInformationAccess}
                        handleRiskLevelChange={
                          setRiskLevelMemberInformationAccess
                        }
                        explanation={
                          riskLevelMemberInformationAccessExplanation
                        }
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="risk_member_information_access"
                        accessToken={authInfo.accessToken ?? null}
                        citations={riskLevelMemberInformationAccessCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-2">
                    <div className="flex-col items-center">
                      <div className="flex items-center gap-2">
                        <Label>
                          Regulatory Impact
                          <span className="text-red-500">*</span>
                        </Label>
                        <Tooltip>
                          <TooltipTrigger>
                            <InfoCircledIcon className="h-4 w-4 text-muted-foreground" />
                          </TooltipTrigger>
                          <TooltipContent className="max-w-[500px] text-sm">
                            This risk factor considers possible risks associated
                            with the operational area to which the process is
                            subject to review by the regulatory agencies, as
                            well the amount to which violations to those
                            regulations would result notices of non-compliance,
                            sanctions, or litigation. This risk can expose the
                            corporation to issues that could result in
                            enforcement actions by the government/regulator and
                            may affect the enrollment, public opinion,
                            relationship with suppliers, and other contracts
                            that could result in adverse publicity.
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <StandardRiskLevelSelector
                        riskLevel={riskLevelRegulatoryImpact}
                        handleRiskLevelChange={setRiskLevelRegulatoryImpact}
                        explanation={riskLevelRegulatoryImpactExplanation}
                        entityAssessmentId={entityAssessmentId ?? ""}
                        metadataKey="risk_regulatory_impact"
                        accessToken={authInfo.accessToken ?? null}
                        citations={riskLevelRegulatoryImpactCitations}
                        handleSetCitations={handleSetCitations}
                      />
                    </div>
                  </div>
                  <div className="flex-col items-center space-y-2">
                    <div className="flex-col items-center">
                      <div className="flex items-center gap-2">
                        <Label>Overall Risk Ranking</Label>
                        <Tooltip>
                          <TooltipTrigger>
                            <InfoCircledIcon className="h-4 w-4 text-muted-foreground" />
                          </TooltipTrigger>
                          <TooltipContent className="max-w-[500px] text-sm">
                            High = Total score greater than 90
                            <br />
                            Moderate = Total score greater than 50
                            <br />
                            Low = Total score less than 50
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 pt-2">
                      {riskLevelMemberInteraction !== null &&
                        riskLevelDecisionMakingAuthority !== null &&
                        riskLevelMemberInformationAccess !== null &&
                        riskLevelRegulatoryImpact !== null && (
                          <Badge
                            className={`font-semibold px-4 py-1 rounded-full text-md items-center justify-center ${
                              overallRiskScore >= 90 && overallRiskScore <= 120
                                ? "bg-red-100 text-red-700 border border-red-300"
                                : overallRiskScore >= 50 &&
                                    overallRiskScore <= 89
                                  ? "bg-yellow-100 text-yellow-700 border border-yellow-300"
                                  : overallRiskScore >= 10 &&
                                      overallRiskScore <= 49
                                    ? "bg-green-100 text-green-700 border border-green-300"
                                    : ""
                            }`}
                            style={{ pointerEvents: "none" }}
                          >
                            {overallRiskScore >= 90 && overallRiskScore <= 120
                              ? `High (${overallRiskScore})`
                              : overallRiskScore >= 50 && overallRiskScore <= 89
                                ? `Moderate (${overallRiskScore})`
                                : overallRiskScore >= 10 &&
                                    overallRiskScore <= 49
                                  ? `Low (${overallRiskScore})`
                                  : overallRiskScore}
                          </Badge>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
      <AlertDialog
        open={isGenerateDialogOpen}
        onOpenChange={setIsGenerateDialogOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirm Generation</AlertDialogTitle>
            <AlertDialogDescription>
              Warning: Generating a new assessment will overwrite any existing
              data. Are you sure you want to proceed?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmGenerate}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Layout>
  );
};

const UploadProgressDisplay: React.FC = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress >= 100) return;

    const timer = setInterval(() => {
      setProgress((prev) => {
        const increment = Math.floor(Math.random() * 2) + 1;
        const newProgress = Math.min(prev + increment, 100);
        return newProgress;
      });
    }, 200);

    return () => clearInterval(timer);
  }, [progress]);

  return (
    <div className="flex flex-col gap-2">
      <Progress value={progress} className="w-[200px]" />
      <div className="text-center">{progress}%</div>
    </div>
  );
};

interface GenerationProgressDisplayProps {
  entityName: string;
  contractEffectiveDate: string;
  servicesProvided: string;
  selectedFunctions: Array<{ id: string; name: string }>;
  selectedOversight: LineOfBusinessOption[];
  selectedImpactedEnrollee: LineOfBusinessOption[];
  selectedInteractedEnrollee: LineOfBusinessOption[];
  selectedDecisionMakingAuthority: YesNoOption;
  selectedLeadToFwa: YesNoOption;
  selectedUseSubcontractor: YesNoOption;
  selectedUseOffshore: YesNoOption;
  selectedOffShoreAssessment: YesNoOption;
  selectedAccessItNetwork: YesNoOption;
  selectedMedicareDelegationClassification: MedicareClassificationOption;
  selectedMediCalDelegationClassification: MediCalClassificationOption;
  riskLevelMemberInteraction: RiskLevel;
  riskLevelDecisionMakingAuthority: RiskLevel;
  riskLevelMemberInformationAccess: RiskLevel;
  riskLevelRegulatoryImpact: RiskLevel;
}

const GenerationProgressDisplay: React.FC<GenerationProgressDisplayProps> = (
  props
) => {
  const [progress, setProgress] = useState(0);

  // Calculate progress based on filled fields
  useEffect(() => {
    const calculateProgress = () => {
      let totalFields = 0;
      let filledFields = 0;

      // Basic info fields
      if (props.entityName) filledFields++;
      if (props.contractEffectiveDate) filledFields++;
      if (props.servicesProvided) filledFields++;
      totalFields += 3;

      // Functions and oversight
      if (props.selectedFunctions.length > 0) filledFields++;
      if (props.selectedOversight.length > 0) filledFields++;
      totalFields += 2;

      // Enrollee impact and interaction
      if (props.selectedImpactedEnrollee.length > 0) filledFields++;
      if (props.selectedInteractedEnrollee.length > 0) filledFields++;
      totalFields += 2;

      // Decision making and FWA
      if (props.selectedDecisionMakingAuthority !== null) filledFields++;
      if (props.selectedLeadToFwa !== null) filledFields++;
      totalFields += 2;

      // Subcontractor and offshore
      if (props.selectedUseSubcontractor !== null) filledFields++;
      if (props.selectedUseOffshore !== null) filledFields++;
      if (props.selectedOffShoreAssessment !== null) filledFields++;
      totalFields += 3;

      // IT network
      if (props.selectedAccessItNetwork !== null) filledFields++;
      totalFields += 1;

      // Classifications
      if (props.selectedMedicareDelegationClassification !== null)
        filledFields++;
      if (props.selectedMediCalDelegationClassification !== null)
        filledFields++;
      totalFields += 2;

      // Risk levels
      if (props.riskLevelMemberInteraction !== null) filledFields++;
      if (props.riskLevelDecisionMakingAuthority !== null) filledFields++;
      if (props.riskLevelMemberInformationAccess !== null) filledFields++;
      if (props.riskLevelRegulatoryImpact !== null) filledFields++;
      totalFields += 4;

      const newProgress = Math.round((filledFields / totalFields) * 100);
      setProgress(newProgress);
    };

    calculateProgress();
  }, [props]);

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="relative w-full h-2 bg-gray-200 rounded-lg mb-2">
        <div
          className="absolute top-0 left-0 h-full bg-gray-500 rounded"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};
