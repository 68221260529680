import { Fragment } from "react";
import { Badge } from "../../shadcn/components/badge";
import { PdfCitation } from "../PdfViewer/PdfHighlighter/types";

export type CitationPassageViewProps = {
  passage: string;
  citations: PdfCitation[];
  onClickCitation: (citationId: string) => void;
};

export const CitationPassageView: React.FC<CitationPassageViewProps> = ({
  passage,
  citations,
  onClickCitation = () => null,
}: CitationPassageViewProps) =>
  passage
    .trimEnd()
    .split(/(<citation.*>.*?<\/citation.*>)/g)
    .map((part) => {
      const matches = part.match(/<citation.*>(.*?)<\/citation.*>/);

      if (matches && matches.length > 0) {
        const citationIds = matches[1];
        const citationIdsArray = citationIds.split(",");
        return (
          <div className="flex items-center gap-2 mt-2">
            <span className="text-sm text-gray-600 font-medium">
              Supporting Citations:
            </span>
            <div className="flex flex-wrap gap-1">
              {citationIdsArray.map((citationId, index) => {
                const citationIndex = citations.findIndex(
                  (c) => c.id === citationId
                );
                if (citationIndex !== -1) {
                  return (
                    <Badge
                      key={citationId}
                      className="cursor-pointer hover:bg-gray-100 transition-colors px-2 py-0.5 text-xs"
                      onClick={() => onClickCitation(citationId)}
                    >
                      Page {citations[citationIndex].page}
                    </Badge>
                  );
                }
                return null;
              })}
            </div>
          </div>
        );
      }

      return part.split("\n").map((line, i) => (
        <Fragment key={i}>
          {line}
          {i < part.split("\n").length - 1 && <br />}
        </Fragment>
      ));
    });
