import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as React from "react";

import { cn } from "../lib/utils";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    container?: HTMLDivElement | null;
  }
>(
  (
    { className, align = "center", sideOffset = 4, container, ...props },
    ref
  ) => (
    <PopoverPrimitive.Portal container={container}>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
          "z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          className
        )}
        {...props}
      />
    </PopoverPrimitive.Portal>
  )
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const PopoverContentWithScroll = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    container?: HTMLDivElement | null;
    isOpen: boolean;
  }
>(
  (
    {
      className,
      align = "center",
      sideOffset = 4,
      container,
      isOpen,
      ...props
    },
    ref
  ) => {
    const contentRef = React.useRef<HTMLDivElement | null>(null);
    const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false);
    const [isScrollable, setIsScrollable] = React.useState(false);

    React.useEffect(() => {
      const popoverElement = contentRef.current;
      if (!popoverElement) return;

      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = popoverElement;
        if (scrollTop + clientHeight >= scrollHeight) {
          setIsScrolledToBottom(true);
        } else {
          setIsScrolledToBottom(false);
        }
      };

      const checkScrollable = () => {
        const { scrollHeight, clientHeight } = popoverElement;
        setIsScrollable(scrollHeight > clientHeight);
      };

      checkScrollable();
      popoverElement.addEventListener("scroll", handleScroll);

      return () => {
        popoverElement.removeEventListener("scroll", handleScroll);
      };
    }, [isOpen]);

    return (
      <PopoverContent
        ref={(node) => {
          contentRef.current = node;
          if (typeof ref === "function") {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        align={align}
        sideOffset={sideOffset}
        className={cn(className, "overflow-y-auto")}
        container={container}
        {...props}
      >
        {props.children}
        {isScrollable && !isScrolledToBottom && (
          <div className="absolute bottom-0 left-0 right-0 h-[50px] bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
        )}
      </PopoverContent>
    );
  }
);
PopoverContentWithScroll.displayName = "PopoverContentWithScroll";

export { Popover, PopoverContent, PopoverContentWithScroll, PopoverTrigger };
