import React, { useMemo } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Badge } from "../../shadcn/components/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Citation } from "../../types";

export type CitationSelectorViewProps = {
  citations?: Citation[];
  onClickCitation?: (citationId: string) => void;
  citationIDSelected?: string | null;
};

export const CitationSelectorView: React.FC<CitationSelectorViewProps> = ({
  citations = [],
  onClickCitation = () => null,
  citationIDSelected = null,
}) => {
  const markdown = useMemo(() => {
    const documents: string[] = [];
    citations.forEach((citation) => {
      if (citation.doc_id && !documents.includes(citation.doc_id)) {
        documents.push(citation.doc_id);
      }
    });

    let markdown = "";

    for (const doc of documents) {
      const docCitations = citations.filter(
        (citation) => citation.doc_id === doc
      );
      if (docCitations.length == 0) {
        continue;
      }

      const docNames = docCitations
        .map((citation) => citation.doc_name)
        .filter((docName) => !!docName);
      if (docNames.length == 0) {
        continue;
      }

      const pages: number[] = [];
      docCitations.forEach((citation) => {
        if (citation.page && !pages.includes(citation.page)) {
          pages.push(citation.page);
        }
      });
      pages.sort((a, b) => a - b);

      markdown += `Page${pages.length > 1 ? "s" : ""}`;

      for (const page of pages) {
        const pageCitations = docCitations.filter(
          (citation) => citation.page === page
        );
        if (pageCitations.length == 0) {
          continue;
        }
        pageCitations.sort(
          (a, b) => (a.start_index ?? -3) - (b.start_index ?? -2)
        );

        for (const pageCitation of pageCitations) {
          markdown += ` \`${pageCitation.id}<>${pageCitations.length}\``;
        }
      }

      markdown += "\n\n";
    }
    return markdown;
  }, [citations]);

  return (
    <Markdown
      className="prose max-w-none text-sm/6"
      remarkPlugins={[remarkGfm]}
      components={{
        code: ({ node, ...props }) => {
          const children = props.children;
          if (!children) {
            return null;
          }

          const [citationID, countString] = children.toString().split("<>");
          if (!citationID) {
            return null;
          }

          const citation = citations.find(
            (citation) => citation.id === citationID
          );
          if (!citation) {
            return null;
          }

          const countMore = parseInt(countString) - 1;

          return (
            <Tooltip>
              <TooltipTrigger>
                <Badge
                  className={
                    citationIDSelected !== citationID
                      ? "bg-black text-white hover:bg-black hover:text-white cursor-pointer text-xs"
                      : "bg-white text-black hover:bg-white hover:text-black cursor-pointer text-xs"
                  }
                  onClick={() => {
                    const citationId = citation.id;
                    if (!citationId) {
                      return null;
                    }

                    onClickCitation(citationId);
                  }}
                >
                  {citation.page}
                  {/* {countMore > 0 ? ` +${countMore}` : ""} */}
                </Badge>
              </TooltipTrigger>
              <TooltipContent>
                <div className="space-y-2 text-xs w-[400px]">
                  <div className="font-semibold truncate ellipsis">
                    {citation.doc_name}
                  </div>
                  <div className="line-clamp-6">{citation.text}</div>
                </div>
              </TooltipContent>
            </Tooltip>
          );
        },
      }}
    >
      {markdown}
    </Markdown>
  );
};
