import { useAuthInfo } from "@propelauth/react";
import * as Sentry from "@sentry/react";
import { createContext, useEffect, useState } from "react";
import { toast } from "sonner";
import {
  Department,
  DocType,
  Feature,
  OrganizationProfile,
  SimpleUser,
} from "../types";
import {
  getDepartments,
  getDocTypes,
  getOrganizationFeatures,
  getSimpleUsers,
} from "../utils/apiCalls";

interface UserContextProps {
  allDocTypes: DocType[];
  setAllDocTypes: React.Dispatch<React.SetStateAction<DocType[]>>;
  internalDocTypes: DocType[];
  policyRepoDocTypes: DocType[];
  org: string;
  setOrg: React.Dispatch<React.SetStateAction<string>>;
  features: Feature[];
  setFeatures: React.Dispatch<React.SetStateAction<Feature[]>>;
  isAdmin: boolean;
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  simpleUsers: SimpleUser[];
  setSimpleUsers: React.Dispatch<React.SetStateAction<SimpleUser[]>>;
  departments: Department[];
  setDepartments: React.Dispatch<React.SetStateAction<Department[]>>;
  regDocGapDocTypes: DocType[];
  isAgent: boolean;
  setIsAgent: React.Dispatch<React.SetStateAction<boolean>>;
  externalDocTypes: DocType[];
  uploadEnabled: boolean;
  setAttributes: React.Dispatch<React.SetStateAction<OrganizationProfile>>;
  getUser: (id: string) => SimpleUser | undefined;
}

export const UserContext = createContext<UserContextProps>({
  allDocTypes: [],
  setAllDocTypes: () => {},
  internalDocTypes: [],
  org: "",
  setOrg: () => {},
  features: [],
  setFeatures: () => {},
  isAdmin: false,
  setIsAdmin: () => {},
  simpleUsers: [],
  setSimpleUsers: () => {},
  policyRepoDocTypes: [],
  departments: [],
  setDepartments: () => {},
  regDocGapDocTypes: [],
  isAgent: false,
  setIsAgent: () => {},
  externalDocTypes: [],
  uploadEnabled: false,
  setAttributes: () => {},
  getUser: (id: string) => undefined,
});

export const UserProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const authInfo = useAuthInfo();
  const [allDocTypes, setAllDocTypes] = useState<DocType[]>([]);
  const [org, setOrg] = useState<string>("");
  const [features, setFeatures] = useState<Feature[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [simpleUsers, setSimpleUsers] = useState<SimpleUser[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [isAgent, setIsAgent] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<OrganizationProfile>({
    regulating_states: {},
  });

  const externalDocTypes = allDocTypes.filter(
    (docType) => docType.external === true
  );

  const internalDocTypes = allDocTypes.filter(
    (docType) => docType.external === false
  );
  const policyRepoDocTypes = allDocTypes.filter(
    (docType) => docType.external === false && docType.editable === true
  );
  const regDocGapDocTypes = allDocTypes.filter(
    (docType) => docType.external === false && docType.reg_doc_gap === true
  );

  const uploadEnabled = internalDocTypes.some(
    (docType) => docType.name === "User Uploads"
  );

  useEffect(() => {
    if (authInfo.isLoggedIn) {
      if (import.meta.env.VITE_ENV === "prod") {
        Sentry.setUser({
          email: authInfo.user!.email,
          id: authInfo.user!.userId,
        });
        window.Atlas.call("identify", {
          userId: authInfo.user!.userId,
          email: authInfo.user!.email,
          firstName: authInfo.user!.firstName,
          lastName: authInfo.user!.lastName,
        });
      }

      let admin: boolean = false;
      let agent: boolean = false;
      const orgs = authInfo.orgHelper?.getOrgs();
      if (orgs?.length === 1) {
        const org = orgs[0];
        setOrg(org.orgName);
        agent = org.userAssignedRole === "Agent";
        admin = org.userAssignedRole === "Admin" || agent;
      }
      setIsAdmin(admin);
      setIsAgent(agent);

      getOrganizationFeatures(authInfo.accessToken).then((features) => {
        if (features) {
          setFeatures(features);
        } else {
          toast.error("Failed to fetch features");
        }
      });

      getDocTypes(authInfo.accessToken).then((response) => {
        if (response !== null) {
          setAllDocTypes(response);
        } else {
          toast.error("Failed to load document types, please refresh");
        }
      });

      getSimpleUsers(authInfo.accessToken ?? null).then((response) => {
        if (response !== null) {
          setSimpleUsers(response);
        } else {
          toast.error("Failed to load users, please refresh");
        }
      });

      getDepartments(authInfo.accessToken ?? null).then((response) => {
        if (response !== null) {
          setDepartments(response);
        } else {
          toast.error("Failed to load departments, please refresh");
        }
      });
    }
  }, [authInfo.isLoggedIn]);

  const getUser = (id: string) => {
    return simpleUsers.find((user) => user.id === id);
  };

  return (
    <UserContext.Provider
      value={{
        allDocTypes,
        setAllDocTypes,
        internalDocTypes,
        org,
        setOrg,
        features,
        setFeatures,
        isAdmin,
        setIsAdmin,
        simpleUsers,
        setSimpleUsers,
        policyRepoDocTypes,
        departments,
        setDepartments,
        regDocGapDocTypes,
        isAgent,
        setIsAgent,
        externalDocTypes,
        uploadEnabled,
        setAttributes,
        getUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
