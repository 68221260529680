import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "../../shadcn/components/toggle-group";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { updateEntityAssessmentMetadata } from "../../utils/apiCalls";

export const functionOptions = [
  { id: "um", name: "Utilization Management" },
  { id: "chm", name: "Care or Population Health Management" },
  { id: "qim", name: "Quality Improvement" },
  { id: "cl", name: "Credentialing or licensing activities" },
  { id: "imso", name: "Influence member satisfaction or outreach" },
  { id: "pga", name: "Process grievance and/or appeals" },
  { id: "pns", name: "Provide network contracting or adequacy services" },
  { id: "sm", name: "Sales & Marketing" },
  { id: "cp", name: "Claims processing" },
  { id: "dca", name: "Develop claims analysis or insights" },
  { id: "co", name: "Coordinating with other payors" },
  { id: "mp", name: "Member enrollment processing" },
  { id: "tph", name: "Third Party Hotline operations" },
  { id: "pr", name: "Pharmacy related administrative or billing services" },
  { id: "pgipa", name: "Provider Group/Independent Practice Association" },
  { id: "na", name: "Not Applicable" },
];
export const dataAccessOptions = [
  { id: "demographic", name: "Demographic Information" },
  { id: "personal", name: "Personal Health Information" },
  { id: "other", name: "Other Information" },
  { id: "na", name: "N/A" },
];

export type LineOfBusinessOption = "dhcs" | "cms" | "dmhc" | "na";

export interface LineOfBusinessItem {
  id: LineOfBusinessOption;
  name: string;
}

export const lineOfBusinessOptions: LineOfBusinessItem[] = [
  { id: "dhcs", name: "Medi-Cal (DHCS)" },
  { id: "cms", name: "Medicare (CMS)" },
  { id: "dmhc", name: "Commercial (DMHC)" },
  { id: "na", name: "Not Applicable" },
];

export interface EntityAssessmentCitation {
  created_at: string | null;
  doc_id: string;
  doc_name: string;
  formatted_text: string | null;
  id: string | null;
  match_index: number | undefined;
  page: number;
  start_index: number;
  text: string;
  user: string | null;
}

export const StandardLineOfBusinessSelector = (props: {
  lineOfBusiness: LineOfBusinessOption[];
  handleLineOfBusinessChange: (value: LineOfBusinessOption[]) => void;
  explanation: string;
  entityAssessmentId: string;
  metadataKey: string;
  accessToken: string | null;
  citations?: EntityAssessmentCitation[];
  handleSetCitations?: (citations: EntityAssessmentCitation[]) => void;
}) => {
  const handleValueChange = (values: string[]) => {
    // Convert string values to full option objects
    const lineOfBusinessValues = values
      .map((value) =>
        lineOfBusinessOptions.find((option) => option.id === value)
      )
      .filter((item): item is LineOfBusinessItem => item !== undefined);

    // Call the parent's handler
    props.handleLineOfBusinessChange(
      lineOfBusinessValues.map((item) => item.id)
    );

    // Update metadata in S3
    try {
      const metadata = {
        [props.metadataKey]: lineOfBusinessValues.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      };
      updateEntityAssessmentMetadata(
        props.entityAssessmentId,
        metadata,
        props.accessToken
      ).catch((error) => {
        console.error("Failed to update metadata:", error);
      });
    } catch (error) {
      console.error("Failed to update metadata:", error);
    }
  };

  return (
    <div className="flex items-center gap-2 w-full">
      <ToggleGroup
        type="multiple"
        value={props.lineOfBusiness}
        onValueChange={handleValueChange}
        className="bg-white rounded-md w-full flex justify-between"
      >
        <ToggleGroupItem
          value="dhcs"
          className="flex-1 data-[state=on]:bg-blue-400 data-[state=off]:hover:bg-blue-100"
        >
          Medi-Cal (DHCS)
        </ToggleGroupItem>
        <ToggleGroupItem
          value="cms"
          className="flex-1 data-[state=on]:bg-yellow-400 data-[state=off]:hover:bg-yellow-100"
        >
          Medicare (CMS)
        </ToggleGroupItem>
        <ToggleGroupItem
          value="dmhc"
          className="flex-1 data-[state=on]:bg-green-400 data-[state=off]:hover:bg-green-100"
        >
          Commercial (DMHC)
        </ToggleGroupItem>
        <ToggleGroupItem
          value="na"
          className="flex-1 data-[state=on]:bg-gray-400 data-[state=off]:hover:bg-gray-100"
        >
          Not Applicable
        </ToggleGroupItem>
      </ToggleGroup>
      <div className="flex w-5 items-center justify-center">
        {props.explanation ? (
          <Tooltip>
            <TooltipTrigger>
              <QuestionMarkCircledIcon
                className="h-5 w-5 text-muted-foreground cursor-pointer"
                onClick={() =>
                  props.citations &&
                  props.citations.length > 0 &&
                  props.handleSetCitations &&
                  props.handleSetCitations(props.citations)
                }
              />
            </TooltipTrigger>
            <TooltipContent className="max-w-[500px] text-sm">
              {props.explanation}
            </TooltipContent>
          </Tooltip>
        ) : (
          <div className="h-5 w-5" />
        )}
      </div>
    </div>
  );
};

export type YesNoOption = "yes" | "no" | null;

export const StandardYesNoSelector = (props: {
  yesNo: YesNoOption;
  handleYesNoChange: (value: YesNoOption) => void;
  explanation: string;
  entityAssessmentId: string;
  metadataKey: string;
  accessToken: string | null;
  citations?: EntityAssessmentCitation[];
  handleSetCitations?: (citations: EntityAssessmentCitation[]) => void;
}) => {
  const handleValueChange = (value: string) => {
    const yesNoValue = value === "" ? null : (value as YesNoOption);

    // Call the parent's handler
    props.handleYesNoChange(yesNoValue);

    // Update metadata in S3
    try {
      const metadata = {
        [props.metadataKey]: yesNoValue,
      };
      updateEntityAssessmentMetadata(
        props.entityAssessmentId,
        metadata,
        props.accessToken
      ).catch((error) => {
        console.error("Failed to update metadata:", error);
      });
    } catch (error) {
      console.error("Failed to update metadata:", error);
    }
  };

  return (
    <div className="flex-col space-y-1 w-full">
      <div className="flex items-center gap-2 w-full">
        <ToggleGroup
          type="single"
          value={props.yesNo ?? ""}
          onValueChange={handleValueChange}
          className="bg-white rounded-md w-full flex justify-between"
        >
          <ToggleGroupItem
            value="yes"
            className="flex-1 data-[state=on]:bg-green-400 data-[state=off]:hover:bg-green-100"
          >
            Yes
          </ToggleGroupItem>
          <ToggleGroupItem
            value="no"
            className="flex-1 data-[state=on]:bg-red-400 data-[state=off]:hover:bg-red-100"
          >
            No
          </ToggleGroupItem>
        </ToggleGroup>
        <div className="flex w-5 items-center justify-center">
          {props.explanation && (
            <Tooltip>
              <TooltipTrigger>
                <QuestionMarkCircledIcon
                  className="h-5 w-5 text-muted-foreground cursor-pointer"
                  onClick={() =>
                    props.citations &&
                    props.citations.length > 0 &&
                    props.handleSetCitations &&
                    props.handleSetCitations(props.citations)
                  }
                />
              </TooltipTrigger>
              <TooltipContent className="max-w-[500px] text-sm">
                {props.explanation}
              </TooltipContent>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export type MedicareClassificationOption =
  | "firstTier"
  | "downstream"
  | "related"
  | "notFdr"
  | null;

export const StandardMedicareClassificationSelector = (props: {
  medicareClassification: MedicareClassificationOption;
  handleMedicareClassificationChange: (
    value: MedicareClassificationOption
  ) => void;
  explanation: string;
  entityAssessmentId: string;
  metadataKey: string;
  accessToken: string | null;
  citations?: EntityAssessmentCitation[];
  handleSetCitations?: (citations: EntityAssessmentCitation[]) => void;
}) => {
  const handleValueChange = (value: string) => {
    const classification =
      value === "" ? null : (value as MedicareClassificationOption);

    // Call the parent's handler
    props.handleMedicareClassificationChange(classification);

    // Update metadata in S3
    try {
      const metadata = {
        [props.metadataKey]: classification,
      };
      updateEntityAssessmentMetadata(
        props.entityAssessmentId,
        metadata,
        props.accessToken
      ).catch((error) => {
        console.error("Failed to update metadata:", error);
      });
    } catch (error) {
      console.error("Failed to update metadata:", error);
    }
  };

  return (
    <div className="flex items-center gap-2 w-full">
      <ToggleGroup
        type="single"
        value={props.medicareClassification ?? ""}
        onValueChange={handleValueChange}
        className="bg-white rounded-md w-full flex justify-between"
      >
        <ToggleGroupItem
          value="firstTier"
          className="flex-1 data-[state=on]:bg-black data-[state=on]:text-white data-[state=off]:hover:bg-gray-400 data-[state=off]:hover:text-white"
        >
          First Tier Entity
        </ToggleGroupItem>
        <ToggleGroupItem
          value="downstream"
          className="flex-1 data-[state=on]:bg-black data-[state=on]:text-white data-[state=off]:hover:bg-gray-400 data-[state=off]:hover:text-white"
        >
          Downstream Entity
        </ToggleGroupItem>
        <ToggleGroupItem
          value="related"
          className="flex-1 data-[state=on]:bg-black data-[state=on]:text-white data-[state=off]:hover:bg-gray-400 data-[state=off]:hover:text-white"
        >
          Related Entity
        </ToggleGroupItem>
        <ToggleGroupItem
          value="notFdr"
          className="flex-1 data-[state=on]:bg-black data-[state=on]:text-white data-[state=off]:hover:bg-gray-400 data-[state=off]:hover:text-white"
        >
          Not an FDR
        </ToggleGroupItem>
      </ToggleGroup>
      <div className="flex w-5 items-center justify-center">
        {props.explanation ? (
          <Tooltip>
            <TooltipTrigger>
              <QuestionMarkCircledIcon
                className="h-5 w-5 text-muted-foreground cursor-pointer"
                onClick={() =>
                  props.citations &&
                  props.citations.length > 0 &&
                  props.handleSetCitations &&
                  props.handleSetCitations(props.citations)
                }
              />
            </TooltipTrigger>
            <TooltipContent className="max-w-[500px] text-sm">
              {props.explanation}
            </TooltipContent>
          </Tooltip>
        ) : (
          <div className="h-5 w-5" />
        )}
      </div>
    </div>
  );
};

export type MediCalClassificationOption =
  | "partiallyDelegated"
  | "administrative"
  | "notASubcontractor"
  | null;

export const StandardMediCalClassificationSelector = (props: {
  mediCalClassification: MediCalClassificationOption;
  handleMediCalClassificationChange: (
    value: MediCalClassificationOption
  ) => void;
  explanation: string;
  entityAssessmentId: string;
  metadataKey: string;
  accessToken: string | null;
  citations?: EntityAssessmentCitation[];
  handleSetCitations?: (citations: EntityAssessmentCitation[]) => void;
}) => {
  const handleValueChange = (value: string) => {
    const classification =
      value === "" ? null : (value as MediCalClassificationOption);

    // Call the parent's handler
    props.handleMediCalClassificationChange(classification);

    // Update metadata in S3
    try {
      const metadata = {
        [props.metadataKey]: classification,
      };
      updateEntityAssessmentMetadata(
        props.entityAssessmentId,
        metadata,
        props.accessToken
      ).catch((error) => {
        console.error("Failed to update metadata:", error);
      });
    } catch (error) {
      console.error("Failed to update metadata:", error);
    }
  };

  return (
    <div className="flex items-center gap-2 w-full">
      <ToggleGroup
        type="single"
        value={props.mediCalClassification ?? ""}
        onValueChange={handleValueChange}
        className="bg-white rounded-md w-full flex justify-between mt-4 mb-2"
      >
        <ToggleGroupItem
          value="partiallyDelegated"
          className="flex-1 data-[state=on]:bg-black data-[state=on]:text-white data-[state=off]:hover:bg-gray-400 data-[state=off]:hover:text-white"
        >
          Partially Delegated Subcontractor
        </ToggleGroupItem>
        <ToggleGroupItem
          value="administrative"
          className="flex-1 data-[state=on]:bg-black data-[state=on]:text-white data-[state=off]:hover:bg-gray-400 data-[state=off]:hover:text-white"
        >
          Administrative Subcontractor
        </ToggleGroupItem>
        <ToggleGroupItem
          value="notASubcontractor"
          className="flex-1 data-[state=on]:bg-black data-[state=on]:text-white data-[state=off]:hover:bg-gray-400 data-[state=off]:hover:text-white"
        >
          Not a Subcontractor
        </ToggleGroupItem>
      </ToggleGroup>
      <div className="flex w-5 items-center mt-2 justify-center">
        {props.explanation ? (
          <Tooltip>
            <TooltipTrigger>
              <QuestionMarkCircledIcon
                className="h-5 w-5 text-muted-foreground cursor-pointer"
                onClick={() =>
                  props.citations &&
                  props.citations.length > 0 &&
                  props.handleSetCitations &&
                  props.handleSetCitations(props.citations)
                }
              />
            </TooltipTrigger>
            <TooltipContent className="max-w-[500px] text-sm">
              {props.explanation}
            </TooltipContent>
          </Tooltip>
        ) : (
          <div className="h-5 w-5" />
        )}
      </div>
    </div>
  );
};

export type RiskLevel = "low" | "moderate" | "high" | null;

export const StandardRiskLevelSelector = (props: {
  riskLevel: RiskLevel;
  handleRiskLevelChange: (value: RiskLevel) => void;
  explanation: string;
  entityAssessmentId: string;
  metadataKey: string;
  accessToken: string | null;
  citations?: EntityAssessmentCitation[];
  handleSetCitations?: (citations: EntityAssessmentCitation[]) => void;
}) => {
  const handleValueChange = (value: string) => {
    if (value === "") return;
    const riskLevel = value as RiskLevel;

    // Call the parent's handler
    props.handleRiskLevelChange(riskLevel);

    // Update metadata in S3
    try {
      const metadata = {
        [props.metadataKey]: riskLevel,
      };
      updateEntityAssessmentMetadata(
        props.entityAssessmentId,
        metadata,
        props.accessToken
      ).catch((error) => {
        console.error("Failed to update metadata:", error);
      });
    } catch (error) {
      console.error("Failed to update metadata:", error);
    }
  };

  return (
    <div className="flex items-center gap-2 w-full">
      <ToggleGroup
        type="single"
        value={props.riskLevel ?? ""}
        onValueChange={handleValueChange}
        className="bg-white rounded-md w-full flex justify-between mt-4 mb-2"
      >
        <ToggleGroupItem
          value="low"
          className="flex-1 data-[state=on]:bg-green-400 data-[state=off]:hover:bg-green-100"
        >
          Low (10)
        </ToggleGroupItem>
        <ToggleGroupItem
          value="moderate"
          className="flex-1 data-[state=on]:bg-yellow-400 data-[state=off]:hover:bg-yellow-100"
        >
          Moderate (20)
        </ToggleGroupItem>
        <ToggleGroupItem
          value="high"
          className="flex-1 data-[state=on]:bg-red-400 data-[state=off]:hover:bg-red-100"
        >
          High (30)
        </ToggleGroupItem>
      </ToggleGroup>
      <div className="flex w-5 items-center mt-2 justify-center">
        {props.explanation ? (
          <Tooltip>
            <TooltipTrigger>
              <QuestionMarkCircledIcon
                className="h-5 w-5 text-muted-foreground cursor-pointer"
                onClick={() =>
                  props.citations &&
                  props.citations.length > 0 &&
                  props.handleSetCitations &&
                  props.handleSetCitations(props.citations)
                }
              />
            </TooltipTrigger>
            <TooltipContent className="max-w-[500px] text-sm">
              {props.explanation}
            </TooltipContent>
          </Tooltip>
        ) : (
          <div className="h-5 w-5" />
        )}
      </div>
    </div>
  );
};
