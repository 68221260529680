import { ChevronRight } from "lucide-react";
import {
  BreadcrumbItem,
  BreadcrumbLink,
} from "../../shadcn/components/breadcrumb";

const Sep = () => (
  <span className="mx-2 w-0.5 border-l border-gray-400 py-0.5 bg-gray-300" />
);

export const BreadCrumbButtonExpandableContent = ({
  label,
  value,
  alwaysExpanded = false,
}: {
  label: string;
  value: string;
  alwaysExpanded?: boolean;
}) => {
  return (
    <div className="inline-flex items-center group">
      <span>{label}</span>
      <span
        className={`inline-block overflow-hidden whitespace-nowrap transition-[max-width] duration-300 ease-in-out ${
          alwaysExpanded ? "max-w-[300px]" : "max-w-0 group-hover:max-w-[300px]"
        }`}
      >
        <Sep />
        {value}
      </span>
    </div>
  );
};
export const BreadCrumbButtonSeperator = () => {
  return (
    <div className="flex items-center">
      <ChevronRight className="h-4 w-4 text-gray-500" />
    </div>
  );
};

export const BreadCrumbButton = ({
  href,
  children,
  disabled = false,
}: {
  href: string;
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  return (
    <BreadcrumbItem>
      <BreadcrumbLink
        href={href}
        className={`px-4 py-2 text-blue-600 hover:underline ${
          disabled ? "cursor-not-allowed opacity-50" : ""
        }`}
        aria-disabled={disabled}
      >
        {children}
      </BreadcrumbLink>
    </BreadcrumbItem>
  );
};

export const BreadCrumbButtonList = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="flex items-center space-x-2">{children}</ol>
    </nav>
  );
};
