import { useAuthInfo } from "@propelauth/react";
import { Mutex } from "async-mutex";
import React, {
  MutableRefObject,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "sonner";
import {
  PdfCitation,
  PdfCitationSettings,
} from "../components/PdfViewer/PdfHighlighter/types";
import { DocMetadata, DocToView } from "../types";
import { getDocMetadata } from "../utils/apiCalls";

interface DocViewerContextProps {
  docToView: DocToView | null;
  setDocToView: React.Dispatch<React.SetStateAction<DocToView | null>>;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  numPages: number | null;
  setNumPages: React.Dispatch<React.SetStateAction<number | null>>;
  citations: PdfCitation[];
  citationsWithSettings: PdfCitation[];
  setCitations: React.Dispatch<React.SetStateAction<PdfCitation[]>>;
  citationSettings: PdfCitationSettings;
  setCitationSettings: React.Dispatch<
    React.SetStateAction<PdfCitationSettings>
  >;
  searchResultCitations: PdfCitation[];
  setSearchResultCitations: React.Dispatch<React.SetStateAction<PdfCitation[]>>;
  rotation: number;
  setRotation: React.Dispatch<React.SetStateAction<number>>;
  docMetadata: DocMetadata | null;
  setDocMetadata: React.Dispatch<React.SetStateAction<DocMetadata | null>>;
  scrollBoxRef: MutableRefObject<HTMLDivElement | null>;
  setScrollBoxRef: (ref: HTMLDivElement | null) => void;
  paginatePDF: boolean;
  setPaginatePDF: (paginatePdf: boolean) => void;
  scrollMutex?: Mutex;
  scrollToElement: (element: HTMLDivElement) => Promise<void>;
  fileSelectorOpen: boolean;
  setFileSelectorOpen: (open: boolean) => void;
}

export const DocViewerContext = createContext<DocViewerContextProps>({
  docToView: null,
  setDocToView: () => {},
  pageNumber: 1,
  setPageNumber: () => {},
  numPages: null,
  setNumPages: () => {},
  citations: [],
  citationsWithSettings: [],
  setCitations: () => {},
  citationSettings: {},
  setCitationSettings: () => {},
  searchResultCitations: [],
  setSearchResultCitations: () => {},
  rotation: 0,
  setRotation: () => {},
  docMetadata: null,
  setDocMetadata: () => {},
  scrollBoxRef: { current: null },
  setScrollBoxRef: (ref) => {},
  paginatePDF: true,
  setPaginatePDF: () => {},
  scrollMutex: undefined,
  scrollToElement: () => Promise.resolve(),
  fileSelectorOpen: false,
  setFileSelectorOpen: () => {},
});

export const DocViewerProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const authInfo = useAuthInfo();
  const [docToView, setDocToView] = useState<DocToView | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [citations, setCitations] = useState<PdfCitation[]>([]);
  const [citationsFacade, setCitationsFacade] = useState<PdfCitation[]>([]);
  const [citationSettings, setCitationSettings] = useState<PdfCitationSettings>(
    {
      showTooltip: true,
    }
  );
  const [searchResultCitations, setSearchResultCitations] = useState<
    PdfCitation[]
  >([]);
  const [rotation, setRotation] = useState<number>(0);
  const [docMetadata, setDocMetadata] = useState<DocMetadata | null>(null);
  const [paginatePDF, setPaginatePDF] = useState<boolean>(true);
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);
  const scrollMutex = new Mutex();
  const [fileSelectorOpen, setFileSelectorOpen] = useState(false);

  const setScrollBoxRef = (ref: HTMLDivElement | null) => {
    scrollBoxRef.current = ref;
  };

  const scrollToElement = async (element: HTMLDivElement) => {
    const release = await scrollMutex?.acquire();
    try {
      element.scrollIntoView({
        behavior: "smooth",
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } finally {
      release?.();
    }
  };

  const updateCitations: React.Dispatch<React.SetStateAction<PdfCitation[]>> = (
    action
  ) => {
    setCitationsFacade((prev) => {
      const newCitations = typeof action === "function" ? action(prev) : action;

      const citationsWithSettings = newCitations.map((citation) => {
        const updatedCitation = { ...citation };
        if (citationSettings.showTooltip === false) {
          delete updatedCitation.citationTooltip;
        }
        if (citationSettings.tooltipPosition) {
          updatedCitation.tooltipPosition = citationSettings.tooltipPosition;
        }
        return updatedCitation;
      });
      setCitations(citationsWithSettings);
      return newCitations;
    });
  };

  useEffect(() => {
    updateCitations(citationsFacade);
  }, [citationSettings]);

  useEffect(() => {
    if (docToView?.docId) {
      getDocMetadata(docToView.docId, authInfo.accessToken ?? null).then(
        (metadata) => {
          if (metadata !== null) {
            setDocMetadata(metadata);
          } else {
            toast.error("Sorry, that document is no longer available");
          }
        }
      );
    }
  }, [docToView?.docId]);

  return (
    <DocViewerContext.Provider
      value={{
        docToView,
        setDocToView,
        pageNumber,
        setPageNumber,
        numPages,
        setNumPages,
        citations: citationsFacade,
        citationsWithSettings: citations,
        setCitations: updateCitations,
        citationSettings,
        setCitationSettings,
        searchResultCitations,
        setSearchResultCitations,
        rotation,
        setRotation,
        docMetadata,
        setDocMetadata,
        scrollBoxRef,
        setScrollBoxRef,
        paginatePDF,
        setPaginatePDF,
        scrollMutex,
        scrollToElement,
        fileSelectorOpen,
        setFileSelectorOpen,
      }}
    >
      {children}
    </DocViewerContext.Provider>
  );
};
