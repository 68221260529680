import { useContext } from "react";
import { ClearHighlightTooltipActionButton } from "../../components/HighlightTooltipActionButton";
import { DocViewerContext } from "../../contexts/DocViewerContext";

export const EntityAssessmentHighlightActions = (props: {
  citationId: string;
}) => {
  const { citations, docMetadata, pageNumber } = useContext(DocViewerContext);
  return (
    <div className="w-24">
      {/* {citations.length && citations[0]?.match && (
        <>
          <CopyTooltipActionButton citationText={citations[0].match} />
          <CopyWithCitationTooltipActionButton
            docName={docMetadata?.name ?? ""}
            pageNumber={pageNumber}
            citationText={citations[0].match}
          />
        </>
      )} */}
      <ClearHighlightTooltipActionButton citationId={props.citationId} />
    </div>
  );
};
