import { useAuthInfo } from "@propelauth/react";
import { PlusIcon } from "@radix-ui/react-icons";
import { Loader2, Trash2 } from "lucide-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";
import { DateDisplay } from "../../components/DateDisplay";
import { Layout } from "../../components/Layout";
import { LoadingView } from "../../components/Loading";
import { DataTable, HeaderCell } from "../../components/Table";
import { UserContext } from "../../contexts/UserContext";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../shadcn/components/alert-dialog";
import { Button } from "../../shadcn/components/button";
import { EntityAssessment } from "../../types";
import {
  getEntityAssessmentMetadata,
  getEntityAssessments,
  updateEntityAssessmentMetadata,
} from "../../utils/apiCalls";

const mapMedicareClassification = (id: string): string => {
  const mapping: Record<string, string> = {
    firstTier: "First Tier Entity",
    downstream: "Downstream Entity",
    related: "Related Entity",
    notFdr: "Not an FDR",
  };
  return mapping[id] || id;
};

const mapMediCalClassification = (id: string): string => {
  const mapping: Record<string, string> = {
    partiallyDelegated: "Partially Delegated",
    administrative: "Administrative",
    notASubcontractor: "Not a Subcontractor",
  };
  return mapping[id] || id;
};

export const EntityAssessmentView = () => {
  const authInfo = useAuthInfo();
  const [assessments, setAssessments] = useState<EntityAssessment[]>([]);
  const [loading, setLoading] = useState(false);
  const [assessmentToDelete, setAssessmentToDelete] = useState<string | null>(
    null
  );
  const navigate = useNavigate();
  const { simpleUsers } = useContext(UserContext);

  const filteredAssessments = useMemo(
    () =>
      assessments
        .filter((assessment) => !assessment.deleted)
        .sort(
          (a, b) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        ),
    [assessments]
  );

  useEffect(() => {
    setLoading(true);
    getEntityAssessments(authInfo.accessToken ?? null).then((assessments) => {
      if (assessments) {
        setAssessments(assessments);
      } else {
        toast.error("Failed to fetch assessments");
      }
      setLoading(false);
    });
  }, []);

  const handleDelete = async (assessmentId: string) => {
    try {
      // Get current metadata
      const metadata = await getEntityAssessmentMetadata(
        assessmentId,
        authInfo.accessToken ?? null
      );
      if (metadata) {
        // Update metadata with deleted flag
        const updatedMetadata = {
          ...metadata,
          deleted: true,
        };

        // Save updated metadata
        await updateEntityAssessmentMetadata(
          assessmentId,
          updatedMetadata,
          authInfo.accessToken ?? null
        );

        // Update local state
        setAssessments((prev) =>
          prev.map((assessment) =>
            assessment.id === assessmentId
              ? { ...assessment, deleted: true }
              : assessment
          )
        );
        setAssessmentToDelete(null);
        toast.success("Assessment deleted");
      } else {
        toast.error("Failed to delete assessment");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete assessment");
    }
  };

  const columns = useMemo(
    () => [
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Project Name" />;
        },
        cell: ({ row }: any) => {
          return (
            <a
              href={`/entity-assessment/${row.original.id}`}
              className="text-blue-500 hover:underline"
            >
              {row.original.name}
            </a>
          );
        },
        id: "name",
        accessorKey: "name",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Entity Name" />;
        },
        cell: ({ row }: any) => {
          return <div>{row.original.entity_name || "N/A"}</div>;
        },
        id: "entity_name",
        accessorKey: "entity_name",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Effective Date" />;
        },
        cell: ({ row }: any) => {
          return <DateDisplay date={row.original.effective_date} />;
        },
        id: "effective_date",
        accessorKey: "effective_date",
        sortingFn: (rowA: any, rowB: any) => {
          const a = rowA.original.effective_date
            ? new Date(rowA.original.effective_date)
            : new Date(0);
          const b = rowB.original.effective_date
            ? new Date(rowB.original.effective_date)
            : new Date(0);
          return a.getTime() - b.getTime();
        },
      },
      {
        header: ({ column }: any) => {
          return (
            <HeaderCell column={column} columnName="Medi-Cal Classification" />
          );
        },
        cell: ({ row }: any) => {
          return (
            <div>
              {row.original.medi_cal_classification
                ? mapMediCalClassification(row.original.medi_cal_classification)
                : "N/A"}
            </div>
          );
        },
        id: "medi_cal_classification",
        accessorKey: "medi_cal_classification",
      },
      {
        header: ({ column }: any) => {
          return (
            <HeaderCell column={column} columnName="Medicare Classification" />
          );
        },
        cell: ({ row }: any) => {
          return (
            <div>
              {row.original.medicare_classification
                ? mapMedicareClassification(
                    row.original.medicare_classification
                  )
                : "N/A"}
            </div>
          );
        },
        id: "medicare_classification",
        accessorKey: "medicare_classification",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Created By" />;
        },
        cell: ({ row }: any) => {
          const owner = simpleUsers.find(
            (user) => user.id === row.original.owner_id
          );
          const displayName = owner
            ? `${owner.first_name || ""} ${owner.last_name || ""}`.trim() ||
              owner.email
            : "N/A";
          return <div>{displayName}</div>;
        },
        id: "owner_id",
        accessorKey: "owner_id",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Created At" />;
        },
        cell: ({ row }: any) => <DateDisplay date={row.original.created_at} />,
        id: "created_at",
        accessorKey: "created_at",
        sortingFn: (rowA: any, rowB: any) => {
          const a = rowA.original.created_at
            ? new Date(rowA.original.created_at)
            : new Date(0);
          const b = rowB.original.created_at
            ? new Date(rowB.original.created_at)
            : new Date(0);
          return a.getTime() - b.getTime();
        },
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Updated At" />;
        },
        cell: ({ row }: any) => <DateDisplay date={row.original.updated_at} />,
        id: "updated_at",
        accessorKey: "updated_at",
        sortingFn: (rowA: any, rowB: any) => {
          const a = rowA.original.updated_at
            ? new Date(rowA.original.updated_at)
            : new Date(0);
          const b = rowB.original.updated_at
            ? new Date(rowB.original.updated_at)
            : new Date(0);
          return a.getTime() - b.getTime();
        },
      },
      {
        header: ({ column }: any) => {
          return (
            <HeaderCell
              column={column}
              columnName=" "
              className="text-center"
            />
          );
        },
        cell: ({ row }: any) => {
          const [isOpen, setIsOpen] = useState(false);
          const [isDeleting, setIsDeleting] = useState(false);

          const onDelete = async () => {
            try {
              setIsDeleting(true);
              await handleDelete(row.original.id);
              setIsOpen(false);
              setIsDeleting(false);
            } catch (error) {
              setIsDeleting(false);
              // Error is already handled in handleDelete function with toast
            }
          };

          return (
            <div className="flex justify-center">
              <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
                <AlertDialogTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-8 w-8 text-red-400/70 hover:text-red-600 hover:bg-red-50"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      Are you sure you want to delete this assessment?
                    </AlertDialogTitle>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel disabled={isDeleting}>
                      Cancel
                    </AlertDialogCancel>
                    <Button
                      variant="destructive"
                      onClick={onDelete}
                      disabled={isDeleting}
                    >
                      {isDeleting ? (
                        <>
                          <span className="mr-2">Deleting...</span>
                          <Loader2 className="h-4 w-4 animate-spin" />
                        </>
                      ) : (
                        "Delete"
                      )}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          );
        },
        id: "actions",
        enableSorting: false,
      },
    ],
    [simpleUsers]
  );

  return (
    <Layout pageName="Entity Assessment">
      <div className="max-w-5xl mx-auto px-4 mb-10">
        <div className="flex justify-end mb-6">
          <Button
            onClick={() => navigate(`/entity-assessment/${uuidv4()}`)}
            className="w-[250px]"
          >
            <PlusIcon className="h-4 w-4 mr-2" />
            New Assessment
          </Button>
        </div>

        {loading ? (
          <LoadingView customText="Loading assessments..." />
        ) : filteredAssessments.length === 0 ? (
          <div className="text-center text-gray-500">No assessments found</div>
        ) : (
          <DataTable
            columns={columns}
            data={filteredAssessments}
            totalRecordCount={filteredAssessments.length}
            paginationParams={{
              pageSize: 50,
              pageIndex: 0,
            }}
          />
        )}
      </div>
    </Layout>
  );
};
