import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Calculator, Check, ChevronsUpDown } from "lucide-react";
import React, { useContext, useRef, useState } from "react";
import { useModalContext } from "../../../contexts/ActiveModalContext";
import { UserContext } from "../../../contexts/UserContext";
import { Button } from "../../../shadcn/components/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../shadcn/components/command";
import { Input } from "../../../shadcn/components/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shadcn/components/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../shadcn/components/select";
import { Textarea } from "../../../shadcn/components/textarea";
import { cn } from "../../../shadcn/lib/utils";
import { Cap, CapStatus } from "../../../types";

interface CreateCapFormProps {
  preExistingCap?: Partial<Cap>;
  onSubmit: (cap: Cap) => void;
}

const capStatuses: CapStatus[] = ["open", "closed"];
const timeUnits = {
  day: "Day(s)",
  week: "Week(s)",
  month: "Month(s)",
} as const;

const CreateCapForm: React.FC<CreateCapFormProps> = ({
  preExistingCap,
  onSubmit,
}) => {
  const { activeModalRef } = useModalContext();
  const { departments } = useContext(UserContext);
  const departmentPopoverTriggerRef = useRef<HTMLButtonElement>(null);
  const [cap, setCap] = useState<Cap>({
    id: "",
    name: "",
    description: "",
    cap_owner_id: null,
    element_metadata_id: null,
    delegate: null,
    department: null,
    cap_issued_date: null,
    cap_due_date: null,
    cap_completed_date: null,
    created_at: null,
    updated_at: null,
    cap_status: "open",
    cap_type: null,
    action_items: [],
    ...preExistingCap,
  });
  const [capIssuedDate, setCapIssuedDate] = useState<Date | null>(
    preExistingCap?.cap_issued_date
      ? dayjs(preExistingCap.cap_issued_date).toDate()
      : null
  );
  const [capDueDate, setCapDueDate] = useState<Date | null>(
    preExistingCap?.cap_due_date
      ? dayjs(preExistingCap.cap_due_date).toDate()
      : null
  );
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [timeValue, setTimeValue] = useState<number>(0);
  const [timeUnit, setTimeUnit] = useState<string>("day");
  const [department, setDepartment] = useState<string | null>(null);
  const [departmentPopoverOpen, setDepartmentPopoverOpen] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setCap((prevCap) => ({
      ...prevCap,
      [name]: value,
    }));
  };

  const handleStatusChange = (value: string) => {
    setCap((prevCap) => ({
      ...prevCap,
      cap_status: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      ...cap,
      cap_issued_date: capIssuedDate
        ? dayjs(capIssuedDate).toISOString()
        : null,
      cap_due_date: capDueDate ? dayjs(capDueDate).toISOString() : null,
    });
  };

  const handlePopoverSubmit = () => {
    const currentDate = dayjs();
    let newDueDate;

    switch (timeUnit) {
      case "day":
        newDueDate = currentDate.add(timeValue, "day");
        break;
      case "week":
        newDueDate = currentDate.add(timeValue, "week");
        break;
      case "month":
        newDueDate = currentDate.add(timeValue, "month");
        break;
      default:
        newDueDate = currentDate;
    }

    setCapDueDate(newDueDate.toDate());
    setPopoverOpen(false);
  };

  const handleDepartmentSelect = (currentValue: string) => {
    console.log("Selected department:", currentValue);
    setDepartment(currentValue === department ? null : currentValue);
    setCap((prevCap) => ({
      ...prevCap,
      department: currentValue === department ? null : currentValue,
    }));
    setDepartmentPopoverOpen(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
    >
      <div style={{ marginBottom: "1rem" }}>
        <label htmlFor="name">Name:</label>
        <Input
          type="text"
          id="name"
          name="name"
          value={cap.name}
          onChange={handleChange}
          required
          style={{ marginTop: "0.5rem" }}
        />
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <label htmlFor="description">Description:</label>
        <Textarea
          id="description"
          name="description"
          value={cap.description}
          onChange={handleChange}
          required
          style={{ marginTop: "0.5rem" }}
        />
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <Popover
          open={departmentPopoverOpen}
          onOpenChange={setDepartmentPopoverOpen}
        >
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={departmentPopoverOpen}
              className="w-[200px] justify-between w-full"
              ref={departmentPopoverTriggerRef}
            >
              {department
                ? departments.find((dept) => dept.name === department)?.name ||
                  "Assign to Department..."
                : "Assign to Department..."}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="w-full p-0"
            container={activeModalRef?.current}
            side="bottom"
            align="center"
            style={{
              width: departmentPopoverTriggerRef.current?.offsetWidth,
            }}
          >
            <Command>
              <CommandInput placeholder="Search department..." />
              <CommandList>
                <CommandEmpty>No department found.</CommandEmpty>
                <CommandGroup>
                  {departments.map((dept) => (
                    <CommandItem
                      key={dept.id}
                      value={dept.name}
                      onSelect={handleDepartmentSelect}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          department === dept.name ? "opacity-100" : "opacity-0"
                        )}
                      />
                      {dept.name}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <label htmlFor="delegate">Delegate:</label>
        <Input
          type="text"
          id="delegate"
          name="delegate"
          value={cap.delegate || ""}
          onChange={handleChange}
          style={{ marginTop: "0.5rem" }}
        />
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <label htmlFor="cap_status">CAP Status:</label>
        <Select value={cap.cap_status || ""} onValueChange={handleStatusChange}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a status..." />
          </SelectTrigger>
          <SelectContent>
            {capStatuses.map((status, index) => (
              <SelectItem key={`${status}-${index}`} value={status}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div
        className="flex flex-row items-center gap-2 mb-4"
        style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}
      >
        <DatePicker
          label="CAP Issued Date"
          value={dayjs(capIssuedDate)}
          onChange={(newValue) => {
            if (newValue) {
              setCapIssuedDate(newValue.toDate());
            }
          }}
          className="w-full"
          disableOpenPicker={true}
        />
        {/* <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
          <PopoverTrigger asChild>
            <Button type="button">
              <Calculator />
              Due
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="flex flex-col items-center gap-2 px-4 py-2 mb-4 w-fit"
            side="top"
            align="center"
            container={activeModalRef?.current}
          >
            <h3 className="font-medium">Calculate CAP Due Date:</h3>
            <div className="flex flex-row items-center gap-2">
              <Input
                type="number"
                value={timeValue}
                onChange={(e) => setTimeValue(Number(e.target.value))}
                placeholder="Enter number"
                style={{ width: "8ch" }}
              />
              <Select value={timeUnit} onValueChange={setTimeUnit}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a time unit..." />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(timeUnits).map(([unit, value], index) => (
                    <SelectItem key={`${unit}-${index}`} value={unit}>
                      {value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Button type="button" onClick={handlePopoverSubmit}>
                Calculate
              </Button>
            </div>
          </PopoverContent>
        </Popover> */}
        <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
          <PopoverTrigger asChild>
            <Button type="button">
              <Calculator />
              Due
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="flex flex-col items-center gap-2 px-4 py-2 mb-4 w-fit"
            side="top"
            align="center"
            container={activeModalRef?.current}
          >
            <h3 className="font-medium">Quick Set CAP Due Date:</h3>
            <div className="flex flex-row items-center gap-2">
              <Button
                type="button"
                onClick={() => {
                  setCapDueDate(dayjs().add(30, "day").toDate());
                  setPopoverOpen(false);
                }}
              >
                30 Days
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setCapDueDate(dayjs().add(60, "day").toDate());
                  setPopoverOpen(false);
                }}
              >
                60 Days
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setCapDueDate(dayjs().add(90, "day").toDate());
                  setPopoverOpen(false);
                }}
              >
                90 Days
              </Button>
            </div>
          </PopoverContent>
        </Popover>
        <DatePicker
          label="CAP Due Date"
          value={dayjs(capDueDate)}
          onChange={(newValue) => {
            if (newValue) {
              setCapDueDate(newValue.toDate());
            }
          }}
          className="w-full"
          disableOpenPicker={true}
        />
      </div>
      {/* Add more fields as necessary */}
      <Button type="submit" style={{ alignSelf: "flex-end" }}>
        Create CAP
      </Button>
    </form>
  );
};

export default CreateCapForm;
