import { useAuthInfo } from "@propelauth/react";
import { format } from "date-fns";
import { Plus } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { StatusSelector } from "../../../components/StatusSelector";
import { useModalContext } from "../../../contexts/ActiveModalContext";
import { UserContext } from "../../../contexts/UserContext";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../shadcn/components/accordion";
import { Badge } from "../../../shadcn/components/badge";
import { Button } from "../../../shadcn/components/button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "../../../shadcn/components/dialog";
import {
  Sheet,
  SheetContent,
  SheetTitle,
} from "../../../shadcn/components/sheet";
import { Textarea } from "../../../shadcn/components/textarea";
import { Cap, CapStatuses, SimpleUser } from "../../../types";

interface CapSheetProps {
  caps: Cap[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUpdateCap: (cap: Cap, actionItemStatus: string) => Promise<void>;
}

const CapSheet: React.FC<CapSheetProps> = ({
  caps,
  onUpdateCap,
  open,
  onOpenChange,
}) => {
  const authInfo = useAuthInfo();
  const { getUser, simpleUsers } = useContext(UserContext);
  const { setActiveModalRef, activeModalRef } = useModalContext();
  const [user, setUser] = useState<SimpleUser | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCap, setSelectedCap] = useState<Cap | null>(null);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (caps.length > 0) {
      const capOwner = getUser(caps[0].cap_owner_id ?? "") ?? null;
      setUser(capOwner);
    }
  }, [caps, simpleUsers, getUser]);

  const handleStatusChange = (cap: Cap) => {
    setSelectedCap(cap);
    setNotes("");
    setDialogOpen(true);
  };

  const handleAddActionItem = (cap: Cap) => {
    setSelectedCap(cap);
    setNotes("");
    setDialogOpen(true);
  };

  const handleDialogSubmit = async () => {
    if (selectedCap) {
      try {
        await onUpdateCap(selectedCap, notes);
        setDialogOpen(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (dialogOpen) {
      setActiveModalRef(activeModalRef);
    }
  }, [dialogOpen]);

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent
          style={{
            maxWidth: "50vw",
            padding: "20px",
            maxHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f0f0f0",
          }}
        >
          <SheetTitle className="mb-5 text-xl font-bold">Cap List</SheetTitle>
          <div className="space-y-8 overflow-y-auto h-full flex-grow">
            {caps.map((cap) => (
              <div
                key={cap.id}
                className="bg-white rounded-lg overflow-hidden shadow"
              >
                {/* Header Section */}
                <div className="p-6 border-b">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl font-bold">{cap.name}</h3>
                  </div>
                </div>

                {/* Status Section */}
                {cap.cap_status && (
                  <div className="py-2 border-b flex flex-row items-center pl-4">
                    <div className="flex-1 text-right">CAP Status:</div>
                    <StatusSelector
                      status={cap.cap_status}
                      handleStatusChange={async (value) =>
                        await handleStatusChange({
                          ...cap,
                          cap_status: value,
                        })
                      }
                      className="border mx-2 border-gray-300 rounded-full"
                      options={CapStatuses.map((status) => ({
                        value: status,
                        label: status.charAt(0).toUpperCase() + status.slice(1),
                        className:
                          status === "open"
                            ? "data-[state=on]:bg-yellow-400 data-[state=off]:hover:bg-yellow-100"
                            : "data-[state=on]:bg-green-400 data-[state=off]:hover:bg-green-100",
                      }))}
                      hideLabel={true}
                    />
                    <div className="flex-1 flex justify-end pr-4">
                      <Button
                        onClick={() => handleAddActionItem(cap)}
                        variant="outline"
                        className="ml-2 flex items-center gap-1 text-sm"
                      >
                        <Plus className="h-4 w-4" />
                        Add Action Item
                      </Button>
                    </div>
                  </div>
                )}

                {/* Description as the Centerpiece */}
                <div className="p-6">
                  <p className="text-lg text-gray-800">{cap.description}</p>
                </div>

                {/* Details Footer */}
                <div className="border-t px-6 py-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    {cap.delegate && (
                      <p className="text-sm text-gray-600">
                        <span className="font-medium">Delegate:</span>{" "}
                        {cap.delegate}
                      </p>
                    )}
                    {cap.department && (
                      <p className="text-sm text-gray-600">
                        <span className="font-medium">Department:</span>{" "}
                        {cap.department}
                      </p>
                    )}
                    {/* {cap.cap_type && (
                      <p className="text-sm text-gray-600">
                        <span className="font-medium">Type:</span>{" "}
                        {cap.cap_type}
                      </p>
                    )} */}
                  </div>
                  <div className="space-y-2 text-right">
                    <p className="text-sm text-gray-600">
                      <span className="font-medium">Issued:</span>{" "}
                      {cap.cap_issued_date
                        ? format(new Date(cap.cap_issued_date), "MMMM dd, yyyy")
                        : "N/A"}
                    </p>
                    <p className="text-sm text-gray-600">
                      <span className="font-medium">Due:</span>{" "}
                      {cap.cap_due_date
                        ? format(new Date(cap.cap_due_date), "MMMM dd, yyyy")
                        : "N/A"}
                    </p>
                    {cap.cap_completed_date && (
                      <p className="text-sm text-gray-600">
                        <span className="font-medium">Completed:</span>{" "}
                        {cap.cap_completed_date
                          ? format(
                              new Date(cap.cap_completed_date),
                              "MMMM dd, yyyy"
                            )
                          : "N/A"}
                      </p>
                    )}
                    {user && (
                      <p className="text-sm text-gray-500">
                        <span className="font-medium">Created by:</span>{" "}
                        {user.first_name} {user.last_name}
                      </p>
                    )}
                  </div>
                </div>

                {/* Optional Related Audit Tool Link */}
                {cap.element_metadata && (
                  <div className="border-t px-6 py-4">
                    <a
                      href={`/delegate-audit/${cap.element_metadata.audit_tool_id}/?fa=${cap.element_metadata.functional_area}&std=${cap.element_metadata.standard_index}&el=${cap.element_metadata.element_index}`}
                      className="text-sm font-semibold text-blue-600 hover:underline"
                    >
                      View Assessment
                    </a>
                  </div>
                )}

                {/* Optional Action Items Section with Accordion */}
                {cap.action_items && (
                  <div className="border-t">
                    <Accordion type="single" collapsible>
                      <AccordionItem value="action-items">
                        <AccordionTrigger className="w-full px-6 py-4 flex items-center justify-between text-sm font-semibold text-gray-700 hover:bg-gray-50">
                          <div className="flex items-center space-x-2">
                            <span>Action History</span>
                            <Badge className="text-xs bg-blue-500 text-white">
                              {cap.action_items.length}
                            </Badge>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent className="px-6 py-4">
                          <ul className="space-y-4">
                            {cap.action_items
                              .sort(
                                (a, b) =>
                                  new Date(b.created_at).getTime() -
                                  new Date(a.created_at).getTime()
                              )
                              .map((item) => (
                                <li
                                  key={item.id}
                                  className="bg-gray-100 p-4 rounded-lg shadow"
                                >
                                  <div className="flex flex-row justify-between gap-4 mb-2">
                                    <div>
                                      <span className="text-xs text-gray-500">
                                        Status:
                                      </span>
                                      <Badge
                                        className={`ml-2 text-xs ${
                                          item.action_item_status === "closed"
                                            ? "bg-green-500"
                                            : "bg-yellow-500"
                                        } text-white`}
                                      >
                                        {item.action_item_status
                                          ? item.action_item_status
                                              .charAt(0)
                                              .toUpperCase() +
                                            item.action_item_status.slice(1)
                                          : "Pending"}
                                      </Badge>
                                    </div>
                                    <div>
                                      <span className="text-xs text-gray-500">
                                        Date:
                                      </span>
                                      <span className="text-xs text-gray-500 ml-2">
                                        {item.created_at
                                          ? format(
                                              new Date(item.created_at),
                                              "MMMM dd, yyyy"
                                            )
                                          : "N/A"}
                                      </span>
                                    </div>
                                  </div>
                                  <p className="text-sm text-gray-800 whitespace-pre-line">
                                    {item.action_item}
                                  </p>
                                  <div className="mt-2">
                                    <span className="text-xs text-gray-500">
                                      Updated by:
                                    </span>
                                    <span className="text-xs text-gray-500 ml-2">
                                      {getUser(item.updated_by ?? "")
                                        ?.first_name || "Unknown"}
                                    </span>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>
                )}
              </div>
            ))}
          </div>
        </SheetContent>
      </Sheet>

      {/* Dialog for status update */}
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent ref={activeModalRef}>
          <DialogTitle>Update Cap Status</DialogTitle>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleDialogSubmit();
            }}
          >
            {/* Status Section */}
            <div className="mt-4 flex flex-row items-center border-b pb-4">
              <div className="flex-1 text-right">
                <label className="block text-sm font-medium text-gray-700">
                  Cap Status:
                </label>
              </div>
              <StatusSelector
                status={selectedCap?.cap_status || ""}
                handleStatusChange={async (value) => {
                  setSelectedCap((prevCap) =>
                    prevCap ? { ...prevCap, cap_status: value } : null
                  );
                }}
                className="border mx-2 border-gray-300 rounded-full space-x-0"
                options={CapStatuses.map((status) => ({
                  value: status,
                  label: status.charAt(0).toUpperCase() + status.slice(1),
                  className:
                    status === "open"
                      ? "data-[state=on]:bg-yellow-400 data-[state=off]:hover:bg-yellow-100"
                      : "data-[state=on]:bg-green-400 data-[state=off]:hover:bg-green-100",
                }))}
                hideLabel={true}
              />
              <div className="flex-1"></div>
            </div>

            {/* Notes Section */}
            <div className="space-y-2 py-4 border-b">
              <label className="block text-sm font-medium text-gray-700">
                Update Notes (Optional)
              </label>
              <Textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="w-full"
              />
            </div>

            {/* Due Date Section */}
            {/* <div className="space-y-2 mt-4 py-4 border-b">
              <label className="block text-sm font-medium text-gray-700">
                Due Date Extension (Optional)
              </label>
              <DatePicker
                value={
                  selectedCap?.cap_due_date
                    ? dayjs(selectedCap.cap_due_date)
                    : null
                }
                onChange={(date) => {
                  setSelectedCap((prevCap) =>
                    prevCap
                      ? {
                          ...prevCap,
                          cap_due_date: date?.toISOString() || null,
                        }
                      : null
                  );
                }}
                disableOpenPicker={true}
              />
            </div> */}

            <Button type="submit" className="mt-6 w-full">
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CapSheet;
