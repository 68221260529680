import { useAuthInfo } from "@propelauth/react";
import { ArrowRight, Check, ChevronsUpDown } from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import {
  BreadCrumbButton,
  BreadCrumbButtonExpandableContent,
  BreadCrumbButtonList,
  BreadCrumbButtonSeperator,
} from "../../components/BreadCrumbButton";
import { Layout } from "../../components/Layout";
import { ListTableToggle } from "../../components/ListTableToggle";
import { useModalContext } from "../../contexts/ActiveModalContext";
import { AuditToolContext } from "../../contexts/AuditToolContext";
import { Badge } from "../../shadcn/components/badge";
import { Breadcrumb } from "../../shadcn/components/breadcrumb";
import { Button } from "../../shadcn/components/button";
import { Checkbox } from "../../shadcn/components/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../shadcn/components/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import { Input } from "../../shadcn/components/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { Separator } from "../../shadcn/components/separator";
import { Textarea } from "../../shadcn/components/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { cn } from "../../shadcn/lib/utils";
import { AuditTool, Factor } from "../../types";
import {
  addCustomFactor,
  addFactors,
  generateAuditToolAssessment,
  getAllFactors,
  getAuditTool,
  getAuditToolPermissions,
  getAuditToolRelatedDocs,
  getAuditToolUserPermissions,
} from "../../utils/apiCalls";
import { AuditToolFileAudit } from "./AuditToolFileAudit";
import { DateSelector } from "./DateSelector";
import { ElementReview } from "./ElementReview";
import {
  AssessmentProgressBar,
  FactorTable,
  FunctionalAreaDashboardDisplay,
} from "./FactorDashboardDisplayTwo";
import { FactorTreeDisplay } from "./FactorTreeDisplay";
import { SharepointUrlPopover, TabButton } from "./components";
import {
  classifySource,
  getUniqueFunctionalAreas,
  smartSort,
  UniqueFunctionalArea,
} from "./utils";

// Function to get a cookie by name
const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
};

// Function to set a cookie
const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};

export const AuditToolBreadcrumbs = ({
  auditTool,
  factor,
}: {
  auditTool: AuditTool | null;
  factor?: Factor;
}) => {
  const [searchParams] = useSearchParams();
  const fa = searchParams.get("fa");
  const std = searchParams.get("std");
  const el = searchParams.get("el");

  let backUrl = `/delegate-audit/${auditTool?.id ?? ""}`;
  if (factor) {
    backUrl += `?fa=${encodeURIComponent(fa ?? factor?.functional_area)}&std=${encodeURIComponent(std ?? factor?.standard_index ?? "")}&el=${encodeURIComponent(el ?? factor?.element_index ?? "")}`;
  } else if (fa && std && el) {
    backUrl += `?fa=${encodeURIComponent(fa)}`;
  }
  return (
    <div className="flex flex-row items-center align-end">
      <Breadcrumb>
        <BreadCrumbButtonList>
          {/* <div className="flex flex-col items-center">
            <BreadCrumbButton href={backUrl} disabled={!(factor || el)}>
              <ArrowLeft className="h-4 w-4 mr-2" />
              Back
            </BreadCrumbButton>
          </div> */}
          <BreadCrumbButton href={`/delegate-audit`}>
            <BreadCrumbButtonExpandableContent
              label="Dashboard"
              value="Delegate Audit"
            />
          </BreadCrumbButton>
          <BreadCrumbButtonSeperator />

          <BreadCrumbButton href={`/delegate-audit/${auditTool?.id ?? ""}`}>
            <BreadCrumbButtonExpandableContent
              label="Audit Tool"
              value={auditTool?.name ?? ""}
              alwaysExpanded={!(fa || factor?.functional_area)}
            />
          </BreadCrumbButton>
          {(fa || factor?.functional_area) && (
            <>
              <BreadCrumbButtonSeperator />
              <BreadCrumbButton
                href={`/delegate-audit/${auditTool?.id ?? ""}?fa=${fa ?? factor?.functional_area}`}
              >
                <BreadCrumbButtonExpandableContent
                  label="Function"
                  value={fa ?? factor?.functional_area ?? "Unknown Function"}
                  alwaysExpanded={!(el || factor?.element_index)}
                />
              </BreadCrumbButton>
            </>
          )}
          {(el || factor?.element_index) && (
            <>
              <BreadCrumbButtonSeperator />
              <BreadCrumbButton
                href={`/delegate-audit/${auditTool?.id ?? ""}?fa=${encodeURIComponent(fa ?? factor?.functional_area ?? "")}&std=${encodeURIComponent(std ?? factor?.standard_index ?? "")}&el=${encodeURIComponent(el ?? factor?.element_index ?? "")}`}
              >
                <BreadCrumbButtonExpandableContent
                  label="Element"
                  value={el ?? factor?.element_index ?? "Unknown Element"}
                  alwaysExpanded={!factor}
                />
              </BreadCrumbButton>
            </>
          )}
          {factor && (
            <>
              <BreadCrumbButtonSeperator />
              <BreadCrumbButton
                href={`/delegate-audit/${auditTool?.id ?? ""}/factor/${factor.id}/audit`}
              >
                <BreadCrumbButtonExpandableContent
                  label="Requirement"
                  value={
                    factor.factor_title || factor.factor_index || "Untitled"
                  }
                  alwaysExpanded={true}
                />
              </BreadCrumbButton>
            </>
          )}
        </BreadCrumbButtonList>
      </Breadcrumb>
    </div>
  );
};

const CustomFactorDialog = ({
  onAddFactor,
}: {
  onAddFactor: (factors: {
    description: string;
    reference: string;
  }) => Promise<void>;
}) => {
  const { isOwner } = useContext(AuditToolContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [factorInfo, setFactorInfo] = useState<{
    description: string;
    reference: string;
  }>({
    description: "",
    reference: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await onAddFactor({ ...factorInfo });
      setIsDialogOpen(false);
      toast.success("Custom requirement added successfully");
    } catch (error) {
      toast.error("Error adding custom requirement");
    }
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button disabled={!isOwner} variant="outline">
          Custom Requirement
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Custom Requirement</DialogTitle>
          <DialogDescription>
            Create a custom requirement for your audit
            <div className="mt-2 p-2 bg-yellow-100 border border-yellow-400 text-yellow-700 rounded">
              We can support importing custom requirements from any source or
              existing audit tool. Contact us through teams or email
              support@readily.co to learn more.
            </div>
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="description">Requirement</label>
            <Textarea
              id="description"
              value={factorInfo.description}
              onChange={(e) =>
                setFactorInfo((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              required
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="reference">Reference</label>
            <Input
              id="reference"
              value={factorInfo.reference}
              onChange={(e) =>
                setFactorInfo((prev) => ({
                  ...prev,
                  reference: e.target.value,
                }))
              }
            />
          </div>
          <DialogFooter>
            <Button type="submit">Add Requirement</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const AddFactorsTwo = ({
  onAddFactors,
  presetFunctionalArea,
}: {
  onAddFactors: (factors: Factor[]) => Promise<void>;
  presetFunctionalArea?: string;
}) => {
  const { isOwner } = useContext(AuditToolContext);
  const authInfo = useAuthInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Factor[]>([]);
  const [filteredFactors, setFilteredFactors] = useState<Factor[]>([]);
  const [uniqueSources, setUniqueSources] = useState<Record<string, string[]>>(
    {}
  );
  const [dataSources, setDataSources] = useState<string[]>([
    "Policies and Procedures",
    "Case Files",
  ]);
  const [isSelectionSubmitted, setIsSelectionSubmitted] = useState(false);
  const [selectedSources, setSelectedSources] = useState<Set<string>>(
    new Set()
  );
  const [selectedFunctionalAreas, setSelectedFunctionalAreas] = useState<
    Set<string>
  >(new Set());
  const [selectedDataSources, setSelectedDataSources] = useState<Set<string>>(
    new Set()
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (isDialogOpen) {
      setSearchResults([]);
      setIsSelectionSubmitted(false);
      setSelectedSources(new Set());
      setSelectedFunctionalAreas(new Set());
      fetchAllFactors();
    }
  }, [isDialogOpen]);

  const handleAddFactors = async (factors: Factor[]) => {
    try {
      setIsLoading(true);
      await onAddFactors(factors);
      toast.success("Requirements added successfully");
    } catch (error) {
      toast.error("Error adding requirements");
    } finally {
      setIsLoading(false);
      setIsDialogOpen(false);
    }
  };

  const fetchAllFactors = async () => {
    try {
      setIsLoading(true);
      const factors = await getAllFactors(authInfo.accessToken ?? null);
      const uniqueSources = [
        ...new Set(factors.map((factor) => factor.source)),
      ];
      for (const source of uniqueSources) {
        const sourceFunctionalAreas = [
          ...new Set(
            factors
              .filter((factor) => factor.source === source)
              .map((factor) => factor.functional_area || "Unknown Function")
          ),
        ];
        setUniqueSources((prev) => ({
          ...prev,
          [source]: sourceFunctionalAreas,
        }));
      }
      setSearchResults(factors);
      setIsLoading(false);
    } catch (error) {
      toast.error("Error fetching factors");
    } finally {
      setIsLoading(false);
    }
  };

  const filterFactors = async () => {
    setIsLoading(true);
    try {
      const filteredFactors = searchResults.filter(
        (factor) =>
          selectedSources.has(factor.source) &&
          selectedFunctionalAreas.has(factor.functional_area) &&
          factorMatchesDataSources(factor)
      );
      setFilteredFactors(filteredFactors);
    } catch (error) {
      toast.error("Error filtering factors");
    } finally {
      setIsLoading(false);
    }
  };
  const factorMatchesDataSources = (factor: Factor) => {
    const standardDataSources = factor.standard_datasources
      ? (() => {
          try {
            return JSON.parse(factor.standard_datasources.replace(/'/g, '"'));
          } catch {
            return [factor.standard_datasources];
          }
        })()
      : [];
    const elementDataSources = factor.element_datasources
      ? (() => {
          try {
            return JSON.parse(factor.element_datasources.replace(/'/g, '"'));
          } catch {
            return [factor.element_datasources];
          }
        })()
      : [];
    const factorDataSource = classifySource([
      ...standardDataSources,
      ...elementDataSources,
    ]);

    switch (factorDataSource) {
      case "policy":
        return selectedDataSources.has("Policies and Procedures");
      case "file":
        return selectedDataSources.has("Case Files");
      case "both":
        return true;
      default:
        return false;
    }
  };
  const handleDataSourceCheckboxChange = (source: string) => {
    setSelectedDataSources((prev) => {
      const newSelectedDataSources = new Set(prev);
      if (newSelectedDataSources.has(source)) {
        newSelectedDataSources.delete(source);
      } else {
        newSelectedDataSources.add(source);
      }
      return newSelectedDataSources;
    });
  };

  const handleSourceCheckboxChange = (source: string) => {
    const danglingFAs = new Set<string>();
    setSelectedSources((prev) => {
      const newSelectedSources = new Set(prev);
      if (newSelectedSources.has(source)) {
        newSelectedSources.delete(source);
      } else {
        newSelectedSources.add(source);
      }
      for (const fa of selectedFunctionalAreas) {
        let isFaDangling = true;
        for (const source of newSelectedSources) {
          if (uniqueSources[source].includes(fa)) {
            isFaDangling = false;
            break;
          }
        }
        if (isFaDangling) {
          danglingFAs.add(fa);
        }
      }
      return newSelectedSources;
    });
    setSelectedFunctionalAreas((prev) => {
      const newSelectedFunctionalAreas = new Set(prev);
      for (const fa of danglingFAs) {
        newSelectedFunctionalAreas.delete(fa);
      }
      return newSelectedFunctionalAreas;
    });
  };

  const handleFunctionalAreaSelect = (functionalArea: string) => {
    setSelectedFunctionalAreas((prev) => {
      const newSelectedFunctionalAreas = new Set(prev);
      if (newSelectedFunctionalAreas.has(functionalArea)) {
        newSelectedFunctionalAreas.delete(functionalArea);
      } else {
        newSelectedFunctionalAreas.add(functionalArea);
      }
      return newSelectedFunctionalAreas;
    });
  };

  const handleSourceSelectAll = (source: string, select: boolean) => {
    setSelectedFunctionalAreas((prev) => {
      const newSelectedFunctionalAreas = new Set(prev);
      for (const functionalArea of uniqueSources[source]) {
        if (select && !newSelectedFunctionalAreas.has(functionalArea)) {
          newSelectedFunctionalAreas.add(functionalArea);
        } else if (!select && newSelectedFunctionalAreas.has(functionalArea)) {
          newSelectedFunctionalAreas.delete(functionalArea);
        }
      }
      return newSelectedFunctionalAreas;
    });
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button disabled={!isOwner} variant="outline">
          Scope Audit
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[90vw] w-[900px] max-h-[90vh] h-[90vh] overflow-y-auto flex flex-col">
        {isLoading && (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            <span className="ml-3">
              {isSelectionSubmitted
                ? "Filtering requirements..."
                : "Loading requirements..."}
            </span>
          </div>
        )}
        {!isLoading && !isSelectionSubmitted && (
          <div className="space-y-8">
            <DialogHeader>
              <DialogTitle>
                <span className="text-2xl font-bold">Audit Scope</span>
              </DialogTitle>
              <div className="flex justify-between space-x-8">
                <DialogDescription>
                  <div className="flex flex-col">
                    <p>Scope your audit in a few steps.</p>
                    <p>
                      We'll suggest relevant requirements for you to choose
                      from.
                    </p>
                  </div>
                </DialogDescription>
                <Button
                  onClick={() => {
                    setIsSelectionSubmitted(true);
                    setIsLoading(true);
                    setTimeout(() => {
                      filterFactors();
                    }, 0);
                  }}
                  className="animate-in fade-in duration-300 w-[280px]"
                  disabled={
                    selectedSources.size === 0 ||
                    selectedFunctionalAreas.size === 0
                  }
                >
                  Show Matching Requirements
                  <ArrowRight className="w-4 h-4 ml-2" />
                </Button>
              </div>
            </DialogHeader>
            <div>
              <div className="flex items-center gap-3 font-semibold text-gray-500 mb-3">
                <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-500 text-white font-semibold">
                  1
                </span>
                Select Data Format:
              </div>
              <div className="grid grid-cols-2 gap-4 ml-8 mr-8">
                {[...dataSources].map((source) => (
                  <Badge
                    key={source}
                    variant={
                      selectedDataSources.has(source) ? "default" : "outline"
                    }
                    className={`cursor-pointer hover:opacity-80 flex justify-center py-2 ${
                      selectedDataSources.has(source)
                        ? "!bg-green-200 !text-black"
                        : ""
                    }`}
                    onClick={() => {
                      handleDataSourceCheckboxChange(source);
                    }}
                  >
                    {source}
                  </Badge>
                ))}
              </div>
            </div>
            {selectedDataSources.size > 0 && (
              <>
                <Separator />
                <div>
                  <div className="flex items-center gap-3 font-semibold text-gray-500 animate-in fade-in duration-300 mb-3">
                    <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-500 text-white font-semibold">
                      2
                    </span>
                    Select Reference Sources:
                  </div>
                  <div className="grid grid-cols-2 gap-4 ml-8 mr-8">
                    {Object.keys(uniqueSources)
                      .sort()
                      .map((source) => (
                        <Badge
                          key={source}
                          variant={
                            selectedSources.has(source) ? "default" : "outline"
                          }
                          className={`cursor-pointer hover:opacity-80 flex justify-center py-2 ${
                            selectedSources.has(source)
                              ? "!bg-green-200 !text-black"
                              : ""
                          }`}
                          onClick={() => {
                            handleSourceCheckboxChange(source);
                          }}
                        >
                          {source}
                        </Badge>
                      ))}
                  </div>
                </div>
              </>
            )}
            {selectedSources.size > 0 && (
              <>
                <Separator />
                <div>
                  <div className="flex items-center gap-3 font-semibold text-gray-500 mb-3">
                    <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-500 text-white font-semibold">
                      3
                    </span>
                    Select Functional Areas:
                  </div>
                  {Array.from(selectedSources).map((source) => (
                    <div
                      key={source}
                      className="animate-in fade-in duration-300 bg-gray-100 pb-2 rounded-md ml-8 mr-8 mb-4 pb-4"
                    >
                      <div className="font-bold text-gray-800 mb-2 px-2 py-1 shadow-sm flex justify-between items-center">
                        <span>{source}</span>
                        <div className="text-xs">
                          <Button
                            variant="secondary"
                            size="sm"
                            className="hover:bg-gray-200"
                            onClick={() => {
                              handleSourceSelectAll(source, true);
                            }}
                          >
                            Select All
                          </Button>
                          <Button
                            variant="secondary"
                            size="sm"
                            className="hover:bg-gray-200"
                            onClick={() => {
                              handleSourceSelectAll(source, false);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4 ml-4 mr-4 mt-4">
                        {uniqueSources[source].sort().map((functionalArea) => (
                          <Badge
                            key={functionalArea}
                            variant={
                              selectedFunctionalAreas.has(functionalArea)
                                ? "default"
                                : "outline"
                            }
                            className={`cursor-pointer hover:opacity-80 flex justify-center py-2 ${
                              selectedFunctionalAreas.has(functionalArea)
                                ? "!bg-green-200 !text-black"
                                : "bg-white"
                            }`}
                            onClick={() => {
                              handleFunctionalAreaSelect(functionalArea);
                            }}
                          >
                            {functionalArea}
                          </Badge>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
        {filteredFactors.length > 0 && !isLoading && isSelectionSubmitted && (
          <div className="overflow-y-auto flex-1">
            <FactorTreeDisplay
              factors={filteredFactors}
              onSubmit={handleAddFactors}
              onBack={() => setIsSelectionSubmitted(false)}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const AuditToolDashboard = () => {
  const { auditToolId } = useParams<{
    auditToolId: string;
  }>();
  const [viewType, setViewType] = useState<"list" | "table">(
    () => (getCookie("audit-tool-view-type") as "list" | "table") || "list"
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const fa = searchParams.get("fa");
  const std = searchParams.get("std");
  const el = searchParams.get("el");
  const [factorDisplayFactors, setFactorDisplayFactors] = useState<Factor[]>(
    []
  );
  const [standardElements, setStandardElements] = useState<
    Record<string, string>
  >({});
  const [elementNeighbors, setElementNeighbors] = useState<{
    previous: { fa: string; std: string; el: string } | false;
    next: { fa: string; std: string; el: string } | false;
  }>({
    previous: false,
    next: false,
  });
  const [selectedTab, setSelectedTab] = useState<"policies" | "files">(
    "policies"
  );

  const authInfo = useAuthInfo();
  const {
    auditTool,
    setAuditTool,
    findElementNeighbors,
    setAuditToolUserPermissions,
    setAuditToolAllPermissions,
    setIsOwner,
  } = useContext(AuditToolContext);
  const handleAddFactors = async (factors: Factor[]) => {
    if (!auditTool) {
      return;
    }
    const updatedFactors = await addFactors(
      auditTool.id,
      factors,
      authInfo.accessToken ?? null
    );
    setAuditTool((prevAuditTool) => {
      if (!prevAuditTool) return null;
      return {
        ...prevAuditTool,
        factors: updatedFactors,
      };
    });
  };
  const handleAddCustomFactor = async ({
    description,
    reference,
  }: {
    description: string;
    reference: string;
  }) => {
    if (!auditTool) {
      return;
    }
    const updatedFactors = await addCustomFactor(
      auditTool.id,
      {
        functional_area: auditTool?.scope?.split(",")[0] ?? "",
        description,
        reference,
      },
      authInfo.accessToken ?? null
    );
    setAuditTool((prevAuditTool) => {
      if (!prevAuditTool) return null;
      return {
        ...prevAuditTool,
        factors: updatedFactors,
      };
    });
  };
  const handleGenerateAuditToolAssessment = async (
    functionalAreas: string[]
  ) => {
    if (!auditTool) {
      return;
    }

    try {
      const { filename, blob } = await generateAuditToolAssessment(
        auditTool.id,
        functionalAreas,
        authInfo.accessToken ?? null
      );
      // Create download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename ?? `${auditTool.name} ${fa} Audit.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("Assessment downloaded successfully");
    } catch (error) {
      console.error("Error generating assessment:", error);
      toast.error("Failed to generate assessment");
    }
  };
  const fetchAuditTool = async () => {
    if (auditToolId) {
      try {
        setIsLoading(true);
        const auditToolPromise = getAuditTool(
          auditToolId,
          authInfo.accessToken ?? null
        );
        const relatedDocsPromise = getAuditToolRelatedDocs(
          auditToolId,
          authInfo.accessToken ?? null
        );
        const permissionsPromise = getAuditToolUserPermissions(
          auditToolId,
          authInfo.accessToken ?? null
        );
        const allPermissionsPromise = getAuditToolPermissions(
          auditToolId,
          authInfo.accessToken ?? null
        );
        const [auditTool, relatedDocs, permissions, allPermissions] =
          await Promise.all([
            auditToolPromise.catch((error) => {
              console.error("Error fetching audit tool:", error);
              toast.error("Failed to fetch audit tool");
              return null;
            }),
            relatedDocsPromise.catch((error) => {
              console.error("Error fetching related docs:", error);
              toast.error("Failed to fetch related docs");
              return [];
            }),
            permissionsPromise.catch((error) => {
              console.error("Error fetching user permissions:", error);
              toast.error("Failed to fetch user permissions");
              return [];
            }),
            allPermissionsPromise.catch((error) => {
              console.error("Error fetching all permissions:", error);
              return [];
            }),
          ]);
        if (!auditTool) {
          return;
        }
        if (auditTool) {
          auditTool.related_docs = relatedDocs;
          setAuditTool(auditTool);
        }
        setAuditToolUserPermissions(permissions);
        setAuditToolAllPermissions(allPermissions);
        const orgs = authInfo.orgHelper?.getOrgs();
        let isAdmin = false;
        if (orgs?.length === 1) {
          const org = orgs[0];
          console.log("org", org);
          isAdmin =
            org.userAssignedRole === "Admin" ||
            org.userAssignedRole === "Agent";
        }
        setIsOwner(
          isAdmin || permissions.some((permission) => permission.is_owner)
        );
      } catch (error) {
        console.error("Error fetching audit tool:", error);
        toast.error("Failed to fetch audit tool");
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (fa && std && el) {
      const standardElements: Record<string, string> = {};
      for (const factor of auditTool?.factors ?? []) {
        if (
          factor.functional_area === fa &&
          factor.standard_index === std &&
          !standardElements[factor.element_index]
        ) {
          standardElements[factor.element_index] = factor.element_title;
        }
      }
      setStandardElements(standardElements);
      const factors = auditTool?.factors.filter(
        (factor) =>
          factor.functional_area === fa &&
          factor.standard_index === std &&
          factor.element_index === el
      );
      setFactorDisplayFactors(
        (factors ?? []).sort((a, b) => {
          return smartSort(a.factor_index, b.factor_index);
        })
      );
      const elementNeighbors = findElementNeighbors(fa, std, el);
      setElementNeighbors(elementNeighbors);
    }
  }, [fa, std, el, auditTool]);
  useEffect(() => {
    fetchAuditTool();
  }, [auditToolId]);
  useEffect(() => {
    fetchAuditTool();
  }, []);

  const handleViewTypeChange = (newViewType: "list" | "table") => {
    setViewType(newViewType);
    setCookie("audit-tool-view-type", newViewType, 365);
  };

  return (
    <Layout pageName={auditTool ? auditTool.name : "Audit Tool Dashboard"}>
      <div
        className={`flex flex-col ${viewType === "list" ? "h-[calc(100vh-81px)]" : "h-auto"}`}
      >
        <div className="flex flex-row justify-between items-center mb-4">
          <AuditToolBreadcrumbs auditTool={auditTool} />
        </div>
        {fa && std && el && !isLoading ? (
          <div className="card bg-white p-4 rounded shadow overflow-auto h-full">
            <div className="flex flex-col gap-4 h-full">
              <div className="flex flex-row justify-between items-center">
                <h1 className="text-2xl font-bold flex flex-row items-center gap-2">
                  <span className="inline-block">{std} - </span>
                  <StandardElementMultiPicker
                    standardElements={standardElements}
                    activeElement={factorDisplayFactors[0]?.element_index}
                    handleElementChange={(element) => {
                      navigate(
                        `/delegate-audit/${auditToolId}/?fa=${fa}&std=${std}&el=${element}`
                      );
                    }}
                  />
                </h1>
                <div className="flex flex-row gap-2">
                  <Tooltip>
                    <TooltipTrigger>
                      <Button
                        onClick={() => {
                          if (elementNeighbors.next) {
                            navigate(
                              `/delegate-audit/${auditToolId}/?fa=${elementNeighbors.next.fa}&std=${elementNeighbors.next.std}&el=${elementNeighbors.next.el}`
                            );
                          }
                        }}
                        variant="outline"
                        disabled={!elementNeighbors.next}
                      >
                        Next Element
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <div className="p-2">
                        {elementNeighbors.next ? (
                          <>
                            <div className="font-semibold">
                              Go to next element:
                            </div>
                            <div className="text-sm">
                              {elementNeighbors.next.std} -{" "}
                              {elementNeighbors.next.el}
                            </div>
                          </>
                        ) : (
                          <div className="text-sm text-gray-500">
                            No more elements in this Function
                          </div>
                        )}
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </div>
              </div>
              <div className="mt-2">
                <AssessmentProgressBar factors={factorDisplayFactors} />
              </div>
              <ResizablePanelGroup direction="horizontal">
                <ResizablePanel>
                  <div className="h-full overflow-y-scroll">
                    <FactorTable factors={factorDisplayFactors} />
                  </div>
                </ResizablePanel>
                <ResizableHandle />
                <ResizablePanel>
                  <div className="h-full overflow-y-scroll">
                    <ElementReview
                      auditToolId={auditTool?.id ?? ""}
                      fa={fa}
                      std={std}
                      el={el}
                      isFactorView={false}
                      elementFactors={factorDisplayFactors}
                    />
                  </div>
                </ResizablePanel>
              </ResizablePanelGroup>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-row gap-2">
              <TabButton
                label="Audit Policies"
                selected={selectedTab === "policies"}
                onClick={() => {
                  setSelectedTab("policies");
                }}
              />
              <TabButton
                label="Audit Files"
                selected={selectedTab === "files"}
                onClick={() => {
                  setSelectedTab("files");
                }}
                disabled={true}
              />
              <div className="flex-1" />
              <ListTableToggle
                viewType={viewType}
                setViewType={handleViewTypeChange}
                title="Audit Tool"
              />
            </div>
            <div className="flex-1 overflow-hidden">
              <div className="card bg-white p-4 h-full rounded shadow overflow-auto">
                {selectedTab === "policies" ? (
                  <div>
                    <div className="mb-4">
                      <div className="toolbar p-1 rounded flex gap-2 items-end border-b border-gray-200 pb-4">
                        {/* Toolbar content goes here */}
                        <AddFactorsTwo
                          onAddFactors={handleAddFactors}
                          presetFunctionalArea={auditTool?.scope ?? undefined}
                        />
                        <CustomFactorDialog
                          onAddFactor={handleAddCustomFactor}
                        />
                        <SharepointUrlPopover />
                        <div className="flex-1 flex justify-center">
                          <DateSelector />
                        </div>
                        {auditTool && (
                          <ExportAuditToolButton
                            auditTool={auditTool}
                            selectedFunctionalArea={fa}
                            onExport={handleGenerateAuditToolAssessment}
                          />
                        )}
                        {/* <Tooltip>
                          <TooltipTrigger>
                            <Button onClick={handleGenerateAuditToolAssessment}>
                              Export Audit
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            Export Delegate Audit to Excel
                          </TooltipContent>
                        </Tooltip> */}
                      </div>
                    </div>
                    {isLoading ? (
                      <div className="flex justify-center items-center py-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                        <span className="ml-3">Loading...</span>
                      </div>
                    ) : (
                      <FunctionalAreaDashboardDisplay viewType={viewType} />
                    )}
                  </div>
                ) : (
                  <AuditToolFileAudit />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

const StandardElementMultiPicker = ({
  standardElements,
  activeElement,
  handleElementChange,
}: {
  standardElements: Record<string, string>;
  activeElement: string;
  handleElementChange: (element: string) => void;
}) => {
  const [standardElementPopoverOpen, setStandardElementPopoverOpen] =
    useState(false);
  const standardElementPopoverTriggerRef = useRef<HTMLButtonElement>(null);
  return (
    <Popover
      open={standardElementPopoverOpen}
      onOpenChange={setStandardElementPopoverOpen}
    >
      <PopoverTrigger asChild>
        <div className="min-w-[400px]">
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={standardElementPopoverOpen}
            className="text-2xl font-bold justify-start"
            ref={standardElementPopoverTriggerRef}
          >
            <div className="flex flex-1 flex-row justify-between items-center space-x-4">
              <div className="text-2xl font-bold">
                {standardElements[activeElement]}
              </div>
              <ChevronsUpDown className="mx-2 h-4 w-4 shrink-0 opacity-50" />
            </div>
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="w-full min-w-[400px] p-0"
        side="bottom"
        align="center"
        style={{
          width: standardElementPopoverTriggerRef.current?.offsetWidth,
        }}
      >
        <Command>
          <CommandInput placeholder="Search standard element..." />
          <CommandList>
            <CommandEmpty>No standard elements found.</CommandEmpty>
            <CommandGroup>
              {Object.keys(standardElements).map((element) => (
                <CommandItem
                  key={element}
                  value={element}
                  onSelect={() => {
                    handleElementChange(element);
                    setStandardElementPopoverOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      element === activeElement ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {standardElements[element]}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const ExportAuditToolButton = ({
  auditTool,
  onExport,
  selectedFunctionalArea,
}: {
  auditTool: AuditTool;
  onExport: (functionalAreas: string[]) => void;
  selectedFunctionalArea: string | null;
}) => {
  const authInfo = useAuthInfo();
  const { activeModalRef } = useModalContext();
  const [functionalAreaPopoverOpen, setFunctionalAreaPopoverOpen] =
    useState(false);
  const functionalAreaPopoverTriggerRef = useRef<HTMLButtonElement>(null);
  const [functionalAreas, setFunctionalAreas] = useState<
    UniqueFunctionalArea[]
  >([]);
  const [selectedFunctionalAreas, setSelectedFunctionalAreas] = useState<
    string[]
  >([]);
  const populateFunctionalAreas = async () => {
    const functionalAreas = await getUniqueFunctionalAreas(auditTool.factors);
    setFunctionalAreas(functionalAreas);
    if (selectedFunctionalArea) {
      setSelectedFunctionalAreas(
        functionalAreas
          .filter((fa) => fa.functional_area === selectedFunctionalArea)
          .map((fa) => fa.functional_area)
      );
    }
  };
  const handleGenerateAuditToolAssessment = async () => {
    if (!auditTool) {
      return;
    }
    onExport(selectedFunctionalAreas);
  };
  useEffect(() => {
    populateFunctionalAreas();
  }, [auditTool]);
  useEffect(() => {
    populateFunctionalAreas();
  }, []);

  const handleFunctionalAreaToggle = (functionalArea: string) => {
    setSelectedFunctionalAreas((prev) => {
      if (prev.includes(functionalArea)) {
        return prev.filter((fa) => fa !== functionalArea);
      } else {
        return [...prev, functionalArea];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedFunctionalAreas.length === functionalAreas.length) {
      setSelectedFunctionalAreas([]);
    } else {
      setSelectedFunctionalAreas(
        functionalAreas.map((fa) => fa.functional_area)
      );
    }
  };

  return (
    <Popover
      open={functionalAreaPopoverOpen}
      onOpenChange={setFunctionalAreaPopoverOpen}
    >
      <PopoverTrigger>
        <Button
          variant="default"
          aria-expanded={functionalAreaPopoverOpen}
          className=""
          ref={functionalAreaPopoverTriggerRef}
        >
          <div className="flex flex-1 flex-row justify-between items-center space-x-4">
            <div className="">Export Audit</div>
            <ChevronsUpDown className="mx-2 h-4 w-4 shrink-0 opacity-50" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-full min-w-[400px] p-0"
        container={activeModalRef?.current}
        side="bottom"
        align="center"
        style={{
          width: functionalAreaPopoverTriggerRef.current?.offsetWidth,
        }}
      >
        <div className="p-4 space-y-4">
          <div className="flex items-center justify-between border-b pb-2">
            <span className="font-semibold">Select Functional Areas</span>
            <Button
              variant="ghost"
              size="sm"
              onClick={handleSelectAll}
              className="text-sm"
            >
              {selectedFunctionalAreas.length === functionalAreas.length
                ? "Deselect All"
                : "Select All"}
            </Button>
          </div>
          <div className="space-y-2 max-h-[300px] overflow-y-auto">
            {functionalAreas.map((fa) => (
              <div
                key={fa.functional_area}
                className="flex items-center space-x-2 p-2 hover:bg-gray-100 rounded cursor-pointer"
                onClick={() => handleFunctionalAreaToggle(fa.functional_area)}
              >
                <Checkbox
                  checked={selectedFunctionalAreas.includes(fa.functional_area)}
                  onChange={() =>
                    handleFunctionalAreaToggle(fa.functional_area)
                  }
                  className="h-4 w-4"
                />
                <span>{fa.functional_area}</span>
                <span className="text-gray-500 text-sm ml-auto">
                  ({fa.factors.length})
                </span>
              </div>
            ))}
          </div>
          <div className="border-t pt-2 flex justify-end space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setFunctionalAreaPopoverOpen(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              onClick={() => {
                handleGenerateAuditToolAssessment();
                setFunctionalAreaPopoverOpen(false);
              }}
              disabled={selectedFunctionalAreas.length === 0}
            >
              Export Selected
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
export default AuditToolDashboard;
