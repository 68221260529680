import { useAuthInfo } from "@propelauth/react";
import { X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { DocViewerCitation } from "../../components/DocViewer";
import { Layout } from "../../components/Layout";
import { PdfCitation } from "../../components/PdfViewer/PdfHighlighter/types";
import { ResourceSelector } from "../../components/Resource/ResourceSelector";
import { SearchResourceBar } from "../../components/Resource/SearchResource";
import { useChatContext } from "../../contexts/ChatContext";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "../../shadcn/components/button";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn/components/tabs";
import { cn } from "../../shadcn/lib/utils";
import { ChatHistoryMetadata } from "../../types";
import { getChat, getChatHistory } from "../../utils/apiCalls";
import { TimeAgo } from "../../utils/format";
import { ChatHighlightActions } from "./ChatHighlightActions";
import { ChatView } from "./ChatView";

const ChatHistoryItem = (props: {
  metadata: ChatHistoryMetadata;
  activeChatId: string | null;
  onClick: (chatId: string) => Promise<void>;
}) => {
  const selectedChat = props.metadata.chat_id === props.activeChatId;
  return (
    <div
      className={cn(
        "space-y-2 h-[80px] p-5 rounded-md",
        selectedChat
          ? "bg-gray-300"
          : "bg-white hover:bg-gray-200 cursor-pointer"
      )}
      onClick={() => props.onClick(props.metadata.chat_id)}
    >
      <div className="font-bold text-xs overflow-hidden text-ellipsis truncate">
        {props.metadata.title}
      </div>
      <div className="text-xs text-gray-500">
        <TimeAgo timestamp={props.metadata.updated_at} />
      </div>
    </div>
  );
};

export const DocChatView = () => {
  const authInfo = useAuthInfo();
  const [chatEnabled, setChatEnabled] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string>("chat");
  const [chatHistory, setChatHistory] = useState<ChatHistoryMetadata[]>([]);
  const { uploadEnabled } = useContext(UserContext);
  const {
    setPageNumber,
    setDocToView,
    docToView,
    setCitations,
    docMetadata,
    fileSelectorOpen,
  } = useContext(DocViewerContext);
  const {
    chatId,
    setChatId,
    sendUserMessage,
    setChatMessages,
    selectedResources,
    activeResource,
    setSelectedResources,
    activeResourceId,
    setActiveResourceId,
  } = useChatContext();

  useEffect(() => {
    const paramsChatId = searchParams.get("chatId");
    if (!paramsChatId) {
      const paramsDocId = searchParams.get("docId");
      if (paramsDocId) {
        if (docToView?.docId !== paramsDocId) {
          setDocToView({
            docId: paramsDocId,
          });
        }
        setSelectedResources([
          {
            id: paramsDocId,
            name: "Document",
            match_type: "name",
            type: "document",
            citation: null,
            doc_type_name: null,
            regulatory_doc_id: null,
            count: null,
          },
        ]);
        setActiveResourceId(paramsDocId);
        const page = Number(searchParams.get("page") ?? 1);
        const text = searchParams.get("text");
        setSearchParams((prev) => {
          prev.delete("docId");
          prev.delete("page");
          prev.delete("text");
          return prev;
        });
        let citations: PdfCitation[] = [];
        if (text) {
          citations = [
            {
              id: `unknown-citation`,
              match: text,
              exactMatch: false,
              page: page,
              citationTooltip: (
                <ChatHighlightActions
                  setMessage={sendUserMessage}
                  onClickCallback={() => setActiveTab("chat")}
                  citationId={`unknown-citation`}
                />
              ),
              tooltipPosition: "bottom",
            },
          ];
        }
        setTimeout(() => {
          setPageNumber(page);
          setCitations(citations);
        }, 200);
      } else if (!paramsDocId) {
        setDocToView(null);
        setPageNumber(1);
        setCitations([]);
        setActiveTab("history");
      }
    }
    getChatHistory(authInfo.accessToken ?? "").then((response) => {
      if (response !== null) {
        setChatHistory(response);
        if (paramsChatId) {
          onChatHistoryClick(paramsChatId);
        } else if (response.length === 0) {
          setActiveTab("chat");
        }
      } else {
        toast.error("Failed to load chat history");
      }
    });
  }, []);

  const onChatHistoryClick = async (chatId: string) => {
    const response = await getChat(chatId, authInfo.accessToken ?? "");
    if (response !== null) {
      setChatId(chatId);
      setChatMessages({
        chat: response.chat,
        citations: response.citations,
      });
      setSelectedResources(response.resources);
      // check if any of those resources are documents
      const docResources = response.resources.filter(
        (resource) =>
          resource.type === "document" ||
          resource.type === "document_requirements"
      );
      if (docResources.length > 0) {
        setActiveResourceId(docResources[0].id);
      }
      setActiveTab("chat");
    } else {
      toast.error("Failed to load chat");
    }
  };

  useEffect(() => {
    if (chatId) {
      setSearchParams({ chatId: chatId });
    }
  }, [chatId]);

  useEffect(() => {
    if (activeResource) {
      if (activeResource.type === "document") {
        setDocToView({
          docId: activeResource.id,
        });
        setActiveTab("chat");
        const citation = activeResource.citation ?? null;
        if (citation !== null) {
          setCitations([
            {
              id: citation.id!,
              match: citation.text,
              exactMatch: false,
              page: citation.page,
              citationTooltip: (
                <ChatHighlightActions
                  setMessage={sendUserMessage}
                  onClickCallback={() => setActiveTab("chat")}
                  citationId={citation.id!}
                />
              ),
              tooltipPosition: "bottom",
            },
          ]);
          setPageNumber(citation.page);
        } else {
          setPageNumber(1);
        }
      }
    } else {
      setDocToView(null);
      setCitations([]);
      setPageNumber(1);
    }
  }, [activeResource]);

  useEffect(() => {
    if (docMetadata && !fileSelectorOpen) {
      // check if doc id in selected resources
      const docResource = selectedResources.find(
        (resource) => resource.id === docMetadata.id
      );
      if (!docResource) {
        setSelectedResources((prev) => [
          ...prev,
          {
            id: docMetadata.id,
            name: docMetadata.name,
            match_type: "name",
            type: "document",
            citation: null,
            doc_type_name: null,
            regulatory_doc_id: docMetadata.regulatory_doc_id,
            count: null,
          },
        ]);
      }
    }
  }, [docMetadata]);

  return (
    <Layout pageName="Assistant">
      <div className="space-y-2">
        <div className="space-y-1">
          <SearchResourceBar
            docTypeIds={[]}
            onItemSelect={(item) => {
              setSelectedResources((prev) => [
                ...prev.filter((r) => r.id !== item.id),
                item,
              ]);
              if (item.type === "document") {
                setActiveResourceId(item.id);
              }
            }}
            hideAtlasWidget={true}
            contentMatch={false}
            multiSelect={true}
            uploadButton={uploadEnabled}
          />
          <ResourceSelector
            selectedResources={selectedResources}
            setSelectedResources={setSelectedResources}
            activeResourceId={activeResourceId}
            setActiveResourceId={setActiveResourceId}
            selectableResourceTypes={["document"]}
          />
        </div>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={docToView ? 70 : 30}
            minSize={30}
            maxSize={70}
            id="doc-panel"
            order={2}
          >
            {docToView && (
              <DocViewerCitation
                docId={docToView.docId}
                className="h-[calc(100vh-280px)] pr-4"
                hideAtlasWidget={true}
                onCitationsUpdate={(pdfCitation: PdfCitation) => {
                  setCitations((prev) => {
                    const newCitation: PdfCitation = {
                      citationTooltip: (
                        <ChatHighlightActions
                          citationId={pdfCitation.id || "new-citation"}
                          setMessage={sendUserMessage}
                          onClickCallback={() => setActiveTab("chat")}
                        />
                      ),
                      tooltipPosition: "bottom",
                      ...pdfCitation,
                    };
                    let prevCitationExists = false;
                    let citations: PdfCitation[] = prev.map((citation) => {
                      if (citation.id === pdfCitation.id) {
                        prevCitationExists = true;
                        return newCitation;
                      } else {
                        return citation;
                      }
                    });
                    if (!prevCitationExists) {
                      citations.push(newCitation);
                    }
                    return citations;
                  });
                }}
              >
                {/* {!chatEnabled && docToView !== null && (
                  <Button
                    variant="default"
                    onClick={() => setChatEnabled(true)}
                    size="sm"
                  >
                    <SparkleIcon className="w-4 h-4 mr-2" />
                    Chat
                  </Button>
                )} */}
              </DocViewerCitation>
            )}
            {!docToView && (
              <div className="h-[calc(100vh-280px)] flex items-center justify-center">
                <div className="text-muted-foreground">
                  Chat with all documents, or select specific one(s) above
                </div>
              </div>
            )}
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel
            defaultSize={docToView ? 30 : 70}
            minSize={30}
            maxSize={70}
            id="chat-panel"
            order={3}
          >
            {chatEnabled && (
              <Tabs value={activeTab} onValueChange={setActiveTab}>
                <div className="pl-4 relative">
                  <div className="flex justify-between items-center">
                    <div>
                      <TabsList>
                        <TabsTrigger value="chat">Chat</TabsTrigger>
                        <TabsTrigger value="history">History</TabsTrigger>
                      </TabsList>
                    </div>
                    <div className="flex items-center">
                      <Button
                        onClick={() => {
                          setChatId(null);
                          setChatMessages(null);
                          setActiveTab("chat");
                        }}
                        variant="default"
                        className="text-sm"
                        size="sm"
                      >
                        New Chat
                      </Button>
                      <Button
                        onClick={() => setChatEnabled(false)}
                        aria-label="Close chat"
                        variant="ghost"
                        size="icon"
                      >
                        <X className="w-4 h-4" />
                      </Button>
                    </div>
                  </div>
                  <TabsContent value="chat">
                    <div className="mr-3 relative">
                      <ChatView
                        chatHighlightCallback={() => setActiveTab("chat")}
                        citationTooltip={true}
                      />
                    </div>
                  </TabsContent>
                  <TabsContent value="history">
                    <div className="space-y-2 h-[calc(100vh-218px)] overflow-y-auto">
                      {chatHistory.map((chatHistoryMetadata) => {
                        return (
                          <ChatHistoryItem
                            key={chatHistoryMetadata.chat_id}
                            metadata={chatHistoryMetadata}
                            activeChatId={chatId}
                            onClick={onChatHistoryClick}
                          />
                        );
                      })}
                    </div>
                  </TabsContent>
                </div>
              </Tabs>
            )}
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </Layout>
  );
};
