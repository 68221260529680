import { useAuthInfo } from "@propelauth/react";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "sonner";

import {
  AuditFactorAssessment,
  AuditToolElementReview,
  Cap,
  Factor,
  auditFactorAssessment,
  auditFactorAssessmentStatusLabelClasses,
} from "../../types";
import {
  CapUpdateRequest,
  createCap,
  generateAuditToolElementReview,
  getAuditToolElementReview,
  getCap,
  getElementMetadataCaps,
  updateAuditToolElementReview,
  updateCap,
} from "../../utils/apiCalls";

import { StatusSelector } from "../../components/StatusSelector";
import { useModalContext } from "../../contexts/ActiveModalContext";
import { AuditToolContext } from "../../contexts/AuditToolContext";
import { UserContext } from "../../contexts/UserContext";
import { Badge } from "../../shadcn/components/badge";
import { Button } from "../../shadcn/components/button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import CreateCapForm from "../cap/components/CapCreateForm";
import CapSheet from "../cap/components/CapSheet";
import { smartSort } from "./utils";

type ElementReviewProps = {
  auditToolId: string;
  fa: string;
  std: string;
  el: string;
  isFactorView: boolean;
  elementFactors: Factor[];
};

const FactorNotesOverview = ({ factors }: { factors: Factor[] }) => {
  if (!factors || factors.length === 0) return null;

  const concatenatedNotes = factors
    .filter((factor) => factor.notes)
    .map(
      (factor) =>
        `${factor.factor_index} ${factor.factor_title ? `- ${factor.factor_title}` : ""}: ${factor.notes || "No notes"}`
    )
    .join("\n");

  return (
    <div className="mb-2">
      <label className="block font-medium mb-1 text-lg">Notes:</label>
      <div className="w-full bg-white border rounded-md p-4">
        <div className="whitespace-pre-wrap text-sm text-gray-700 leading-relaxed">
          {concatenatedNotes}
        </div>
      </div>
    </div>
  );
};

const FactorFindingsOverview = ({ factors }: { factors: Factor[] }) => {
  if (!factors || factors.length === 0) return null;

  const concatenatedFindings = factors
    .filter((factor) => factor.findings)
    .sort((a, b) => smartSort(a.factor_index, b.factor_index))
    .map(
      (factor) =>
        `${factor.factor_index} ${factor.factor_title ? `- ${factor.factor_title}` : ""}: ${factor.findings || "No findings"}`
    )
    .join("\n");

  return (
    <div className="mb-2">
      <label className="block font-medium mb-1 text-lg">Findings:</label>
      <div className="w-full bg-white border rounded-md p-4">
        <div className="whitespace-pre-wrap text-sm text-gray-700 leading-relaxed">
          {concatenatedFindings}
        </div>
      </div>
    </div>
  );
};

const FactorObservationsOverview = ({ factors }: { factors: Factor[] }) => {
  if (!factors || factors.length === 0) return null;

  const concatenatedObservations = factors
    .filter((factor) => factor.observations)
    .map(
      (factor: Factor) =>
        `${factor.factor_index} ${factor.factor_title ? `- ${factor.factor_title}` : ""}: ${factor.observations || "No observations"}`
    )
    .join("\n");

  return (
    <div className="mb-2">
      <label className="block font-medium mb-1 text-lg">Observations:</label>
      <div className="w-full bg-white border rounded-md p-4">
        <div className="whitespace-pre-wrap text-sm text-gray-700 leading-relaxed">
          {concatenatedObservations}
        </div>
      </div>
    </div>
  );
};

export const ElementReview = ({
  auditToolId,
  fa,
  std,
  el,
  isFactorView,
  elementFactors,
}: ElementReviewProps) => {
  const { auditTool } = useContext(AuditToolContext);
  const { setActiveModalRef } = useModalContext();
  const [elementReview, setElementReview] =
    useState<AuditToolElementReview | null>(null);
  const [elementMetadataCaps, setElementMetadataCaps] = useState<Cap[]>([]);
  const [openCapSheet, setOpenCapSheet] = useState(false);
  const [openCapCreateDialog, setOpenCapCreateDialog] = useState(false);
  const [capCreateLoading, setCapCreateLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const notesRef = useRef<HTMLTextAreaElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const authInfo = useAuthInfo();
  const { simpleUsers } = useContext(UserContext);

  const fetchElementMetadataCaps = async (elementMetadataId: string) => {
    try {
      const caps = await getElementMetadataCaps(
        elementMetadataId,
        authInfo.accessToken ?? null
      );
      setElementMetadataCaps(caps);
    } catch (error) {
      console.error("Error fetching element metadata caps:", error);
      toast.error("Error fetching element metadata caps");
    }
  };

  const fetchElementReview = async () => {
    if (!auditToolId || !std || !el) return;
    try {
      const review = await getAuditToolElementReview(
        auditToolId,
        fa,
        std,
        el,
        authInfo.accessToken ?? null
      );
      setElementReview(review);
      await fetchElementMetadataCaps(review.id);
    } catch (error) {
      console.error("Error fetching element review:", error);
      toast.error("Error fetching element review");
    }
  };

  const handleCreateCap = async (cap: Cap) => {
    try {
      setCapCreateLoading(true);
      const createdCap: Cap = {
        ...cap,
        element_metadata_id: elementReview?.id ?? null,
        cap_type: "cap",
      };

      await createCap(createdCap, authInfo.accessToken ?? null);
      await fetchElementMetadataCaps(elementReview?.id ?? "");
      setOpenCapCreateDialog(false);
      toast.success("CAP created successfully");
    } catch (error) {
      toast.error("Unable to create CAP");
    } finally {
      setCapCreateLoading(false);
    }
  };

  const handleUpdateCap = async (cap: Cap, notes: string) => {
    const updateRequest: CapUpdateRequest = {
      cap_status: cap.cap_status,
      cap_completed_date: cap.cap_completed_date,
      // cap_due_date: cap.cap_due_date,
    };
    if (notes) {
      updateRequest.action_item_status = notes;
    }
    await updateCap(cap.id, updateRequest, authInfo?.accessToken ?? null);
    const updatedCap = await getCap(cap.id, authInfo?.accessToken ?? null);
    setElementMetadataCaps((prevCaps) => {
      const updatedCaps = prevCaps.map((c) =>
        c.id === cap.id ? updatedCap : c
      );
      return updatedCaps;
    });
  };

  /**
   * Calls the server to generate an AuditToolElementReview (e.g. AI generation) for this fa/std/el.
   */
  const handleGenerateElementReview = async () => {
    if (!auditToolId || !std || !el) return;
    try {
      setIsGenerating(true);
      setElementReview(null);
      for await (const review of generateAuditToolElementReview(
        auditToolId,
        std,
        el,
        authInfo.accessToken ?? null
      )) {
        notesRef.current?.scrollIntoView({ behavior: "smooth" });
        notesRef.current?.scrollTo({ top: 9999, behavior: "smooth" });
        setElementReview(review);
      }
    } catch (error) {
      console.error("Error generating element review:", error);
      toast.error("Error generating element review");
    } finally {
      setIsGenerating(false);
    }
  };

  /**
   * Updates an AuditToolElementReview after user input.
   */
  const handleUpdateElementReview = async (
    newReview: AuditToolElementReview
  ) => {
    try {
      const updatedElementReview = await updateAuditToolElementReview(
        auditToolId,
        newReview,
        authInfo.accessToken ?? null
      );
      setElementReview(updatedElementReview);
      toast.success("Element review updated successfully");
    } catch (error) {
      console.error("Error updating element review:", error);
      toast.error("Error updating element review");
    }
  };

  useEffect(() => {
    setActiveModalRef(dialogRef);
  }, [dialogRef]);

  useEffect(() => {
    fetchElementReview();
  }, [auditToolId, fa, std, el]);

  if (!fa || !std || !el) return null;

  return (
    <div className={`flex flex-col ${isFactorView ? "" : "p-4 bg-gray-100"}`}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">
          Element {isFactorView ? "Assessment" : "Assessment"}
          {isFactorView && <span className="text-gray-500"> - {el}</span>}
        </h2>
      </div>

      <div className="flex flex-col gap-4 flex-1">
        {/* 1. Assessment Status */}
        {!isFactorView && (
          <div className="flex flex-row items-end gap-2">
            <div>
              <label className="block text-sm font-medium mb-2">
                Assessment
              </label>
              <StatusSelector
                status={elementReview?.assessment ?? ""}
                handleStatusChange={async (value) => {
                  if (!elementReview) return;
                  await handleUpdateElementReview({
                    ...elementReview,
                    assessment: value,
                  });
                }}
                options={Object.entries(auditFactorAssessment).map(
                  ([key, value]) => ({
                    value: key,
                    label: value,
                    className:
                      auditFactorAssessmentStatusLabelClasses[
                        key as AuditFactorAssessment
                      ],
                  })
                )}
                hideLabel={true}
              />
            </div>
            <div className="flex-1 border-r border-gray-300 h-full"></div>
            <div className="flex-1 h-full"></div>
            <div className="flex flex-col items-start gap-1">
              <span className="text-sm font-medium">
                Corrective Action Plans
              </span>
              <div className="flex flex-row items-center gap-2">
                <Dialog
                  open={openCapCreateDialog}
                  onOpenChange={setOpenCapCreateDialog}
                >
                  <DialogTrigger asChild>
                    <Button variant="outline">Create CAP</Button>
                  </DialogTrigger>
                  <DialogContent ref={dialogRef} className="space-y-0">
                    <DialogTitle>Create CAP</DialogTitle>
                    <CreateCapForm
                      onSubmit={handleCreateCap}
                      preExistingCap={{
                        name: `CAP - ${fa ? fa + " " : ""}${std ? std + " " : ""}${el ? el : ""}`,
                        delegate: auditTool?.delegate ?? null,
                        description: elementFactors
                          .sort((a, b) =>
                            smartSort(a.factor_index, b.factor_index)
                          )
                          .filter((factor) => factor.findings)
                          .map(
                            (factor) =>
                              `${factor.factor_index}: ${factor.findings}`
                          )
                          .join("\n"),
                        element_metadata_id: elementReview?.id ?? null,
                        cap_issued_date: new Date().toISOString(),
                        cap_due_date: new Date(
                          new Date().getTime() + 30 * 24 * 60 * 60 * 1000
                        ).toISOString(),
                        cap_status: "open",
                        cap_type: "cap",
                      }}
                    />
                  </DialogContent>
                </Dialog>
                <Button
                  variant="outline"
                  onClick={() => setOpenCapSheet(true)}
                  disabled={elementMetadataCaps.length === 0}
                >
                  Review CAPs
                  {elementMetadataCaps.length > 0 && (
                    <Badge className="text-xs ml-1 px-2 py-1">
                      {elementMetadataCaps.length}
                    </Badge>
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* 2. Narrative / Findings */}
        <div className="flex-1 flex flex-col">
          <FactorFindingsOverview factors={elementFactors} />
          <FactorObservationsOverview factors={elementFactors} />
          <FactorNotesOverview factors={elementFactors} />
        </div>
      </div>
      <CapSheet
        caps={elementMetadataCaps}
        open={openCapSheet}
        onOpenChange={setOpenCapSheet}
        onUpdateCap={handleUpdateCap}
      />
    </div>
  );
};
