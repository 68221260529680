import { useNavigate } from "react-router-dom";
import {
  getMedicareApplicablePlanDisplay,
  MedicareApplicablePlan,
} from "../../biz/medicare";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../shadcn/components/avatar";
import { Badge } from "../../shadcn/components/badge";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../shadcn/components/card";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { cn } from "../../shadcn/lib/utils";
import { RegulatoryDoc, Relationship } from "../../types";
import { loadAndFormatDate } from "../../utils/format";

const DocLinks = (props: { title: string; relationships: Relationship[] }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="font-semibold text-sm">{props.title}</div>
      {props.relationships.map((relationship) => (
        <div
          key={relationship.id}
          onClick={() => navigate(`/doc-chat?docId=${relationship.id}`)}
          className="cursor-pointer underline"
        >
          {relationship.name}
        </div>
      ))}
    </div>
  );
};

const DocGroup = (props: { title: string; relationships: Relationship[] }) => {
  return (
    <div className="flex items-center space-x-2">
      <div className="font-semibold text-sm">{props.title}</div>
      <Tooltip>
        <TooltipTrigger>
          <Badge variant="default" className="text-sm">
            {props.relationships.length}
          </Badge>
        </TooltipTrigger>
        <TooltipContent>
          <div className="space-y-2">
            {props.relationships.map((relationship) => (
              <div key={relationship.id}>{relationship.name}</div>
            ))}
          </div>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

const RelevantPlansDisplay = (props: { plans: string }) => {
  return <div className="text-sm text-gray-500">{props.plans}</div>;
};

const ApplicabilityDisplay = (props: {
  applicable: {
    states: string[];
    plans: Record<keyof typeof MedicareApplicablePlan, boolean>;
  };
}) => {
  const planDisplay = getMedicareApplicablePlanDisplay(props.applicable.plans);

  return (
    <div className="space-x-2">
      {planDisplay && (
        <Badge key="plan" className="text-sm" variant="secondary">
          {planDisplay}
        </Badge>
      )}
      {props.applicable.states.map((state) => (
        <Badge key={`state-${state}`} className="text-sm" variant="secondary">
          {state}
        </Badge>
      ))}
    </div>
  );
};

const UpdatedDisplay = (props: {
  original_date: string;
  last_updated_date: string;
}) => {
  return (
    <div className="text-sm text-gray-500">
      This document has been updated. The original document was issued on{" "}
      {loadAndFormatDate(props.original_date, false)} and last updated on{" "}
      {loadAndFormatDate(props.last_updated_date, false)}.
    </div>
  );
};

const SupersedeDisplay = (props: {
  regulatoryDocId: string;
  docIdentifier: string;
  direction: "primary" | "secondary";
}) => {
  const navigate = useNavigate();
  const handleSupersedeClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/regulatory-doc/overview/${props.regulatoryDocId}`);
  };
  return (
    <Badge
      className={`text-sm cursor-pointer ${props.direction === "primary" ? "bg-blue-400" : "bg-red-400"}`}
      onClick={handleSupersedeClick}
    >
      {props.direction === "primary" ? "Supersedes" : "Superseded by"}{" "}
      {props.docIdentifier}
    </Badge>
  );
};

export const RegulatoryDocContent = (props: {
  regulatoryDoc: RegulatoryDoc;
  feedView: boolean;
}) => {
  const original_date = props.regulatoryDoc.metadata_["original_date"] as
    | string
    | undefined;

  const supersedesDocs = props.regulatoryDoc.relationships.filter(
    (relationship) =>
      relationship.relationship_type === "supersedes" &&
      relationship.direction == "primary"
  );

  const supersededDocs = props.regulatoryDoc.relationships.filter(
    (relationship) =>
      relationship.relationship_type === "supersedes" &&
      relationship.direction == "secondary"
  );

  const attachments = props.regulatoryDoc.relationships.filter(
    (relationship) =>
      relationship.relationship_type === "attachment" &&
      relationship.direction == "primary"
  );

  const revisions = props.regulatoryDoc.relationships.filter(
    (relationship) =>
      relationship.relationship_type === "revision" &&
      relationship.direction == "primary"
  );

  const relevantPlans = props.regulatoryDoc.metadata_["to"] as
    | string
    | undefined;

  const applicable =
    props.regulatoryDoc.metadata_["states"] &&
    props.regulatoryDoc.metadata_["applicable_plans"]
      ? {
          states: props.regulatoryDoc.metadata_["states"] as string[],
          plans: props.regulatoryDoc.metadata_["applicable_plans"] as Record<
            keyof typeof MedicareApplicablePlan,
            boolean
          >,
        }
      : null;

  return (
    <div className="space-y-4">
      <div className="space-x-2">
        {supersededDocs.length > 0 &&
          supersededDocs.map((relationship) => (
            <SupersedeDisplay
              key={relationship.id}
              regulatoryDocId={relationship.id}
              docIdentifier={relationship.name}
              direction={relationship.direction}
            />
          ))}
        {supersedesDocs.length > 0 &&
          supersedesDocs.map((relationship) => (
            <SupersedeDisplay
              key={relationship.id}
              regulatoryDocId={relationship.id}
              docIdentifier={relationship.name}
              direction={relationship.direction}
            />
          ))}
      </div>
      {revisions.length > 0 && (
        <DocLinks title="Revisions" relationships={revisions} />
      )}
      {relevantPlans && <RelevantPlansDisplay plans={relevantPlans} />}
      {props.feedView && applicable && (
        <ApplicabilityDisplay applicable={applicable} />
      )}
      {props.feedView && attachments.length > 0 && (
        <DocGroup title="Attachments" relationships={attachments} />
      )}
      {original_date && original_date !== props.regulatoryDoc.date && (
        <UpdatedDisplay
          original_date={original_date}
          last_updated_date={props.regulatoryDoc.date}
        />
      )}
    </div>
  );
};

export const RegulatoryDocAgencyImage = (props: { agency: string }) => {
  return (
    <Avatar className="h-12 w-24 flex-shrink-0 mr-4">
      <AvatarImage
        src={`/${props.agency.toLowerCase()}.png`}
        alt={props.agency}
      />
      <AvatarFallback>{props.agency}</AvatarFallback>
    </Avatar>
  );
};

export const RegulatoryDocOverviewContent = (props: {
  regulatoryDoc: RegulatoryDoc;
}) => {
  const navigate = useNavigate();

  const handleSummaryClick = () => {
    navigate(`/regulatory-doc/overview/${props.regulatoryDoc.id}`);
  };

  return (
    <Card
      className={cn("flex-grow hover:shadow-lg cursor-pointer")}
      onClick={() => {
        handleSummaryClick();
      }}
    >
      <CardHeader className="space-y-4">
        <div className="flex items-center mb-2">
          <RegulatoryDocAgencyImage
            agency={props.regulatoryDoc.doc_type.agency}
          />
          <div className="flex flex-col">
            <span className="text-base text-gray-800">
              {loadAndFormatDate(props.regulatoryDoc.date, false)}
            </span>
            <div className="flex space-x-2">
              <span className="text-xs text-gray-400">
                {props.regulatoryDoc.doc_type.name}
              </span>
            </div>
          </div>
          {props.regulatoryDoc.configuration.relevant == false && (
            <Badge className="bg-gray-400 text-sm ml-2">Not Relevant</Badge>
          )}
        </div>
        <CardTitle className="flex flex-col item-center space-y-4">
          <div className="flex items-center space-x-2">
            <div className="font-bold">
              {props.regulatoryDoc.doc_identifier}
            </div>
          </div>
          {props.regulatoryDoc.title !== props.regulatoryDoc.doc_identifier && (
            <div className="font-normal text-lg text-gray-600">
              {props.regulatoryDoc.title}
            </div>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <RegulatoryDocContent
          regulatoryDoc={props.regulatoryDoc}
          feedView={true}
        />
      </CardContent>
    </Card>
  );
};
