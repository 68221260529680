import { PdfCitation } from "./components/PdfViewer/PdfHighlighter/types";

export interface RelevantQuote {
  start_index: number;
  end_index: number;
}

export interface DocType {
  id: string;
  name: string;
  short_display_name: string;
  agency: string;
  url: string;
  external: boolean;
  editable: boolean;
  reg_doc_gap: boolean;
  last_updated_at: string | null;
}

export interface FeedDocType extends DocType {
  enabled: boolean;
}

export interface SearchResult {
  original_rank: number;
  final_rank: number;
  search_doc_id: string;
  relevant_quote: RelevantQuote | null;
  doc_type: DocType;
  doc_name: string;
  text: string;
  additional_info: string | null;
  page: number | null;
  doc_id: string;
  score: number;
}

export interface Permission {
  doc_type: DocType;
  enabled: boolean;
  organization_doc_id: string;
}

export interface ManagedUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  picture_url: string;
  permissions: Permission[];
}

export interface SimpleUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
}

export type DocRelationshipType = "supersedes" | "attachment" | "revision";

export interface Relationship {
  id: string;
  id_type: "doc" | "regulatory_doc";
  relationship_type: DocRelationshipType;
  direction: "primary" | "secondary";
  name: string;
}

export interface RegulatoryDocConfiguration {
  relevant: boolean;
  implementation_date: string | null;
  status: string | null;
}

export interface RegulatoryDoc {
  id: string;
  doc_type: DocType;
  doc_identifier: string;
  title: string;
  date: string;
  metadata_: any;
  doc_id: string;
  overview_items: RegulatoryDocOverviewItem[];
  requirements: Requirement[];
  relationships: Relationship[];
  configuration: RegulatoryDocConfiguration;
  summary: string | null;
}

export interface PaginationOutput {
  total_pages: number;
  total_records: number;
  current_page: number;
  next_page: number | null;
  previous_page: number | null;
  record_metadata: Record<string, Record<string, number>>;
}

export interface RelevantSection {
  page: number;
  text: string;
  start_index: number;
  end_index: number;
}

type SearchDocResultType = "filename" | "content";

export interface SearchDocName {
  id: string | null;
  doc_id: string;
  name: string;
  doc_type_name: string;
  additional_metadata: Record<string, string>;
  result_type: SearchDocResultType;
  citation: Citation | null;
}

export type ModelChatType = "system" | "user" | "assistant";

export interface Citation {
  id: string | null;
  page: number;
  text: string;
  formatted_text: string | null;
  doc_id?: string;
  doc_name?: string;
  created_at: string | null;
  user: SimpleUser | null;
  start_index: number | null;
  match_index: number | null;
}

export const citationFromSearchResult = (result: SearchResult): Citation => {
  return {
    id: result.search_doc_id,
    page: result.page ?? 1,
    text: result.text,
    formatted_text: null,
    doc_id: result.doc_id,
    doc_name: result.doc_name,
    created_at: null,
    user: null,
    start_index: null,
    match_index: null,
  };
};

export interface ModelChat {
  role: ModelChatType;
  content: string;
}

export interface SpecificDocSearchResult {
  doc_id: string;
  doc_name: string;
  answer: string;
}

export interface SearchDocumentResponse {
  query: string;
  search_id: string;
  error: string;
  search_results: SearchResult[];
  filter_: string[] | null;
  query_suggestions: string[];
  summary: string | null;
  search_doc_names: SearchDocName[];
  data_source_suggestions: DocType[];
  web_search_result: string | null;
  specific_doc_search_result: SpecificDocSearchResult | null;
  exact_match_search: string | null;
}

export interface DataSource {
  doc_type: DocType;
  enabled: boolean;
  external: boolean;
}

export interface ActionItem {
  id: string;
  text: string;
  citations: Citation[];
  status: TaskStatus;
  assignees: Department[];
  text_updated_at: string;
  text_updated_by: SimpleUser;
  status_updated_at: string;
  status_updated_by: SimpleUser;
  created_at: string;
  notes: Note[];
  requirement_id: string;
  updated_at: string;
  reference_doc_types: string[] | null;
  impacted_doc_types?: string[] | null;
  reference_documents: Citation[];
}

export type RequirementTag =
  | "no_substantive_changes"
  | "informational_only"
  | "actionable"
  | "not_applicable";

export interface Requirement {
  id: string | null;
  text: string;
  citations: Citation[];
  action_items: ActionItem[];
  tag: RequirementTag | null;
  impacted_documents: Citation[];
  reference_documents: Citation[];
  assignees: Department[];
  updated_at: string;
  reference_doc_types: string[] | null;
  impacted_doc_types?: string[] | null;
  due_date: string | null;
  summary: string | null;
  analysis: string | null;
  is_gap: boolean | null;
  auto_populating?: boolean;
}

export interface RegulatoryDocOverviewItem {
  name: string;
  value: string;
  citation: Citation;
}

export interface RegulatoryDocOverview {
  overview_items: RegulatoryDocOverviewItem[];
  requirements: Requirement[];
}

export interface DocToView {
  docId: string;
  historyId?: string;
}

export interface NoteReaction {
  reaction: string | null;
  user: SimpleUser;
}

export interface Note {
  id: string;
  note: string;
  user: SimpleUser;
  created_at: string;
  reactions: NoteReaction[];
}

export interface AuditQuestion {
  id: string;
  question: string;
  question_index: string;
  section_index: string;
  section_title: string;
  assignees: Department[];
  narrative: string;
  notes: Note[];
  status: TaskStatus;
  citations: Citation[];
  narrative_updated_at: string;
  narrative_updated_by: SimpleUser;
  status_updated_at: string;
  status_updated_by: SimpleUser;
  doc_types_to_search: string[] | null;
  review_docs_available: boolean;
  review_summary: string | null;
}

export interface Audit {
  id: string;
  name: string;
}

export interface QuestionAnswerId {
  question_id: string;
  answer_id: string;
}

export interface Insight {
  id: string;
  name: string;
}

export interface PageMatch {
  page: number;
  count: number;
  id: string;
}

export interface ExactMatches {
  page_matches: PageMatch[];
  total: number;
}

export interface CitationText {
  match: string;
  exactMatch: boolean;
  page: number;
}

export type Feature =
  | "search"
  | "regulatory_doc"
  | "audit"
  | "delegate_audit"
  | "cap"
  | "insight"
  | "policy_repo"
  | "gap_analysis"
  | "dashboard"
  | "crosswalk"
  | "entity_assessment";

export interface FileIndex {
  type: "file" | "folder";
  children: FileIndex[];
  name: string;
  id: string | null;
  doc_type_name: string;
  regulatory_doc_id: string | null;
  isSelected?: boolean;
  isReviewed?: boolean;
  parentFolders?: string[];
}

export interface Department {
  id: string;
  name: string;
}

export interface OrganizationProfile {
  regulating_states: Partial<Record<keyof typeof USState, boolean>>;
}

export type ApproverType = "user" | "department";

export interface ApprovalStage {
  id: string;
  approver_type: ApproverType;
  approver_id: string;
  on_accept: string | null;
  on_reject: string | null;
}

export interface WorkflowApprovalStatus {
  approval_stage: ApprovalStage | null;
  allowed_user_emails: string[];
  can_start: boolean;
}

export interface DocMetadata {
  id: string;
  regulatory_doc_id: string | null;
  editable_doc_id: string | null;
  approval_flow_id: string | null;
  flow_instance_id: string | null;
  doc_source: string | null;
  base_doc_id: string | null;
  name: string;
}

export interface DocHistory {
  id: string;
  initially_active: string | null;
  last_active: string | null;
  name: string;
  created_at: string;
  active?: boolean;
}

type ApprovalEventType =
  | "approve"
  | "reject"
  | "skip"
  | "cancel"
  | "failed"
  | "start";

interface ApprovalEventPayload {
  name: ApprovalEventType;
  approver_type: ApproverType;
  approver_id: string;
}

export interface ApprovalEvent {
  id: string;
  flow_instance_id: string;
  doc_id: string;
  event: ApprovalEventPayload;
  approval_flow_id: string;
  user: SimpleUser;
  updated_at: string;
}

export interface DocDiff {
  page: number;
  diffs: string[];
  page_missing_in_base: boolean;
  page_missing_in_compare: boolean;
}

export interface FlowSchedule {
  schedule_start_date: string;
  schedule_interval_unit: "months" | "years";
  schedule_interval_amount: number;
  schedule_instance_id: string | null;
}

export interface ApprovalConfig {
  schedule: FlowSchedule | null;
  attestation: boolean;
  flow_id: string | null;
}

export interface PolicyRepoDoc {
  doc_id: string;
  name: string;
  relevant_date: string;
}

export interface PolicyRepoOverview {
  recently_updated_docs: PolicyRepoDoc[];
  currently_processing_docs: PolicyRepoDoc[];
  user_action_docs: PolicyRepoDoc[];
  upcoming_docs: PolicyRepoDoc[];
  attestations: Attestation[];
}

export interface RecentlyViewedDoc {
  doc_id: string;
  name: string;
  doc_type_name: string;
  date: string;
}

export type TaskStatus = "todo" | "review" | "done" | "in_progress" | "on_hold";

export interface Attestation {
  id: string;
  flow_instance_id: string;
  user: SimpleUser;
  doc_id: string;
  doc_name: string;
  attested: boolean;
  updated_at: string;
}

export interface ApprovalFlow {
  id: string;
  name: string;
  flow: ApprovalStage[];
}

export interface PolicyRepoError {
  error: string;
  citation: Citation;
}

export const statusToLabelMap: Record<TaskStatus, string> = {
  todo: "To Do",
  review: "In Review",
  done: "Complete",
  in_progress: "In Progress",
  on_hold: "On Hold",
};

export const tagToLabelMap: Record<RequirementTag, string> = {
  not_applicable: "Not Applicable",
  no_substantive_changes: "No Substantive Changes",
  informational_only: "Informational Only",
  actionable: "Actionable",
};

export interface PreviousSearch {
  id: string;
  query: string;
  timestamp: string;
}

export interface GapAnalysis {
  id: string;
  name: string;
  doc_id: string;
  doc_name: string;
  doc_type_name: string;
  created_at: string;
  updated_at: string;
}

export interface AddCitationResponse {
  citation_ids: string[];
  citation_start_indices: number[];
  departments: Department[];
  source_text: string | null;
}

export interface DeleteCitationRequest {
  citation_id: string;
}

export interface DeleteCitationResponse {
  source_text: string | null;
}

export type ChatMessageType =
  | "message"
  | "definition"
  | "clarify"
  | "linked_document"
  | "impacted_policies";

export interface ChatHistoryMetadata {
  chat_id: string;
  title: string;
  updated_at: string;
}

export type AuditResourceType = "questionnaire" | "upload" | "table";

export interface AuditResource {
  id: string;
  name: string;
  resource_type: AuditResourceType;
  resource_value: Record<string, any>;
  status: TaskStatus;
  questions?: AuditQuestion[];
}

export type SearchResourceType =
  | "document"
  | "document_type"
  | "document_requirements"
  | "requirement";
export interface SearchResource {
  id: string;
  name: string;
  type: SearchResourceType;
  match_type: "name" | "content";
  doc_type_name: string | null;
  citation: Citation | null;
  regulatory_doc_id: string | null;
  count: number | null;
}

export interface AuditQuestionReviewDoc {
  id: string;
  audit_tool_related_doc_id: string;
  doc_id: string;
  doc_name: string;
  audit_question_id: string;
  review: string | null;
  review_status: string | null;
  deleted: boolean;
  citations: Citation[];
  review_status_updated_by?: string;
  review_status_updated_at?: string;
  review_rank?: number | null;
  review_flagged?: boolean | null;
  review_flagged_reason?: string | null;
  review_flagged_by?: string;
}

export const auditFactorAssessment = {
  met: "Met",
  not_met: "Not Met",
  partially_met: "Partially Met",
  na: "N/A",
};

export type AuditFactorAssessment = keyof typeof auditFactorAssessment;

export const auditFactorAssessmentStatusLabelClasses: Record<
  AuditFactorAssessment,
  string
> = {
  met: "data-[state=on]:bg-green-400 data-[state=off]:hover:bg-green-100",
  partially_met:
    "data-[state=on]:bg-yellow-400 data-[state=off]:hover:bg-yellow-100",
  not_met: "data-[state=on]:bg-red-400 data-[state=off]:hover:bg-red-100",
  na: "data-[state=on]:bg-gray-400 data-[state=off]:hover:bg-gray-100",
};

export const auditFactorStatus = {
  todo: "To Do",
  review: "AI Reviewed",
  done: "Complete",
};

export type AuditFactorStatus = keyof typeof auditFactorStatus;

export const auditFactorStatusLabelClasses: Record<AuditFactorStatus, string> =
  {
    todo: "data-[state=on]:bg-gray-400 data-[state=off]:hover:bg-gray-100",
    review:
      "data-[state=on]:bg-yellow-400 data-[state=off]:hover:bg-yellow-100",
    done: "data-[state=on]:bg-green-400 data-[state=off]:hover:bg-green-100",
  };
export interface AuditFactorDocReviewResponse {
  id: string;
  audit_factor_id: string;
  audit_tool_related_doc: {
    id: string;
    doc: {
      id: string;
      doc_name: string;
    };
  };
  audit_tool_related_doc_id: string;
  citations: {
    id: string;
    citation_id: string;
    citations: Citation[];
  }[];
  review_summary: string | null;
  created_at: string;
  updated_at: string;
  review_rank?: number | null;
  review_flagged?: boolean | null;
  review_flagged_reason?: string | null;
  review_flagged_by?: string;
  review_status_updated_by?: string;
  review_status_updated_at?: string;
}
export interface AuditToolFactorReviewDoc {
  id: string;
  audit_tool_related_doc_id: string;
  doc_id: string;
  doc_name: string;
  factor_id: string;
  review: string | null;
  review_status: string | null;
  citations: PdfCitation[];
  review_rank?: number | null;
  review_status_updated_by?: string;
  review_status_updated_at?: string;
  review_flagged?: boolean | null;
  review_flagged_reason?: string | null;
  review_flagged_by?: string;
}

export interface AuditToolPermission {
  id: string;
  audit_tool_id: string;
  user_id: string;
  functional_area: string;
  standard_index: string;
  element_index: string;
  is_owner: boolean;
}

export interface AuditTool {
  id: string;
  name: string;
  organization_id: string;
  audit_period?: string | null;
  folder_path?: string;
  doc_folder_path?: Record<string, any>;
  delegate?: string | null;
  scope?: string | null;
  factors: Factor[];
  related_docs: AuditToolRelatedDoc[];
  related_docs_last_updated_at: string | null;
  created_at: string | null;
  updated_at: string | null;
  audit_work_start_date: string | null;
  audit_work_end_date: string | null;
  audit_start_date: string | null;
  audit_end_date: string | null;
  owner_id: string | null;
  element_metadata: AuditToolElementReview[];
}

export const auditToolFunctionalAreas = [
  //NCQA
  "Utilization Management",
  "Member Experience",
  "Population Health Management",
  "Health Equity",
  "Network Management",
  "Credentialing and Recredentialing",
  "Quality Management and Improvement",
  //DMHC
  "Claims Management and Processing",
  "Grievances And Appeals",
  "Quality Assurance",
  "Access And Availability Of Services",
  "Prescription Drug Coverage",
  "Access To Emergency Services And Payment",
];

export interface FileAuditMetadata {
  metadata_name: string;
  metadata_key: string;
  format: string;
  group: string;
  sub_group: string;
  source: string;
  method: string;
}

export interface AuditToolRelatedDoc {
  id: string;
  doc_id: string;
  doc_name: string;
  doc_type_name: string;
  doc_type_id: string;
  doc_url: string;
}

export interface Factor {
  id: string;
  name: string;
  source: string;
  functional_area: string;
  standard_index: string | null;
  standard_title: string | null;
  standard_datasources: string | null;
  element_index: string;
  element_title: string;
  element_scoring: Record<string, any> | null;
  element_datasources: string | null;
  element_explanation: string;
  element_must_pass: boolean;
  element_num_factors: number;
  element_reference: string;
  factor_index: string;
  factor_title: string;
  factor_description: string;
  factor_explanation: string;
  factor_critical: boolean;
  factor_reference: string;
  notes?: string | null;
  notes_updated_at?: string | null;
  notes_updated_by?: string | null;
  assessment?: AuditFactorAssessment | null;
  narrative?: string | null;
  findings?: string | null;
  findings_updated_at?: string | null;
  findings_updated_by?: string | null;
  observations?: string | null;
  observations_updated_at?: string | null;
  observations_updated_by?: string | null;
  status?: AuditFactorStatus | null;
  previous_assessment?: {
    approved_24?: string | null;
    comments_24?: string | null;
    criteria_24?: string | null;
    reviewed_24?: string | null;
    submitted_24?: string | null;
  } | null;
  background_info?: string | null;
  effective_date: string;
  updated_at: string;
}

export interface CustomFactor {
  factor_title: string;
  factor_description: string;
  factor_explanation: string;
  factor_index: string;
  factor_reference?: string;
  effective_date?: string;
}

export interface AuditToolElementReview {
  id: string;
  audit_tool_id: string;
  functional_area: string;
  source: string;
  standard_index: string;
  element_index: string;
  assessment: string;
  narrative: string;
  narrative_updated_at: string;
  narrative_updated_by: string;
  created_at: string;
  updated_at: string;
  element_metadata?: Record<string, any>;
}

export type SharepointFolderInfo = {
  "@odata.context": string;
  createdBy: {
    application: {
      id: string;
      displayName: string;
    };
    user: {
      displayName: string;
    };
  };
  createdDateTime: string;
  eTag: string;
  id: string;
  lastModifiedBy: {
    application: {
      id: string;
      displayName: string;
    };
    user: {
      displayName: string;
    };
  };
  lastModifiedDateTime: string;
  name: string;
  parentReference: {
    driveType: string;
    driveId: string;
    id: string;
    name: string;
    path: string;
    siteId: string;
  };
  webUrl: string;
  cTag: string;
  fileSystemInfo: {
    createdDateTime: string;
    lastModifiedDateTime: string;
  };
  folder: {
    childCount: number;
  };
  shared: {
    scope: string;
  };
  size: number;
};

export interface EntityAssessment {
  id: string;
  name: string;
  deleted: boolean;
  created_at: string;
  updated_at: string;
}

export enum USState {
  AL = "Alabama",
  AK = "Alaska",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  FL = "Florida",
  GA = "Georgia",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VT = "Vermont",
  VA = "Virginia",
  WA = "Washington",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming",
}

export const CapStatuses = ["open", "closed"];
export type CapStatus = (typeof CapStatuses)[number];

export interface Cap {
  id: string;
  name: string;
  description: string;
  cap_owner_id: string | null;
  element_metadata_id: string | null;
  delegate: string | null;
  department: string | null;
  cap_issued_date: string | null;
  cap_due_date: string | null;
  cap_completed_date: string | null;
  cap_status: CapStatus;
  cap_type: string | null;
  created_at: string | null;
  updated_at: string | null;
  element_metadata?: AuditToolElementReview;
  action_items: CapActionItem[];
}

export interface CapActionItem {
  id: string;
  cap_id: string;
  action_item: string;
  action_item_status: CapStatus;
  created_at: string;
  updated_at: string;
  updated_by: string | null;
}

export type CmsTool = "CDAG" | "CPE" | "FA" | "ODAG" | "SNPCC";

export interface CmsRequirement {
  audit_element: string;
  compliance_standard: string;
  data_request: string;
  universe_id: string[];
  method_of_evaluation: string;
  source: string;
  assessment: Record<number, string>;
}
