import {
  CheckCircle2,
  ChevronRight,
  FileIcon,
  Folder,
  FolderOpen,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { smartSort } from "../pages/auditTool/utils";
import { Badge } from "../shadcn/components/badge";
import { Checkbox } from "../shadcn/components/checkbox";
import { Input } from "../shadcn/components/input";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import { cn } from "../shadcn/lib/utils";
import { FileIndex } from "../types";

function countFiles(node: FileIndex): number {
  if (node.type === "file") return 1;
  return node.children?.reduce((sum, child) => sum + countFiles(child), 0) ?? 0;
}

function hasSelectedChild(node: FileIndex): boolean {
  if (node.type === "file" && (node.isSelected || node.isReviewed)) return true;
  return node.children?.some(hasSelectedChild) ?? false;
}

function getAllFiles(node: FileIndex): FileIndex[] {
  if (node.type === "file") {
    return [node];
  }
  return node.children?.flatMap(getAllFiles) ?? [];
}

type FileTreeNodeProps = {
  node: FileIndex;
  level: number;
  onSelectFile: (file: FileIndex) => void;
  onSelectFolder?: (checked: boolean, files: FileIndex[]) => void;
  isDupeView?: boolean;
};

const FileTreeNode: React.FC<FileTreeNodeProps> = ({
  node,
  level,
  onSelectFile,
  onSelectFolder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDupeView, setIsDupeView] = useState(false);
  const [dedupedFiles, setDedupedFiles] = useState<UniqueFileMap>({});
  const [isFileAndTruncated, setIsFileAndTruncated] = useState(false);
  const textRef = useRef<HTMLLabelElement>(null);
  const buildDedupedFiles = () => {
    const fileMap: UniqueFileMap = {};
    node.children.forEach((child) => {
      buildUniqueFileMap(child, [], fileMap);
    });
    setDedupedFiles(fileMap);
  };
  const handleDupeViewToggle = () => {
    const isDupe = !isDupeView;
    if (isDupe) {
      buildDedupedFiles();
    }
    setIsDupeView(isDupe);
  };

  useEffect(() => {
    if (node.type === "folder" && hasSelectedChild(node)) {
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    if (isDupeView) {
      buildDedupedFiles();
    }
  }, [node.children, isDupeView]);

  useEffect(() => {
    if (textRef.current) {
      const isTruncated =
        textRef.current.scrollWidth > textRef.current.clientWidth;
      setIsFileAndTruncated(isTruncated);
    }
  }, [node.name]);

  const areAllFilesSelected = (node: FileIndex): boolean => {
    if (node.type === "file") return !!node.isSelected || !!node.isReviewed;
    return node.children?.every((child) => areAllFilesSelected(child)) ?? false;
  };

  const areSomeFilesSelected = (node: FileIndex): boolean => {
    if (node.type === "file") return !!node.isSelected;
    return (
      node.children?.some(
        (child) => !child.isReviewed && areSomeFilesSelected(child)
      ) ?? false
    );
  };

  if (node.type === "folder") {
    const allSelected = areAllFilesSelected(node);
    const someSelected = areSomeFilesSelected(node);
    const allDedupedSelected =
      isDupeView &&
      Object.values(dedupedFiles).every(
        (file) => file.fileIndex.isSelected || file.fileIndex.isReviewed
      );
    const partiallySelected = isDupeView
      ? someSelected && !allSelected
      : someSelected && !allDedupedSelected;

    const checkedStatus = partiallySelected
      ? "indeterminate"
      : allSelected || allDedupedSelected;

    return (
      <div className="flex flex-col">
        <div
          className="flex items-center select-none hover:bg-gray-50 rounded-md py-1.5 transition-colors"
          style={{ paddingLeft: `${level * 1.25}rem` }}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen((prev) => !prev);
          }}
        >
          <div className="flex items-center cursor-pointer">
            <ChevronRight
              className={cn(
                "h-[16px] w-[16px] transition-transform text-gray-500",
                isOpen ? "rotate-90" : ""
              )}
            />
            <Checkbox
              checked={
                (isDupeView ? allDedupedSelected : allSelected) || checkedStatus
              }
              className={cn("ml-2 mr-2", partiallySelected && "bg-gray-200")}
              onCheckedChange={(checked) => {
                const files = getAllFiles(node);
                if (isDupeView && checked) {
                  onSelectFolder?.(
                    !!checked,
                    Object.values(dedupedFiles).map((file) => file.fileIndex)
                  );
                } else {
                  onSelectFolder?.(!!checked, files);
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              id={node.id || node.name}
            />
            {isOpen ? (
              <FolderOpen className="h-[16px] w-[16px] text-gray-500 ml-1 text-yellow-500" />
            ) : (
              <Folder className="h-[16px] w-[16px] text-gray-500 ml-1 text-yellow-500" />
            )}
          </div>
          <div className="ml-1.5 font-medium text-gray-700 cursor-pointer">
            {decodeURIComponent(node.name)}{" "}
            <span className="text-gray-400 text-sm font-normal">
              (
              {isDupeView
                ? `${Object.keys(dedupedFiles).length} files`
                : `${countFiles(node)} ${countFiles(node) === 1 ? "file" : "files"}`}
              )
            </span>
          </div>
          <div className="flex-1" />
          {indexContainsDuplicate(node, []) && (
            <Tooltip>
              <TooltipTrigger>
                <Badge
                  variant="outline"
                  className={cn(
                    "ml-2 text-xs py-0.5 px-2 h-5 text-gray-500 hover:bg-gray-300 cursor-pointer bg-white",
                    isDupeView ? "bg-gray-900 text-white hover:bg-gray-500" : ""
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDupeViewToggle();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {isDupeView ? "Show Folders" : "Show Unique Files"}
                </Badge>
              </TooltipTrigger>
              <TooltipContent>
                {isDupeView ? (
                  "View Regular Folder Structure"
                ) : (
                  <div className="flex flex-col gap-2 p-2 text-center">
                    <span>Duplicate File names found in this folder</span>
                    <span className="font-bold">
                      Show all files without duplicates
                    </span>
                  </div>
                )}
              </TooltipContent>
            </Tooltip>
          )}
        </div>
        {isDupeView
          ? Object.values(dedupedFiles)
              .sort((a, b) => smartSort(a.fileIndex.name, b.fileIndex.name))
              .map((file) => (
                <FileTreeNode
                  key={file.fileIndex.id || file.fileIndex.name}
                  node={file.fileIndex}
                  level={level + 1}
                  onSelectFile={onSelectFile}
                />
              ))
          : isOpen && (
              <div className="flex flex-col">
                {node.children
                  .sort((a, b) => smartSort(a.name, b.name))
                  .map((child) => (
                    <FileTreeNode
                      key={child.id || child.name}
                      node={child}
                      level={level + 1}
                      onSelectFile={onSelectFile}
                      onSelectFolder={onSelectFolder}
                    />
                  ))}
              </div>
            )}
      </div>
    );
  }

  return (
    <div
      className={cn(
        "flex items-center py-1.5 transition-colors hover:bg-gray-50 rounded-md",
        node.isSelected ? "bg-blue-50 hover:bg-blue-100" : "",
        node.isReviewed ? "opacity-50" : ""
      )}
      style={{ paddingLeft: `${level * 1.25 + 1.5}rem` }}
    >
      {node.isReviewed ? (
        <Tooltip>
          <TooltipTrigger>
            <CheckCircle2 className="h-4 w-4 text-gray-400 flex-shrink-0" />
          </TooltipTrigger>
          <TooltipContent>Already reviewed</TooltipContent>
        </Tooltip>
      ) : (
        <Checkbox
          checked={!!node.isSelected}
          onCheckedChange={(checked) => {
            onSelectFile(node);
          }}
          id={node.id || node.name}
          className="flex-shrink-0"
          disabled={node.isReviewed}
        />
      )}
      <FileIcon className="h-[16px] w-[16px] text-blue-500 ml-2 flex-shrink-0" />
      {isFileAndTruncated ? (
        <Tooltip>
          <TooltipTrigger className="max-w-full flex-1 justify-start overflow-hidden">
            <label
              htmlFor={node.id || node.name}
              ref={textRef}
              className={cn(
                "ml-2 cursor-pointer truncate block w-full text-left",
                node.isReviewed && "cursor-not-allowed text-gray-500"
              )}
            >
              {decodeURIComponent(node.name)}
            </label>
          </TooltipTrigger>
          <TooltipContent>
            <div className="flex flex-col gap-2 p-2 text-center">
              <span>{node.name}</span>
            </div>
          </TooltipContent>
        </Tooltip>
      ) : (
        <label
          htmlFor={node.id || node.name}
          ref={textRef}
          className={cn(
            "ml-2 cursor-pointer truncate block w-full text-left",
            node.isReviewed && "cursor-not-allowed text-gray-500"
          )}
        >
          {decodeURIComponent(node.name)}
        </label>
      )}
    </div>
  );
};

type FileTreeProps = {
  files: FileIndex[];
  folderName: string;
  onSelectFile?: (file: FileIndex) => void;
  onSelectFolder?: (checked: boolean, files: FileIndex[]) => void;
  isDupeView?: boolean;
};

export const FileTree: React.FC<FileTreeProps> = ({
  files,
  folderName,
  onSelectFile,
  onSelectFolder,
}) => {
  const [search, setSearch] = useState("");
  const [uniqueFileMap, setUniqueFileMap] = useState<UniqueFileMap>({});
  const [filteredFiles, setFilteredFiles] = useState<FileIndex[]>([]);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setFilteredFiles(() => {
      const filteredFiles: FileIndex[] = [];
      Object.keys(uniqueFileMap).forEach((key) => {
        if (key.toLowerCase().includes(e.target.value.toLowerCase())) {
          filteredFiles.push(uniqueFileMap[key].fileIndex);
        }
      });
      return filteredFiles;
    });
  };
  useEffect(() => {
    const fileMap: UniqueFileMap = {};
    files.forEach((file) => {
      buildUniqueFileMap(file, [], fileMap);
    });
    setUniqueFileMap(fileMap);
  }, [files]);

  return (
    <div className="border rounded-lg p-4 min-w-lg bg-white shadow-sm">
      <div className="flex items-center justify-between mb-1">
        <h2 className="text-lg font-bold mb-4 text-gray-800">{folderName}</h2>
        <Input
          placeholder="Search"
          className="w-64"
          onChange={(e) => {
            handleSearch(e);
          }}
        />
      </div>
      {search
        ? filteredFiles.map((node) => (
            <FileTreeNode
              key={node.id || node.name}
              node={node}
              level={0}
              onSelectFile={(file) => onSelectFile?.(file)}
              onSelectFolder={onSelectFolder}
            />
          ))
        : files.map((node) => (
            <FileTreeNode
              key={node.id || node.name}
              node={node}
              level={0}
              onSelectFile={(file) => onSelectFile?.(file)}
              onSelectFolder={onSelectFolder}
            />
          ))}
    </div>
  );
};

interface UniqueFileMap {
  [fileName: string]: {
    parentFolders: string[];
    fileIndex: FileIndex;
  };
}

export function buildUniqueFileMap(
  node: FileIndex,
  currentPath: string[] = [],
  result: UniqueFileMap = {}
): UniqueFileMap {
  if (node.type === "file") {
    const fileName = decodeURIComponent(node.name);
    if (result[fileName]) {
      result[fileName].parentFolders.push(
        ...currentPath.filter(
          (path) => !result[fileName].parentFolders.includes(path)
        )
      );
    } else {
      result[fileName] = {
        parentFolders: [...currentPath],
        fileIndex: node,
      };
    }
  } else if (node.type === "folder") {
    const folderName = decodeURIComponent(node.name);
    node.children?.forEach((child) => {
      buildUniqueFileMap(child, [...currentPath, folderName], result);
    });
  }
  return result;
}

export const indexContainsDuplicate = (
  index: FileIndex,
  existingNames: string[]
): boolean => {
  if (index.type === "folder") {
    return index.children.some((child) =>
      indexContainsDuplicate(child, existingNames)
    );
  }
  if (existingNames.includes(index.name)) {
    return true;
  }
  existingNames.push(index.name);
  return false;
};
