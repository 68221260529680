import React, { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "sonner";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { FileAuditMetadata } from "../../types";
import { SharepointUrlPopover } from "./components";

const ToolInformation = ({
  data,
  toolName,
}: {
  data: FileAuditMetadata[];
  toolName: string;
}) => {
  const groupedData = data.reduce(
    (acc, item) => {
      if (!acc[item.group]) {
        acc[item.group] = {};
      }
      if (!acc[item.group][item.sub_group]) {
        acc[item.group][item.sub_group] = [];
      }
      acc[item.group][item.sub_group].push(item);
      return acc;
    },
    {} as Record<string, Record<string, FileAuditMetadata[]>>
  );

  return (
    <div>
      <h1 className="text-2xl text-center font-bold mb-6 bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
        {toolName}
      </h1>
      {Object.entries(groupedData).map(([group, subGroups]) => (
        <div
          key={group}
          className="bg-gray-100 rounded-md border border-gray-300 p-4"
        >
          <h2 className="text-lg font-semibold">{group}</h2>
          {Object.entries(subGroups).map(([subGroup, items]) =>
            subGroup ? (
              <div
                key={subGroup}
                className="bg-gray-200 border border-gray-100 p-3 mt-4 rounded-md"
              >
                <h3 className="text-md font-medium">{subGroup}</h3>
                <div className="grid grid-cols-1 gap-4 mt-3">
                  {items.map((item, index) => (
                    <div
                      key={index}
                      className="bg-white p-4 border border-gray-200 rounded-lg shadow-sm"
                    >
                      <h4 className="font-semibold">{item.metadata_name}</h4>
                      {/* Add more fields here if needed */}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div
                key={subGroup}
                className="grid grid-cols-1 gap-4 mt-4 rounded-md"
              >
                {items.map((item, index) => (
                  <div
                    key={index}
                    className="bg-white p-4 border border-gray-200 rounded-lg shadow-sm"
                  >
                    <h4 className="font-semibold">{item.metadata_name}</h4>
                    {/* Add more fields here if needed */}
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      ))}
    </div>
  );
};

const UniverseUpload = ({
  variant,
  successCallback,
}: {
  variant: "default" | "ghost";
  successCallback: (newDocs: File[]) => Promise<void>;
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [".csv"],
    },
    onDrop: async (files) => {
      try {
        await successCallback(files);
      } catch (error) {
        toast.error("Error uploading files");
      }
    },
  });
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  // const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const files = e.target.files;
  //   if (!files) return;

  //   setUploading(true);
  //   try {
  //     await successCallback();
  //   } catch (error) {
  //     toast.error("Error uploading files");
  //   } finally {
  //     setUploading(false);
  //   }
  // };

  return (
    <div className="w-full h-full">
      <div
        {...getRootProps()}
        className="border-2 border-dashed border-gray-300 rounded-lg p-8 hover:border-gray-400 transition-colors cursor-pointer w-64 h-64 mx-auto flex items-center justify-center"
      >
        <input {...getInputProps()} />
        <p className="text-gray-500 text-center">
          Drag and drop CSV files here, or click to select files
        </p>
      </div>
    </div>
  );
};
const UniverseDisplay = () => {
  const [universe, setUniverse] = useState<any[]>([]);
  return (
    <div>
      <h1 className="text-2xl text-center font-bold mb-6 bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
        File Universe
      </h1>
      {universe.length < 1 && (
        <UniverseUpload
          variant="default"
          successCallback={(files) => {
            setUniverse(files);
            return Promise.resolve();
          }}
        />
      )}
    </div>
  );
};

interface FileAuditToolDisplayProps {
  data: FileAuditMetadata[];
  toolName: string;
}

const FileAuditToolDisplay: React.FC<FileAuditToolDisplayProps> = ({
  data,
  toolName,
}) => {
  return (
    <div className="flex flex-col">
      {/* Toolbar Section */}
      <div className="bg-gray-200 p-3 rounded-md flex justify-start items-center gap-4 mb-5">
        <SharepointUrlPopover />
        <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
          Action 2
        </button>
        {/* Add more toolbar buttons or elements as needed */}
      </div>
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel
          defaultSize={50}
          minSize={40}
          maxSize={60}
          id="resource-panel"
          order={2}
        >
          <div className="w-full h-full overflow-y-auto">
            <div className="">
              <ToolInformation data={data} toolName={toolName} />
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel
          defaultSize={50}
          minSize={40}
          maxSize={60}
          id="universe-panel"
          order={2}
        >
          <UniverseDisplay />
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};

export default FileAuditToolDisplay;
