import { useAuthInfo } from "@propelauth/react";
import { InfoIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { LoadingView } from "../../components/Loading";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../shadcn/components/avatar";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../shadcn/components/card";
import { Label } from "../../shadcn/components/label";
import { Separator } from "../../shadcn/components/separator";
import { Switch } from "../../shadcn/components/switch";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { FeedDocType, PaginationOutput, RegulatoryDoc } from "../../types";
import { allRegulatoryDocs } from "../../utils/apiCalls";
import { RegulatoryDocOverviewContent } from "./CustomDocFeedContent";

const FollowToggle = (props: {
  docTypeId: string;
  handleToggle: (docTypeId: string, enabled: boolean) => void;
  selected: boolean;
  disabled: boolean;
}) => {
  const [toggle, setToggle] = useState<boolean>(props.selected);

  const onChange = (checked: boolean) => {
    props.handleToggle(props.docTypeId, checked);
    setToggle(checked);
  };

  return (
    <div>
      <Switch
        checked={toggle}
        onCheckedChange={onChange}
        className="transform scale-150"
        disabled={props.disabled}
      />
    </div>
  );
};

const SourcesFilter = (props: {
  feedDocTypes: FeedDocType[];
  agencyFilters: string[];
  setAgencyFilters: React.Dispatch<React.SetStateAction<string[]>>;
  relevantFilters: string[];
  setRelevantFilters: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const handleToggle = (docTypeId: string, enabled: boolean) => {
    props.setAgencyFilters((prevFilters) => {
      if (enabled) {
        return [...prevFilters, docTypeId];
      } else {
        return prevFilters.filter((item) => item !== docTypeId);
      }
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-2">
        <Switch
          id="relevant-only"
          checked={
            props.relevantFilters.length == 1 &&
            props.relevantFilters[0] == "relevant"
          }
          onCheckedChange={(checked) => {
            const newFilters = checked ? ["relevant"] : [];
            props.setRelevantFilters(newFilters);
            localStorage.setItem("relevant-filter", JSON.stringify(newFilters));
          }}
        />
        <Label htmlFor="relevant-only">Relevant Only</Label>
        <Tooltip>
          <TooltipTrigger>
            <InfoIcon className="h-4 w-4 text-blue-500" />
          </TooltipTrigger>
          <TooltipContent>
            Click to only show guidances relevant to your organization
          </TooltipContent>
        </Tooltip>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>Sources</CardTitle>
          <CardDescription>
            Connect with authoritative sources and stay up to date with
            regulatory guidance for Managed Care Plans.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Separator />
          <div className="space-y-4 pt-8">
            <div className="grid gap-10 p-2">
              {props.feedDocTypes
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((docType) => (
                  <div
                    key={docType.id}
                    className="flex items-center justify-between space-x-4"
                  >
                    <div className="flex items-center space-x-4">
                      <Avatar className="h-12 w-24">
                        <AvatarImage
                          src={`/${docType.agency.toLowerCase()}.png`}
                        />
                        <AvatarFallback>{docType.agency}</AvatarFallback>
                      </Avatar>
                      <div>
                        <p className="text-base font-medium leading-none truncate">
                          {docType.name}
                        </p>
                        <p className="text-sm text-gray-400 pt-1">
                          {docType.agency}
                        </p>
                      </div>
                    </div>
                    <div className="pr-2">
                      <FollowToggle
                        docTypeId={docType.id}
                        selected={
                          docType.enabled &&
                          props.agencyFilters.includes(docType.id)
                        }
                        handleToggle={handleToggle}
                        disabled={!docType.enabled}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const FeedView = (props: {
  agencyFilters: string[];
  relevantFilters: string[];
}) => {
  const authInfo = useAuthInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [regulatoryDocs, setRegulatoryDocs] = useState<{
    docs: RegulatoryDoc[];
    pagination_output: PaginationOutput | null;
  }>({ docs: [], pagination_output: null });
  const [activePage, setActivePage] = useState(1);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setActivePage(1);
    setRegulatoryDocs({ docs: [], pagination_output: null });
  }, [props.agencyFilters, props.relevantFilters]);

  const getDocs = async (page: number) => {
    setIsLoading(true);
    const response = await allRegulatoryDocs(
      15,
      page,
      props.agencyFilters,
      [],
      props.relevantFilters,
      [],
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      setRegulatoryDocs((prevDocs) => {
        return {
          docs: [...prevDocs.docs, ...response.docs],
          pagination_output: response.pagination_output,
        };
      });
    } else {
      toast.error("Something went wrong pulling docs");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      !isLoading &&
      props.agencyFilters.length > 0 &&
      activePage === (regulatoryDocs.pagination_output?.next_page ?? 1) &&
      (!regulatoryDocs.pagination_output ||
        regulatoryDocs.pagination_output.total_pages > 1) // prevent infinite load if regulations only span a single page
    ) {
      getDocs(activePage);
    } else if (
      activePage > (regulatoryDocs.pagination_output?.total_pages ?? 1)
    ) {
      setActivePage(regulatoryDocs.pagination_output?.current_page ?? 1);
    }
  }, [
    activePage,
    regulatoryDocs.pagination_output,
    props.agencyFilters,
    props.relevantFilters,
  ]);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setActivePage((no) => no + 1);
      }
    })
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  return (
    <div className="overflow-y-auto space-y-5 pr-4 h-[calc(100vh-150px)]">
      {regulatoryDocs.docs.map((regulatoryDoc, index) => (
        <div
          key={index}
          ref={
            !isLoading && index === regulatoryDocs.docs.length - 1
              ? setLastElement
              : null
          }
        >
          <RegulatoryDocOverviewContent
            regulatoryDoc={regulatoryDoc}
            key={regulatoryDoc.id}
          />
        </div>
      ))}
      {!isLoading && regulatoryDocs.docs.length === 0 && (
        <p>No Regulatory Guidance found</p>
      )}
      {isLoading && <LoadingView />}
    </div>
  );
};

export const RegulatoryDocFeedView = (props: {
  agencyFilters: string[];
  relevantFilters: string[];
  feedDocTypes: FeedDocType[];
  setAgencyFilters: React.Dispatch<React.SetStateAction<string[]>>;
  setRelevantFilters: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <div className="grid grid-cols-[auto_500px] gap-4">
      <FeedView
        agencyFilters={props.agencyFilters}
        relevantFilters={props.relevantFilters}
      />
      <SourcesFilter
        feedDocTypes={props.feedDocTypes}
        agencyFilters={props.agencyFilters}
        setAgencyFilters={props.setAgencyFilters}
        relevantFilters={props.relevantFilters}
        setRelevantFilters={props.setRelevantFilters}
      />
    </div>
  );
};
