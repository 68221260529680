import { useAuthInfo } from "@propelauth/react";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { DocViewerCitation } from "../../components/DocViewer";
import { Layout } from "../../components/Layout";
import { PdfCitation } from "../../components/PdfViewer/PdfHighlighter/types";
import {
  AuditFactorReview,
  AuditToolContext,
} from "../../contexts/AuditToolContext";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { Button } from "../../shadcn/components/button";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import {
  auditFactorAssessment,
  AuditFactorAssessment,
  auditFactorAssessmentStatusLabelClasses,
  AuditFactorDocReviewResponse,
  auditFactorStatus,
  AuditFactorStatus,
  auditFactorStatusLabelClasses,
  AuditTool,
  AuditToolElementReview,
  AuditToolFactorReviewDoc,
  Factor,
  FileIndex,
} from "../../types";
import {
  createFactorFindings,
  deleteAuditToolFactorReview,
  generateFactorDocReview,
  getAuditFactor,
  getAuditFactorDocReview,
  getAuditToolElementReview,
  loadAuditToolDocs,
  regenerateFactorDocReview,
  updateAuditFactor,
} from "../../utils/apiCalls";
import { AuditToolBreadcrumbs } from "./AuditToolDashboard";

import {
  AlertCircle,
  AlertTriangle,
  CheckCircle,
  Loader2,
  RefreshCw,
  Sparkles,
  Trash2,
  TriangleAlert,
} from "lucide-react";
import ReactMarkdown from "react-markdown";
import { AutoSavingTextArea } from "../../components/AutoSavingTextArea";
import { CitationPassageView } from "../../components/Citation/CitationPassageView";
import { FileTree } from "../../components/FileTreeDisplay";
import { CopyTooltip } from "../../components/HighlightTooltipActionButton";
import { StatusSelector } from "../../components/StatusSelector";
import { useModalContext } from "../../contexts/ActiveModalContext";
import { UserContext } from "../../contexts/UserContext";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../shadcn/components/accordion";
import { Badge } from "../../shadcn/components/badge";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import {
  Popover,
  PopoverContentWithScroll,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import { Sheet, SheetContent, SheetTitle } from "../../shadcn/components/sheet";
import { localizeDate, TimeAgo } from "../../utils/format";
import {
  BadgeList,
  ExpandableContent,
  SharepointUrlPopover,
} from "./components";
import {
  createFileIndexFromAuditTool,
  escapeReviewMarkdown,
  fileNameWithoutExtension,
} from "./utils";

const DocumentReview = forwardRef(
  (
    {
      reviewDocs,
      isAuditGenerating,
      setOpenDocSheet,
      deleteReview,
    }: {
      reviewDocs: AuditToolFactorReviewDoc[];
      isAuditGenerating: boolean;
      setOpenDocSheet: (open: boolean) => void;
      deleteReview: (reviewId: string) => void;
    },
    ref
  ) => {
    const { setDocToView, setCitations, docToView, setPageNumber } =
      useContext(DocViewerContext);
    const [activeCitation, setActiveCitation] = useState<
      PdfCitation["id"] | null
    >(null);
    const [prevReviewDocsLength, setPrevReviewDocsLength] = useState(0);
    const lastDocRef = React.useRef<HTMLDivElement>(null);
    const loaderRef = React.useRef<HTMLDivElement>(null);

    // Create a map to store refs for each document review
    const docRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());

    const handleCitationClick = (
      reviewDoc: AuditToolFactorReviewDoc,
      citationId: string
    ) => {
      setActiveCitation(citationId);
      const citations = reviewDoc.citations.map((citation) => ({
        key:
          citation.id ??
          `doc-${reviewDoc.doc_id}-citation-${citation.match_index}`,
        id:
          citation.id ??
          `doc-${reviewDoc.doc_id}-citation-${citation.match_index}`,
        match: citation.match,
        exactMatch: true,
        page: citation.page,
        match_index: citation.match_index,
        className: `bg-highlight-${citation.id === citationId ? "default" : "inactive"}`,
        classNameOnHover: `bg-highlight-default`,
        onClick: () => {
          handleCitationClick(
            reviewDoc,
            citation.id ??
              `doc-${reviewDoc.doc_id}-citation-${citation.match_index}`
          );
        },
        citationTooltip:
          citation.id === citationId ? (
            <CopyTooltip
              citationText={citation.match}
              citationId={citation.id ?? ""}
            />
          ) : null,
      }));
      setCitations(citations);
      setPageNumber(
        reviewDoc.citations.find((c) => c.id === citationId)?.page ?? 1
      );
      if (reviewDoc.doc_id !== docToView?.docId) {
        setDocToView({ docId: reviewDoc.doc_id });
      }
      setOpenDocSheet(true);
    };

    useEffect(() => {
      if (reviewDocs.length > prevReviewDocsLength && isAuditGenerating) {
        loaderRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
        setPrevReviewDocsLength(reviewDocs.length);
      }
    }, [lastDocRef.current]);

    const relevantDocs = reviewDocs
      .filter((doc) => doc.review_rank && doc.review_rank > 1)
      .sort((a, b) => (b.review_rank ?? 0) - (a.review_rank ?? 0));
    const irrelevantDocs = reviewDocs
      .filter((doc) => !doc.review_rank || doc.review_rank <= 1)
      .sort((a, b) => (b.review_rank ?? 0) - (a.review_rank ?? 0));

    // Function to open accordion and scroll to a specific document
    const scrollToDocReview = (docId: string) => {
      const ref = docRefs.current.get(docId);
      if (ref) {
        ref.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    // Expose the scrollToDocReview function to the parent component
    useImperativeHandle(ref, () => ({
      scrollToDocReview,
    }));

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] =
      useState<AuditToolFactorReviewDoc | null>(null);

    const handleDeleteClick = (doc: AuditToolFactorReviewDoc) => {
      setSelectedDoc(doc);
      setIsDialogOpen(true);
    };

    const confirmDelete = () => {
      if (selectedDoc) {
        deleteReview(selectedDoc.id);
        setIsDialogOpen(false);
        setSelectedDoc(null);
      }
    };

    const DeleteButton = ({ doc }: { doc: AuditToolFactorReviewDoc }) => {
      return (
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="outline"
              size="icon"
              className="text-red-500 hover:text-red-700 hover:bg-red-50"
              onClick={() => handleDeleteClick(doc)}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <div className="flex items-center gap-2">
              <AlertCircle className="h-4 w-4 text-red-500" />
              Delete Requirement Review From:
              <br />
              <p className="font-bold">{doc.doc_name}</p>
            </div>
          </TooltipContent>
        </Tooltip>
      );
    };
    return (
      <div className="mt-4">
        <Accordion type="single" collapsible defaultValue={"relevant-docs"}>
          <AccordionItem value="relevant-docs">
            <AccordionTrigger>
              <div className="flex items-center gap-2">
                <span>Document Reviews</span>
                <Badge className="ml-2 text-xs" variant="default">
                  {relevantDocs.length}
                </Badge>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className="space-y-4">
                {relevantDocs.map((doc, docIndex) => (
                  <div
                    key={docIndex}
                    className="border rounded-md p-4 bg-white"
                    ref={(el) => docRefs.current.set(doc.doc_id, el)}
                  >
                    <h3
                      className="font-semibold hover:text-blue-800 underline cursor-pointer"
                      onClick={() =>
                        handleCitationClick(doc, doc.citations[0]?.id ?? "none")
                      }
                    >
                      {doc.doc_name}
                    </h3>
                    <div className="text-sm mb-2">
                      <CitationPassageView
                        passage={doc.review || ""}
                        citations={doc.citations}
                        onClickCitation={(citationId) =>
                          handleCitationClick(doc, citationId)
                        }
                      />
                    </div>
                    <div className="flex justify-end">
                      <DeleteButton doc={doc} />
                    </div>
                  </div>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="irrelevant-docs">
            <AccordionTrigger>
              <div className="flex items-center gap-2">
                <span>Not Applicable Documents</span>
                <Badge className="ml-2 text-xs" variant="default">
                  {irrelevantDocs.length}
                </Badge>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className="border rounded-md p-4 bg-white space-y-4">
                {irrelevantDocs.map((doc, docIndex) => (
                  <div
                    key={docIndex}
                    className="flex justify-between items-center border rounded-md pl-4 pr-1 py-1 bg-white"
                    ref={(el) => docRefs.current.set(doc.doc_id, el)}
                  >
                    <h3
                      className="font-semibold hover:text-blue-800 underline cursor-pointer"
                      onClick={() =>
                        handleCitationClick(doc, doc.citations[0]?.id ?? "none")
                      }
                    >
                      {doc.doc_name}
                    </h3>
                    <DeleteButton doc={doc} />
                  </div>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Confirm Delete</DialogTitle>
            </DialogHeader>
            <DialogDescription className="flex items-center gap-4">
              <AlertCircle className="h-12 w-12 text-red-500 flex-none" />
              <div>
                Are you sure you want to delete Requirement Review From:{" "}
                <span className="font-bold">{selectedDoc?.doc_name}</span>?
              </div>
            </DialogDescription>
            <DialogFooter>
              <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                variant="default"
                className="bg-red-600 text-white hover:bg-red-700"
                onClick={confirmDelete}
              >
                Yes, delete
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
);

const UpdateFactorStatus = ({ factor }: { factor: Factor }) => {
  const authInfo = useAuthInfo();
  const { auditToolId } = useParams<{
    auditToolId: string;
  }>();
  const { setAuditTool } = useContext(AuditToolContext);
  return (
    <StatusSelector
      status={factor.status || ""}
      hideLabel={true}
      className="border border-gray-200"
      options={Object.entries(auditFactorStatus).map(([key, value]) => ({
        value: key,
        label: value,
        className: auditFactorStatusLabelClasses[key as AuditFactorStatus],
      }))}
      handleStatusChange={async (value) => {
        if (!auditToolId || !authInfo.accessToken) return;
        const updatedFactor: Factor = {
          ...factor,
          status: value as AuditFactorStatus,
        };
        try {
          await updateAuditFactor(
            auditToolId,
            updatedFactor,
            authInfo.accessToken
          );
          toast.success("Factor status updated successfully");
        } catch (error) {
          toast.error("Failed to update factor status");
        }
        setAuditTool((prevAuditTool) => {
          if (!prevAuditTool) {
            return null;
          }
          return {
            ...prevAuditTool,
            factors: prevAuditTool.factors.map((f) =>
              f.id === updatedFactor.id ? updatedFactor : f
            ),
          };
        });
      }}
    />
  );
};

const UpdateFactorAssessment = ({ factor }: { factor: Factor }) => {
  const authInfo = useAuthInfo();
  const { auditToolId } = useParams<{
    auditToolId: string;
  }>();
  const { setAuditTool } = useContext(AuditToolContext);
  return (
    <StatusSelector
      status={factor.assessment || auditFactorAssessment.na}
      hideLabel={true}
      className="border border-gray-200"
      options={Object.entries(auditFactorAssessment).map(([key, value]) => ({
        value: key,
        label: value,
        className:
          auditFactorAssessmentStatusLabelClasses[key as AuditFactorAssessment],
      }))}
      handleStatusChange={async (value) => {
        try {
          let assessment = value as AuditFactorAssessment;
          if (!auditToolId || !authInfo.accessToken) return;
          const updatedFactor: Factor = {
            ...factor,
            assessment: assessment,
            status: assessment ? "done" : factor.narrative ? "review" : "todo",
          };
          try {
            await updateAuditFactor(
              auditToolId,
              updatedFactor,
              authInfo.accessToken
            );
            toast.success("Factor assessment updated successfully");
          } catch (error) {
            toast.error("Failed to update factor assessment");
          }
          setAuditTool((prevAuditTool) => {
            if (!prevAuditTool) {
              return null;
            }
            return {
              ...prevAuditTool,
              factors: prevAuditTool.factors.map((f) =>
                f.id === updatedFactor.id ? updatedFactor : f
              ),
            };
          });
        } catch (error) {
          toast.error("Failed to update factor assessment");
        }
      }}
    />
  );
};

export const RelatedDocsDialog = ({
  auditTool,
  onSelect,
  syncSharepoint,
  isSharepointSynced,
  isSharepointLoading,
  alreadySelectedRelatedDocIds = [],
  alreadyReviewedDocIds = [],
}: {
  auditTool: AuditTool;
  syncSharepoint: () => Promise<void>;
  onSelect: (relatedDocIds: string[]) => void;
  alreadySelectedRelatedDocIds: string[];
  isSharepointLoading: boolean;
  isSharepointSynced: boolean;
  alreadyReviewedDocIds?: string[];
}) => {
  const { activeModalRef, setActiveModalRef } = useModalContext();
  const relatedDocRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const fileIndex = useMemo(() => {
    return createFileIndexFromAuditTool(
      auditTool,
      alreadySelectedRelatedDocIds,
      alreadyReviewedDocIds
    );
  }, [alreadyReviewedDocIds, alreadySelectedRelatedDocIds]);

  const handleSelect = (fileIndex: FileIndex) => {
    if (!fileIndex.id) return;
    let updatedSelectedRelatedDocIds = alreadySelectedRelatedDocIds.slice();
    const id = fileIndex.id as string;
    updatedSelectedRelatedDocIds = updatedSelectedRelatedDocIds.includes(id)
      ? updatedSelectedRelatedDocIds.filter((docId) => docId !== id)
      : [...updatedSelectedRelatedDocIds, id];
    onSelect(updatedSelectedRelatedDocIds);
  };

  const handleSelectFolder = (checked: boolean, files: FileIndex[]) => {
    if (!files.length) return;
    let updatedSelectedRelatedDocIds = alreadySelectedRelatedDocIds.slice();
    for (const file of files) {
      if (
        checked &&
        !updatedSelectedRelatedDocIds.includes(file.id as string)
      ) {
        updatedSelectedRelatedDocIds.push(file.id as string);
      } else if (
        !checked &&
        updatedSelectedRelatedDocIds.includes(file.id as string)
      ) {
        updatedSelectedRelatedDocIds = updatedSelectedRelatedDocIds.filter(
          (docId) => docId !== file.id
        );
      }
    }
    onSelect(updatedSelectedRelatedDocIds);
  };

  useEffect(() => {
    if (open) {
      setActiveModalRef(relatedDocRef);
    } else {
      setActiveModalRef(null);
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Select Docs to Review</Button>
      </DialogTrigger>
      <DialogContent ref={relatedDocRef} className="min-w-[800px]">
        <DialogHeader>
          <DialogTitle>Select Related Documents for Audit</DialogTitle>
          <div className="flex items-center gap-2 text-sm text-gray-500">
            {isSharepointLoading ? (
              <>
                <span>Syncing with SharePoint</span>
                <Loader2 className="h-4 w-4 animate-spin" />
              </>
            ) : isSharepointSynced ? (
              <div className="flex items-center gap-2">
                <CheckCircle className="h-4 w-4 text-green-500" />
                <span>
                  Synced with SharePoint{" "}
                  <TimeAgo
                    timestamp={auditTool.related_docs_last_updated_at ?? ""}
                  />
                </span>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={(e) => {
                    e.preventDefault();
                    syncSharepoint();
                  }}
                  className="h-6 w-6 hover:bg-gray-100 rounded-full"
                >
                  <RefreshCw className="h-3 w-3 text-gray-400" />
                </Button>
              </div>
            ) : null}
          </div>
        </DialogHeader>
        <div className="space-y-2 max-h-[calc(100vh-200px)] overflow-y-auto">
          <FileTree
            files={fileIndex.children}
            folderName={fileIndex.name}
            onSelectFile={handleSelect}
            onSelectFolder={handleSelectFolder}
          />
        </div>
        <div className="flex items-center justify-between mt-4">
          <SharepointUrlPopover />
          <Button
            onClick={() => {
              onSelect(alreadySelectedRelatedDocIds);
              setOpen(false);
            }}
          >
            Select Documents ({alreadySelectedRelatedDocIds.length})
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const AuditToolFactorAuditView = ({}: {}) => {
  const authInfo = useAuthInfo();
  const reviewSummaryRef = useRef<HTMLDivElement>(null);
  const {
    auditTool,
    setAuditTool,
    fetchAuditTool,
    setActiveFactor,
    activeFactor,
    auditFactorReview,
    setAuditFactorReview,
    findFactorNeighbors,
  } = useContext(AuditToolContext);
  const navigate = useNavigate();
  const { simpleUsers } = useContext(UserContext);
  const { activeModalRef, setActiveModalRef } = useModalContext();
  const { docToView, setCitations } = useContext(DocViewerContext);
  const { auditToolId, factorId } = useParams<{
    auditToolId: string;
    factorId: string;
  }>();
  const docSheetRef = useRef<HTMLDivElement>(null);
  const [openDocSheet, setOpenDocSheet] = useState(false);
  const [isAuditGenerating, setIsAuditGenerating] = useState(false);
  const [isSharepointSynced, setIsSharepointSynced] = useState(false);
  const [isSharepointLoading, setIsSharepointLoading] = useState(false);
  const [selectedRelatedDocIds, setSelectedRelatedDocIds] = useState<string[]>(
    []
  );
  const [reviewTab, setReviewTab] = useState<"ai" | "history">("ai");
  const [elementReview, setElementReview] =
    useState<AuditToolElementReview | null>(null);
  const [isElementReviewPopoverOpen, setIsElementReviewPopoverOpen] =
    useState(false);

  const [isNoReviewDialogOpen, setIsNoReviewDialogOpen] = useState(false);
  const documentReviewRef = useRef<{
    scrollToDocReview: (docId: string) => void;
  }>(null);
  const [thoughtProcess, setThoughtProcess] = useState<string | null>(null);
  const [factorNeighbors, setFactorNeighbors] = useState<{
    previous: Factor | boolean;
    next: Factor | boolean;
  }>(
    activeFactor
      ? findFactorNeighbors(activeFactor)
      : { previous: false, next: false }
  );
  const syncSharepoint = async (override = false) => {
    if (!auditTool || isSharepointLoading) return;
    const lastUpdated = localizeDate(
      auditTool.related_docs_last_updated_at ?? ""
    );
    const currentTime = new Date();
    const diffInMinutes =
      (currentTime.getTime() - lastUpdated.getTime()) / (1000 * 60);

    setIsSharepointLoading(true);
    try {
      // const sharepointFolderInfo = await getAuditToolSharepointFolderInfo(
      //   auditTool.id,
      //   authInfo.accessToken ?? null
      // );
      if (
        !auditTool.related_docs_last_updated_at ||
        override ||
        diffInMinutes > 3
      ) {
        const related_docs = await loadAuditToolDocs(
          auditTool.id,
          authInfo.accessToken ?? null
        );
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setAuditTool((prevAuditTool) => {
          if (!prevAuditTool) return null;
          return {
            ...prevAuditTool,
            related_docs_last_updated_at: new Date()
              .toISOString()
              .replace("Z", "")
              .replace("T", " "),
            related_docs: related_docs ?? [],
          };
        });
      }
      setIsSharepointSynced(true);
    } catch (error) {
      console.error("Error syncing sharepoint", error);
    } finally {
      setIsSharepointLoading(false);
    }
  };
  const fetchReview = async () => {
    if (!auditToolId || !factorId || !authInfo.accessToken) return;
    const review = await getAuditFactorDocReview(
      auditToolId,
      factorId,
      authInfo.accessToken ?? null
    );
    const reviewDocs: AuditToolFactorReviewDoc[] = review.map(
      (reviewDoc: AuditFactorDocReviewResponse) => ({
        id: reviewDoc.id,
        audit_tool_related_doc_id: reviewDoc.audit_tool_related_doc_id,
        doc_id: reviewDoc.audit_tool_related_doc.doc.id,
        doc_name: reviewDoc.audit_tool_related_doc.doc.doc_name,
        factor_id: reviewDoc.audit_factor_id,
        review: reviewDoc.review_summary,
        review_status: reviewDoc.review_summary,
        review_rank: reviewDoc.review_rank,
        review_flagged: reviewDoc.review_flagged,
        review_flagged_reason: reviewDoc.review_flagged_reason,
        review_flagged_by: reviewDoc.review_flagged_by,
        citations: reviewDoc.citations.map((citation: any) => ({
          id: citation.citation.id,
          match: citation.citation.text,
          exactMatch: true,
          page: citation.citation.page,
          match_index: citation.citation.match_index ?? undefined,
        })),
      })
    );
    setAuditFactorReview((prev) => {
      return factorId
        ? {
            ...prev,
            [factorId]: {
              review_summary: activeFactor?.narrative ?? "",
              review_docs: reviewDocs,
            },
          }
        : prev;
    });
    setSelectedRelatedDocIds((selected) => {
      return selected.filter(
        (id) => !reviewDocs.some((doc) => doc.audit_tool_related_doc_id === id)
      );
    });
  };

  const fetchElementReview = async () => {
    if (!auditToolId) return;
    try {
      const review = await getAuditToolElementReview(
        auditToolId,
        activeFactor?.functional_area ?? "",
        activeFactor?.standard_index ?? "",
        activeFactor?.element_index ?? "",
        authInfo.accessToken ?? null
      );
      setElementReview(review);
    } catch (error) {
      console.error("Error fetching element review:", error);
      toast.error("Error fetching element review");
    }
  };

  const handleDeleteReview = async (reviewId: string) => {
    if (!auditToolId || !factorId || !authInfo.accessToken) return;
    await deleteAuditToolFactorReview(
      auditToolId,
      factorId,
      reviewId,
      authInfo.accessToken ?? null
    );
    fetchReview();
  };
  useEffect(() => {
    if (openDocSheet) {
      setActiveModalRef(docSheetRef);
    }
  }, [docSheetRef, openDocSheet]);
  useEffect(() => {
    if (activeFactor) {
      fetchReview();
      fetchElementReview();
      setFactorNeighbors(findFactorNeighbors(activeFactor));
    }
  }, [activeFactor?.id]);
  useEffect(() => {
    if (auditTool) {
      setActiveFactor(
        auditTool.factors.find((factor) => factor.id === factorId) ?? null
      );
      syncSharepoint();
    }
  }, [auditTool, factorId]);
  useEffect(() => {
    if (auditToolId) {
      fetchAuditTool(auditToolId);
    }
  }, [auditToolId]);
  useEffect(() => {
    if (reviewSummaryRef.current && isAuditGenerating) {
      reviewSummaryRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [
    reviewSummaryRef.current,
    auditFactorReview[factorId ?? ""]?.review_summary?.length,
  ]);
  useEffect(() => {
    if (docToView?.docId) {
      setOpenDocSheet(true);
    }
  }, [docToView?.docId]);

  useEffect(() => {
    setOpenDocSheet(false);
  }, []);

  const handleUpdateFactor = useCallback(
    async (updatedFactor: Partial<Factor>) => {
      if (!auditToolId || !activeFactor?.id || !authInfo.accessToken) return;
      await updateAuditFactor(
        auditToolId,
        {
          ...updatedFactor,
          id: activeFactor.id,
        },
        authInfo.accessToken ?? null
      );
    },
    [activeFactor, factorId]
  );

  const handleRegenerateReview = async () => {
    if (!auditToolId || !factorId || !authInfo.accessToken) return;
    try {
      setIsAuditGenerating(true);
      setReviewTab("ai");
      for await (const output of regenerateFactorDocReview(
        auditToolId,
        factorId,
        authInfo.accessToken ?? null
      )) {
        console.log(`output: ${output}`);
        setAuditFactorReview((prev) => {
          const prevReview = prev[factorId];
          return factorId
            ? {
                ...prev,
                [factorId]: {
                  ...prevReview,
                  review_summary: output.review_summary,
                },
              }
            : prev;
        });
      }
      const factor = await getAuditFactor(
        auditToolId,
        factorId,
        authInfo.accessToken ?? null
      );
      setAuditTool((prevAuditTool) => {
        if (!prevAuditTool) return null;
        return {
          ...prevAuditTool,
          factors: prevAuditTool.factors.map((f) =>
            f.id === factorId ? factor : f
          ),
        };
      });
    } catch (error) {
      console.error("Error regenerating review", error);
    } finally {
      setIsAuditGenerating(false);
    }
  };
  const handleSubmitAudit = async (relatedDocIds: string[]) => {
    if (!auditToolId || !factorId || !authInfo.accessToken) return;
    try {
      setIsAuditGenerating(true);
      setReviewTab("ai");
      for await (const output of generateFactorDocReview(
        auditToolId,
        factorId,
        relatedDocIds,
        authInfo.accessToken ?? null
      )) {
        const auditResponse: AuditFactorReview = {
          ...output,
          review_docs: output?.review_docs.map((doc) => {
            return {
              ...doc,
              audit_tool_related_doc_id: doc.audit_tool_related_doc_id ?? null,
              factor_id: doc.audit_question_id,
              citations: doc.citations.map((citation, index) => {
                return {
                  id: citation.id ?? `doc-${doc.doc_id}-citation-${index}`,
                  match: citation.text,
                  exactMatch: true,
                  page: citation.page,
                  match_index: citation.match_index ?? 0,
                };
              }),
            };
          }),
        };
        setAuditFactorReview((prev) => {
          return factorId
            ? {
                ...prev,
                [factorId]: auditResponse,
              }
            : prev;
        });
        if (output.thought_process) {
          setThoughtProcess(output.thought_process);
        }
        if (auditResponse.review_docs.length > 0) {
          setCitations(auditResponse.review_docs[0].citations);
        }
      }
      const factor = await getAuditFactor(
        auditToolId,
        factorId,
        authInfo.accessToken ?? null
      );
      setAuditTool((prevAuditTool) => {
        if (!prevAuditTool) return null;
        return {
          ...prevAuditTool,
          factors: prevAuditTool.factors.map((f) =>
            f.id === factorId ? factor : f
          ),
        };
      });
    } catch (error: any) {
      console.error("There was an error generating the review", error);
    } finally {
      setIsAuditGenerating(false);
    }
  };

  const FactorLinkToolTipContent = ({
    factor,
  }: {
    factor: Factor | boolean;
  }) => {
    return (
      <div className="p-2">
        {factor && typeof factor !== "boolean" ? (
          <>
            <div className="font-medium text-gray-900 mb-1">Go to:</div>
            <div className="text-sm text-gray-700">
              Requirement: {factor.factor_title}
            </div>
            {activeFactor &&
              factor.element_index !== activeFactor.element_index && (
                <div className="text-xs text-gray-500 mt-1">
                  Different element: {factor.element_index}
                </div>
              )}
          </>
        ) : (
          <div className="text-sm text-gray-500">No factor available</div>
        )}
      </div>
    );
  };

  const handleNextFactor = () => {
    const { next } = factorNeighbors;
    if (next && typeof next !== "boolean") {
      const newSelectedRelatedDocIds: string[] = [...selectedRelatedDocIds];
      auditFactorReview[factorId ?? ""].review_docs.forEach((doc) => {
        if (
          !newSelectedRelatedDocIds.includes(doc.audit_tool_related_doc_id) &&
          doc.audit_tool_related_doc_id
        ) {
          newSelectedRelatedDocIds.push(doc.audit_tool_related_doc_id);
        }
      });
      setSelectedRelatedDocIds(newSelectedRelatedDocIds);
      if (activeFactor && !isFactorSameElement(next, activeFactor)) {
        navigate(
          `/delegate-audit/${auditToolId}/?fa=${encodeURIComponent(activeFactor?.functional_area ?? "")}&std=${encodeURIComponent(activeFactor?.standard_index ?? "")}&el=${encodeURIComponent(activeFactor?.element_index ?? "")}`
        );
        return;
      }
      navigate(`/delegate-audit/${auditToolId}/factor/${next.id}/audit`);
      setActiveFactor(next);
    }
  };
  interface InlineDocReviewLinkProps {
    inline?: boolean;
    children?: React.ReactNode;
    [key: string]: any;
  }
  const InlineDocReviewDisplay = ({
    children,
    ...props
  }: InlineDocReviewLinkProps) => {
    if (typeof children === "string" && children.startsWith("doc:")) {
      const docName = children.replace("doc:", "");
      const doc = auditFactorReview[factorId ?? ""].review_docs
        .sort((a, b) => (b.review_rank ?? 0) - (a.review_rank ?? 0))
        .find(
          (doc) =>
            fileNameWithoutExtension(doc.doc_name) ===
            fileNameWithoutExtension(docName)
        );
      return (
        <span
          onClick={() => {
            documentReviewRef.current?.scrollToDocReview(doc?.doc_id ?? "");
          }}
          className="text-blue-600 underline inline font-bold hover:cursor-pointer hover:text-blue-800"
        >
          "{docName}"
        </span>
      );
    }
    return <code {...props}>{children}</code>;
  };

  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerateFactorFindings = async () => {
    if (!auditToolId || !factorId || !authInfo.accessToken) return;
    try {
      setIsGenerating(true);
      for await (const findings of createFactorFindings(
        auditToolId,
        factorId,
        authInfo.accessToken ?? null
      )) {
        console.log(`findings: ${findings}`);
        setActiveFactor((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            findings: findings.findings,
          };
        });
      }
    } catch (error) {
      console.error("Error generating factor findings:", error);
      toast.error("Error generating factor findings");
    } finally {
      setIsGenerating(false);
    }
  };

  const isFactorSameElement = (factorOne: Factor, factorTwo: Factor) => {
    return (
      factorOne.element_index === factorTwo.element_index &&
      factorOne.functional_area === factorTwo.functional_area &&
      factorOne.standard_index === factorTwo.standard_index
    );
  };

  const submittedDocs = elementReview?.element_metadata?.submitted_docs
    ?.split(/[\n;]+/)
    .map((doc: string) => doc.trim())
    .filter((doc: string) => doc.length > 0);

  return (
    <Layout pageName="Audit Tool">
      <div>
        <div className="flex flex-row items-center mb-4">
          <AuditToolBreadcrumbs
            auditTool={auditTool}
            factor={activeFactor ?? undefined}
          />
        </div>

        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={50}
            minSize={40}
            maxSize={60}
            id="resource-panel"
            order={1}
          >
            <div className="space-y-4 pb-10 pl-1 pr-5 h-[calc(100vh-165px)] overflow-y-auto">
              <div className="space-y-6">
                {activeFactor ? (
                  <>
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center justify-between gap-4">
                        <Tooltip>
                          <TooltipTrigger>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                const { previous } = factorNeighbors;
                                if (previous && typeof previous !== "boolean") {
                                  navigate(
                                    `/delegate-audit/${auditToolId}/factor/${previous.id}/audit`
                                  );
                                  setActiveFactor(previous);
                                }
                              }}
                              disabled={!factorNeighbors.previous}
                            >
                              Previous
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <FactorLinkToolTipContent
                              factor={factorNeighbors.previous}
                            />
                          </TooltipContent>
                        </Tooltip>
                        <h1 className="text-2xl font-bold tracking-tight bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
                          {activeFactor?.factor_title
                            ? `${activeFactor.factor_index} - ${activeFactor?.factor_title}`
                            : activeFactor?.factor_index}
                        </h1>
                        <Dialog
                          open={isNoReviewDialogOpen}
                          onOpenChange={setIsNoReviewDialogOpen}
                        >
                          <DialogContent>
                            <DialogHeader>
                              <DialogTitle>
                                No Auditor Assessment Made
                              </DialogTitle>
                            </DialogHeader>
                            <DialogDescription className="flex items-center gap-4">
                              <AlertTriangle className="h-12 w-12 text-yellow-500 flex-none" />
                              <div>
                                Are you sure you want to leave without making an
                                Assessment?
                              </div>
                            </DialogDescription>
                            <DialogFooter>
                              <Button
                                variant="outline"
                                onClick={() => setIsNoReviewDialogOpen(false)}
                              >
                                Make Assessment
                              </Button>
                              <Button
                                variant="default"
                                onClick={() => {
                                  handleNextFactor();
                                  setIsNoReviewDialogOpen(false);
                                }}
                              >
                                Continue
                              </Button>
                            </DialogFooter>
                          </DialogContent>
                        </Dialog>
                        <Tooltip>
                          <TooltipTrigger>
                            <Button
                              variant="outline"
                              size="sm"
                              style={{ whiteSpace: "nowrap" }}
                              onClick={() => {
                                if (activeFactor?.status !== "done") {
                                  setIsNoReviewDialogOpen(true);
                                  return;
                                }
                                handleNextFactor();
                              }}
                              disabled={!factorNeighbors.next}
                            >
                              {typeof factorNeighbors.next !== "boolean" &&
                              !isFactorSameElement(
                                factorNeighbors.next,
                                activeFactor
                              )
                                ? "Review Element"
                                : "Next"}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            {typeof factorNeighbors.next !== "boolean" &&
                            factorNeighbors.next?.element_index !==
                              activeFactor?.element_index ? (
                              <div className="text-xs text-gray-500 m-2">
                                Last Requirment in element:{" "}
                                <span className="font-bold">
                                  {factorNeighbors.next.element_index}
                                </span>
                              </div>
                            ) : (
                              <FactorLinkToolTipContent
                                factor={factorNeighbors.next}
                              />
                            )}
                          </TooltipContent>
                        </Tooltip>
                      </div>
                      <div className="flex flex-col gap-4 mt-2 ml-6 mr-6">
                        <div className="text-sm text-gray-500 italic">
                          <span className="font-medium">
                            <span className="mr-1">
                              Reference:
                              {activeFactor?.factor_reference.split("\n")
                                .length > 1 && "s"}
                            </span>
                            {BadgeList({
                              text: activeFactor?.factor_reference,
                            })?.map((item, index) => (
                              <div
                                key={index}
                                className="inline-block mr-1 mb-1"
                              >
                                {item}
                              </div>
                            ))}
                          </span>
                        </div>
                        {elementReview?.element_metadata && (
                          <div className="text-sm text-gray-500 italic">
                            <span className="font-medium">
                              <span className="mr-1">Delegate Submission:</span>
                            </span>
                            <Popover
                              onOpenChange={setIsElementReviewPopoverOpen}
                            >
                              <PopoverTrigger>
                                <Badge>
                                  {
                                    elementReview?.element_metadata
                                      ?.deliverable_number
                                  }
                                </Badge>
                              </PopoverTrigger>
                              <PopoverContentWithScroll
                                isOpen={isElementReviewPopoverOpen}
                                className="w-[500px] max-h-[400px] overflow-y-auto"
                                side="left"
                                align="center"
                              >
                                <div className="flex flex-col gap-2 align-start">
                                  <h3 className="font-bold text-lg">
                                    Delegate Submission
                                  </h3>
                                  <hr className="my-2" />
                                  <ReactMarkdown>
                                    {submittedDocs
                                      ?.map((doc: string) => `**-** ${doc}`)
                                      .join("  \n")}
                                  </ReactMarkdown>
                                </div>
                              </PopoverContentWithScroll>
                            </Popover>
                          </div>
                        )}
                        <ExpandableContent
                          description={activeFactor.factor_description}
                          explanation={activeFactor.factor_explanation}
                        />
                      </div>
                    </div>
                    <div>
                      {auditTool && (
                        <>
                          <div className="flex justify-center gap-4">
                            <div className="flex gap-4">
                              <RelatedDocsDialog
                                auditTool={auditTool}
                                onSelect={setSelectedRelatedDocIds}
                                syncSharepoint={() => syncSharepoint(true)}
                                isSharepointSynced={isSharepointSynced}
                                isSharepointLoading={isSharepointLoading}
                                alreadySelectedRelatedDocIds={
                                  selectedRelatedDocIds
                                }
                                alreadyReviewedDocIds={auditFactorReview[
                                  factorId ?? ""
                                ]?.review_docs.map(
                                  (doc) => doc.audit_tool_related_doc_id
                                )}
                              />
                              <Tooltip>
                                <TooltipTrigger>
                                  <Button
                                    variant="default"
                                    className="gap-2"
                                    onClick={() =>
                                      handleSubmitAudit(selectedRelatedDocIds)
                                    }
                                    disabled={
                                      selectedRelatedDocIds.length === 0 ||
                                      isAuditGenerating
                                    }
                                  >
                                    <Sparkles
                                      className={`h-4 w-4 ${selectedRelatedDocIds.length > 0 ? "animate-pulse" : ""}`}
                                    />
                                    Audit (
                                    {
                                      selectedRelatedDocIds?.filter(
                                        (id) =>
                                          !auditFactorReview[
                                            factorId ?? ""
                                          ]?.review_docs?.some(
                                            (doc) => doc.id === id
                                          )
                                      ).length
                                    }
                                    )
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent className="max-w-sm p-4 bg-white border border-gray-200 rounded-md shadow-md text-sm text-gray-700">
                                  {selectedRelatedDocIds.length === 0 ? (
                                    <div className="text-gray-500 italic pl-4">
                                      <p>No documents selected for audit</p>
                                      <p>
                                        Choose documents by clicking "Select
                                        Docs to Review"
                                      </p>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="font-semibold mb-2">
                                        Documents to be audited:
                                      </div>
                                      <ul className="list-disc list-inside max-h-48 overflow-scroll relative">
                                        {selectedRelatedDocIds.map(
                                          (related_doc_id, index) => {
                                            const related_doc =
                                              auditTool.related_docs.find(
                                                (rd) => rd.id === related_doc_id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                className="truncate"
                                                title={related_doc?.doc_name}
                                              >
                                                {related_doc?.doc_name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                      {selectedRelatedDocIds.length > 8 && (
                                        <div className="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-white to-transparent pointer-events-none opacity-75 to-opacity-0"></div>
                                      )}
                                    </>
                                  )}
                                </TooltipContent>
                              </Tooltip>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {auditFactorReview[factorId ?? ""] && (
                      <div className="space-y-4">
                        <h2 className="text-lg font-semibold flex items-center gap-2">
                          <div className="flex space-x-4">
                            <button
                              className={`${
                                reviewTab === "ai"
                                  ? "text-primary font-semibold"
                                  : "text-gray-400 hover:text-gray-500"
                              }`}
                              onClick={() => setReviewTab("ai")}
                              disabled={isAuditGenerating}
                            >
                              AI Review
                            </button>
                            <button
                              className={`${
                                reviewTab === "history"
                                  ? "text-primary font-semibold"
                                  : "text-gray-400 hover:text-gray-500"
                              }`}
                              onClick={() => setReviewTab("history")}
                              disabled={isAuditGenerating}
                            >
                              Past Review
                            </button>
                          </div>
                          <div className="flex-1" />
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={handleRegenerateReview}
                            disabled={isAuditGenerating}
                          >
                            Regenerate
                          </Button>
                        </h2>
                        <div className="space-y-2">
                          {reviewTab === "ai" && (
                            <div>
                              {isAuditGenerating &&
                              !auditFactorReview[factorId ?? ""]
                                .review_summary ? (
                                <div className="h-[120px] flex items-center justify-center bg-gray-50 rounded-md">
                                  <div className="flex items-center justify-center space-x-2">
                                    <span className="animate-pulse text-lg text-gray-800">
                                      {thoughtProcess
                                        ? thoughtProcess
                                        : "Generating review..."}
                                    </span>
                                    <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-primary"></div>
                                  </div>
                                </div>
                              ) : !auditFactorReview[factorId ?? ""]
                                  .review_summary ? (
                                <div className="h-[120px] flex items-center justify-center bg-gray-50 rounded-md">
                                  <span className="text-lg text-gray-500">
                                    To create an AI review, select documents to
                                    review, then click Audit
                                  </span>
                                </div>
                              ) : (
                                <div
                                  ref={reviewSummaryRef}
                                  className="prose max-w-none p-4 bg-gray-50 rounded-md"
                                >
                                  <ReactMarkdown
                                    components={{
                                      code: InlineDocReviewDisplay,
                                    }}
                                  >
                                    {escapeReviewMarkdown(
                                      auditFactorReview[factorId ?? ""]
                                    )}
                                  </ReactMarkdown>
                                </div>
                              )}
                            </div>
                          )}
                          {reviewTab === "history" && (
                            <div className="prose max-w-none p-4 bg-gray-50 rounded-md">
                              {activeFactor.previous_assessment &&
                              (activeFactor.previous_assessment.approved_24 ||
                                activeFactor.previous_assessment.comments_24 ||
                                activeFactor.previous_assessment.reviewed_24 ||
                                activeFactor.previous_assessment
                                  .submitted_24) ? (
                                <>
                                  <div className="mb-2 p-2 bg-white border rounded">
                                    <p className="text-center m-1 font-semibold text-xl">
                                      Approved:{" "}
                                      <strong>
                                        {activeFactor.previous_assessment
                                          ?.approved_24 || "N/A"}
                                      </strong>
                                    </p>
                                  </div>
                                  <div className="mb-2 p-2 bg-white border rounded">
                                    <h3 className="text-center font-semibold my-1">
                                      Criteria
                                    </h3>
                                    <p className="whitespace-pre-wrap break-words">
                                      {activeFactor.previous_assessment
                                        ?.criteria_24 || "N/A"}
                                    </p>
                                  </div>
                                  <div className="mb-2 p-2 bg-white border rounded">
                                    <h3 className="text-center font-semibold my-1">
                                      Documents Submitted
                                    </h3>
                                    <p className="whitespace-pre-wrap break-words">
                                      {activeFactor.previous_assessment
                                        ?.submitted_24 || "N/A"}
                                    </p>
                                  </div>
                                  <div className="mb-2 p-2 bg-white border rounded">
                                    <h3 className="text-center font-semibold my-1">
                                      Documents Reviewed
                                    </h3>
                                    <p className="whitespace-pre-wrap break-words">
                                      {activeFactor.previous_assessment
                                        ?.reviewed_24 || "N/A"}
                                    </p>
                                  </div>
                                  <div className="mb-2 p-2 bg-white border rounded">
                                    <h3 className="text-center font-semibold my-1">
                                      SME Comments
                                    </h3>
                                    <p className="whitespace-pre-wrap break-words">
                                      {activeFactor.previous_assessment
                                        ?.comments_24 || "N/A"}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <div className="h-[120px] flex items-center justify-center bg-gray-50 rounded-md">
                                  <span className="text-lg text-gray-500">
                                    No previous assessment found
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {reviewTab === "ai" && (
                          <DocumentReview
                            reviewDocs={
                              auditFactorReview[factorId ?? ""].review_docs
                            }
                            isAuditGenerating={isAuditGenerating}
                            setOpenDocSheet={setOpenDocSheet}
                            ref={documentReviewRef}
                            deleteReview={handleDeleteReview}
                          />
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <div>No factor selected</div>
                )}
              </div>
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel
            defaultSize={50}
            minSize={40}
            maxSize={60}
            id="review-panel"
            order={2}
          >
            <div className="ml-2 flex flex-col space-y-4 pb-10 pl-1 pr-5 h-[calc(100vh-165px)] overflow-y-auto">
              {activeFactor && (
                <div className="ml-2 mb-4 space-y-4 p-4 border border-gray-200 rounded-lg bg-white">
                  <h2 className="text-xl font-semibold mb-2 flex items-center gap-2">
                    Requirement Assessment{" "}
                    <span className="text-gray-500">
                      -{" "}
                      {(
                        activeFactor.factor_title || activeFactor.factor_index
                      ).slice(0, 33) +
                        ((
                          activeFactor.factor_title || activeFactor.factor_index
                        ).length > 33
                          ? "..."
                          : "")}
                    </span>
                  </h2>
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col gap-1 relative">
                      <span className="font-medium flex items-center">
                        {activeFactor.status === "review" &&
                          !activeFactor.assessment && (
                            <Tooltip>
                              <TooltipTrigger>
                                <div className="flex items-center rounded-full p-1 mr-5">
                                  <TriangleAlert className="h-5 w-5 text-yellow-500 absolute" />
                                  <TriangleAlert className="h-5 w-5 text-yellow-500 absolute animate-ping" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                This factor has not been assessed yet.
                              </TooltipContent>
                            </Tooltip>
                          )}
                        Assessment:
                      </span>
                      <UpdateFactorAssessment factor={activeFactor} />
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="font-medium flex items-center">
                        {activeFactor.status === "review" &&
                          activeFactor.assessment && (
                            <Tooltip>
                              <TooltipTrigger>
                                <div className="flex items-center rounded-full p-1 mr-5">
                                  <TriangleAlert className="h-5 w-5 text-yellow-500 absolute" />
                                  <TriangleAlert className="h-5 w-5 text-yellow-500 absolute animate-ping" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                Mark as Complete to finish assessment.
                              </TooltipContent>
                            </Tooltip>
                          )}
                        Status:
                      </span>
                      <UpdateFactorStatus factor={activeFactor} />
                    </div>
                  </div>
                </div>
              )}
              <div className="ml-2 p-4 border border-gray-200 rounded-lg bg-white flex-1">
                <div className="flex flex-col h-full">
                  <div className="flex flex-col gap-2 min-w-[300px] flex-1">
                    <div className="flex items-center justify-between">
                      <label className="block font-medium">Findings:</label>
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            variant="outline"
                            onClick={handleGenerateFactorFindings}
                            disabled={isGenerating}
                            className="mb-0"
                          >
                            Generate Findings
                            {isGenerating ? (
                              <Loader2 className="ml-2 w-4 h-4 animate-spin" />
                            ) : (
                              <Sparkles className="ml-2 w-4 h-4" />
                            )}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>
                            Generate a findings report that will be sent to the
                            audited organization.
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    <AutoSavingTextArea
                      onSave={async (e) => {
                        if (!activeFactor || isGenerating) return;
                        await handleUpdateFactor({
                          findings: e.target.value,
                          findings_updated_at: new Date().toISOString(),
                          findings_updated_by: authInfo.user?.userId,
                        });
                        toast.success("Findings saved");
                        setActiveFactor((prev) => {
                          if (!prev) return prev;
                          return {
                            ...prev,
                            findings_updated_at: new Date()
                              .toISOString()
                              .slice(0, -1),
                            findings_updated_by: authInfo.user?.userId,
                          };
                        });
                      }}
                      textareaProps={{
                        value: activeFactor?.findings ?? "",
                        onChange: (e) => {
                          setActiveFactor((prev) => {
                            if (!prev) return prev;
                            return {
                              ...prev,
                              findings: e.target.value,
                            };
                          });
                        },
                        placeholder: "Enter your findings here...",
                        className: "w-full flex-1 border rounded-md p-2",
                      }}
                      className="flex-1 flex flex-col pb-4 min-h-[150px]"
                      updatedText={
                        activeFactor?.findings_updated_at ? (
                          <div className="text-xs text-gray-500 text-center">
                            Last updated{" "}
                            <TimeAgo
                              timestamp={
                                activeFactor.findings_updated_at ??
                                new Date().toISOString()
                              }
                            />{" "}
                            by{" "}
                            {simpleUsers.find(
                              (user) =>
                                user.id === activeFactor.findings_updated_by
                            )?.first_name || "Unknown"}
                          </div>
                        ) : null
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-2 min-w-[300px] flex-1">
                    <div className="flex items-center justify-between h-[40px]">
                      <label className="block font-medium">Observations:</label>
                      {/* <Tooltip>
                        <TooltipTrigger>
                          <Info className="h-4 w-4 text-gray-500 hover:text-gray-700" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>
                            Enter any observations related to the audit factor.
                          </p>
                        </TooltipContent>
                      </Tooltip> */}
                    </div>
                    <AutoSavingTextArea
                      onSave={async (e) => {
                        if (!activeFactor || isGenerating) return;
                        await handleUpdateFactor({
                          observations: e.target.value,
                          observations_updated_at: new Date().toISOString(),
                          observations_updated_by: authInfo.user?.userId,
                        });
                        toast.success("Observations saved");
                        setActiveFactor((prev) => {
                          if (!prev) return prev;
                          return {
                            ...prev,
                            observations_updated_at: new Date()
                              .toISOString()
                              .slice(0, -1),
                            observations_updated_by: authInfo.user?.userId,
                          };
                        });
                      }}
                      updatedText={
                        activeFactor?.observations_updated_at ? (
                          <div className="text-xs text-gray-500 text-center">
                            Last updated{" "}
                            <TimeAgo
                              timestamp={
                                activeFactor.observations_updated_at ??
                                new Date().toISOString()
                              }
                            />{" "}
                            by{" "}
                            {simpleUsers.find(
                              (user) =>
                                user.id === activeFactor.observations_updated_by
                            )?.first_name || "Unknown"}
                          </div>
                        ) : null
                      }
                      textareaProps={{
                        value: activeFactor?.observations ?? "",
                        onChange: (e) => {
                          setActiveFactor((prev) => {
                            if (!prev) return prev;
                            return {
                              ...prev,
                              observations: e.target.value,
                            };
                          });
                        },
                        rows: 5,
                        placeholder: "Enter your observations here...",
                        className: "w-full flex-1 border rounded-md p-2",
                      }}
                      className="flex-1 flex flex-col pb-4 min-h-[0px]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row gap-2 justify-between">
                      <span className="font-medium">Notes:</span>
                      {/* <Tooltip>
                        <TooltipTrigger>
                          <Info className="h-4 w-4 text-gray-500 hover:text-gray-700" />
                        </TooltipTrigger>
                        <TooltipContent
                          side="left"
                          className="p-4 bg-gray-50 border border-gray-200 rounded shadow-md max-w-xs"
                        >
                          <div className="text-sm text-gray-700">
                            <p>
                              This notes section is for internal auditor use
                              only and should not be reported to the health
                              plan.
                            </p>
                          </div>
                        </TooltipContent>
                      </Tooltip> */}
                    </div>
                    <AutoSavingTextArea
                      onSave={async (e) => {
                        await handleUpdateFactor({
                          notes: e.target.value,
                          notes_updated_at: new Date().toISOString(),
                          notes_updated_by: authInfo.user?.userId,
                        });
                        setActiveFactor((prev) => {
                          if (!prev) return prev;
                          return {
                            ...prev,
                            notes_updated_at: new Date()
                              .toISOString()
                              .slice(0, -1),
                            notes_updated_by: authInfo.user?.userId,
                          };
                        });
                        toast.success("Notes saved");
                      }}
                      updatedText={
                        activeFactor?.notes_updated_at ? (
                          <div className="text-xs text-gray-500 text-center">
                            Last updated{" "}
                            <TimeAgo
                              timestamp={
                                activeFactor.notes_updated_at ??
                                new Date().toUTCString()
                              }
                            />{" "}
                            by{" "}
                            {simpleUsers.find(
                              (user) =>
                                user.id === activeFactor.notes_updated_by
                            )?.first_name || "Unknown"}
                          </div>
                        ) : null
                      }
                      textareaProps={{
                        value: activeFactor?.notes ?? "",
                        onChange: (e) => {
                          setActiveFactor((prev) => {
                            if (!prev) return prev;
                            return {
                              ...prev,
                              notes: e.target.value,
                            };
                          });
                        },
                        rows: 14,
                        placeholder: `Enter your internal auditor notes here`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
      <Sheet open={openDocSheet} onOpenChange={setOpenDocSheet}>
        <SheetContent style={{ maxWidth: "50vw" }} ref={docSheetRef}>
          <SheetTitle>Audit Tool</SheetTitle>
          {docToView?.docId && (
            <DocViewerCitation
              docId={docToView?.docId}
              className="h-[calc(100vh-130px)]"
              hideAtlasWidget={true}
              parentModalRef={activeModalRef ?? undefined}
            />
          )}
        </SheetContent>
      </Sheet>
    </Layout>
  );
};

export default DocumentReview;
