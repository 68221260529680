import { useAuthInfo } from "@propelauth/react";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { HeaderCell } from "../../components/Table";
import { AuditToolContext } from "../../contexts/AuditToolContext";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import {
  AuditFactorAssessment,
  auditFactorStatus,
  AuditFactorStatus,
  AuditToolPermission,
  Factor,
} from "../../types";
import {
  deleteAuditToolElement,
  deleteAuditToolFactor,
  getAuditToolElementReviews,
} from "../../utils/apiCalls";
import {
  getUniqueElements,
  getUniqueFunctionalAreas,
  getUniqueStandards,
  smartSort,
  UniqueElement,
  UniqueFunctionalArea,
  UniqueStandard,
} from "./utils";

import { Button } from "../../shadcn/components/button";

import { Check, ChevronsUpDown, Info, Trash2 } from "lucide-react";
import React from "react";
import ReactMarkdown from "react-markdown";
import { toast } from "sonner";
import SimpleUserComboBox from "../../components/SimpleUserComboBox";
import { DataTable } from "../../components/Table";
import { useModalContext } from "../../contexts/ActiveModalContext";
import { UserContext } from "../../contexts/UserContext";
import { Badge } from "../../shadcn/components/badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../shadcn/components/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import {
  Popover,
  PopoverContent,
  PopoverContentWithScroll,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import { cn } from "../../shadcn/lib/utils";
import {
  createAuditToolPermission,
  deleteAuditToolPermission,
} from "../../utils/apiCalls";
import {
  AuditFactorAssessmentBadge,
  AuditFactorStatusBadge,
  BadgeList,
  CriticalFactorBadge,
} from "./components";

function randomDistributionOf100() {
  const met = Math.floor(Math.random() * 101);
  const partiallyMet = Math.floor(Math.random() * (101 - met));
  const notMet = 100 - (met + partiallyMet);

  return {
    met,
    partially_met: partiallyMet,
    not_met: notMet,
    total: 100,
  };
}

function aggregateAssessments(factors: Factor[]) {
  const counts: Record<AuditFactorAssessment, number> = {
    met: 0,
    not_met: 0,
    partially_met: 0,
    na: 0,
  };
  const statusCounts: Record<AuditFactorStatus | "total", number> = {
    review: 0,
    done: 0,
    todo: 0,
    total: 0,
  };

  let total = 0;
  for (const factor of factors) {
    if (factor.assessment !== "na") {
      if (factor.assessment) {
        counts[factor.assessment] = (counts[factor.assessment] || 0) + 1;
      }
      total++;
    } else {
      counts["na"] = (counts["na"] || 0) + 1;
    }
    if (!factor.assessment) {
      statusCounts[factor.status ?? "todo"] =
        (statusCounts[factor.status ?? "todo"] || 0) + 1;
      statusCounts["total"] = (statusCounts["total"] || 0) + 1;
    }
  }
  return { counts, statusCounts, total };
}
interface AssessmentSummaryProps {
  factors: Factor[];
}

function AssessmentPercentageDisplay({ factors }: AssessmentSummaryProps) {
  const { counts, statusCounts, total } = useMemo(
    () => aggregateAssessments(factors),
    [factors]
  );
  const metCount = counts["met"] || 0;
  const naCount = counts["na"] || 0;
  const notMetCount = counts["not_met"] || 0;
  const partiallyMetCount = counts["partially_met"] || 0;

  const metPercentage = total > 0 ? (metCount / total) * 100 : 0;
  const naPercentage = total > 0 ? (naCount / total) * 100 : 0;
  const notMetPercentage = total > 0 ? (notMetCount / total) * 100 : 0;
  const partiallyMetPercentage =
    total > 0 ? (partiallyMetCount / total) * 100 : 0;

  return (
    <div className="flex flex-col space-y-1">
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-green-500"></div>
        <span className="font-medium">Met:</span> {metCount}/{total} (
        {metPercentage.toFixed(1)}%)
      </div>
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-yellow-400"></div>
        <span className="font-medium">Partially Met:</span> {partiallyMetCount}/
        {total} ({partiallyMetPercentage.toFixed(1)}%)
      </div>
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-red-500"></div>
        <span className="font-medium">Not Met:</span> {notMetCount}/{total} (
        {notMetPercentage.toFixed(1)}%)
      </div>
      <div className="flex items-center gap-2">
        <div
          className="w-4 h-4 bg-gray-300"
          style={{
            backgroundImage: `repeating-linear-gradient(
            45deg,
            #9ca3af,
            #9ca3af 10px,
            #6b7280 10px,
            #6b7280 20px
          )`,
          }}
        ></div>
        <span className="font-medium">N/A:</span> {naCount}/{total} (
        {naPercentage.toFixed(1)}%)
      </div>
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-yellow-400/30 border border-gray-400"></div>
        <span className="font-medium">AI Reviewed:</span> {statusCounts.review}/
        {statusCounts.total} (
        {((statusCounts.review / statusCounts.total) * 100).toFixed(1)}%)
      </div>
    </div>
  );
}

export function AssesmentCircleDisplay({
  factors,
  displayText,
  size = 10,
}: AssessmentSummaryProps & { displayText?: string; size?: number }) {
  const { counts, statusCounts, total } = useMemo(
    () => aggregateAssessments(factors),
    [factors]
  );

  const metCount = counts["met"] || 0;
  const partiallyCount = counts["partially_met"] || 0;
  const notMetCount = counts["not_met"] || 0;
  const naCount = counts["na"] || 0;
  const incompleteCount = total - (metCount + notMetCount + partiallyCount);

  const metPercentage = total > 0 ? (metCount / total) * 100 : 0;
  const partiallyPercentage = total > 0 ? (partiallyCount / total) * 100 : 0;
  const naPercentage = total > 0 ? (naCount / total) * 100 : 0;
  const notMetPercentage = total > 0 ? (notMetCount / total) * 100 : 0;
  const incompletePercentage = total > 0 ? (incompleteCount / total) * 100 : 0;

  const metStop = metPercentage;
  const partiallyStop = metPercentage + partiallyPercentage;
  const notMetStop = partiallyStop + notMetPercentage;
  const naStop = partiallyStop + naPercentage;

  const gradient = `conic-gradient(
    #22c55e 0% ${metStop}%,
    #facc15 ${metStop}% ${partiallyStop}%,
    #ef4444 ${partiallyStop}% ${notMetStop}%,
    #4b5563 ${notMetStop}% ${naStop}%,
    #9ca3af ${naStop}% 100%
  )`;

  return (
    <div className="flex flex-col items-center space-y-2">
      <div
        className={`relative w-${size} h-${size} rounded-full`}
        style={{ background: gradient }}
      >
        <div className="absolute inset-2 bg-gray-200 rounded-full flex items-center justify-center text-sm font-medium">
          {displayText ?? ""}
        </div>
      </div>
    </div>
  );
}

export function AssessmentProgressBar({ factors }: AssessmentSummaryProps) {
  const { counts, statusCounts, total } = useMemo(
    () => aggregateAssessments(factors),
    [factors]
  );

  const metCount = counts["met"] || 0;
  const naCount = counts["na"] || 0;
  const notMetCount = counts["not_met"] || 0;
  const partiallyMetCount = counts["partially_met"] || 0;
  const incompleteCount = total - (metCount + notMetCount + partiallyMetCount);

  return (
    <div className="">
      {/* Stacked Bar with Status Counts */}
      <div className="flex justify-between items-center">
        <span className="text-sm text-gray-600">Progress</span>
        <span className="text-sm font-medium">{total} Requirements</span>
      </div>
      <div className="w-full h-6 bg-white border border-gray-200 rounded overflow-hidden flex relative">
        {metCount > 0 && (
          <div
            className="bg-green-500 flex items-center justify-center text-white text-xs font-medium"
            style={{ width: `${(metCount / (total + naCount)) * 100}%` }}
            title={`Met: ${metCount}/${total}`}
          >
            {metCount}
          </div>
        )}
        {partiallyMetCount > 0 && (
          <div
            className="bg-yellow-400 flex items-center justify-center text-white text-xs font-medium"
            style={{
              width: `${(partiallyMetCount / (total + naCount)) * 100}%`,
            }}
            title={`Partially Met: ${partiallyMetCount}/${total}`}
          >
            {partiallyMetCount}
          </div>
        )}
        {notMetCount > 0 && (
          <div
            className="bg-red-500 flex items-center justify-center text-white text-xs font-medium"
            style={{ width: `${(notMetCount / (total + naCount)) * 100}%` }}
            title={`Not Met: ${notMetCount}/${total}`}
          >
            {notMetCount}
          </div>
        )}
        {naCount > 0 && (
          <div
            className="bg-gray-300 flex items-center justify-center text-gray-700 text-xs font-medium"
            style={{
              width: `${(naCount / (total + naCount)) * 100}%`,
              backgroundImage: `repeating-linear-gradient(
                45deg,
                #9ca3af,
                #9ca3af 10px,
                #6b7280 10px,
                #6b7280 20px
              )`,
            }}
            title={`N/A: ${naCount}/${total}`}
          >
            {naCount}
          </div>
        )}
        {incompleteCount > 0 && (
          <div
            className="bg-white relative flex items-center justify-center text-gray-600 text-xs font-medium"
            style={{ width: `${(incompleteCount / (total + naCount)) * 100}%` }}
            title={`Incomplete: ${incompleteCount}/${total}`}
          >
            {/* Secondary Overlay for Incomplete Statuses */}
            <div className="flex h-full absolute top-0 left-0 w-full pointer-events-none">
              {Object.entries(statusCounts).map(([status, count]) => {
                if (status === "total") return null;
                const percent = (count / total) * 100;
                const color =
                  status === "todo"
                    ? "bg-white/0"
                    : status === "review"
                      ? "bg-yellow-200/30"
                      : status === "done"
                        ? "bg-green-200/30"
                        : "bg-white/0"; // fallback
                return (
                  <div
                    key={status}
                    className={`${color} h-full`}
                    style={{ width: `${percent}%` }}
                    title={`${auditFactorStatus[status as AuditFactorStatus]}: ${count}/${total}`}
                  />
                );
              })}
            </div>
            {incompleteCount}
          </div>
        )}
      </div>
    </div>
  );
}

const PermissionMultiSelect = ({
  auditToolId,
  fa,
  std,
}: {
  auditToolId: string;
  fa: string;
  std: string;
}) => {
  const { auditToolAllPermissions, setAuditToolAllPermissions } =
    useContext(AuditToolContext);
  const { accessToken } = useAuthInfo();
  const { simpleUsers } = useContext(UserContext);
  const filteredPermissions = useCallback(
    (
      functionalArea: string,
      standardIndex: string | false,
      elementIndex: string | false
    ) => {
      return auditToolAllPermissions.filter(
        (perm) =>
          perm.audit_tool_id === auditToolId &&
          (!standardIndex || perm.standard_index === standardIndex) &&
          (!elementIndex || perm.element_index === elementIndex) &&
          perm.functional_area === functionalArea
      );
    },
    [auditToolId, auditToolAllPermissions, fa]
  );
  const handleCreatePermission = async (
    userId: string,
    auditToolId: string | null,
    functionalArea: string,
    standardIndex: string,
    elementIndex: string
  ) => {
    if (!auditToolId) return;
    const newPermission: AuditToolPermission = {
      id: "",
      user_id: userId,
      audit_tool_id: auditToolId,
      functional_area: functionalArea,
      standard_index: standardIndex,
      element_index: elementIndex,
      is_owner: false,
    };
    try {
      const newPermissions = await createAuditToolPermission(
        newPermission,
        accessToken ?? null
      );
      setAuditToolAllPermissions((prevPermissions) => [
        ...prevPermissions,
        ...newPermissions,
      ]);
      toast.success("Permission created successfully");
    } catch (error) {
      toast.error("Failed to create permission");
    }
  };

  const handleDeletePermission = async (
    userId: string,
    auditToolId: string | null,
    functionalArea: string,
    standardIndex: string,
    elementIndex: string
  ) => {
    if (!auditToolId) return;
    const permissionToDelete: AuditToolPermission = {
      id: "",
      user_id: userId,
      audit_tool_id: auditToolId,
      functional_area: functionalArea,
      standard_index: standardIndex,
      element_index: elementIndex,
      is_owner: false,
    };
    try {
      const deletedPermissions = await deleteAuditToolPermission(
        auditToolId,
        permissionToDelete,
        accessToken ?? null
      );
      setAuditToolAllPermissions((prevPermissions) =>
        prevPermissions.filter(
          (perm) =>
            !deletedPermissions.some(
              (deletedPerm) =>
                deletedPerm.user_id === perm.user_id &&
                deletedPerm.functional_area === perm.functional_area &&
                deletedPerm.standard_index === perm.standard_index &&
                deletedPerm.element_index === perm.element_index
            )
        )
      );
      toast.success("Permissions deleted successfully");
    } catch (error) {
      toast.error("Failed to delete permission");
    }
  };
  return (
    <div className="flex flex-row gap-2 items-center">
      <SimpleUserComboBox
        selectedUsers={simpleUsers.filter((user) =>
          filteredPermissions(fa, std === "*" ? false : std, false).some(
            (perm) => perm.user_id === user.id
          )
        )}
        handleUserSelect={(userId: string, isSelected: boolean) => {
          const selectedUser = simpleUsers.find((user) => user.id === userId);
          if (selectedUser) {
            if (isSelected) {
              handleCreatePermission(
                selectedUser.id,
                auditToolId ?? null,
                fa,
                std,
                "*"
              );
            } else {
              handleDeletePermission(
                selectedUser.id,
                auditToolId ?? null,
                fa,
                std,
                "*"
              );
            }
          }
        }}
      />
    </div>
  );
};

export function FunctionalAreaDashboardDisplay({
  viewType,
}: {
  viewType: "list" | "table";
}) {
  const {
    auditTool,
    setAuditTool,
    isOwner,
    elementReviews,
    setElementReviews,
  } = useContext(AuditToolContext);
  const [searchParams] = useSearchParams();
  const fa = searchParams.get("fa");
  const { auditToolId } = useParams<{
    auditToolId: string;
  }>();
  const { accessToken } = useAuthInfo();
  const [isDeleteElementDialogOpen, setIsDeleteElementDialogOpen] =
    useState("false");
  const functionalAreas = getUniqueFunctionalAreas(auditTool?.factors ?? []);
  const navigate = useNavigate();
  const [selectedTabIndex, setSelectedTabIndex] = useState<string>(fa ?? "");
  const [functionalAreaPopoverOpen, setFunctionalAreaPopoverOpen] =
    useState(false);
  const functionalAreaPopoverTriggerRef = useRef<HTMLButtonElement>(null);

  const fetchElementReviews = async () => {
    const elementReviews = await getAuditToolElementReviews(
      auditToolId ?? "",
      accessToken ?? null
    );
    setElementReviews(elementReviews);
  };

  useEffect(() => {
    fetchElementReviews();
  }, [auditToolId]);

  const handleDeleteElement = useCallback(
    async (
      functionalArea: string,
      standardIndex: string,
      elementIndex: string
    ) => {
      if (!auditTool?.id || !accessToken) {
        return;
      }
      try {
        const updatedTool = await deleteAuditToolElement(
          auditTool.id,
          functionalArea,
          standardIndex,
          elementIndex,
          accessToken
        );
        setAuditTool(updatedTool);
        setIsDeleteElementDialogOpen("false");
        toast.success("Element deleted successfully");
      } catch (error) {
        toast.error("Failed to delete element");
      }
    },
    [auditTool?.id, accessToken]
  );

  const handleFunctionalAreaSelect = (functionalArea: string) => {
    navigate(`/delegate-audit/${auditTool?.id}/?fa=${functionalArea}`);
    setSelectedTabIndex(functionalArea);
    setFunctionalAreaPopoverOpen(false);
  };

  useEffect(() => {
    if (auditToolId === auditTool?.id && functionalAreas.length > 0) {
      if (!fa) {
        navigate(`/delegate-audit/${auditTool?.id}/?fa=all`);
      }
    }
  }, [functionalAreas, auditToolId]);
  useEffect(() => {
    if (fa) {
      setSelectedTabIndex(fa);
    }
  }, [fa]);

  if (viewType === "table") {
    return (
      <DashboardTableView
        auditToolId={auditToolId ?? ""}
        factors={auditTool?.factors ?? []}
      />
    );
  }
  return (
    <div className="space-y-8">
      {/* Tab Content */}
      {selectedTabIndex === "all" && (
        <FunctionOverview
          factors={auditTool?.factors ?? []}
          isOwner={isOwner}
          auditToolId={auditToolId ?? ""}
        />
      )}
      {functionalAreas.map((fa) => {
        if (fa.functional_area !== selectedTabIndex) return null;
        const faFactors = fa.factors;
        const standards = getUniqueStandards(faFactors);
        return (
          <div key={fa.functional_area} className="bg-gray-100 p-4 rounded-md">
            <div className="space-y-4">
              {/* Functional Area Header & Summary */}
              <div className="space-y-4">
                <div className="flex justify-start items-center gap-2">
                  {functionalAreas.length > 1 ? (
                    <FAMultiPicker
                      functionalAreas={functionalAreas}
                      selectedTabIndex={selectedTabIndex}
                      functionalAreaPopoverOpen={functionalAreaPopoverOpen}
                      setFunctionalAreaPopoverOpen={
                        setFunctionalAreaPopoverOpen
                      }
                      functionalAreaPopoverTriggerRef={
                        functionalAreaPopoverTriggerRef
                      }
                      handleFunctionalAreaSelect={handleFunctionalAreaSelect}
                    />
                  ) : (
                    <h2 className="text-2xl font-bold">{fa.functional_area}</h2>
                  )}
                  <div className="flex-1" />
                  {isOwner && (
                    <PermissionMultiSelect
                      auditToolId={auditToolId ?? ""}
                      fa={fa.functional_area}
                      std="*"
                    />
                  )}
                </div>
                <div className="w-full">
                  <Tooltip>
                    <TooltipTrigger className="mb-4 w-full">
                      <AssessmentProgressBar factors={faFactors} />
                    </TooltipTrigger>
                    <TooltipContent>
                      <AssessmentPercentageDisplay factors={faFactors} />
                    </TooltipContent>
                  </Tooltip>
                </div>
              </div>
              {/* Standards within Functional Area */}
              <div className="space-y-6 pl-4">
                {standards.map((std) => {
                  const stdFactors = std.factors;
                  const elements = getUniqueElements(stdFactors);
                  return (
                    <div
                      key={std.standard_index}
                      className="space-y-4 rounded-md bg-gray-200 p-4"
                    >
                      <div className="flex items-center gap-2">
                        <h3 className="text-xl font-semibold">
                          {std.standard_index}{" "}
                          {std.standard_title ? ` - ${std.standard_title}` : ""}
                        </h3>
                        <div className="flex-1" />
                        {isOwner && (
                          <PermissionMultiSelect
                            auditToolId={auditToolId ?? ""}
                            fa={fa.functional_area}
                            std={std.standard_index}
                          />
                        )}
                      </div>
                      {/* Elements as table rows instead of cards */}
                      <div className="overflow-hidden">
                        <div className="flex flex-col w-full gap-2">
                          {elements.map((el, index) => (
                            <div
                              key={el.element_index}
                              className={`flex items-end gap-4 p-2 ${
                                index < elements.length - 1
                                  ? "border-b border-gray-300"
                                  : ""
                              }`}
                            >
                              <div
                                className="flex-1 cursor-pointer flex flex-row justify-between gap-2"
                                onClick={() => {
                                  navigate(
                                    `/delegate-audit/${auditTool?.id}/?fa=${encodeURIComponent(fa.functional_area)}&std=${encodeURIComponent(std.standard_index)}&el=${encodeURIComponent(el.element_index)}`
                                  );
                                }}
                              >
                                <>
                                  {el.element_explanation ? (
                                    <Tooltip>
                                      <TooltipTrigger>
                                        <div className="font-medium text-left text-blue-600 hover:text-blue-800 hover:underline">
                                          {el.element_index}{" "}
                                          {el.element_title
                                            ? `- ${el.element_title}`
                                            : ""}
                                        </div>
                                      </TooltipTrigger>
                                      <TooltipContent
                                        side="right"
                                        align="center"
                                      >
                                        <div className="max-w-[350px] max-h-[200px] overflow-y-auto p-4 whitespace-normal break-words text-sm">
                                          <h4 className="font-bold">
                                            Element Explanation
                                          </h4>
                                          <hr className="my-2" />
                                          {el.element_explanation}
                                        </div>
                                      </TooltipContent>
                                    </Tooltip>
                                  ) : (
                                    <div className="font-medium text-left text-blue-600 hover:text-blue-800 hover:underline">
                                      {el.element_index}{" "}
                                      {el.element_title
                                        ? `- ${el.element_title}`
                                        : ""}
                                    </div>
                                  )}
                                  <div className="flex-1" />
                                  {el.element_must_pass && (
                                    <CriticalFactorBadge message="This element must pass" />
                                  )}
                                  {
                                    <div className="text-sm text-gray-500">
                                      <AuditFactorAssessmentBadge
                                        status={
                                          elementReviews.find(
                                            (review) =>
                                              review.element_index ===
                                                el.element_index &&
                                              review.standard_index ===
                                                std.standard_index &&
                                              review.functional_area ===
                                                fa.functional_area
                                          )?.assessment as AuditFactorAssessment
                                        }
                                      />
                                    </div>
                                  }
                                </>
                              </div>
                              <div className="w-[40%]">
                                <div className="w-full space-y-2">
                                  <Tooltip>
                                    <TooltipTrigger className="w-full">
                                      <div className="hover:opacity-80 transition-opacity">
                                        <AssessmentProgressBar
                                          factors={el.factors}
                                        />
                                      </div>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      <AssessmentPercentageDisplay
                                        factors={el.factors}
                                      />
                                    </TooltipContent>
                                  </Tooltip>
                                </div>
                              </div>
                              {isOwner && (
                                <div className="">
                                  <Dialog
                                    open={
                                      isDeleteElementDialogOpen ===
                                      `${fa.functional_area}/${std.standard_index}/${el.element_index}`
                                    }
                                    onOpenChange={(open) =>
                                      setIsDeleteElementDialogOpen(
                                        open
                                          ? `${fa.functional_area}/${std.standard_index}/${el.element_index}`
                                          : "false"
                                      )
                                    }
                                  >
                                    <DialogTrigger>
                                      <Button
                                        variant="outline"
                                        size="icon"
                                        className="border-red-500 text-red-500 hover:bg-red-100 h-7 w-7"
                                        onClick={() =>
                                          setIsDeleteElementDialogOpen(
                                            `${fa.functional_area}/${std.standard_index}/${el.element_index}`
                                          )
                                        }
                                      >
                                        <Trash2 className="text-red-500 h-4 w-4" />
                                      </Button>
                                    </DialogTrigger>
                                    <DialogContent className="sm:max-w-[425px]">
                                      <DialogHeader>
                                        <DialogTitle className="text-red-500">
                                          Delete Element
                                        </DialogTitle>
                                        <DialogDescription>
                                          Are you sure you want to delete this
                                          element? This action cannot be undone.
                                        </DialogDescription>
                                      </DialogHeader>
                                      <DialogFooter className="gap-2 sm:gap-0">
                                        <Button
                                          variant="destructive"
                                          className="bg-red-500 hover:bg-red-600"
                                          onClick={() => {
                                            handleDeleteElement(
                                              fa.functional_area,
                                              std.standard_index,
                                              el.element_index
                                            );
                                          }}
                                        >
                                          Delete Element
                                        </Button>
                                      </DialogFooter>
                                    </DialogContent>
                                  </Dialog>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export const DashboardTableView = ({
  auditToolId,
  factors,
}: {
  auditToolId: string;
  factors: Factor[];
}) => {
  const { elementReviews } = useContext(AuditToolContext);
  const [searchParams] = useSearchParams();
  const fa = searchParams.get("fa");
  const navigate = useNavigate();
  const [selectedStandardIndex, setSelectedStandardIndex] = useState<
    string | null
  >(null);
  const [faPopoverOpen, setFaPopoverOpen] = useState(false);
  const [standardPopoverOpen, setStandardPopoverOpen] = useState(false);
  const allUniqueElements: UniqueElement[] = [];
  const allUniqueStandards: UniqueStandard[] = [];
  const functionalAreas = getUniqueFunctionalAreas(factors);
  functionalAreas.forEach((fa) => {
    const uniqueStandards = getUniqueStandards(fa.factors);
    allUniqueStandards.push(...uniqueStandards);
    uniqueStandards.forEach((std) => {
      const uniqueElements = getUniqueElements(std.factors);
      allUniqueElements.push(...uniqueElements);
    });
  });

  const filteredElements = allUniqueElements.filter((element) => {
    return (
      (fa ? element.functional_area === fa || fa === "all" : true) &&
      (selectedStandardIndex
        ? element.standard_index === selectedStandardIndex
        : true)
    );
  });

  return (
    <div>
      <div className="flex gap-4 mb-4">
        <Popover open={faPopoverOpen} onOpenChange={setFaPopoverOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="flex items-center justify-between w-[240px] overflow-hidden text-ellipsis"
            >
              <span className="truncate">{fa || "All Functional Areas"}</span>
              <ChevronsUpDown className="ml-2 h-4 w-4 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[400px]" side="bottom" align="start">
            <Command>
              <CommandInput placeholder="Search functional area..." />
              <CommandList>
                <CommandEmpty>No functional area found.</CommandEmpty>
                <CommandGroup>
                  <CommandItem
                    value="all"
                    onSelect={() => {
                      navigate(`/delegate-audit/${auditToolId}`);
                      setSelectedStandardIndex(null);
                      setFaPopoverOpen(false);
                    }}
                  >
                    All Functions
                  </CommandItem>
                  {functionalAreas.map((fa) => (
                    <CommandItem
                      key={fa.functional_area}
                      value={fa.functional_area}
                      onSelect={() => {
                        navigate(
                          `/delegate-audit/${auditToolId}/?fa=${encodeURIComponent(fa.functional_area)}`
                        );
                        setSelectedStandardIndex(null);
                        setFaPopoverOpen(false);
                      }}
                    >
                      {fa.functional_area}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>

        <Popover
          open={standardPopoverOpen}
          onOpenChange={setStandardPopoverOpen}
        >
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="flex items-center justify-between w-[240px] overflow-hidden text-ellipsis"
            >
              <span className="truncate">
                {selectedStandardIndex || "Select Standard"}
              </span>
              <ChevronsUpDown className="ml-2 h-4 w-4 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[400px]" side="bottom" align="start">
            <Command>
              <CommandInput placeholder="Search standard index..." />
              <CommandList>
                <CommandEmpty>No standard index found.</CommandEmpty>
                <CommandGroup>
                  <CommandItem
                    value="all"
                    onSelect={() => {
                      setSelectedStandardIndex(null);
                      setStandardPopoverOpen(false);
                    }}
                  >
                    All Standards
                  </CommandItem>
                  {Array.from(
                    new Set(
                      allUniqueStandards
                        .filter((std) => !fa || std.functional_area === fa)
                        .map((std) => (
                          <CommandItem
                            key={std.standard_index}
                            value={std.standard_index}
                            onSelect={() => {
                              setSelectedStandardIndex(std.standard_index);
                              setStandardPopoverOpen(false);
                            }}
                          >
                            {std.standard_index}
                            {std.standard_title
                              ? ` - ${std.standard_title}`
                              : ""}
                          </CommandItem>
                        ))
                    )
                  )}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
      <DataTable
        paginationAtTop={true}
        paginationClassName="justify-end py-0 mb-0"
        tableClassName="space-y-1"
        columns={[
          {
            header: "Functional Area",
            accessorKey: "functional_area",
            cell: ({ row }) => (
              <div className="flex flex-row gap-2 align-start">
                <div className="text-gray-800">
                  {row.original.functional_area}
                </div>
              </div>
            ),
          },
          {
            header: "Standard",
            cell: ({ row }) => (
              <div className="flex flex-row gap-2 align-start">
                <div className="text-gray-800 max-w-[100px] whitespace-normal break-words">
                  <span>{row.original.standard_index}</span>
                  <span className="font-bold">
                    {row.original.standard_title
                      ? ` - ${row.original.standard_title}`
                      : ""}
                  </span>
                </div>
              </div>
            ),
          },
          {
            header: "Element",
            accessorKey: "element_index",
            cell: ({ row }) => (
              <div
                onClick={row.original.onNavigate}
                className="flex flex-row gap-2 align-start cursor-pointer"
              >
                <div className="text-blue-600 hover:text-blue-800 hover:underline max-w-[100px] whitespace-normal break-words line-clamp-4">
                  <span>{row.original.element_index}</span>
                  <span className="font-bold">
                    {row.original.element_title
                      ? ` - ${row.original.element_title}`
                      : ""}
                  </span>
                </div>
              </div>
            ),
          },
          {
            header: "Requirement",
            cell: ({ row }) => {
              const [isPopoverOpen, setIsPopoverOpen] = useState(false);
              const factorConcat = row.original.factors
                .sort((a, b) => smartSort(a.factor_index, b.factor_index))
                .map((factor) => (
                  <div key={factor.id} className="flex flex-col">
                    <div className="font-bold">
                      {factor.factor_index} - {factor.factor_title}
                    </div>
                    <div>{factor.factor_description}</div>
                  </div>
                ));

              const truncatedFactorConcat = row.original.factors
                .sort((a, b) => smartSort(a.factor_index, b.factor_index))
                .map(
                  (factor) =>
                    `**${factor.factor_index}** - **${factor.factor_title}** - ${factor.factor_description}`
                )
                .join(" | ");

              return (
                <Popover onOpenChange={setIsPopoverOpen}>
                  <PopoverTrigger>
                    <div className="w-[200px] relative text-left">
                      <ReactMarkdown className="line-clamp-4 text-wrap">
                        {truncatedFactorConcat}
                      </ReactMarkdown>
                      <span className="absolute bottom-0 right-0 bg-white pr-1 text-blue-600 hover:text-blue-800 hover:underline cursor-pointer text-sm">
                        … read more
                      </span>
                    </div>
                  </PopoverTrigger>
                  <PopoverContentWithScroll
                    isOpen={isPopoverOpen}
                    className="w-[400px] max-h-[400px] overflow-y-auto"
                    side="left"
                    align="center"
                  >
                    <div className="flex flex-col gap-4">{factorConcat}</div>
                  </PopoverContentWithScroll>
                </Popover>
              );
            },
          },
          {
            header: "Sources",
            cell: ({ row }) => {
              const [isPopoverOpen, setIsPopoverOpen] = useState(false);
              const uniqueReferences = Array.from(
                new Set(
                  row.original.factors.flatMap((factor) =>
                    factor.element_reference
                      .split("\n")
                      .map((ref) => ref.trim())
                  )
                )
              );
              const refs = uniqueReferences.join("\n");
              const truncatedReferences =
                BadgeList({
                  text: refs,
                  truncate: true,
                }) ?? [];

              return (
                <Popover onOpenChange={setIsPopoverOpen}>
                  <PopoverTrigger>
                    <div className="w-[200px] relative text-left">
                      {truncatedReferences}
                    </div>
                  </PopoverTrigger>
                  <PopoverContentWithScroll
                    isOpen={isPopoverOpen}
                    className="w-[400px] max-h-[400px] overflow-y-auto"
                    side="left"
                    align="center"
                  >
                    <ReactMarkdown>
                      {uniqueReferences
                        .map((reference) => `- ${reference}`)
                        .join("\n")}
                    </ReactMarkdown>
                  </PopoverContentWithScroll>
                </Popover>
              );
            },
          },
          {
            header: "Delegate Response",
            cell: ({ row }) => {
              const [isPopoverOpen, setIsPopoverOpen] = useState(false);
              const elementMetadata =
                row.original.element_review?.element_metadata;
              const submittedDocs = elementMetadata?.submitted_docs
                ?.split(/[\n;]+/)
                .map((doc: string) => doc.trim())
                .filter((doc: string) => doc.length > 0);
              return (
                <Popover onOpenChange={setIsPopoverOpen}>
                  <PopoverTrigger>
                    <Badge>
                      {elementMetadata?.deliverable_number ?? "No Response"}
                    </Badge>
                  </PopoverTrigger>
                  <PopoverContentWithScroll
                    isOpen={isPopoverOpen}
                    className="w-[500px] max-h-[400px] overflow-y-auto"
                    side="left"
                    align="center"
                  >
                    <div className="flex flex-col gap-2 align-start">
                      <h3 className="font-bold text-lg">Delegate Submission</h3>
                      <hr className="my-2" />
                      <ReactMarkdown>
                        {submittedDocs
                          ?.map((doc: string) => `**-** ${doc}`)
                          .join("  \n") ?? "No Response"}
                      </ReactMarkdown>
                    </div>
                  </PopoverContentWithScroll>
                </Popover>
              );
            },
          },
          {
            header: "Assessment",
            accessorKey: "element_review",
            cell: ({ row }) => (
              <div className="flex flex-row gap-2 align-start flex-1">
                <AuditFactorAssessmentBadge
                  status={
                    row.original.element_review
                      ?.assessment as AuditFactorAssessment
                  }
                />
              </div>
            ),
          },
          {
            header: "Progress",
            cell: ({ row }) => (
              <div className="flex flex-row gap-2 align-start">
                <Tooltip>
                  <TooltipTrigger className="">
                    <AssesmentCircleDisplay
                      factors={row.original.factors}
                      displayText={`${row.original.factors.length}`}
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    <AssessmentPercentageDisplay
                      factors={row.original.factors}
                    />
                  </TooltipContent>
                </Tooltip>
              </div>
            ),
          },
        ]}
        data={
          filteredElements?.map((el: UniqueElement) => ({
            functional_area: el.functional_area,
            standard_index: el.standard_index,
            standard_title: el.standard_title,
            element_index: el.element_index,
            element_title: el.element_title,
            element_review: elementReviews.find(
              (el_metadata) =>
                el_metadata.functional_area === el.functional_area &&
                el_metadata.standard_index === el.standard_index &&
                el_metadata.element_index === el.element_index
            ),
            factors: el.factors,
            onNavigate: () => {
              navigate(
                `/delegate-audit/${auditToolId}/?fa=${encodeURIComponent(el.functional_area)}&std=${encodeURIComponent(el.standard_index)}&el=${encodeURIComponent(el.element_index)}`
              );
            },
          })) || []
        }
        paginationParams={{ pageSize: 10, pageIndex: 0 }}
        totalRecordCount={filteredElements.length}
      />
    </div>
  );
};

export const FAMultiPicker = ({
  functionalAreas,
  selectedTabIndex,
  functionalAreaPopoverOpen,
  setFunctionalAreaPopoverOpen,
  functionalAreaPopoverTriggerRef,
  handleFunctionalAreaSelect,
}: {
  functionalAreas: UniqueFunctionalArea[];
  selectedTabIndex: string;
  functionalAreaPopoverOpen: boolean;
  setFunctionalAreaPopoverOpen: (open: boolean) => void;
  functionalAreaPopoverTriggerRef: React.RefObject<HTMLButtonElement>;
  handleFunctionalAreaSelect: (fa: string) => void;
}) => {
  const { activeModalRef } = useModalContext();
  return (
    <>
      <span className="text-xl text-gray-500 italic">Function -</span>
      <Popover
        open={functionalAreaPopoverOpen}
        onOpenChange={setFunctionalAreaPopoverOpen}
      >
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={functionalAreaPopoverOpen}
            className="text-2xl font-bold min-w-[400px] justify-start"
            ref={functionalAreaPopoverTriggerRef}
          >
            <div className="flex flex-1 flex-row justify-between items-center space-x-4">
              <div className="text-2xl font-bold">{selectedTabIndex}</div>
              <ChevronsUpDown className="mx-2 h-4 w-4 shrink-0 opacity-50" />
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-full p-0"
          container={activeModalRef?.current}
          side="bottom"
          align="center"
          style={{
            width: functionalAreaPopoverTriggerRef.current?.offsetWidth,
          }}
        >
          <Command>
            <CommandInput placeholder="Search functional area..." />
            <CommandList>
              <CommandEmpty>No functional area found.</CommandEmpty>
              <CommandGroup>
                <CommandItem
                  key="all"
                  value="all"
                  onSelect={() => handleFunctionalAreaSelect("all")}
                >
                  All
                </CommandItem>
                {functionalAreas.map((fa, index) => (
                  <CommandItem
                    key={index}
                    value={fa.functional_area}
                    onSelect={() =>
                      handleFunctionalAreaSelect(fa.functional_area)
                    }
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        fa.functional_area === selectedTabIndex
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    {fa.functional_area}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
};
type FunctionOverviewProps = {
  factors: Factor[];
  isOwner: boolean;
  auditToolId: string;
};
export const FunctionOverview = ({
  factors,
  isOwner,
  auditToolId,
}: FunctionOverviewProps) => {
  const [searchParams] = useSearchParams();
  const fa = searchParams.get("fa");
  const navigate = useNavigate();
  const { auditTool } = useContext(AuditToolContext);

  // Only show overview if fa=all
  if (fa !== "all") {
    return null;
  }

  const functionalAreas = getUniqueFunctionalAreas(factors);

  return (
    <div className="bg-gray-100 p-4 rounded-md">
      <div className="space-y-6">
        <h2 className="text-2xl font-bold">Function Overview</h2>
        <div className="grid gap-4">
          {functionalAreas.map((fa) => (
            <div
              key={fa.functional_area}
              className="bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow cursor-pointer"
              onClick={() =>
                navigate(
                  `/delegate-audit/${auditTool?.id}/?fa=${encodeURIComponent(fa.functional_area)}`
                )
              }
            >
              <div className="space-y-3">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold">
                    {fa.functional_area}
                  </h3>
                  {isOwner && (
                    <div
                      className="flex flex-row gap-2 items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <PermissionMultiSelect
                        auditToolId={auditToolId ?? ""}
                        fa={fa.functional_area}
                        std="*"
                      />
                    </div>
                  )}
                </div>
                <Tooltip>
                  <TooltipTrigger className="w-full">
                    <AssessmentProgressBar factors={fa.factors} />
                  </TooltipTrigger>
                  <TooltipContent>
                    <AssessmentPercentageDisplay factors={fa.factors} />
                  </TooltipContent>
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const FactorTable = ({ factors }: { factors: Factor[] }) => {
  const { auditToolId } = useParams();
  const { accessToken } = useAuthInfo();
  const { setAuditTool, isOwner } = useContext(AuditToolContext);
  const [sortedFactors, setSortedFactors] = useState<Factor[]>([]);
  const deleteFactor = useCallback(
    async (factorId: string) => {
      if (!auditToolId || !accessToken) {
        return;
      }
      await deleteAuditToolFactor(auditToolId, factorId, accessToken);
      setAuditTool((prev) => {
        if (!prev) {
          return prev;
        }
        const newFactors = prev.factors.filter((f) => f.id !== factorId);
        return {
          ...prev,
          factors: newFactors,
        };
      });
      toast.success("Factor deleted successfully");
    },
    [auditToolId, accessToken]
  );
  const columns: any[] = useMemo(() => {
    return [
      {
        id: "factorInfo",
        header: ({ column }: { column: any }) => (
          <Tooltip>
            <TooltipTrigger>
              <Info className="h-4 w-4 text-gray-500" />
            </TooltipTrigger>
            <TooltipContent>
              <div className="text-xs text-gray-500">Important factor Info</div>
            </TooltipContent>
          </Tooltip>
        ),
        cell: ({ row }: { row: any }) => (
          <>
            {row.original.factor_critical && (
              <CriticalFactorBadge message="This factor is critical" />
            )}
          </>
        ),
        size: 20,
        minSize: 20,
      },
      {
        accessorFn: (row: any) => row.factor_index,
        id: "factorIndex",
        header: ({ column }: { column: any }) => (
          <HeaderCell column={column} columnName="Index" />
        ),
        cell: ({ row }: { row: any }) => (
          <Link
            to={`/delegate-audit/${auditToolId}/factor/${row.original.id}/audit`}
            className="text-blue-600 hover:text-blue-800 hover:underline cursor-pointer"
          >
            <span>
              {row.original.factor_index}{" "}
              {row.original.factor_title
                ? `- ${row.original.factor_title.slice(0, 25)}`
                : ""}
              {row.original.factor_title?.length > 25 && "..."}
            </span>
          </Link>
        ),
      },
      {
        accessorFn: (row: any) => row.status,
        id: "status",
        header: ({ column }: { column: any }) => (
          <div className="h-[50px] flex items-center">Status</div>
        ),
        cell: ({ row }: { row: any }) => (
          <AuditFactorStatusBadge status={row.original.status} />
        ),
      },
      {
        accessorFn: (row: any) => row.assessment,
        id: "assessment",
        header: ({ column }: { column: any }) => (
          <div className="h-[50px] flex items-center">Assessment</div>
        ),
        cell: ({ row }: { row: any }) => (
          <AuditFactorAssessmentBadge status={row.original.assessment} />
        ),
      },
      {
        id: "actions",
        header: ({ column }: { column: any }) => (
          <HeaderCell column={column} columnName="" />
        ),
        cell: ({ row }: { row: any }) => (
          <div className="flex items-center justify-end gap-2">
            {isOwner && (
              <Dialog>
                <DialogTrigger>
                  <Button
                    variant="outline"
                    size="icon"
                    className="border-red-500 text-red-500 hover:bg-red-100 h-7 w-7"
                  >
                    <Trash2 className="text-red-500 h-4 w-4" />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>
                      Are you sure you want to delete this factor?
                    </DialogTitle>
                    <DialogDescription>
                      This action cannot be undone.
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter>
                    <Button
                      variant="destructive"
                      onClick={() => deleteFactor(row.original.id)}
                    >
                      Delete
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            )}
          </div>
        ),
      },
    ];
  }, []);

  useEffect(() => {
    const sorted = factors.sort((a, b) => {
      return smartSort(a.factor_index ?? "", b.factor_index ?? "");
    });
    setSortedFactors(sorted);
  }, [factors]);

  return (
    <DataTable
      columns={columns}
      data={sortedFactors}
      paginationParams={{ pageSize: 10, pageIndex: 0 }}
      totalRecordCount={sortedFactors.length}
    />
  );
};
