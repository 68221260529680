import { ReloadIcon } from "@radix-ui/react-icons";
import { useContext, useState } from "react";
import { toast } from "sonner";
import { DocViewerContext } from "../contexts/DocViewerContext";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";

export const HighlightTooltipActionButton = (props: {
  text: string;
  explanationContent: string;
  onClick: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await props.onClick();
    setLoading(false);
  };

  return (
    <div className="overflow-hidden cursor-pointer rounded-md border bg-popover text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className="w-full hover:bg-accent hover:text-accent-foreground text-sm text-left py-2 pl-8 flex items-center"
            onClick={handleClick}
          >
            {props.text}
            {loading && <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />}
          </div>
        </TooltipTrigger>
        <TooltipContent side="right">{props.explanationContent}</TooltipContent>
      </Tooltip>
    </div>
  );
};

export const CopyTooltipActionButton = (props: { citationText: string }) => {
  return (
    <HighlightTooltipActionButton
      text="Copy"
      onClick={async () => {
        await navigator.clipboard.writeText(props.citationText);
        toast.success("Copied to clipboard");
      }}
      explanationContent="Copy the highlighted text to clipboard"
    />
  );
};

export const CopyWithCitationTooltipActionButton = (props: {
  docName: string;
  pageNumber: number;
  citationText: string;
}) => {
  return (
    <HighlightTooltipActionButton
      text="Copy with Citation"
      onClick={async () => {
        // TODO make sure same format as copy search citation
        const citation = `${props.docName} - Page ${props.pageNumber}\n\n"${props.citationText}"`;
        await navigator.clipboard.writeText(citation);
        toast.success("Copied to clipboard");
      }}
      explanationContent="Copy the highlighted text to clipboard with the document name and page number"
    />
  );
};

export const ClearHighlightTooltipActionButton = (props: {
  citationId: string;
  additionalCallback?: () => void;
}) => {
  const { setCitations } = useContext(DocViewerContext);
  return (
    <HighlightTooltipActionButton
      text="Clear"
      onClick={async () => {
        // remove the citation
        setCitations((prev) => prev.filter((c) => c.id !== props.citationId));
        if (props.additionalCallback) {
          props.additionalCallback();
        }
      }}
      explanationContent="Clear the highlighted text"
    />
  );
};

export const BaseHighlightTooltip = (props: {
  citationText: string;
  citationId: string;
}) => {
  const { docMetadata, pageNumber } = useContext(DocViewerContext);

  return (
    <div className="w-48">
      <CopyTooltipActionButton citationText={props.citationText} />
      <CopyWithCitationTooltipActionButton
        docName={docMetadata?.name ?? ""}
        pageNumber={pageNumber}
        citationText={props.citationText}
      />
      <ClearHighlightTooltipActionButton citationId={props.citationId} />
    </div>
  );
};

export const CopyTooltip = (props: {
  citationText: string;
  citationId: string;
}) => {
  const { docMetadata, pageNumber } = useContext(DocViewerContext);

  return (
    <div className="w-48">
      <CopyTooltipActionButton citationText={props.citationText} />
      <CopyWithCitationTooltipActionButton
        docName={docMetadata?.name ?? ""}
        pageNumber={pageNumber}
        citationText={props.citationText}
      />
    </div>
  );
};
