import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import { CmsRequirement } from "../types";

interface CmsRequirementTableProps {
  requirements: CmsRequirement[];
}

const CmsRequirementTable: React.FC<CmsRequirementTableProps> = ({
  requirements,
}) => {
  if (requirements.length === 0) {
    return <div>No audit results available.</div>;
  }

  // Get all unique row indices from the assessments
  const rowIndices = Array.from(
    new Set(
      requirements.flatMap((req) => Object.keys(req.assessment).map(Number))
    )
  ).sort((a, b) => a - b);

  // Function to determine cell color based on assessment value
  const getCellColor = (value: string) => {
    switch (value) {
      case "Met":
        return "bg-green-200";
      case "Not Met":
        return "bg-red-200";
      case "Not Applicable":
        return "bg-gray-200";
      default:
        return "";
    }
  };

  // Function to calculate the percentage of 'Met' assessments
  const calculateMetPercentage = (assessments: Record<number, string>) => {
    const total = Object.values(assessments).filter(
      (value) => value === "Met" || value === "Not Met"
    ).length;
    const metCount = Object.values(assessments).filter(
      (value) => value === "Met"
    ).length;
    return total > 0 ? ((metCount / total) * 100).toFixed(2) : "0.00";
  };

  return (
    <table className="min-w-full bg-white">
      <thead>
        <tr>
          <th className="py-2">Row Index</th>
          {requirements.map((req, index) => (
            <th key={index} className="py-2">
              <Tooltip>
                <TooltipTrigger>
                  {req.audit_element} - {req.compliance_standard}
                </TooltipTrigger>
                <TooltipContent>{req.method_of_evaluation}</TooltipContent>
              </Tooltip>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowIndices.map((rowIndex) => (
          <tr key={rowIndex}>
            <td className="border px-4 py-2">{rowIndex}</td>
            {requirements.map((req, index) => (
              <td
                key={index}
                className={`border px-4 py-2 ${getCellColor(req.assessment[rowIndex] || "")}`}
              >
                {req.assessment[rowIndex] || "N/A"}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td className="border px-4 py-2 font-bold">% Met</td>
          {requirements.map((req, index) => (
            <td key={index} className="border px-4 py-2 font-bold">
              {calculateMetPercentage(req.assessment)}%
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
};

export default CmsRequirementTable;
