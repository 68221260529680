import { useAuthInfo } from "@propelauth/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { DateDisplay } from "../../components/DateDisplay";
import { Layout } from "../../components/Layout";
import { LoadingView } from "../../components/Loading";
import { DataTable, HeaderCell } from "../../components/Table";
import { UserContext } from "../../contexts/UserContext";
import { HeaderCard } from "../../pages/dashboard/DashboardView";
import { Badge } from "../../shadcn/components/badge";
import { cn } from "../../shadcn/lib/utils";
import { Cap } from "../../types";
import {
  CapUpdateRequest,
  getCap,
  getCaps,
  updateCap,
} from "../../utils/apiCalls";
import CapSheet from "./components/CapSheet";

const CapDashboard = () => {
  const authInfo = useAuthInfo();
  const { getUser, simpleUsers } = useContext(UserContext);
  const [caps, setCaps] = useState<Cap[]>([]);
  const [selectedCap, setSelectedCap] = useState<Cap | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleUpdateCapStatus = async (cap: Cap, actionItemStatus: string) => {
    const updateRequest: CapUpdateRequest = {
      cap_status: cap.cap_status,
      cap_completed_date: cap.cap_completed_date,
      // cap_due_date: cap.cap_due_date,
    };
    if (actionItemStatus) {
      updateRequest.action_item_status = actionItemStatus;
    }
    await updateCap(cap.id, updateRequest, authInfo?.accessToken ?? null);
    const updatedCap = await getCap(cap.id, authInfo?.accessToken ?? null);
    setCaps((prevCaps) => {
      const updatedCaps = prevCaps.map((c) =>
        c.id === cap.id ? updatedCap : c
      );
      return updatedCaps;
    });
  };

  const fetchCaps = async () => {
    try {
      setIsLoading(true);
      const caps = await getCaps(authInfo.accessToken ?? null);
      // Sort caps by cap_issued_date in ascending order
      caps.sort(
        (a, b) =>
          new Date(a.created_at ?? "").getTime() -
          new Date(b.created_at ?? "").getTime()
      );
      setCaps(caps);
    } catch (error) {
      toast.error("Failed to fetch CAPs");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchCaps();
  }, []);

  useEffect(() => {
    setSelectedCap((prevCap) => {
      const updatedCap = caps.find((c) => c.id === prevCap?.id);
      return updatedCap ?? null;
    });
  }, [caps]);

  const filteredCaps = useMemo(() => {
    if (!searchTerm) return caps;
    return caps.filter((cap) => {
      const delegate = cap.delegate;
      return delegate?.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [caps, searchTerm]);

  const columns: any[] = useMemo(
    () => [
      {
        header: ({ column }: any) => (
          <HeaderCell column={column} columnName="Name" />
        ),
        cell: ({ row }: any) => (
          <div
            className="text-blue-500 cursor-pointer"
            onClick={() => setSelectedCap(row.original)}
          >
            {row.original.name}
          </div>
        ),
        id: "name",
        accessorKey: "name",
      },
      {
        header: ({ column }: any) => (
          <HeaderCell column={column} columnName="Delegate" />
        ),
        cell: ({ row }: any) => {
          const delegate = row.original.delegate;
          return <div>{delegate || "N/A"}</div>;
        },
        id: "delegate",
        accessorKey: "delegate",
      },
      {
        header: ({ column }: any) => (
          <HeaderCell column={column} columnName="Description" />
        ),
        cell: ({ row }: any) => (
          <div className="max-h-[4.5em] overflow-hidden text-ellipsis line-clamp-3">
            {row.original.description}
          </div>
        ),
        id: "description",
        accessorKey: "description",
      },
      {
        header: ({ column }: any) => (
          <HeaderCell column={column} columnName="Owner" />
        ),
        cell: ({ row }: any) => {
          const user = getUser(row.original.cap_owner_id);
          console.log(user, row.original.cap_owner_id);
          return (
            <div>{user ? `${user.first_name} ${user.last_name}` : "N/A"}</div>
          );
        },
        id: "owner",
        accessorKey: "cap_owner_id",
      },
      {
        header: ({ column }: any) => (
          <HeaderCell column={column} columnName="Issued Date" />
        ),
        cell: ({ row }: any) => (
          <DateDisplay date={row.original.cap_issued_date} />
        ),
        id: "issued_date",
        accessorKey: "cap_issued_date",
      },
      {
        header: ({ column }: any) => (
          <HeaderCell column={column} columnName="Due Date" />
        ),
        cell: ({ row }: any) => (
          <DateDisplay date={row.original.cap_due_date} />
        ),
        id: "due_date",
        accessorKey: "cap_due_date",
      },
      {
        header: ({ column }: any) => (
          <HeaderCell column={column} columnName="Status" />
        ),
        cell: ({ row }: any) => (
          <div>
            <Badge
              className={cn(
                "capitalize",
                row.original.cap_status === "open"
                  ? "bg-yellow-100 text-yellow-800 hover:bg-green-200"
                  : row.original.cap_status === "closed"
                    ? "bg-green-100 text-green-800 hover:bg-green-200"
                    : "bg-gray-100 text-gray-800 hover:bg-gray-200"
              )}
            >
              {row.original.cap_status || "N/A"}
            </Badge>
          </div>
        ),
        id: "status",
        accessorKey: "cap_status",
      },
      {
        header: ({ column }: any) => (
          <HeaderCell column={column} columnName="Created At" />
        ),
        cell: ({ row }: any) => <DateDisplay date={row.original.created_at} />,
        id: "created_at",
        accessorKey: "created_at",
      },
    ],
    [simpleUsers]
  );

  // Example data for HeaderCards
  const totalCaps = caps.length;
  const openCaps = caps.filter((cap) => cap.cap_status === "open").length;
  const closedCaps = caps.filter((cap) => cap.cap_status === "closed").length;

  return (
    <Layout pageName="CAP Dashboard">
      <div className="w-full mx-auto px-4 mb-10">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Corrective Action Plans
        </h2>

        {/* HeaderCards for important data */}
        <div className="grid grid-cols-3 gap-4 mb-6">
          <HeaderCard
            title="Total CAPs"
            value={totalCaps}
            activeItem=""
            setActiveItem={() => {}}
            clickable={false}
          />
          <HeaderCard
            title="Open CAPs"
            value={openCaps}
            activeItem=""
            setActiveItem={() => {}}
            clickable={false}
          />
          <HeaderCard
            title="Closed CAPs"
            value={closedCaps}
            activeItem=""
            setActiveItem={() => {}}
            clickable={false}
          />
        </div>

        <div className="mb-1">
          <input
            type="text"
            placeholder="Search by delegate"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-[300px] p-2 border border-gray-300 rounded"
          />
        </div>

        {isLoading ? (
          <LoadingView customText="Loading CAPs..." />
        ) : filteredCaps.length === 0 ? (
          <div className="text-center text-gray-500 py-8">No CAPs found</div>
        ) : (
          <DataTable
            columns={columns}
            data={filteredCaps}
            totalRecordCount={filteredCaps.length}
            paginationParams={{
              pageSize: 50,
              pageIndex: 0,
            }}
          />
        )}
      </div>
      <CapSheet
        caps={selectedCap ? [selectedCap] : []}
        open={selectedCap !== null}
        onOpenChange={() => {
          setSelectedCap(null);
        }}
        onUpdateCap={handleUpdateCapStatus}
      />
    </Layout>
  );
};

export default CapDashboard;
