import { useAuthInfo } from "@propelauth/react";
import "katex/dist/katex.min.css";
import { InfoIcon, X } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { InlineMath } from "react-katex";
import { toast } from "sonner";
import { getFilterCounts, SelectFilter } from "../../../components/FilterUtils";
import { Layout } from "../../../components/Layout";
import { LoadingView } from "../../../components/Loading";
import { Button } from "../../../shadcn/components/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../shadcn/components/card";
import { Search } from "../../../shadcn/components/search";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shadcn/components/tooltip";
import { cn } from "../../../shadcn/lib/utils";
import { getDashboardData } from "../../../utils/apiCalls";
import { DashboardRecord } from "./DashboardRecord";
import { DashboardTable } from "./DashboardTable";

const SearchRequirement = (props: {
  setSearchTerm: (searchTerm: string) => void;
  width: string;
}) => {
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const onSearchChange = useCallback(
    (e: any) => {
      const value = e.target.value.trimEnd();

      // Clear the previous timeout
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      // Set a new timeout to update the search term after 1 second
      searchTimeoutRef.current = setTimeout(() => {
        props.setSearchTerm(value);
      }, 300);
    },
    [props.setSearchTerm]
  );

  return (
    <Search
      onChangeHandler={onSearchChange}
      width={props.width}
      placeholder="Search..."
    />
  );
};

const HeaderCard = (props: {
  title: string;
  value: number | string;
  textColor?: string;
  info?: string;
  formula?: string;
}) => {
  return (
    <Card className={cn("border-2 border-transparent")}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{props.title}</CardTitle>
        {props.info && (
          <Tooltip>
            <TooltipTrigger>
              <InfoIcon className="h-4 w-4 text-blue-500" />
            </TooltipTrigger>
            <TooltipContent>
              <div>{props.info}</div>
              {props.formula && (
                <div className="p-4 text-xl flex justify-center items-center">
                  <InlineMath math={props.formula} />
                </div>
              )}
            </TooltipContent>
          </Tooltip>
        )}
      </CardHeader>
      <CardContent className={props.textColor && `${props.textColor}`}>
        <div className="text-2xl font-bold">{props.value}</div>
      </CardContent>
    </Card>
  );
};

export const DashboardView = () => {
  const authInfo = useAuthInfo();
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [activeRow, setActiveRow] = useState<Record<string, any> | null>(null);
  const [requirementSearch, setRequirementSearch] = useState<string>("");
  const [activeStatus, setActiveStatus] = useState<string[]>([]);
  const [activeDocument, setActiveDocument] = useState<string[]>([]);
  const [activeDepartments, setActiveDepartments] = useState<string[]>([]);

  const compliantDataCount = data.filter(
    (item) => item["status"] === "Compliant"
  ).length;
  const nonCompliantDataCount = data.filter(
    (item) => item["status"] === "Non-Compliant"
  ).length;

  // filter for regulator
  const requirementData = data.filter(
    (item) =>
      item["requirement"]
        .toLowerCase()
        .includes(requirementSearch.toLowerCase()) || requirementSearch === ""
  );

  const statusData = requirementData.filter(
    (item) => activeStatus.includes(item["status"]) || activeStatus.length === 0
  );

  const documentData = statusData.filter(
    (item) =>
      activeDocument.includes(item["document"]) || activeDocument.length === 0
  );

  // filter for document
  const departmentData = documentData.filter(
    (item) =>
      item["departments"].some((dept: string) =>
        activeDepartments.includes(dept)
      ) || activeDepartments.length === 0
  );

  // get relevant data counts

  const statusDataCounts = getFilterCounts(
    requirementData.map((item) => item["status"])
  );

  const documentDataCounts = getFilterCounts(
    requirementData.map((item) => item["document"])
  );
  const departmentDataCounts = getFilterCounts(
    documentData.flatMap((item) => item["departments"])
  );

  useEffect(() => {
    setDataLoading(true);
    getDashboardData(null, authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        const cleanedData: Record<string, any>[] = response.data.map((item) => {
          return {
            ...item,
            departments: Array.isArray(item.departments)
              ? item.departments
              : item.departments.split("|"),
            requirement: item.requirement.replace(//g, "•"),
          };
        });
        setData(cleanedData);
      } else {
        toast.error("Failed to fetch dashboard data");
      }
      setDataLoading(false);
    });
  }, []);

  return (
    <Layout pageName="Dashboard">
      {activeRow && (
        <DashboardRecord record={activeRow} setActiveRecord={setActiveRow} />
      )}
      {!dataLoading && (
        <>
          <div className="grid gap-4 grid-cols-5">
            <HeaderCard
              title="Compliance Rate"
              value={`${(
                (compliantDataCount /
                  (compliantDataCount + nonCompliantDataCount)) *
                100
              ).toFixed(1)}%`}
              info="Percentage of tasks compliant."
              formula="\frac{\text{Compliant}}{\text{Compliant} + \text{Non-Compliant}}"
            />
            <HeaderCard
              title="Non-Compliance Rate"
              value={`${(
                (nonCompliantDataCount /
                  (compliantDataCount + nonCompliantDataCount)) *
                100
              ).toFixed(1)}%`}
              info="Percentage of tasks non-compliant."
              formula="\frac{\text{Non-Compliant}}{\text{Compliant} + \text{Non-Compliant}}"
            />
            <HeaderCard
              title="Total"
              value={data.length}
              info="All Requirements"
            />
            <HeaderCard
              title="Compliant"
              value={compliantDataCount}
              textColor="text-green-500"
              info="Compliant Tasks"
            />
            <HeaderCard
              title="Non-Compliant"
              value={nonCompliantDataCount}
              textColor="text-red-500"
              info="Non-Compliant Tasks"
            />
          </div>
          <div className="pb-10">
            <DashboardTable
              data={departmentData.sort((a, b) => {
                return b["requirement"].localeCompare(a["requirement"]);
              })}
              setActiveRow={setActiveRow}
              setData={setData}
            >
              {data.length > 0 && (
                <div className="flex items-center justify-end space-x-2">
                  {(requirementSearch.length > 0 ||
                    activeDocument.length > 0 ||
                    activeDepartments.length > 0 ||
                    activeStatus.length > 0) && (
                    <Button
                      variant="outline"
                      className="text-red-500"
                      onClick={() => {
                        setRequirementSearch("");
                        setActiveDocument([]);
                        setActiveDepartments([]);
                        setActiveStatus([]);
                      }}
                    >
                      <X className="h-4 w-4 mr-2" />
                      Reset
                    </Button>
                  )}
                  <SearchRequirement
                    setSearchTerm={setRequirementSearch}
                    width="100%"
                  />
                  <SelectFilter
                    title="Status"
                    filterCounts={statusDataCounts}
                    activeFilter={activeStatus}
                    setActiveFilter={setActiveStatus}
                  />
                  <SelectFilter
                    title="Document"
                    filterCounts={documentDataCounts}
                    activeFilter={activeDocument}
                    setActiveFilter={setActiveDocument}
                  />
                  <SelectFilter
                    title="Department"
                    filterCounts={departmentDataCounts}
                    activeFilter={activeDepartments}
                    setActiveFilter={setActiveDepartments}
                  />
                </div>
              )}
            </DashboardTable>
          </div>
        </>
      )}
      {dataLoading && <LoadingView customText="Loading dashboard data..." />}
    </Layout>
  );
};
