import { format } from "date-fns";

interface DateDisplayProps {
  date: string | Date | null;
  format?: string;
  placeholder?: string;
}

export const DateDisplay = ({
  date,
  format: dateFormat = "yyyy-MM-dd",
  placeholder = "N/A",
}: DateDisplayProps) => {
  if (!date) {
    return <span className="text-gray-400">{placeholder}</span>;
  }

  try {
    const formattedDate = format(new Date(date), dateFormat);
    return <span>{formattedDate}</span>;
  } catch (error) {
    return <span className="text-gray-400">{placeholder}</span>;
  }
};

export const DateRangeDisplay = ({
  startDate,
  endDate,
  format: dateFormat = "yyyy-MM-dd",
  placeholder = "N/A",
}: {
  startDate: string | Date | null;
  endDate: string | Date | null;
  format?: string;
  placeholder?: string;
}) => {
  if (!startDate || !endDate) {
    return <span className="text-gray-400">{placeholder}</span>;
  }

  return (
    <div className="flex flex-col">
      <div className="flex space-x-1">
        <DateDisplay date={startDate} format={dateFormat} />
        <div className="text-gray-400 text-sm">–</div>
      </div>
      <DateDisplay date={endDate} format={dateFormat} />
    </div>
  );
};
