import debounce from "debounce";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { usePdf } from "../PdfContext";
import { Box } from "./types";

type HighlightToolTipProps = {
  isVisible: boolean;
  highlightBox: Box;
  pageNum?: number;
  toolTipClass?: string;
  tooltipPosition?: "top" | "bottom" | "left" | "right";
};
export const HighlightTooltip: React.FC<
  PropsWithChildren & HighlightToolTipProps
> = ({
  children,
  isVisible,
  highlightBox,
  pageNum,
  toolTipClass,
  tooltipPosition = "left",
}) => {
  const { scrollOffset, parentModalRef } = usePdf();
  const toolTipRef = useRef<HTMLDivElement | null>(null);
  const [toolTipHeight, setToolTipHeight] = useState<number>(0);
  const [toolTipWidth, setToolTipWidth] = useState<number>(0);
  const [containerPosition, setContainerPosition] = useState({
    top: 0,
    left: 0,
    height: 0,
    bottom: 0,
    width: 0,
    right: 0,
  });
  const [parentModalPosition, setParentModalPosition] = useState({
    top: 0,
    left: 0,
    height: 0,
    bottom: 0,
    width: 0,
    right: 0,
  });
  const { pageRefs, containerRef } = usePdf();
  const [toolTipH, setToolTipH] = useState(0);
  const [toolTipR, setToolTipR] = useState(0);
  const [isHighlightOob, setIsHighlightOob] = useState(false);
  const calculateTooltipHeight = () => {
    if (!pageNum) {
      setIsHighlightOob(true);
      return;
    }
    const pageElement = pageRefs.current?.get(pageNum);
    if (!pageElement) return;
    const pageTop = parseFloat(
      pageElement.getAttribute("data-page-top") || "0"
    );
    let calcH =
      pageTop -
      scrollOffset +
      highlightBox.top +
      containerPosition.top -
      (parentModalRef?.current ? parentModalPosition.top : 0);

    if (tooltipPosition === "top") {
      calcH = calcH - toolTipHeight - 10;
    } else if (tooltipPosition === "bottom") {
      calcH = calcH + (highlightBox.bottom - highlightBox.top) + 10;
    }

    const isOutOfBounds =
      pageTop + highlightBox.bottom < scrollOffset ||
      pageTop + highlightBox.top > scrollOffset + containerPosition.height;

    const boundedHeight = Math.max(
      Math.min(calcH, containerPosition.bottom - toolTipHeight),
      containerPosition.top
    );
    setToolTipH(boundedHeight);

    let calcR =
      window.innerWidth - containerPosition.left - highlightBox.left + 10;

    if (
      tooltipPosition === "right" ||
      tooltipPosition === "bottom" ||
      tooltipPosition === "top"
    ) {
      calcR =
        window.innerWidth -
        containerPosition.left -
        highlightBox.right -
        toolTipWidth -
        15;
    }
    if (tooltipPosition === "right") {
      calcR = calcR - 20;
    }
    if (tooltipPosition === "top" || tooltipPosition === "bottom") {
      calcR = calcR + toolTipWidth;
    }
    if (parentModalRef?.current) {
      calcR = calcR - (window.innerWidth - parentModalPosition.right) - 10;
    }
    const boundedR = Math.min(
      Math.max(calcR, 0),
      window.innerWidth - toolTipWidth - 60
    );
    setToolTipR(boundedR);

    setIsHighlightOob(isOutOfBounds);
  };

  const updateContainerPosition = () => {
    const container = containerRef.current;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      setContainerPosition({
        top: containerRect.top,
        left: containerRect.left,
        height: containerRect.height,
        bottom: containerRect.bottom,
        width: containerRect.width,
        right: containerRect.right,
      });
      const parentModalRect = parentModalRef?.current?.getBoundingClientRect();
      if (parentModalRect) {
        setParentModalPosition({
          top: parentModalRect.top,
          left: parentModalRect.left,
          height: parentModalRect.height,
          bottom: parentModalRect.bottom,
          width: parentModalRect.width,
          right: parentModalRect.right,
        });
      }
    }
  };

  useEffect(() => {
    updateContainerPosition();
    calculateTooltipHeight();
  }, [scrollOffset, containerRef]);

  useEffect(() => {
    updateContainerPosition();
  }, []);
  useEffect(() => {
    calculateTooltipHeight();
  }, [containerPosition]);

  useEffect(() => {
    calculateTooltipHeight();
  }, [pageNum, highlightBox]);

  const updateContainerPositionDebounced = debounce(
    updateContainerPosition,
    5000
  );
  useEffect(() => {
    updateContainerPosition();

    window.addEventListener("resize", updateContainerPositionDebounced);

    return () => {
      window.removeEventListener("resize", updateContainerPositionDebounced);
    };
  }, []);

  useEffect(() => {
    const rect = toolTipRef.current?.getBoundingClientRect();
    setToolTipHeight(rect?.height || 0);
    setToolTipWidth(rect?.width || 0);
  }, [children, toolTipClass]);

  return ReactDOM.createPortal(
    <div
      className={`absolute ${toolTipClass}`}
      id={`tt-${pageNum}-${highlightBox.top}`}
      style={{
        top: `${Math.floor(toolTipH)}px`,
        right: `${Math.floor(toolTipR)}px`,
        opacity: isVisible && !isHighlightOob ? "1" : "0",
        pointerEvents: isVisible && !isHighlightOob ? "all" : "none",
        transition: `opacity 100ms`,
        zIndex: 2001,
      }}
      ref={toolTipRef}
    >
      {children}
    </div>,
    parentModalRef?.current
      ? parentModalRef?.current
      : (document.getElementById("root") as Element)
  );
};
