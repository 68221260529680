import { useAuthInfo } from "@propelauth/react";
import { UseAuthInfoProps } from "@propelauth/react/dist/types/hooks/useAuthInfo";
import { Pencil1Icon, PlusIcon } from "@radix-ui/react-icons";
import { Copy, SparkleIcon, XIcon } from "lucide-react";
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { RequirementContext } from "../../contexts/RequirementContext";
import { UserContext } from "../../contexts/UserContext";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../shadcn/components/alert-dialog";
import { Badge } from "../../shadcn/components/badge";
import { Button } from "../../shadcn/components/button";
import { MultiSelect } from "../../shadcn/components/multi-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import { Separator } from "../../shadcn/components/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { cn } from "../../shadcn/lib/utils";
import {
  ActionItem,
  Citation,
  Department,
  Requirement,
  RequirementTag,
  SimpleUser,
  tagToLabelMap,
} from "../../types";
import {
  addCitation,
  autoPopulateRequirement,
  debounce,
  deleteCitation,
  generateActionItem,
  saveActionItem,
  saveAssignees,
  updateRequirements,
} from "../../utils/apiCalls";
import { citationSortOrder, TimeAgo } from "../../utils/format";
import { AutoSavingTextArea } from "../AutoSavingTextArea";
import { CitationResultView } from "../Citation/CitationResultView";
import { CitationSelectorView } from "../Citation/CitationSelectorView";
import { capPassageWords } from "../Citation/util";
import { DocWorkspace } from "../DocWorkspace";
import { getFilterCounts, SelectFilter } from "../FilterUtils";
import { HIGHLIGHTED_CITATION_ID } from "../PdfViewer";
import { PdfCitation } from "../PdfViewer/PdfHighlighter/types";
import { ConfirmActionItemDeletion } from "./ActionItemDialog";
import { RequirementDisplay } from "./GapDocDisplayUtils";
import { RequirementImpactedDocumentView } from "./RequirementDocumentView";

const ImpactedDepartmentView = (props: {
  docId: string;
  requirement: Requirement;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  actionItemIds: string[];
  uniqueDepartments: Department[];
}) => {
  const authInfo = useAuthInfo();
  const { departments } = useContext(UserContext);

  const updateAssignees = debounce(async (assignees: Department[]) => {
    await saveAssignees(
      `gap-analysis/requirement/assignees/${props.docId}/${props.requirement.id}`,
      assignees,
      authInfo.accessToken ?? ""
    );
    for (const actionItem of props.requirement.action_items) {
      await saveAssignees(
        `gap-analysis/action-item/assignees/${props.docId}/${props.requirement.id}/${actionItem.id}`,
        assignees,
        authInfo.accessToken ?? ""
      );
    }
  }, 1000);

  return (
    <div className="space-y-3 rounded-b-md my-4">
      <Separator className="bg-gray-200" />
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-2">
          <div className="text-base font-semibold">Impacted Departments</div>
        </div>
      </div>
      <MultiSelect
        title="Departments"
        maxCount={100}
        options={departments.map((dept) => ({
          label: dept.name,
          value: dept.id,
        }))}
        defaultValue={props.uniqueDepartments.map((dept) => dept.id)}
        onValueChange={(departmentIDs) => {
          const newAssignees = departments
            .filter((dept) => departmentIDs.includes(dept.id))
            .reduce((depts, dept) => depts.add(dept.id), new Set<String>())
            .values()
            .map((dept) => departments.find((d) => d.id === dept))
            .filter((d) => !!d)
            .toArray();

          props.setRequirements((prev) => {
            return prev.map((requirement) => {
              if (requirement.id === props.requirement.id) {
                return {
                  ...requirement,
                  assignees: newAssignees,
                  action_items: requirement.action_items.map((action_item) => {
                    if (props.actionItemIds.includes(action_item.id)) {
                      return {
                        ...action_item,
                        assignees: newAssignees,
                      };
                    }
                    return action_item;
                  }),
                };
              }
              return requirement;
            });
          });

          updateAssignees(newAssignees);
        }}
      />
    </div>
  );
};

const ConfirmActionableRemovable = (props: {
  alertOpen: { tag: RequirementTag | null; open: boolean };
  setAlertOpen: React.Dispatch<
    React.SetStateAction<{ tag: RequirementTag | null; open: boolean }>
  >;
  onSaveClick: (tag: RequirementTag | null) => void;
}) => {
  return (
    <AlertDialog
      open={props.alertOpen.open}
      onOpenChange={(open) => {
        props.setAlertOpen((prev) => ({
          ...prev,
          open: open,
        }));
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you wish to switch the requirement from Actionable?
            This will delete the associated action items.
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            onClick={() => {
              props.onSaveClick(props.alertOpen.tag);
              props.setAlertOpen((prev) => ({
                ...prev,
                open: false,
              }));
            }}
          >
            Confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const IndividualActionItemView = (props: {
  docId: string;
  actionItemRoutePrefix: string;
  actionItem: ActionItem;
  index: number;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  existingActionItem: ActionItem | null;
  setExistingActionItem: React.Dispatch<
    React.SetStateAction<ActionItem | null>
  >;
  requirementId: string;
}) => {
  const activeActionItem = props.existingActionItem?.id === props.actionItem.id;
  const authInfo = useAuthInfo();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const onClickSave = useCallback(
    async (e?: React.ChangeEvent<HTMLTextAreaElement>) => {
      setSaveLoading(true);
      const actionItemToSave = {
        ...props.existingActionItem,
      } as ActionItem;
      if (e) {
        actionItemToSave.text = e.target.value;
      }
      let newAction = false;
      if (actionItemToSave.id === "") {
        actionItemToSave.id = uuidv4();
        newAction = true;
      }
      const response = await saveActionItem(
        props.docId,
        props.actionItem.requirement_id,
        actionItemToSave,
        authInfo.accessToken ?? ""
      );
      if (response) {
        props.setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === props.actionItem.requirement_id) {
              return {
                ...requirement,
                action_items: requirement.action_items.map((item) => {
                  if (item.id === props.existingActionItem?.id) {
                    return {
                      ...actionItemToSave,
                      text_updated_by: {
                        id: authInfo.user?.userId ?? "unknown",
                        email: authInfo.user?.email ?? "unknown",
                        first_name: authInfo.user?.firstName ?? "unknown",
                        last_name: authInfo.user?.lastName ?? "unknown",
                      },
                      text_updated_at: new Date().toISOString().slice(0, -1),
                    };
                  }
                  return item;
                }),
              };
            }
            return requirement;
          });
        });
        if (newAction) {
          for (const citation of actionItemToSave.citations) {
            await addCitation(
              `gap-analysis/action-item/impacted-document/citation/${props.docId}/${props.requirementId}/${actionItemToSave.id}`,
              citation,
              authInfo.accessToken ?? ""
            );
          }
          await saveAssignees(
            `gap-analysis/action-item/assignees/${props.docId}/${props.requirementId}/${actionItemToSave.id}`,
            actionItemToSave.assignees,
            authInfo.accessToken ?? ""
          );
        }
      } else {
        toast.error("Failed to save");
      }
      setSaveLoading(false);
    },
    [props.existingActionItem]
  );

  return (
    <div
      key={props.actionItem.id}
      className="flex justify-between items-start space-x-2 pl-2"
    >
      <div className="flex-auto">
        {activeActionItem ? (
          <AutoSavingTextArea
            onSave={(e) => {
              if (props.existingActionItem?.id) {
                onClickSave(e);
              }
            }}
            textareaProps={{
              placeholder: "Create Action Item...",
              value: props.existingActionItem!.text,
              onChange: (e) => {
                props.setExistingActionItem((prev) => {
                  if (prev) {
                    return {
                      ...prev,
                      text: e.target.value,
                    };
                  }
                  return prev;
                });
              },
            }}
            updatedText={
              props.actionItem?.text_updated_at &&
              props.actionItem?.text_updated_by && (
                <div className="text-xs text-gray-500 text-center">
                  Last updated{" "}
                  <TimeAgo timestamp={props.actionItem.text_updated_at} /> by{" "}
                  {`${props.actionItem.text_updated_by.first_name}${props.actionItem.text_updated_by.last_name ? ` ${props.actionItem.text_updated_by.last_name[0]}.` : ""}`}
                </div>
              )
            }
          />
        ) : (
          <div className="flex items-start space-x-2">
            <div className="text-sm">
              {props.actionItem.text.length > 300 ? (
                <Tooltip>
                  <TooltipTrigger asChild={true}>
                    <div className="flex text-sm pt-0.5">
                      <span className="mr-1">{props.index + 1}. </span>
                      {props.actionItem.text.slice(0, 300)}...
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side="right" className="w-[500px]">
                    {props.actionItem.text}
                  </TooltipContent>
                </Tooltip>
              ) : (
                <div className="flex text-sm pt-0.5">
                  <span className="mr-1">{props.index + 1}. </span>
                  {props.actionItem.text}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center space-x-2 ">
        {!activeActionItem && (
          <div className="flex">
            <Tooltip>
              <TooltipTrigger asChild={true}>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    props.setExistingActionItem(props.actionItem);
                  }}
                >
                  <Pencil1Icon className="w-4 h-4 mr-2" />
                  Edit
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                Edit Action Item {props.index + 1}
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild={true}>
                <ConfirmActionItemDeletion
                  docId={props.docId}
                  actionItem={props.actionItem}
                  setRequirements={props.setRequirements}
                >
                  <Button size="sm" variant="ghost">
                    <XIcon className="w-4 h-4" />
                  </Button>
                </ConfirmActionItemDeletion>
              </TooltipTrigger>
              <TooltipContent>
                Delete Action Item {props.index + 1}
              </TooltipContent>
            </Tooltip>
          </div>
        )}
        {activeActionItem && (
          <>
            {props.existingActionItem?.text && (
              <Badge
                onClick={(_) => {
                  onClickSave();
                  props.setExistingActionItem(null);
                }}
              >
                Done
              </Badge>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const IndividualResourceActionsView = (props: {
  requirement: Requirement;
  docId: string;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  existingActionItem: ActionItem | null;
  setExistingActionItem: React.Dispatch<
    React.SetStateAction<ActionItem | null>
  >;
  actionItemRoutePrefix: string;
}) => {
  const authInfo = useAuthInfo();
  const [addActionItemLoading, setAddActionItemLoading] =
    useState<boolean>(false);

  const onClickAddActionItem = async () => {
    setAddActionItemLoading(true);
    let newActionItem: ActionItem | undefined;
    try {
      for await (const output of generateActionItem(
        `gap-analysis/action-item/generate/${props.docId}/${props.requirement.id}`,
        authInfo.accessToken ?? null
      )) {
        newActionItem = { ...output };
        props.setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === props.requirement.id) {
              return {
                ...requirement,
                action_items: [
                  ...requirement.action_items.filter(
                    (item) => item.id !== newActionItem!.id
                  ),
                  newActionItem!,
                ],
              };
            }
            return requirement;
          });
        });
        props.setExistingActionItem(newActionItem);
      }
      if (newActionItem) {
        await saveActionItem(
          props.docId,
          props.requirement.id!,
          newActionItem,
          authInfo.accessToken ?? ""
        );
      }
    } catch (error: any) {
      toast.error("Failed to generate action item");
    } finally {
      setAddActionItemLoading(false);
    }
  };

  return (
    <div className="space-y-3 rounded-b-md my-4">
      <Separator className="bg-gray-200" />
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="text-base font-semibold">Action Items</div>
        </div>
        <Tooltip>
          <TooltipTrigger asChild={true}>
            <Button size="sm" onClick={onClickAddActionItem}>
              <PlusIcon
                className={
                  addActionItemLoading
                    ? "w-4 h-4 mr-2 animate-spin"
                    : "w-4 h-4 mr-2"
                }
              />
              Add
            </Button>
          </TooltipTrigger>
          <TooltipContent>Add Action Item</TooltipContent>
        </Tooltip>
      </div>

      {props.requirement.action_items
        .sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at))
        .map((actionItem, i) => (
          <IndividualActionItemView
            key={i}
            actionItemRoutePrefix={props.actionItemRoutePrefix}
            docId={props.docId}
            actionItem={actionItem}
            index={i}
            setRequirements={props.setRequirements}
            setExistingActionItem={props.setExistingActionItem}
            existingActionItem={props.existingActionItem}
            requirementId={props.requirement.id!}
          />
        ))}
    </div>
  );
};

const IndividualRequirementAssessmentView = (props: {
  requirement: Requirement;
}) => {
  if (
    props.requirement.is_gap === undefined ||
    props.requirement.analysis === null
  ) {
    return null;
  }

  return (
    <div className="space-y-3 rounded-b-md my-4">
      <Separator className="bg-gray-200" />
      <div className="flex flex-col space-y-2">
        <div className="text-base font-semibold">Gap Analysis</div>

        <CitationResultView
          passage={`${props.requirement.is_gap ? "❌" : "✅"} ${props.requirement.analysis}`}
        />
      </div>
    </div>
  );
};

const IndividualRequirementCitationView = (props: {
  citations: Citation[];
  requirementID: string;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  onDeleteCitation: (
    requirementID: string,
    citationID: string
  ) => Promise<void>;
}) => {
  const {
    editingIndividualRequirementCitation: editingCitation,
    setEditingIndividualRequirementCitation: setEditingCitation,
    individualRequirementActiveCitationIndex: activeCitationIndex,
    setIndividualRequirementActiveCitationIndex: setActiveCitationIndex,
  } = useContext(RequirementContext);
  const { setPageNumber } = useContext(DocViewerContext);

  const citationIndexMap = useMemo(
    () =>
      props.citations.reduce(
        (acc, citation, i) => acc.set(citation.id ?? "", i),
        new Map<string, number>()
      ),
    [props.citations]
  );

  const [citationDeleting, setCitationDeleting] = useState<Citation | null>(
    null
  );

  return (
    <div className="space-y-3 rounded-b-md">
      <div className="flex items-center justify-between">
        <div>
          <div className="flex space-x-2">
            <span>Citations:</span>
            <Tooltip>
              <TooltipTrigger asChild={true}>
                <Button
                  size={null}
                  variant={activeCitationIndex === -1 ? "default" : "ghost"}
                  onClick={() => {
                    setEditingCitation(false);

                    const citationIndex = activeCitationIndex === -1 ? 0 : -1;

                    setActiveCitationIndex(citationIndex);
                    if (citationIndex >= 0) {
                      setPageNumber(props.citations[citationIndex].page);
                    }
                  }}
                >
                  <PlusIcon className="w-6 h-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Add citation to requirement</TooltipContent>
            </Tooltip>

            <div className="flex flex-col space-y-2">
              <AlertDialog
                open={citationDeleting !== null}
                onOpenChange={(open) => {
                  if (open) {
                    return;
                  }

                  setCitationDeleting(null);
                }}
              >
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Delete Citation?</AlertDialogTitle>
                    <AlertDialogDescription>
                      {`Page ${citationDeleting?.page ?? -1}: "${capPassageWords(citationDeleting?.text ?? "")}"?`}
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <Button
                      variant="destructive"
                      onClick={async () => {
                        if (!citationDeleting?.id) {
                          return;
                        }

                        await props.onDeleteCitation(
                          props.requirementID,
                          citationDeleting.id
                        );
                        setCitationDeleting(null);
                      }}
                    >
                      Delete Citation
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
              <CitationSelectorView
                citations={props.citations}
                onClickCitation={(citationID) => {
                  setEditingCitation(false);

                  const citationIndex = citationIndexMap.get(citationID);
                  if (citationIndex === undefined) {
                    return;
                  }

                  setActiveCitationIndex(citationIndex);
                  setPageNumber(props.citations[citationIndex].page);
                }}
              />
            </div>
          </div>
        </div>
        {props.citations.length > 1 && (
          <div className="flex space-x-2">
            <Tooltip>
              <TooltipTrigger asChild={true}>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    if (activeCitationIndex < 0) {
                      setEditingCitation(false);
                      if (props.citations.length > 0) {
                        setActiveCitationIndex(0);
                        setPageNumber(props.citations[0].page);
                      }
                      return;
                    }

                    setCitationDeleting(props.citations[activeCitationIndex]);
                  }}
                >
                  <XIcon className="w-4 h-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Delete citation from requirement</TooltipContent>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

interface IndividualRequirementViewProps {
  activeRequirementId: string | null;
  onRequirementClick: (resourceId: string) => void;
  onRequirementSaveClick: (
    tag: RequirementTag | null,
    requirement: Requirement,
    citation: PdfCitation
  ) => void;
  requirement: Requirement;
  docId: string;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setExistingActionItem: React.Dispatch<
    React.SetStateAction<ActionItem | null>
  >;
  existingActionItem: ActionItem | null;
  setActiveRequirementId: React.Dispatch<React.SetStateAction<string | null>>;
  actionItemRoutePrefix: string;
  handleDocModalChange: (open: boolean) => void;
  handleAutoPopulate: (
    authInfo: UseAuthInfoProps,
    docId: string,
    requirement: Requirement,
    setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>
  ) => Promise<void>;
  onDeleteCitation: (
    requirementID: string,
    citationID: string
  ) => Promise<void>;
}

const IndividualRequirementView = forwardRef<
  HTMLDivElement,
  IndividualRequirementViewProps
>((props, ref) => {
  const authInfo = useAuthInfo();
  const { setCitations } = useContext(DocViewerContext);
  const { individualRequirementActiveCitationIndex: activeCitationIndex } =
    useContext(RequirementContext);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<{
    tag: RequirementTag | null;
    open: boolean;
  }>({ tag: "actionable", open: false });
  const [reAnalyzeAlertOpen, setReAnalyzeAlertOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const isActive = props.activeRequirementId === props.requirement.id;
  const actionItemIds = props.requirement.action_items.map((item) => item.id);
  const uniqueDepartments = [
    ...new Map(
      props.requirement.assignees
        .concat(
          props.requirement.action_items.flatMap(
            (action_item) => action_item.assignees
          )
        )
        .filter(Boolean)
        .map((assignee) => [assignee.id, assignee])
    ).values(),
  ].sort((a, b) => a.name.localeCompare(b.name));

  const onDeleteClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (props.requirement) {
      setDeleteLoading(true);
      const response = await updateRequirements(
        props.docId,
        "delete",
        props.requirement,
        authInfo.accessToken ?? ""
      );
      if (response !== null) {
        toast.success("Successfully deleted");
        props.setRequirements((prev) => {
          return prev.filter(
            (individualRequirement) =>
              individualRequirement.id !== props.requirement.id
          );
        });
        setCitations([]);
      } else {
        toast.error("Failed to delete");
      }
      setDeleteLoading(false);
    }
  };

  return (
    <div className="space-y-0" ref={ref}>
      <div
        className={cn(
          "flex space-y-2",
          isActive
            ? "min-h-[100px] bg-white pt-5 px-5 rounded-md border-2 border-black"
            : "h-[100px] bg-white pt-5 px-5 rounded-md cursor-pointer"
        )}
        onClick={() => {
          if (props.requirement.id) {
            props.setEditing(false);
            props.setRequirements((requirements) =>
              requirements.filter((requirement) => !!requirement.id)
            );
            props.onRequirementClick(props.requirement.id);
          }
        }}
      >
        <div className="flex flex-col justify-between pb-4 w-full">
          <div
            className={cn(
              "text-ellipsis overflow-hidden text-sm space-y-6 pb-6",
              isActive
            )}
          >
            <RequirementDisplay requirement={props.requirement} />
          </div>
          <div>
            {isActive && !props.editing && (
              <div className="flex flex-col space-y-4">
                <div className="flex justify-between">
                  <div className="flex space-x-2">
                    <Select
                      value={props.requirement.tag ?? ""}
                      onValueChange={(tag) => {
                        if (
                          props.requirement.tag === "actionable" &&
                          tag !== "actionable"
                        ) {
                          setAlertOpen({
                            tag: tag as RequirementTag,
                            open: true,
                          });
                        } else {
                          const activeCitation =
                            props.requirement.citations[activeCitationIndex];
                          const pdfCitation: PdfCitation = {
                            match: activeCitation.text,
                            exactMatch: true,
                            ...activeCitation,
                            id: activeCitation.id || undefined,
                            match_index:
                              activeCitation.match_index || undefined,
                          };
                          props.onRequirementSaveClick(
                            tag as RequirementTag,
                            props.requirement,
                            pdfCitation
                          );
                        }
                      }}
                    >
                      <SelectTrigger className="w-64 bg-white">
                        <SelectValue placeholder="Select label..." />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.keys(tagToLabelMap).map((tag) => (
                          <SelectItem value={tag} key={tag}>
                            {tagToLabelMap[tag as RequirementTag]}
                          </SelectItem>
                        ))}
                        <Button
                          size="sm"
                          className="w-full px-2"
                          onClick={() => {
                            const activeCitation =
                              props.requirement.citations[activeCitationIndex];
                            const pdfCitation: PdfCitation = {
                              match: activeCitation.text,
                              exactMatch: true,
                              ...activeCitation,
                              id: activeCitation.id || undefined,
                              match_index:
                                activeCitation.match_index || undefined,
                            };
                            props.onRequirementSaveClick(
                              null,
                              props.requirement,
                              pdfCitation
                            );
                          }}
                        >
                          Clear
                        </Button>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex space-x-2">
                    <AlertDialog
                      open={reAnalyzeAlertOpen}
                      onOpenChange={setReAnalyzeAlertOpen}
                    >
                      <AlertDialogTrigger asChild={true}>
                        <Tooltip>
                          <TooltipTrigger asChild={true}>
                            <Button
                              size="sm"
                              variant="ghost"
                              disabled={
                                props.requirement.auto_populating ||
                                saveLoading ||
                                deleteLoading
                              }
                              onClick={() => setReAnalyzeAlertOpen(true)}
                            >
                              <SparkleIcon
                                className={
                                  props.requirement.auto_populating
                                    ? "w-4 h-4 animate-spin"
                                    : "w-4 h-4"
                                }
                              />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            Erase and re-analyze requirement
                          </TooltipContent>
                        </Tooltip>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Re-analyze Requirement?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            Requirement analyses including impacted departments,
                            action items & impacted documents will be erased for
                            re-analysis.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <Button
                            variant="destructive"
                            onClick={() => {
                              props.handleAutoPopulate(
                                authInfo,
                                props.docId,
                                props.requirement,
                                props.setRequirements
                              );
                              setReAnalyzeAlertOpen(false);
                            }}
                          >
                            Erase & Re-analyze
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                    <AlertDialog
                      open={deleteAlertOpen}
                      onOpenChange={setDeleteAlertOpen}
                    >
                      <AlertDialogTrigger asChild={true}>
                        <Tooltip>
                          <TooltipTrigger asChild={true}>
                            <Button
                              size="sm"
                              variant="ghost"
                              disabled={
                                props.requirement.auto_populating ||
                                saveLoading ||
                                deleteLoading
                              }
                              onClick={() => setDeleteAlertOpen(true)}
                            >
                              <XIcon className="w-4 h-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            Delete requirement entirely
                          </TooltipContent>
                        </Tooltip>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Delete Requirement?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            Requirement analyses including impacted departments,
                            action items & impacted documents will be erased for
                            re-analysis.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <Button
                            variant="destructive"
                            onClick={(e) => {
                              onDeleteClick(e);
                              setDeleteAlertOpen(false);
                            }}
                          >
                            Delete Requirement
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                </div>
                <ConfirmActionableRemovable
                  alertOpen={alertOpen}
                  setAlertOpen={setAlertOpen}
                  onSaveClick={(tag: RequirementTag | null) => {
                    const activeCitation =
                      props.requirement.citations[activeCitationIndex];
                    const pdfCitation: PdfCitation = {
                      match: activeCitation.text,
                      exactMatch: true,
                      ...activeCitation,
                      id: activeCitation.id || undefined,
                      match_index: activeCitation.match_index || undefined,
                    };
                    props.onRequirementSaveClick(
                      tag,
                      props.requirement,
                      pdfCitation
                    );
                  }}
                />
                <div>
                  {props.requirement.id && (
                    <IndividualRequirementCitationView
                      citations={props.requirement.citations}
                      requirementID={props.requirement.id}
                      setRequirements={props.setRequirements}
                      onDeleteCitation={props.onDeleteCitation}
                    />
                  )}
                  <ImpactedDepartmentView
                    docId={props.docId}
                    requirement={props.requirement}
                    setRequirements={props.setRequirements}
                    actionItemIds={actionItemIds}
                    uniqueDepartments={uniqueDepartments}
                  />
                  <RequirementImpactedDocumentView
                    requirement={props.requirement}
                    setRequirements={props.setRequirements}
                    actionItemIds={actionItemIds}
                    uniqueDepartments={uniqueDepartments}
                    docId={props.docId}
                    onModalChange={(open) => props.handleDocModalChange(open)}
                  />
                  {props.requirement.tag === "actionable" && (
                    <IndividualResourceActionsView
                      actionItemRoutePrefix={props.actionItemRoutePrefix}
                      requirement={props.requirement}
                      docId={props.docId}
                      setRequirements={props.setRequirements}
                      existingActionItem={props.existingActionItem}
                      setExistingActionItem={props.setExistingActionItem}
                    />
                  )}
                  {props.requirement.is_gap !== undefined && (
                    <IndividualRequirementAssessmentView
                      requirement={props.requirement}
                    />
                  )}
                </div>
              </div>
            )}
            {isActive && props.editing && props.requirement.id === null && (
              <div className="flex justify-end">
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    props.setEditing(false);
                    props.setRequirements((prev) => {
                      return prev.filter(
                        (requirement) => requirement.id !== null
                      );
                    });
                    props.setActiveRequirementId(null);
                  }}
                >
                  <XIcon className="w-4 h-4" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

interface RequirementListViewProps {
  docId: string;
  actionItemRoutePrefix: string;
  targetDocTypes?: string[];
}

export const RequirementListView = forwardRef<
  HTMLDivElement,
  RequirementListViewProps
>((props, ref) => {
  const {
    requirements,
    activeRequirement,
    setRequirements,
    setActiveRequirementId,
    activeRequirementId,
    setEditing,
    editing,
    viewType,
    individualRequirementActiveCitationIndex: activeCitationIndex,
    setIndividualRequirementActiveCitationIndex: setActiveCitationIndex,
    editingIndividualRequirementCitation: editingCitation,
    setEditingIndividualRequirementCitation: setEditingCitation,
    addRequirement,
  } = useContext(RequirementContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const authInfo = useAuthInfo();
  const [requirementsToDisplay, setRequirementsToDisplay] = useState<
    Requirement[]
  >([]);
  const {
    docToView,
    setDocToView,
    setPageNumber,
    pageNumber,
    setCitations,
    scrollToElement,
  } = useContext(DocViewerContext);
  const [existingActionItem, setExistingActionItem] =
    useState<ActionItem | null>(null);
  const [labelFilter, setLabelFilter] = useState<string[]>([]);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const activeRequirementRef = useRef<HTMLDivElement>(null);
  const [docModalOpen, setDocModalOpen] = useState(false);
  const labelFilterCounts = getFilterCounts(
    requirements.map((requirement) => requirement.tag || "None")
  );

  useEffect(() => {
    const requirementsFiltered = requirements
      .filter((requirement) =>
        labelFilter.length > 0
          ? (requirement.tag && labelFilter.includes(requirement.tag)) ||
            (requirement.tag === null && labelFilter.includes("None"))
          : true
      )
      .sort(citationSortOrder);

    setRequirementsToDisplay(requirementsFiltered);

    if (
      !requirementsFiltered.find(
        (requirement) => requirement.id === activeRequirement?.id
      )
    ) {
      setActiveRequirementId(requirementsFiltered[0]?.id ?? null);
    }
  }, [requirements, labelFilter]);

  const onResourceClick = (resourceId: string) => {
    setActiveRequirementId(resourceId);
    setSearchParams(
      (prev) => {
        prev.set("requirementId", resourceId);
        prev.delete("actionItemId");
        prev.set("activeTab", "requirements");
        prev.set("viewType", "list");
        return prev;
      },
      {
        replace: true,
      }
    );
  };

  const onSaveClick = useCallback(
    async (
      tag: RequirementTag | null,
      requirement: Requirement,
      citation: PdfCitation
    ): Promise<Requirement | undefined> => {
      setSaveLoading(true);
      const operation = activeRequirementId === null ? "add" : "update";
      const requirementToUpdate = {
        text:
          operation === "add"
            ? citation.match
            : requirement.text + " " + citation.match,
        id: activeRequirementId,
        action_items: requirement?.action_items ?? [],
        tag: tag,
        impacted_documents: requirement.impacted_documents ?? [],
        citations: requirement.citations ?? [],
        assignees: requirement.assignees ?? [],
        reference_doc_types: requirement.reference_doc_types ?? [],
        reference_documents: requirement.reference_documents ?? [],
        updated_at: new Date().toISOString().slice(0, -1),
      } as Requirement;

      const response = await updateRequirements(
        props.docId,
        operation,
        requirementToUpdate,
        authInfo.accessToken ?? ""
      );
      if (response !== null) {
        const newCitation = {
          id: null,
          page: citation.page,
          doc_id: docToView?.docId,
          doc_name: "",
          text: citation.match,
          formatted_text: null,
          start_index: null,
          match_index: citation.match_index,
          created_at: new Date().toISOString().slice(0, -1),
          user: {
            id: authInfo.user?.userId,
            first_name: authInfo.user?.firstName,
            last_name: authInfo.user?.lastName,
            email: authInfo.user?.email,
          } as SimpleUser,
        } as Citation;
        if (operation === "add") {
          const citationResponse = await addCitation(
            `gap-analysis/requirement/citation/${docToView?.docId}/${response.id}`,
            newCitation,
            authInfo.accessToken ?? ""
          );
          if (citationResponse !== null) {
            newCitation.id = citationResponse.citation_ids[0];
            newCitation.start_index =
              citationResponse.citation_start_indices[0];
            toast.success("Successfully saved");
          } else {
            toast.error("Failed to save citation");
          }
        }
        requirementToUpdate.id = response.id;
        setRequirements((prev) => {
          if (operation === "update") {
            let newResources = prev.map((resource) => {
              if (resource.id === response.id) {
                return {
                  ...requirementToUpdate,
                  action_items:
                    resource.tag === "actionable" &&
                    requirementToUpdate.tag !== "actionable"
                      ? []
                      : requirementToUpdate.action_items,
                };
              } else {
                return resource;
              }
            });
            return newResources;
          } else {
            return prev.map((resource) => {
              if (resource.id === null) {
                return {
                  ...requirementToUpdate,
                  citations: [newCitation],
                };
              } else {
                return resource;
              }
            });
          }
        });
        setActiveRequirementId(requirementToUpdate.id);
        setEditing(false);
        toast.success("Successfully saved");
        requirementToUpdate.id = response.id;
        return requirementToUpdate;
      } else {
        toast.error("Failed to save");
      }
      setSaveLoading(false);
    },
    [requirements, activeRequirementId, setRequirements]
  );

  const onNewCitation = async (pdfCitation: PdfCitation) => {
    setSaveLoading(true);
    const newCitation = {
      id: null,
      page: pdfCitation.page,
      doc_id: docToView?.docId,
      doc_name: "",
      text: pdfCitation.match,
      formatted_text: null,
      start_index: null,
      match_index: pdfCitation.match_index,
      created_at: new Date().toISOString().slice(0, -1),
      user: {
        id: authInfo.user?.userId ?? "",
        first_name: authInfo.user?.firstName ?? "",
        last_name: authInfo.user?.lastName ?? "",
        email: authInfo.user?.email ?? "",
      },
    } as Citation;
    const response = await addCitation(
      `gap-analysis/requirement/citation/${docToView?.docId}/${activeRequirementId}`,
      newCitation,
      authInfo.accessToken ?? ""
    );
    if (response !== null) {
      let requirementCitationLen = 0;
      setRequirements((prev) => {
        return prev.map((requirement) => {
          if (requirement.id === activeRequirementId) {
            requirementCitationLen = requirement.citations.length;
            newCitation.id = response.citation_ids[0];
            newCitation.start_index = response.citation_start_indices[0];
            return {
              ...requirement,
              citations: [...requirement.citations, newCitation],
              text: requirement.text + " " + newCitation.text,
            };
          }
          return requirement;
        });
      });
      setActiveCitationIndex(requirementCitationLen);
      updateCitations();
      toast.success("Successfully saved citation");
    } else {
      toast.error("Failed to save citation");
    }
    setSaveLoading(false);
  };

  const getNewRequirementTooltip = useCallback(
    (newCitation: PdfCitation) =>
      newCitation.match.length > 0 ? (
        <div className="flex space-x-2">
          <Button
            size="sm"
            onClick={async () => {
              if (!activeRequirement) {
                return;
              }

              const requirement = await onSaveClick(
                null,
                activeRequirement,
                newCitation
              );
              if (!requirement) {
                return;
              }
              setActiveRequirementId(requirement.id);

              await handleAutoPopulateGenerator(props.targetDocTypes)(
                authInfo,
                props.docId,
                requirement,
                setRequirements
              );
            }}
          >
            <PlusIcon className="w-4 h-4 mr-2" />
            <span className="w-40">Add Requirement</span>
          </Button>
          <Button
            size="sm"
            onClick={async () => {
              navigator.clipboard.writeText(newCitation.match);
              toast.success("Copied highlighted text to clipboard");
            }}
          >
            <Copy className="h-4 w-4" />
          </Button>
        </div>
      ) : null,
    [activeRequirement]
  );

  const onUpdateCitation = async (pdfCitation: PdfCitation) => {
    if (activeRequirement && activeCitationIndex >= 0) {
      setSaveLoading(true);
      const newCitation = {
        id: activeRequirement?.citations[activeCitationIndex].id,
        page: pageNumber,
        doc_id: docToView?.docId,
        doc_name: "",
        text: pdfCitation.match,
        formatted_text: null,
        start_index: null,
        match_index: pdfCitation.match_index,
        created_at: new Date().toISOString().slice(0, -1),
        user: {
          id: authInfo.user?.userId ?? "",
          first_name: authInfo.user?.firstName ?? "",
          last_name: authInfo.user?.lastName ?? "",
          email: authInfo.user?.email ?? "",
        },
      } as Citation;
      const response = await addCitation(
        `gap-analysis/requirement/citation/${docToView?.docId}/${activeRequirementId}`,
        newCitation,
        authInfo.accessToken ?? ""
      );
      if (response !== null) {
        setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === activeRequirementId) {
              newCitation.id = response.citation_ids[0];
              newCitation.start_index = response.citation_start_indices[0];
              const newCitations = requirement.citations.map((citation) => {
                if (
                  citation.id ===
                  activeRequirement.citations[activeCitationIndex].id
                ) {
                  return newCitation;
                }
                return citation;
              });
              return {
                ...requirement,
                text: newCitations.map((citation) => citation.text).join(" "),
                citations: newCitations,
              };
            }
            return requirement;
          });
        });
        toast.success("Successfully updated citation");
        setEditing(false);
      } else {
        toast.error("Failed to save citation");
      }
    }
    setSaveLoading(false);
  };

  const onDeleteCitation = async (
    requirementID: string,
    citationID: string
  ) => {
    setDeleteLoading(true);

    const response = await deleteCitation(
      `gap-analysis/requirement/citation/${docToView?.docId}/${requirementID}`,
      [citationID],
      authInfo.accessToken ?? ""
    );
    if (response === true) {
      let newCitationCount = 0;
      setRequirements((prev) => {
        return prev.map((requirement) => {
          if (requirement.id === activeRequirementId) {
            const newCitations = requirement.citations.filter(
              (citation) => citation.id !== citationID
            );
            newCitationCount = newCitations.length;
            return {
              ...requirement,
              citations: newCitations,
              text: newCitations.map((citation) => citation.text).join(" "),
            };
          }
          return requirement;
        });
      });
      setActiveCitationIndex(newCitationCount > 0 ? 0 : -1);
      updateCitations();
    } else {
      toast.error("Failed to delete citation");
    }

    setDeleteLoading(false);
  };

  const getActiveCitationTooltip = (newCitation: PdfCitation): ReactNode => {
    newCitation.tooltipPosition = "bottom";

    // New requirement being edited
    if (editing) {
      return getNewRequirementTooltip(newCitation);
    }

    // New citation being added to requirement
    if (activeCitationIndex === -1) {
      return (
        <div className="flex space-x-2">
          <Button size="sm" onClick={() => onNewCitation(newCitation)}>
            <PlusIcon className="w-4 h-4 mr-2" />
            <span className="w-28">Add Citation</span>
          </Button>
          <Button
            size="sm"
            onClick={async () => {
              navigator.clipboard.writeText(newCitation.match);
              toast.success("Copied highlighted text to clipboard");
            }}
          >
            <Copy className="h-4 w-4" />
          </Button>
        </div>
      );
    }

    // Existing citation being edited
    return undefined;
  };

  useEffect(() => {
    if (docToView?.docId !== props.docId) {
      setDocToView({
        docId: props.docId,
      });
    }
  }, [props.docId]);

  useEffect(() => {
    if (!activeRequirement) {
      return;
    }

    if (activeRequirement.citations.length > 0) {
      setPageNumber(activeRequirement.citations[0].page);
    }
    setCitations((citations) =>
      citations.filter((citation) => citation.id !== HIGHLIGHTED_CITATION_ID)
    );
    setActiveCitationIndex(0);
    setEditingCitation(false);
  }, [activeRequirement]);

  const updateCitations = (newCitation?: PdfCitation) => {
    if (docModalOpen) {
      return;
    }

    const citationsDisplay: PdfCitation[] = [];

    if (newCitation) {
      const citationTooltip = getActiveCitationTooltip(newCitation);

      if (citationTooltip) {
        // We have a use for the new highlight
        citationsDisplay.push({
          ...newCitation,
          citationTooltip,
        });

        // Update new requirement to match the new highlight
        if (activeRequirement && activeRequirement.id === null) {
          activeRequirement.text = newCitation.match;
          activeRequirement.citations = [
            {
              id: null,
              page: pageNumber,
              text: newCitation.match,
              formatted_text: null,
              created_at: new Date().toISOString().slice(0, -1),
              user: null,
              start_index: null,
              match_index: null,
            },
          ];
          const revisedRequirements = [...requirements];
          setRequirements(revisedRequirements);
        }
      } else if (docToView?.docId) {
        // Add requirement upon new highlight
        addRequirement(docToView.docId, newCitation.page, newCitation);
        citationsDisplay.push({
          ...newCitation,
          citationTooltip: getNewRequirementTooltip(newCitation),
        });
      }
    }

    for (const [
      requirementIndex,
      requirement,
    ] of requirementsToDisplay.entries()) {
      for (const [citationIndex, citation] of requirement.citations.entries()) {
        const isCitationSelected =
          requirement.id === activeRequirementId &&
          citationIndex === activeCitationIndex;
        const isCitationEditing =
          isCitationSelected && editingCitation && newCitation;

        let pdfCitation: PdfCitation = {
          id: citation.id || `cit-${citation.text.slice(0, 14)}`,
          match: citation.text,
          exactMatch: false,
          match_index: citation.match_index || undefined,
          page: citation.page,
          className: isCitationSelected
            ? `bg-highlight-default`
            : requirement.id === activeRequirementId
              ? `bg-highlight-off`
              : `bg-highlight-inactive`,
          classNameOnHover: isCitationSelected ? "" : "bg-highlight-default",
          onClick:
            editing || editingCitation || activeCitationIndex === -1
              ? undefined
              : () => {
                  setRequirements((requirements) =>
                    requirements.filter((requirement) => !!requirement.id)
                  );
                  setActiveRequirementId(requirement.id);
                  setActiveCitationIndex(citationIndex);
                },
        };
        if (isCitationSelected && !isCitationEditing) {
          pdfCitation.citationTooltip = getActiveCitationTooltip(pdfCitation);
        }
        if (editing && requirement.id === null && newCitation) {
          // We don't want to display the default "ADD" citation
        } else {
          citationsDisplay.push(pdfCitation);
        }
      }
    }

    setCitations(citationsDisplay);
  };

  useEffect(() => {
    updateCitations();
  }, [
    requirementsToDisplay,
    activeRequirementId,
    activeCitationIndex,
    editingCitation,
  ]);

  useEffect(() => {
    if (
      viewType !== "list" ||
      !activeRequirementRef ||
      !activeRequirementRef.current
    ) {
      setRequirements((requirements) =>
        requirements.filter((requirement) => !!requirement.id)
      );

      return;
    }

    if (activeRequirementId !== null) {
      setSearchParams(
        (prev: URLSearchParams) => {
          prev.set("requirementId", activeRequirementId);
          prev.set("activeTab", "requirements");
          return prev;
        },
        {
          replace: true,
        }
      );
    } else {
      setSearchParams(
        (prev: URLSearchParams) => {
          prev.delete("requirementId");
          prev.set("activeTab", "requirements");
          return prev;
        },
        { replace: true }
      );
    }

    setActiveCitationIndex(0);
    scrollToElement(activeRequirementRef.current);
  }, [viewType, activeRequirementRef.current]);

  return (
    <DocWorkspace
      className="h-[calc(100vh-295px)]"
      docID={docToView?.docId}
      onCitationsUpdate={updateCitations}
      isDocModalOpen={docModalOpen}
      ref={ref}
    >
      <div className="space-y-4 pr-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="text-sm italic text-right">{`${requirementsToDisplay.length} Requirements Found`}</div>
            <SelectFilter
              title="Label"
              filterCounts={labelFilterCounts}
              activeFilter={labelFilter}
              setActiveFilter={setLabelFilter}
              nameFormatter={(name: string) =>
                name !== "None" ? tagToLabelMap[name as RequirementTag] : "None"
              }
            />
          </div>
        </div>
        <div className="w-[100%] space-y-3 overflow-y-auto h-[calc(100vh-255px)]">
          {requirementsToDisplay.map((requirement) => (
            <IndividualRequirementView
              key={requirement.id}
              requirement={requirement}
              docId={props.docId}
              actionItemRoutePrefix={props.actionItemRoutePrefix}
              activeRequirementId={activeRequirementId}
              onRequirementSaveClick={onSaveClick}
              onRequirementClick={onResourceClick}
              setRequirements={setRequirements}
              setEditing={setEditing}
              editing={editing}
              setExistingActionItem={setExistingActionItem}
              ref={
                requirement.id === activeRequirementId
                  ? activeRequirementRef
                  : null
              }
              setActiveRequirementId={setActiveRequirementId}
              existingActionItem={existingActionItem}
              handleDocModalChange={setDocModalOpen}
              handleAutoPopulate={handleAutoPopulateGenerator(
                props.targetDocTypes
              )}
              onDeleteCitation={onDeleteCitation}
            />
          ))}
        </div>
      </div>
    </DocWorkspace>
  );
});

const handleAutoPopulateGenerator =
  (targetDocTypes?: string[]) =>
  async (
    authInfo: UseAuthInfoProps,
    docId: string,
    requirement: Requirement,
    setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>
  ) => {
    setRequirements((prev) =>
      prev.map((req) =>
        req.id === requirement.id
          ? {
              ...req,
              auto_populating: true,
            }
          : req
      )
    );

    try {
      for await (const populated of autoPopulateRequirement(
        docId,
        requirement.id!,
        authInfo.accessToken ?? "",
        targetDocTypes
      )) {
        populated.auto_populating = true;
        setRequirements((prev) =>
          prev.map((req) => (req.id === populated.id ? populated : req))
        );
      }
    } catch (error: any) {
      console.error("There was an error auto-populating", error);
      toast.error("Unable to auto-populate");
    } finally {
      setRequirements((prev) =>
        prev.map((req) =>
          req.id === requirement.id
            ? {
                ...req,
                auto_populating: false,
              }
            : req
        )
      );
    }
  };
