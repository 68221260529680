import { useAuthInfo } from "@propelauth/react";
import { ListBulletIcon, TableIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Layout } from "../../components/Layout";
import { SearchResourceBar } from "../../components/Resource/SearchResource";
import { Button } from "../../shadcn/components/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { FeedDocType } from "../../types";
import { getFilterDocTypes } from "../../utils/apiCalls";
import { RegulatoryDocFeedView } from "./RegulatoryDocFeedView";
import { RegulatoryDocTableView } from "./RegulatoryDocTableView";

const RegluatoryDocViewViewToggle = (props: {
  viewType: "list" | "table";
  setViewType: (viewType: "list" | "table") => void;
}) => {
  return (
    <div className="flex items-center space-x-1 bg-gray-200 p-1 rounded-md">
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <Button
            size="icon"
            variant={props.viewType === "list" ? "outline" : "ghost"}
            onClick={() => {
              if (props.viewType !== "list") {
                props.setViewType("list");
              }
            }}
          >
            <ListBulletIcon className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>View Regulatory Guidance as a List</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <Button
            size="icon"
            variant={props.viewType === "table" ? "outline" : "ghost"}
            onClick={() => {
              if (props.viewType !== "table") {
                props.setViewType("table");
              }
            }}
          >
            <TableIcon className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>View Regulatory Guidance as a Table</TooltipContent>
      </Tooltip>
    </div>
  );
};

export const RegulatoryDocSummaryView = () => {
  const authInfo = useAuthInfo();
  const [agencyFilters, setAgencyFilters] = useState<string[]>(() =>
    JSON.parse(localStorage.getItem("reg-feed-filter") || "[]")
  );
  const [statusFilters, setStatusFilters] = useState<string[]>(() =>
    JSON.parse(localStorage.getItem("reg-feed-status-filter") || "[]")
  );
  const [relevantFilters, setRelevantFilters] = useState<string[]>(() =>
    JSON.parse(localStorage.getItem("relevant-filter") || "[]")
  );
  const [feedDocTypes, setFeedDocTypes] = useState<FeedDocType[]>([]);
  const [viewType, setViewType] = useState<"list" | "table">(
    () =>
      (localStorage.getItem("reg-feed-view-type") as "list" | "table") || "list"
  );
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("reg-feed-filter", JSON.stringify(agencyFilters));
  }, [agencyFilters]);

  useEffect(() => {
    localStorage.setItem("reg-feed-view-type", viewType);
  }, [viewType]);

  useEffect(() => {
    localStorage.setItem(
      "reg-feed-status-filter",
      JSON.stringify(statusFilters)
    );
  }, [statusFilters]);

  useEffect(() => {
    getFilterDocTypes(authInfo.accessToken ?? null).then((feedDocTypes) => {
      if (feedDocTypes !== null) {
        setFeedDocTypes(feedDocTypes);
        const storedAgencyFilters = localStorage.getItem("reg-feed-filter");
        if (storedAgencyFilters) {
          const parsedFilters = JSON.parse(storedAgencyFilters);
          setAgencyFilters(
            parsedFilters.length > 0
              ? parsedFilters
              : feedDocTypes
                  .filter((docType) => docType.enabled)
                  .map((docType) => docType.id)
          );
        } else {
          setAgencyFilters(
            feedDocTypes
              .filter((docType) => docType.enabled)
              .map((docType) => docType.id)
          );
        }
      } else {
        toast.error("Something went wrong fetching filter doc types");
      }
    });
    setRelevantFilters(
      JSON.parse(localStorage.getItem("relevant-filter") || "[]")
    );
  }, []);

  return (
    <Layout pageName="Regulatory Guidance">
      <div className="space-y-1">
        <div className="flex items-center space-x-2 justify-between mb-4">
          <SearchResourceBar
            docTypeIds={feedDocTypes
              .filter((docType) => docType.enabled)
              .map((docType) => docType.id)}
            placeholder="Search Regulations..."
            regulatoryDocIdsRequired={true}
            onItemSelect={(result) => {
              navigate(`/regulatory-doc/overview/${result.regulatory_doc_id}`);
            }}
            hideAtlasWidget={true}
            className="w-full"
            docNameOnly={true}
          />
          <RegluatoryDocViewViewToggle
            viewType={viewType}
            setViewType={setViewType}
          />
        </div>
        {viewType === "list" ? (
          <RegulatoryDocFeedView
            agencyFilters={agencyFilters}
            relevantFilters={relevantFilters}
            feedDocTypes={feedDocTypes}
            setAgencyFilters={setAgencyFilters}
            setRelevantFilters={setRelevantFilters}
          />
        ) : (
          <RegulatoryDocTableView
            agencyFilter={agencyFilters}
            setAgencyFilter={setAgencyFilters}
            relevantFilters={relevantFilters}
            setRelevantFilters={setRelevantFilters}
            feedDocTypes={feedDocTypes}
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters}
          />
        )}
      </div>
    </Layout>
  );
};
