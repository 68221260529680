import { Copy } from "lucide-react";
import { toast } from "sonner";
import { Button } from "../../shadcn/components/button";
import { Requirement } from "../../types";
import { RedlinedPassageView } from "./RedlinedPassageView";

const closeOpenTags = (text: string) => {
  const openStrikethrough =
    (text.match(/<strikethrough>/g) || []).length >
    (text.match(/<\/strikethrough>/g) || []).length;
  const openUnderline =
    (text.match(/<underline>/g) || []).length >
    (text.match(/<\/underline>/g) || []).length;

  return (
    text +
    (openStrikethrough ? "</strikethrough>" : "") +
    (openUnderline ? "</underline>" : "")
  );
};

export const RequirementTextDisplay = (props: {
  text: string;
  maxWords?: number;
}) => {
  const words = props.text.split(" ");
  const endEllipsis =
    props.maxWords && words.length > props.maxWords ? "..." : "";
  const truncatedText = words.slice(0, props.maxWords).join(" ");
  const finalText = closeOpenTags(truncatedText) + endEllipsis;

  return <RedlinedPassageView passage={finalText} />;
};

export const RequirementDisplay = (props: { requirement: Requirement }) => {
  const handleRequirementCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(props.requirement.text);
    toast.success("Copied requirement to clipboard");
  };
  return (
    <div className="flex">
      <span className="text-gray-400 whitespace-nowrap mr-2">
        pg {props.requirement.citations[0]?.page ?? 1} |
      </span>
      <RequirementTextDisplay text={props.requirement.text} maxWords={20} />
      <Button
        variant="ghost"
        size="default"
        className=""
        onClick={handleRequirementCopy}
      >
        <Copy className="mr-1 h-4 w-4" />
        Copy
      </Button>
    </div>
  );
};
