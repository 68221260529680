import { PDFDocumentProxy } from "pdfjs-dist";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { PdfLazyLoaderRef } from "../LazyLoader";
import { PdfCitation } from "../PdfHighlighter/types";
import { filterMapByKeyRange } from "../util";

export interface PageDimensions {
  width: number;
  height: number;
  top: number;
}

type DocumentStatus = "UNSET" | "LOADING" | "ERROR" | "READY" | "ACCESS_DENIED";

type PdfProviderProps = {
  pageNumber?: number;
  citations?: PdfCitation[];
  onUpdateCitation?: (citation: PdfCitation) => void;
  onUpdatePageNumber?: (page: number) => void;
  onUpdateNumPages?: (pages: number) => void;
  parentModalRef?: React.RefObject<HTMLDivElement>;
};
interface PdfContextProps {
  pdfDocument: PDFDocumentProxy | null;
  setPdfDocument: (pdf: PDFDocumentProxy | null) => void;
  documentStatus: DocumentStatus;
  setdocumentStatus: (status: DocumentStatus) => void;
  onDocumentLoad: () => void;
  overscanCount: number;
  citations: PdfCitation[];
  updateCitation: (citation: PdfCitation) => void;
  numPages: number;
  setNumPages: (pages: number) => void;
  scale: number;
  setScale: (scale: number) => void;
  loadedPageStartIndex: number;
  setLoadedPageStartIndex: (page: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  setCurrentPageAndScroll: (page: number) => void;
  loadedPageEndIndex: number;
  setLoadedPageEndIndex: (page: number) => void;
  pageDimensions: Map<number, PageDimensions>;
  setPageDimensions: (dimensionsMap: Map<number, PageDimensions>) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  listRef: React.RefObject<PdfLazyLoaderRef>;
  pageRefs: React.RefObject<Map<number, HTMLDivElement>>;
  canvasRefs: React.RefObject<Map<number, HTMLCanvasElement>>;
  highlightLayerRefs: React.RefObject<Map<number, HTMLCanvasElement>>;
  textLayerRefs: React.RefObject<Map<number, HTMLDivElement>>;
  scrollToPage: (pageIndex: number) => void;
  scrollOffset: number;
  setScrollOffset: React.Dispatch<React.SetStateAction<number>>;
  citationToScroll: string | null;
  setCitationToScroll: (citation: string | null) => void;
  parentModalRef?: React.RefObject<HTMLDivElement>;
}

const PdfContext = createContext<PdfContextProps | undefined>(undefined);

export const PdfProvider: React.FC<PropsWithChildren & PdfProviderProps> = ({
  children,
  pageNumber,
  citations: citationsProps,
  onUpdateCitation = () => {},
  onUpdatePageNumber = () => {},
  onUpdateNumPages = () => {},
  parentModalRef,
}) => {
  //DOCUMENT
  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy | null>(null);
  const [documentStatus, setdocumentStatus] = useState<DocumentStatus>("UNSET");
  const [pageDimensions, setPageDimensions] = useState<
    Map<number, PageDimensions>
  >(new Map());
  const [scale, setScale] = useState<number>(1.0);
  const [numPages, setNumPages] = useState<number>(0);
  const [citations, setCitations] = useState<PdfCitation[]>(
    citationsProps || []
  );
  //PAGE AND VIRTUAL LIST
  const [currentPage, setCurrentPage] = useState<number>(pageNumber || 0);
  const [overscanCount, setOverscanCount] = useState<number>(3);
  const [loadedPageStartIndex, setLoadedPageStartIndex] = useState<number>(0);
  const [loadedPageEndIndex, setLoadedPageEndIndex] = useState<number>(0);
  const [scrollOffset, setScrollOffset] = useState<number>(0);
  const [citationToScroll, setCitationToScroll] = useState<string | null>(null);

  //REFS
  const containerRef = useRef<HTMLDivElement>(null);
  const pageRefs = useRef<Map<number, HTMLDivElement>>(new Map());
  const canvasRefs = useRef<Map<number, HTMLCanvasElement>>(new Map());
  const textLayerRefs = useRef<Map<number, HTMLDivElement>>(new Map());
  const highlightLayerRefs = useRef<Map<number, HTMLCanvasElement>>(new Map());
  const listRef = useRef<PdfLazyLoaderRef>(null);

  /**
   * CALLBACK WRAPPERS
   *
   * UPDATE STATE WRAPPERS THAT HAVE CALLBACKS TO PARENT COMPONENT
   */
  const updateCitationAndCallback = (pdfCitation: PdfCitation) => {
    onUpdateCitation(pdfCitation);
  };
  const updateCurrentPageAndCallback = (newPageNum: number) => {
    if (documentStatus === "READY") {
      // console.log(`setting pageRef to ${newPageNum} and callback`);
      if (newPageNum !== currentPage) {
        setCurrentPage(newPageNum);
      }
      if (newPageNum !== pageNumber) {
        onUpdatePageNumber(newPageNum);
      }
    }
  };

  const updateNumPagesAndCallback: React.Dispatch<
    React.SetStateAction<number>
  > = (value) => {
    if (typeof value === "function") {
      setNumPages((prevNumPages) => {
        const newPageNum = value(prevNumPages);
        onUpdateNumPages(newPageNum);
        return newPageNum;
      });
    } else {
      setNumPages(value);
      onUpdateNumPages(value);
    }
  };
  /**
   * Page controllers
   */

  //Scroll to page when props are updated
  useEffect(() => {
    if (
      typeof pageNumber === "number" &&
      documentStatus === "READY" &&
      pageNumber !== currentPage
    ) {
      setCurrentPageAndScroll(pageNumber);
    } else {
      pageNumber && setCurrentPage(pageNumber);
    }
  }, [pageNumber, documentStatus]);

  const onDocumentLoad = () => {
    //Could have callback later
  };

  const setCurrentPageAndScroll = (page: number) => {
    scrollToPage(page);
    setCurrentPage(page);
  };

  const scrollToPage = (pageIndex: number) => {
    if (listRef.current) {
      listRef.current.scrollToPage(pageIndex);
    } else {
      // console.log(`document not ready`, documentStatus, listRef.current);
    }
  };

  // Function to clear cached refs (pages, highlights, text layers)
  const clearCachedRefs = () => {
    // console.log("returning clearing cache cuz new scale");
    pageRefs.current.clear();
    textLayerRefs.current.clear();
    canvasRefs.current.clear();
    highlightLayerRefs.current.clear();
  };

  const setScaleAndClearRefs = useCallback(
    (newScale: number) => {
      if (newScale !== scale) {
        clearCachedRefs();
        setScale(newScale);
      }
    },
    [scale, setScale, clearCachedRefs]
  );

  //Clear refs that are based on scale
  useEffect(() => {
    clearCachedRefs();
  }, [scale, pdfDocument]);

  useEffect(() => {
    pageRefs.current = new Map();
  }, [numPages]);

  useEffect(() => {
    const min = currentPage - overscanCount;
    const max = currentPage + overscanCount;
    filterMapByKeyRange(textLayerRefs.current, min, max);
    filterMapByKeyRange(pageRefs.current, min, max);
    filterMapByKeyRange(highlightLayerRefs.current, min, max);
  }, [currentPage]);

  useEffect(() => {
    if (citationsProps) {
      setCitations(citationsProps);
    }
  }, [citationsProps]);
  return (
    <PdfContext.Provider
      value={{
        overscanCount,
        citations,
        updateCitation: updateCitationAndCallback,
        documentStatus,
        setdocumentStatus,
        onDocumentLoad,
        pdfDocument,
        setPdfDocument,
        numPages,
        setNumPages: updateNumPagesAndCallback,
        scale,
        setScale: setScaleAndClearRefs,
        currentPage,
        setCurrentPage: updateCurrentPageAndCallback,
        setCurrentPageAndScroll,
        scrollOffset,
        setScrollOffset,
        loadedPageStartIndex,
        setLoadedPageStartIndex,
        loadedPageEndIndex,
        setLoadedPageEndIndex,
        pageDimensions,
        setPageDimensions,
        containerRef,
        listRef,
        pageRefs,
        canvasRefs,
        highlightLayerRefs,
        textLayerRefs,
        scrollToPage,
        citationToScroll,
        setCitationToScroll,
        parentModalRef,
      }}
    >
      {children}
    </PdfContext.Provider>
  );
};

export const usePdf = (): PdfContextProps => {
  const context = useContext(PdfContext);
  if (!context) {
    throw new Error("usePdf must be used within a PdfProvider");
  }
  return context;
};
