import { useAuthInfo } from "@propelauth/react";
import { Copy, TriangleAlert } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { useModalContext } from "../../contexts/ActiveModalContext";
import { Button } from "../../shadcn/components/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import {
  auditFactorAssessment,
  AuditFactorAssessment,
  auditFactorStatus,
  AuditFactorStatus,
  auditToolFunctionalAreas,
  Factor,
} from "../../types";
import { getAuditToolSharepointFolderInfo } from "../../utils/apiCalls";

export const AuditFactorAssessmentBadge = ({
  status,
}: {
  status: AuditFactorAssessment | undefined;
}) => {
  if (!status) {
    return <></>;
  }
  const getStatusColor = (status: AuditFactorAssessment | undefined) => {
    switch (status) {
      case "met":
        return "bg-green-100 text-green-800 border-green-200";
      case "not_met":
        return "bg-red-100 text-red-800 border-red-200";
      case "partially_met":
        return "bg-yellow-100 text-yellow-800 border-yellow-200";
      case "na":
        return "bg-gray-100 text-gray-800 border-gray-200";
      default:
        return "bg-gray-100 text-gray-800 border-gray-200";
    }
  };

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${getStatusColor(status)}`}
    >
      {status &&
      auditFactorAssessment[status as keyof typeof auditFactorAssessment]
        ? auditFactorAssessment[status as keyof typeof auditFactorAssessment]
        : "Todo"}
    </span>
  );
};

export const SharepointUrlPopover = () => {
  const { auditToolId } = useParams<{
    auditToolId: string;
  }>();
  const authInfo = useAuthInfo();
  const [sharepointUrl, setSharepointUrl] = useState<string | null>(null);
  const { activeModalRef } = useModalContext();
  const handleGetSharepointUrl = async () => {
    if (!auditToolId) {
      toast.error("No audit tool selected");
      return;
    }
    try {
      const response = await getAuditToolSharepointFolderInfo(
        auditToolId,
        authInfo.accessToken ?? null
      );
      const url = response.webUrl;
      setSharepointUrl(url);
    } catch (error) {
      // toast.error("Error getting sharepoint url");
    }
  };
  useEffect(() => {
    handleGetSharepointUrl();
  }, [auditToolId]);
  useEffect(() => {
    handleGetSharepointUrl();
  }, []);
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" disabled={!sharepointUrl}>
          Upload Documents
        </Button>
      </PopoverTrigger>
      <PopoverContent
        side="right"
        className="flex flex-col gap-4 p-4 w-[400px]"
        container={activeModalRef?.current}
      >
        <div className="text-sm text-gray-600">
          <p className="mb-2">To upload documents for this audit:</p>
          <ol className="list-decimal list-inside space-y-1">
            <li>Click the Sharepoint link below</li>
            <li>
              Select the appropriate folder based on the step of the audit
            </li>
            <li>Upload your files using the Sharepoint interface</li>
            <li>
              Every time you start to audit, our system will check for new
              files.
            </li>
          </ol>
        </div>
        <div className="flex items-center gap-2">
          <a
            href={sharepointUrl ?? "#"}
            target="_blank"
            rel="noopener noreferrer"
            className="flex-1 inline-flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            <span>Open Sharepoint</span>
          </a>
          <Tooltip>
            <TooltipTrigger>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  if (sharepointUrl) {
                    navigator.clipboard.writeText(sharepointUrl);
                    toast.success("Copied to clipboard!");
                  }
                }}
                className="h-8 w-8 flex-shrink-0 hover:bg-gray-100"
              >
                <Copy className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Copy to clipboard</TooltipContent>
          </Tooltip>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export const AuditFactorStatusBadge = ({
  status,
}: {
  status: AuditFactorStatus | undefined;
}) => {
  const getStatusColor = (status: AuditFactorStatus | undefined) => {
    switch (status) {
      case "todo":
        return "bg-red-100 text-red-800 border-red-200";
      case "review":
        return "bg-yellow-100 text-yellow-800 border-yellow-200";
      case "done":
        return "bg-green-100 text-green-800 border-green-200";
      default:
        return "bg-red-100 text-red-800 border-red-200";
    }
  };
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${getStatusColor(status)}`}
    >
      {status && auditFactorStatus[status as keyof typeof auditFactorStatus]
        ? auditFactorStatus[status as keyof typeof auditFactorStatus]
        : "Todo"}
    </span>
  );
};

export const MarkdownToJSX = ({ markdown }: { markdown: string }) => {
  return markdown.split("\n").map((line, i, arr) => (
    <React.Fragment key={i}>
      {line}
      {i < arr.length - 1 && <br />}
    </React.Fragment>
  ));
};

export const FunctionalAreaSelect = ({
  functionalArea,
  handleChangeFunctionalArea,
  placeholder,
}: {
  functionalArea: string;
  handleChangeFunctionalArea: (functionalArea: string) => void;
  placeholder?: string;
}) => {
  return (
    <Select value={functionalArea} onValueChange={handleChangeFunctionalArea}>
      <SelectTrigger className="w-full">
        <SelectValue
          placeholder={placeholder || "Select a functional area..."}
        />
      </SelectTrigger>
      <SelectContent>
        {auditToolFunctionalAreas.map((area, index) => (
          <SelectItem key={`${area}-${index}`} value={area}>
            {area}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

interface MultiLevelSelectProps {
  factors: Factor[];
  onSelect: (source: string, functionalArea: string) => void;
}

const MultiLevelSelect: React.FC<MultiLevelSelectProps> = ({
  factors,
  onSelect,
}) => {
  const [selectedSource, setSelectedSource] = useState<string | null>(null);
  const [selectedFunctionalArea, setSelectedFunctionalArea] = useState<
    string | null
  >(null);

  const uniqueSources = Array.from(
    new Set(factors.map((factor) => factor.source))
  );
  const uniqueFunctionalAreas = selectedSource
    ? Array.from(
        new Set(
          factors
            .filter((factor) => factor.source === selectedSource)
            .map((factor) => factor.functional_area)
        )
      )
    : [];

  const handleSourceChange = (source: string) => {
    setSelectedSource(source);
    setSelectedFunctionalArea(null); // Reset functional area when source changes
  };

  const handleFunctionalAreaChange = (functionalArea: string) => {
    setSelectedFunctionalArea(functionalArea);
    onSelect(selectedSource!, functionalArea);
  };

  return (
    <div className="flex flex-row items-end gap-4">
      <div className="flex flex-col flex-1">
        <label className="text-lg font-bold">Source</label>
        <Select value={selectedSource ?? ""} onValueChange={handleSourceChange}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a source..." />
          </SelectTrigger>
          <SelectContent>
            {uniqueSources.map((source) => (
              <SelectItem key={source} value={source}>
                {source}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="flex flex-col flex-1">
        <label className="text-lg font-bold">Functional Area</label>
        <Select
          value={selectedFunctionalArea ?? ""}
          onValueChange={handleFunctionalAreaChange}
          disabled={!selectedSource}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a functional area..." />
          </SelectTrigger>
          <SelectContent>
            {uniqueFunctionalAreas.map((area) => (
              <SelectItem key={area || "undefined"} value={area || "N/A"}>
                {area || "N/A"}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export default MultiLevelSelect;

export const TabButton = ({
  label,
  onClick,
  selected,
  ...props
}: {
  label: string;
  onClick: () => void;
  selected: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={`px-4 py-2 ${
        selected ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500"
      }`}
      onClick={onClick}
      {...props}
    >
      {label}
    </button>
  );
};

export const ExpandableContent = ({
  description,
  explanation,
}: {
  description: string;
  explanation: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="relative">
      <div
        className={`relative space-y-4 ${!isExpanded ? "max-h-[4.3em] overflow-hidden" : "pb-12"}`}
      >
        <div className="text-sm">{description}</div>
        {explanation && (
          <div className="text-sm space-y-2 text-gray-600">
            <p className="font-bold">Explanation:</p>
            <MarkdownToJSX markdown={explanation} />
          </div>
        )}
      </div>
      <button
        className={`absolute bottom-0 left-0 right-0 ${!isExpanded ? "h-full" : "py-1"} w-full text-sm text-blue-600 hover:bg-white/70 hover:text-blue-700 flex items-end justify-center gap-2 rounded-b-lg transition-all duration-200 font-medium not-prose ${
          !isExpanded
            ? "bg-gradient-to-t from-white via-white/70 to-transparent"
            : "bg-gradient-to-b from-white/50 to-white/90"
        }`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className={`${!isExpanded ? "mb-1" : ""}`}>
          {isExpanded ? "Show Less ↑" : "Show More ↓"}
        </span>
      </button>
    </div>
  );
};

export const BadgeList = ({
  text,
  truncate = true,
}: {
  text: string;
  truncate?: boolean;
}) => {
  if (!text) return null;

  const items = text.split("\n").filter((item) => item.trim());

  return items
    .sort((a, b) => b.length - a.length)
    .map((item, index) => {
      const isTruncated = truncate && item.length > 25;
      const displayText = isTruncated ? `${item.slice(0, 22)}...` : item;
      return (
        <Tooltip key={index}>
          <TooltipTrigger>
            <div className="inline-block align-middle text-black bg-gray-200 rounded-full px-2 py-0.5 hover:bg-gray-200 hover:text-black">
              {displayText.trim()}
            </div>
          </TooltipTrigger>
          {isTruncated && (
            <TooltipContent>
              <div>{item.trim()}</div>
            </TooltipContent>
          )}
        </Tooltip>
      );
    });
};

export const CriticalFactorBadge = ({ message }: { message: string }) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <TriangleAlert className="text-red-500 h-4 w-4" />
      </TooltipTrigger>
      <TooltipContent>
        <div className="text-xs text-gray-500">{message}</div>
      </TooltipContent>
    </Tooltip>
  );
};
