import { DatePicker } from "@mui/x-date-pickers";
import { useAuthInfo } from "@propelauth/react";
import dayjs from "dayjs";
import { Loader2, Plus } from "lucide-react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { DateDisplay, DateRangeDisplay } from "../../components/DateDisplay";
import { Layout } from "../../components/Layout";
import { LoadingView } from "../../components/Loading";
import { DataTable, HeaderCell } from "../../components/Table";
import { useModalContext } from "../../contexts/ActiveModalContext";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "../../shadcn/components/button";
import { Checkbox } from "../../shadcn/components/checkbox";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import { Input } from "../../shadcn/components/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import { AuditTool } from "../../types";
import {
  createAuditTool,
  CreateAuditToolRequest,
  deleteAuditTool,
  getAuditTools,
} from "../../utils/apiCalls";

const MultiSelectPopover = ({
  entities,
  selectedEntities,
  onSelect,
}: {
  entities: string[];
  selectedEntities: string[];
  onSelect: (selectedAreas: string[]) => void;
}) => {
  const { activeModalRef } = useModalContext();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button>Select Functional Areas</Button>
      </PopoverTrigger>
      <PopoverContent
        container={activeModalRef?.current}
        side="left"
        align="center"
      >
        <div className="flex flex-col space-y-2 p-4">
          {entities.map((area) => (
            <label key={area} className="flex items-center">
              <Checkbox
                checked={selectedEntities.includes(area)}
                className="mr-2"
                onClick={(e) => {
                  const newAreas = selectedEntities.includes(area)
                    ? selectedEntities.filter((a) => a !== area)
                    : [...selectedEntities, area];
                  onSelect(newAreas);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
              {area}
            </label>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export const AuditToolView = () => {
  const authInfo = useAuthInfo();
  const [audits, setAudits] = useState<AuditTool[]>([]);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const [isCreateAuditLoading, setIsCreateAuditLoading] = useState(false);
  const navigate = useNavigate();
  const { simpleUsers } = useContext(UserContext);
  const [delegateSearch, setDelegateSearch] = useState<string>("");

  useEffect(() => {
    setAuditsLoading(true);
    getAuditTools(authInfo.accessToken ?? null).then((audits) => {
      if (audits) {
        setAudits(audits);
      } else {
        toast.error("Failed to fetch audits");
      }
      setAuditsLoading(false);
    });
  }, []);

  const handleCreateAudit = async (request: CreateAuditToolRequest) => {
    setIsCreateAuditLoading(true);
    try {
      const res = await createAuditTool(request, authInfo.accessToken ?? null);
      if (res) {
        toast.success("Audit tool created successfully");
        navigate(`/delegate-audit/${res.id}`);
      } else {
        toast.error("Failed to create audit tool");
      }
    } catch (error) {
      toast.error("Failed to create audit tool");
    } finally {
      setIsCreateAuditLoading(false);
    }
  };

  const columns: any[] = useMemo(
    () => [
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Name" />;
        },
        cell: ({ row }: any) => {
          return (
            <Link
              to={`/delegate-audit/${row.original.id}`}
              className="text-blue-500 hover:underline"
            >
              {row.original.name}
            </Link>
          );
        },
        id: "name",
        accessorKey: "name",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Delegate" />;
        },
        cell: ({ row }: any) => {
          return <div>{row.original.delegate || "N/A"}</div>;
        },
        id: "delegate",
        accessorKey: "delegate",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Owner" />;
        },
        cell: ({ row }: any) => {
          return (
            <div>
              {simpleUsers.find((user) => user.id === row.original.owner_id)
                ?.first_name || "N/A"}
            </div>
          );
        },
        id: "owner",
        accessorKey: "owner_id",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Audit Period" />;
        },
        cell: ({ row }: any) => (
          <DateRangeDisplay
            startDate={row.original.audit_start_date}
            endDate={row.original.audit_end_date}
          />
        ),
        id: "audit_period",
        accessorKey: "audit_start_date",
        sortingFn: (rowA: any, rowB: any) => {
          const a = rowA.original.audit_start_date
            ? new Date(rowA.original.audit_start_date)
            : new Date(0);
          const b = rowB.original.audit_start_date
            ? new Date(rowB.original.audit_start_date)
            : new Date(0);
          return a.getTime() - b.getTime();
        },
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Work Period" />;
        },
        cell: ({ row }: any) => (
          <DateRangeDisplay
            startDate={row.original.audit_work_start_date}
            endDate={row.original.audit_work_end_date}
          />
        ),
        id: "work_period",
        accessorKey: "audit_work_start_date",
        sortingFn: (rowA: any, rowB: any) => {
          const a = rowA.original.audit_work_start_date
            ? new Date(rowA.original.audit_work_start_date)
            : new Date(0);
          const b = rowB.original.audit_work_start_date
            ? new Date(rowB.original.audit_work_start_date)
            : new Date(0);
          return a.getTime() - b.getTime();
        },
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Created At" />;
        },
        cell: ({ row }: any) => <DateDisplay date={row.original.created_at} />,
        id: "created_at",
        accessorKey: "created_at",
        sortingFn: (rowA: any, rowB: any) => {
          const a = rowA.original.created_at
            ? new Date(rowA.original.created_at)
            : new Date(0);
          const b = rowB.original.created_at
            ? new Date(rowB.original.created_at)
            : new Date(0);
          return a.getTime() - b.getTime();
        },
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName=" " />;
        },
        cell: ({ row }: any) => {
          return (
            <Button
              variant="destructive"
              onClick={async (e) => {
                e.preventDefault();
                if (
                  window.confirm(
                    "Are you sure you want to delete this audit tool?"
                  )
                ) {
                  const res = await deleteAuditTool(
                    row.original.id,
                    authInfo.accessToken ?? null
                  );
                  if (res) {
                    toast.success("Audit tool deleted successfully");
                    setAudits(audits.filter((a) => a.id !== row.original.id));
                  } else {
                    toast.error("Failed to delete audit tool");
                  }
                }
              }}
            >
              Delete
            </Button>
          );
        },
        id: "actions",
        enableSorting: false,
      },
    ],
    [simpleUsers, audits]
  );

  const filteredAudits = audits.filter(
    (audit) =>
      !delegateSearch ||
      audit.delegate?.toLowerCase().includes(delegateSearch.toLowerCase())
  );

  return (
    <Layout pageName="Delegate Audit">
      <div className="max-w-5xl mx-auto px-4 mb-10">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Delegate Audit Tools
        </h2>
        <div className="mb-2 flex justify-between items-center gap-4">
          <Input
            placeholder="Filter by delegate..."
            value={delegateSearch}
            onChange={(e) => setDelegateSearch(e.target.value)}
            className="max-w-xs"
          />
          <Dialog>
            <DialogTrigger asChild>
              <Button className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2.5 px-4 rounded-lg transition-colors duration-200 shadow-sm hover:shadow-md group">
                <span className="flex items-center justify-center gap-2">
                  <Plus className="w-5 h-5" />
                  Create Audit Tool
                </span>
              </Button>
            </DialogTrigger>
            <AuditToolCreateDialog
              onSubmit={handleCreateAudit}
              isLoading={isCreateAuditLoading}
            />
          </Dialog>
        </div>
        {auditsLoading ? (
          <LoadingView customText="Loading audits..." />
        ) : audits.length === 0 ? (
          <div className="text-center text-gray-500 py-8">
            No audit tools found
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={filteredAudits}
            totalRecordCount={filteredAudits.length}
            paginationParams={{
              pageSize: 50,
              pageIndex: 0,
            }}
          />
        )}
      </div>
    </Layout>
  );
};

const AuditToolCreateDialog = ({
  onSubmit,
  isLoading,
}: {
  onSubmit: (request: CreateAuditToolRequest) => void;
  isLoading: boolean;
}) => {
  const [auditName, setAuditName] = useState("");
  const [auditDelegate, setAuditDelegate] = useState("");
  const [auditScope, setAuditScope] = useState<string[]>([]);
  const { setActiveModalRef } = useModalContext();
  const createAuditModalRef = useRef<HTMLDivElement>(null);
  const [auditStartDate, setAuditStartDate] = useState<Date | undefined>(
    undefined
  );
  const [auditEndDate, setAuditEndDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (createAuditModalRef.current) {
      setActiveModalRef(createAuditModalRef);
    }
  }, [createAuditModalRef, setActiveModalRef]);

  return (
    <DialogContent
      ref={createAuditModalRef}
      onOpenAutoFocus={() => {
        if (createAuditModalRef.current) {
          setActiveModalRef(createAuditModalRef);
        }
      }}
    >
      <DialogTitle>Create Audit Tool</DialogTitle>
      <div className="flex flex-col space-y-2">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (
              auditName &&
              auditDelegate &&
              // auditScope.length > 0 &&
              auditStartDate &&
              auditEndDate
            ) {
              onSubmit({
                name: auditName,
                delegate: auditDelegate,
                scope: auditScope.join(","),
                audit_start_date: auditStartDate.toISOString(),
                audit_end_date: auditEndDate.toISOString(),
              });
            } else {
              toast.error("Please fill in all fields");
            }
          }}
        >
          <div className="space-y-4">
            <div>
              <label htmlFor="auditName">Audit Name</label>
              <Input
                type="text"
                id="auditName"
                value={auditName}
                onChange={(e) => setAuditName(e.target.value)}
                placeholder="Enter audit name"
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label htmlFor="auditDelegate">Audit Delegate</label>
              <Input
                type="text"
                id="auditDelegate"
                value={auditDelegate}
                onChange={(e) => setAuditDelegate(e.target.value)}
                placeholder="Enter audit delegate"
                required
                disabled={isLoading}
              />
            </div>
            <div className="space-y-2">
              {/* <MultiSelectPopover
                onSelect={(areas) => {
                  setAuditScope(areas);
                }}
                entities={auditToolFunctionalAreas}
                selectedEntities={auditScope}
              /> */}
              <div className="flex flex-wrap gap-2">
                {auditScope.map((scope) => (
                  <span
                    key={scope}
                    className="px-2 py-1 text-sm bg-gray-200 rounded-full"
                  >
                    {scope}
                  </span>
                ))}
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="auditStartDate">Audit Lookback Period</label>
              <div className="flex items-center gap-4">
                <DatePicker
                  value={auditStartDate ? dayjs(auditStartDate) : null}
                  onChange={(value) =>
                    setAuditStartDate(value?.toDate() ?? undefined)
                  }
                  disableOpenPicker={true}
                />
                <DatePicker
                  value={auditEndDate ? dayjs(auditEndDate) : null}
                  onChange={(value) =>
                    setAuditEndDate(value?.toDate() ?? undefined)
                  }
                  disableOpenPicker={true}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between mt-4">
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Creating..." : "Create"}
            </Button>
            {isLoading && (
              <div className="flex flex-row items-center gap-2 text-sm text-gray-500">
                <Loader2 className="animate-spin h-4 w-4" />
                Creating Audit Tool and Sharepoint Folders
              </div>
            )}
          </div>
        </form>
      </div>
    </DialogContent>
  );
};
