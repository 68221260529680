import React, { PropsWithChildren, forwardRef } from "react";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../shadcn/components/resizable";
import { DocViewerCitation } from "./DocViewer";
import { PdfCitation } from "./PdfViewer/PdfHighlighter/types";

export type DocWorkspaceProps = PropsWithChildren<{
  className?: string;
  docID?: string;
  onCitationsUpdate?: (citations: PdfCitation) => void;
  parentModalRef?: React.RefObject<HTMLDivElement>;
  placeholder?: React.ReactNode;
  defaultSize?: number;
  isResizable?: boolean;
  isDocModalOpen?: boolean;
}>;

export const DocWorkspace = forwardRef<HTMLDivElement, DocWorkspaceProps>(
  (
    {
      children,
      className,
      docID,
      onCitationsUpdate,
      isDocModalOpen,
      parentModalRef,
      placeholder = null,
      defaultSize = 50,
      isResizable = true,
    },
    ref
  ) => (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel
        defaultSize={100 - defaultSize}
        minSize={25}
        maxSize={75}
        id="doc-view-panel"
        order={2}
      >
        {docID && isDocModalOpen !== true ? (
          <DocViewerCitation
            ref={ref}
            className={className ?? "h-[calc(100vh-300px)]"}
            hideAtlasWidget={true}
            docId={docID}
            onCitationsUpdate={onCitationsUpdate}
            parentModalRef={parentModalRef}
          />
        ) : (
          placeholder
        )}
      </ResizablePanel>
      {isResizable && <ResizableHandle withHandle={true} className="mx-4" />}
      <ResizablePanel
        defaultSize={defaultSize}
        minSize={25}
        maxSize={75}
        id="resource-panel"
        order={3}
      >
        {children}
      </ResizablePanel>
    </ResizablePanelGroup>
  )
);
