import { useAuthInfo } from "@propelauth/react";
import { useContext, useEffect, useRef, useState } from "react";
import { DocViewerCitation } from "../../components/DocViewer";
import { PdfCitation } from "../../components/PdfViewer/PdfHighlighter/types";
import { useModalContext } from "../../contexts/ActiveModalContext";
import { AuditToolContext } from "../../contexts/AuditToolContext";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { Sheet, SheetContent, SheetTitle } from "../../shadcn/components/sheet";
import { FileAuditMetadata } from "../../types";
import { getFileAuditMetadata, loadAuditToolDocs } from "../../utils/apiCalls";
import { localizeDate } from "../../utils/format";
import { FunctionalAreaSelect } from "./components";
import FileAuditToolDisplay from "./FileAuditToolDisplay";
import { mockFileAuditMetadata } from "./mockData";
export type AuditedFile = {
  id: string;
  doc?: {
    docId: string;
    docName?: string;
  };
  metadata: Record<
    string,
    {
      value: any;
      citation?: PdfCitation;
    }
  >;
};
export const AuditToolFileAudit = () => {
  const { auditTool, setAuditTool } = useContext(AuditToolContext);
  const { setCitations } = useContext(DocViewerContext);
  const authInfo = useAuthInfo();
  const accessToken = authInfo.accessToken;
  const { activeModalRef, setActiveModalRef } = useModalContext();
  const [fileAuditTool, setFileAuditTool] = useState<FileAuditMetadata[]>([]);
  const [selectedFunctionalArea, setSelectedFunctionalArea] = useState<
    string | null
  >(null);
  const [isAuditFileLoading, setIsAuditFileLoading] = useState(false);
  const [auditedFiles, setAuditedFiles] = useState<AuditedFile[]>([]);
  const [openDocSheet, setOpenDocSheet] = useState(false);
  const [docToView, setDocToView] = useState<AuditedFile | null>(null);
  const [isSharepointLoading, setIsSharepointLoading] = useState(false);
  const [isSharepointSynced, setIsSharepointSynced] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

  const docSheetRef = useRef<HTMLDivElement>(null);
  const fetchFileAuditTool = async () => {
    if (accessToken) {
      const data = await getFileAuditMetadata(accessToken);
      setFileAuditTool(data);
    }
  };

  const syncSharepoint = async (override = false) => {
    if (!auditTool || isSharepointLoading) return;
    const lastUpdated = localizeDate(
      auditTool.related_docs_last_updated_at ?? ""
    );
    const currentTime = new Date();
    const diffInMinutes =
      (currentTime.getTime() - lastUpdated.getTime()) / (1000 * 60);

    setIsSharepointLoading(true);
    try {
      // const sharepointFolderInfo = await getAuditToolSharepointFolderInfo(
      //   auditTool.id,
      //   authInfo.accessToken ?? null
      // );
      if (
        !auditTool.related_docs_last_updated_at ||
        override ||
        diffInMinutes > 3
      ) {
        console.log(
          "syncing sharepoint",
          lastUpdated,
          auditTool.related_docs_last_updated_at,
          currentTime,
          diffInMinutes
        );
        const related_docs = await loadAuditToolDocs(
          auditTool.id,
          authInfo.accessToken ?? null
        );
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setAuditTool((prevAuditTool) => {
          if (!prevAuditTool) return null;
          return {
            ...prevAuditTool,
            related_docs_last_updated_at: new Date()
              .toISOString()
              .replace("Z", "")
              .replace("T", " "),
            related_docs: related_docs ?? [],
          };
        });
      }
      setIsSharepointSynced(true);
    } catch (error) {
      console.error("Error syncing sharepoint", error);
    } finally {
      setIsSharepointLoading(false);
    }
  };

  const handleDocSelect = async (relatedDocIds: string[]) => {
    setIsAuditFileLoading(true);
    setAuditedFiles([{ id: "1", doc: { docId: "1" }, metadata: {} }]);
    await new Promise((resolve) => setTimeout(resolve, 5000));
    const doc_ids =
      auditTool?.related_docs
        ?.filter((doc) => relatedDocIds.includes(doc.id))
        .map((doc) => doc.doc_id) || [];
    console.log("relatedDocIds", doc_ids);
    const docMockRes = mockFileAuditMetadata.filter(
      (item) => item.doc?.docId && doc_ids.includes(item.doc?.docId)
    );
    setAuditedFiles(docMockRes);
    setIsAuditFileLoading(false);
  };

  const handleCitationClick = (docId: string) => {
    const displayFile = auditedFiles.find((file) => file.doc?.docId === docId);
    if (!displayFile?.doc?.docId) return;
    setDocToView(displayFile);
    setCitations(
      Object.entries(displayFile.metadata)
        .map(([key, value]) => value.citation ?? undefined)
        .filter((citation) => citation) as PdfCitation[]
    );
    setOpenDocSheet(true);
  };

  const handleSelectFileTool = (functionalArea: string) => {
    setSelectedFunctionalArea(functionalArea);
    fetchFileAuditTool();
  };

  useEffect(() => {
    if (docSheetRef.current) {
      setActiveModalRef(docSheetRef);
    }
  }, [docSheetRef]);
  return (
    <div>
      <div className="flex flex-col justify-between">
        {fileAuditTool.length < 1 && (
          <>
            <label className="text-sm text-gray-500 mb-1">
              Select a file review tool
            </label>
            <div className="w-full mb-4">
              <FunctionalAreaSelect
                functionalArea={selectedFunctionalArea ?? ""}
                handleChangeFunctionalArea={handleSelectFileTool}
                placeholder="Select a file review tool"
              />
            </div>
          </>
        )}
      </div>
      <div className="overflow-x-auto border border-gray-200 rounded-md p-4 mt">
        {fileAuditTool.length > 0 ? (
          <>
            <FileAuditToolDisplay
              data={fileAuditTool}
              toolName={`${selectedFunctionalArea} Audit Tool`}
            />
          </>
        ) : (
          <div className="flex flex-row justify-center items-center h-full">
            <p className="text-gray-500">
              Select a Functional Area to view Audit Tool
            </p>
          </div>
        )}
      </div>
      <Sheet open={openDocSheet} onOpenChange={setOpenDocSheet}>
        <SheetContent style={{ maxWidth: "50vw" }} ref={docSheetRef}>
          <SheetTitle>Audit Tool</SheetTitle>
          {docToView?.doc?.docId && (
            <DocViewerCitation
              docId={docToView?.doc?.docId}
              className="h-[calc(100vh-130px)]"
              hideAtlasWidget={true}
              parentModalRef={activeModalRef ?? undefined}
            />
          )}
        </SheetContent>
      </Sheet>
    </div>
  );
};
