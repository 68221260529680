export enum MedicareApplicablePlan {
  applicable_integrated_plan_targeted = "AIP",
  dual_eligible_special_needs_plan_targeted = "D-SNP",
  medicare_advantage_plan_targeted = "Medicare Advantage",
  medicare_part_d_sponsor_targeted = "MA-PD",
  pace_organization_targeted = "PACE",
  medicare_medicaid_plan_targeted = "MMP",
}

export const getMedicareApplicablePlanDisplay = (
  targets: Record<keyof typeof MedicareApplicablePlan, boolean>
) => {
  // TODO: reverse hardcodes once organization profile is live

  const applicable_integrated_plan_targeted = true;

  const dual_eligible_special_needs_plan_targeted =
    applicable_integrated_plan_targeted || true;

  const medicare_advantage_plan_targeted =
    dual_eligible_special_needs_plan_targeted || true;

  const medicare_part_d_sponsor_targeted =
    medicare_advantage_plan_targeted && true;

  const pace_organization_targeted = false;

  const medicare_medicaid_plan_targeted =
    !dual_eligible_special_needs_plan_targeted && false;

  // end hardcodes

  if (
    medicare_advantage_plan_targeted &&
    targets["medicare_advantage_plan_targeted"]
  ) {
    return MedicareApplicablePlan["medicare_advantage_plan_targeted"];
  }

  if (
    medicare_part_d_sponsor_targeted &&
    targets["medicare_part_d_sponsor_targeted"]
  ) {
    return MedicareApplicablePlan["medicare_part_d_sponsor_targeted"];
  }

  if (
    dual_eligible_special_needs_plan_targeted &&
    targets["dual_eligible_special_needs_plan_targeted"]
  ) {
    return MedicareApplicablePlan["dual_eligible_special_needs_plan_targeted"];
  }

  if (
    applicable_integrated_plan_targeted &&
    targets["applicable_integrated_plan_targeted"]
  ) {
    return MedicareApplicablePlan["applicable_integrated_plan_targeted"];
  }

  if (
    medicare_medicaid_plan_targeted &&
    targets["medicare_medicaid_plan_targeted"]
  ) {
    return MedicareApplicablePlan["medicare_medicaid_plan_targeted"];
  }

  if (pace_organization_targeted && targets["pace_organization_targeted"]) {
    return MedicareApplicablePlan["pace_organization_targeted"];
  }

  return null;
};
