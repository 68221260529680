import { useAuthInfo } from "@propelauth/react";
import {
  ListBulletIcon,
  PlusIcon,
  ReloadIcon,
  TableIcon,
} from "@radix-ui/react-icons";
import { TrashIcon } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import {
  requirementCanBeAutoPopulated,
  RequirementContext,
} from "../../contexts/RequirementContext";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../shadcn/components/alert-dialog";
import { Button } from "../../shadcn/components/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn/components/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import {
  autoPopulateRequirement,
  exportRequirements,
  exportRequirementsWord as exportRequirementsOrg,
  resetRequirements,
} from "../../utils/apiCalls";
import { LoadingView } from "../Loading";
import { RequirementListView } from "./RequirementListView";
import { RequirementTable } from "./RequirementTable";

export const RequirementView = (props: {
  actionItemRoutePrefix: string;
  docId: string;
  targetDocTypes?: string[];
}) => {
  const authInfo = useAuthInfo();
  const { requirementsLoading, viewType, requirements, setRequirements } =
    useContext(RequirementContext);

  useEffect(() => {
    if (requirementsLoading) {
      return;
    }

    async function autoPopulateRequirements() {
      for (const requirement of requirements) {
        if (!requirement.id || !requirementCanBeAutoPopulated(requirement)) {
          continue;
        }
        setRequirements((prev) =>
          prev.map((req) =>
            req.id === requirement.id
              ? {
                  ...req,
                  auto_populating: true,
                }
              : req
          )
        );

        try {
          for await (const populated of autoPopulateRequirement(
            props.docId,
            requirement.id,
            authInfo.accessToken ?? "",
            props.targetDocTypes
          )) {
            populated.auto_populating = true;
            setRequirements((prev) =>
              prev.map((req) => (req.id === populated.id ? populated : req))
            );
          }
        } catch (error: any) {
          console.error("There was an error auto-populating", error);
          toast.error("Unable to auto-populate");
        } finally {
          setRequirements((prev) =>
            prev.map((req) =>
              req.id === requirement.id
                ? {
                    ...req,
                    auto_populating: false,
                  }
                : req
            )
          );
        }
      }
    }

    autoPopulateRequirements();
  }, [requirementsLoading]);

  if (requirementsLoading) {
    return <LoadingView customText="Loading requirements..." />;
  }

  if (viewType !== "list") {
    return <RequirementTable />;
  }

  return (
    <RequirementListView
      actionItemRoutePrefix={props.actionItemRoutePrefix}
      docId={props.docId}
      targetDocTypes={props.targetDocTypes}
    />
  );
};

const AddRequirementButton = () => {
  const { pageNumber, docToView } = useContext(DocViewerContext);
  const {
    addRequirement,
    generateRequirementLoading,
    setViewType,
    viewType,
    editing,
  } = useContext(RequirementContext);
  return (
    <Button
      variant="default"
      onClick={() => {
        if (viewType !== "list") {
          setViewType("list");
          // wait for the view to change
          setTimeout(() => {
            addRequirement(docToView?.docId ?? "", pageNumber);
          }, 100);
        } else {
          addRequirement(docToView?.docId ?? "", pageNumber);
        }
      }}
      disabled={editing}
    >
      {generateRequirementLoading ? (
        <ReloadIcon className="w-4 h-4 mr-2 animate-spin" />
      ) : (
        <PlusIcon className="w-4 h-4 mr-2" />
      )}
      Identify Requirement
    </Button>
  );
};

const ExportRequirementButton = (props: { docId: string }) => {
  const authInfo = useAuthInfo();
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const onClickExport = async (includePolicyText: boolean) => {
    setExportLoading(true);
    const response = await exportRequirements(
      props.docId,
      includePolicyText,
      authInfo.accessToken ?? null
    );
    if (!response) {
      toast.error("Failed to export requirements");
    }
    setExportLoading(false);
  };

  const onClickExportOrg = async () => {
    setExportLoading(true);
    const response = await exportRequirementsOrg(
      props.docId,
      authInfo.accessToken ?? null
    );
    if (!response) {
      toast.error("Failed to export requirements");
    }
    setExportLoading(false);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="w-24">
          Export{" "}
          {exportLoading && (
            <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuItem onClick={() => onClickExport(true)}>
          Export with Full Details
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onClickExport(false)}>
          Export with only Document Name
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onClickExportOrg()}>
          Export to Org Format
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const ResetRequirementsButton = (props: { docId: string }) => {
  const authInfo = useAuthInfo();
  const [resetAlertOpen, setResetAlertOpen] = useState(false);

  return (
    <AlertDialog open={resetAlertOpen} onOpenChange={setResetAlertOpen}>
      <AlertDialogTrigger asChild={true}>
        <Button variant="destructive" size="icon">
          <TrashIcon className="w-4 h-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Reset Requirements?</AlertDialogTitle>
          <AlertDialogDescription>
            Resetting requirements will ERASE ALL existing impacted departments,
            action items & impacted documents for ALL requirements. Please
            confirm that you wish to reset requirements?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            onClick={() => {
              resetRequirements(props.docId, authInfo.accessToken ?? "");
              setResetAlertOpen(false);
            }}
          >
            Reset Requirements
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const RequirementViewToggle = (props: {
  viewType: "list" | "table";
  setViewType: (viewType: "list" | "table") => void;
}) => {
  return (
    <div className="flex items-center space-x-1 bg-gray-200 p-1 rounded-md">
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <Button
            size="icon"
            variant={props.viewType === "list" ? "outline" : "ghost"}
            onClick={() => {
              if (props.viewType !== "list") {
                props.setViewType("list");
              }
            }}
          >
            <ListBulletIcon className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>View Requirements as a List</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <Button
            size="icon"
            variant={props.viewType === "table" ? "outline" : "ghost"}
            onClick={() => {
              if (props.viewType !== "table") {
                props.setViewType("table");
              }
            }}
          >
            <TableIcon className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>View Requirements as a Table</TooltipContent>
      </Tooltip>
    </div>
  );
};

export const RequirementControlsHeader = (props: {
  docId: string;
  requirementView: boolean;
  isResetRequirementsEnabled?: boolean;
}) => {
  const { viewType, setViewType, requirements } =
    useContext(RequirementContext);

  return (
    <div className="flex items-center space-x-2">
      {props.requirementView && <AddRequirementButton />}
      {requirements.length > 0 && (
        <ExportRequirementButton docId={props.docId} />
      )}
      {props.requirementView && (
        <RequirementViewToggle viewType={viewType} setViewType={setViewType} />
      )}
      {props.isResetRequirementsEnabled && (
        <ResetRequirementsButton docId={props.docId} />
      )}
    </div>
  );
};
