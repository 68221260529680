import { useAuthInfo } from "@propelauth/react";
import { TooltipContent, TooltipTrigger } from "@radix-ui/react-tooltip";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { MultiSelect } from "../shadcn/components/multi-select";
import { Tooltip } from "../shadcn/components/tooltip";
import { SimpleUser } from "../types";

interface SimpleUserComboBoxProps {
  selectedUsers: SimpleUser[];
  handleUserSelect: (userId: string, isSelected: boolean) => void;
  comboTooltip?: React.ReactNode;
  showBadge?: boolean;
}

const SimpleUserComboBox: React.FC<SimpleUserComboBoxProps> = ({
  selectedUsers,
  comboTooltip,
  handleUserSelect,
}) => {
  const { simpleUsers } = useContext(UserContext);
  const { user: currentUser } = useAuthInfo();
  const [options, setOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    const fullUserList = simpleUsers
      .filter((user) => user.id !== currentUser?.userId)
      .map((user) => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`.trim() || user.email,
      }));
    const selectedUserList = selectedUsers
      ?.filter((user) => user && user.id)
      .map((user) => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`.trim() || user.email,
      }));
    setOptions(fullUserList);
    setSelectedValues(selectedUserList.map((user) => user.id));
  }, [simpleUsers, currentUser, selectedUsers]);

  return (
    <div className="flex flex-row gap-2 items-center">
      <Tooltip>
        <TooltipTrigger>
          <MultiSelect
            placeholder="Assign Users"
            options={options}
            defaultValue={selectedValues}
            onValueChange={(value) => {
              const addedUser = value.filter(
                (userId) => !selectedValues.some((user) => user === userId)
              );
              if (addedUser.length > 0) {
                addedUser.forEach((userId) => {
                  handleUserSelect(userId, true);
                });
              }
              const removedUser = selectedValues.filter(
                (userId) => !value.includes(userId)
              );
              removedUser.forEach((userId) => {
                handleUserSelect(userId, false);
              });
            }}
          />
        </TooltipTrigger>
        <TooltipContent>{comboTooltip}</TooltipContent>
      </Tooltip>
    </div>
  );
};

export default SimpleUserComboBox;
