import { ArrowLeft } from "lucide-react";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../shadcn/components/accordion";
import { Button } from "../../shadcn/components/button";
import { Checkbox } from "../../shadcn/components/checkbox";
import { cn } from "../../shadcn/lib/utils";
import { Factor } from "../../types";

type FactorWithChecked = Factor & { checked: boolean };

type FactorTableDisplayProps = {
  factors: Factor[];
  onSubmit?: (factors: Factor[]) => void;
  onBack?: () => void;
};

export const FactorTreeDisplay = ({
  factors,
  onSubmit,
  onBack,
}: FactorTableDisplayProps) => {
  const [factorsState, setFactorsState] = useState<FactorWithChecked[]>(
    factors.map((f) => ({ ...f, checked: true }))
  );

  const handleSubmit = () => {
    if (!onSubmit) {
      return;
    }
    onSubmit(
      factorsState
        .filter((f) => f.checked)
        .map((f) => {
          const { checked, ...rest } = f;
          return rest;
        })
    );
  };

  const onToggleFactor = (factorToToggle: FactorWithChecked) => {
    setFactorsState((prevFactors) =>
      prevFactors.map((f) =>
        f === factorToToggle ? { ...f, checked: !f.checked } : f
      )
    );
  };

  const onToggleAllFactors = (
    factorsToToggle: FactorWithChecked[],
    check: boolean
  ) => {
    setFactorsState((prev) =>
      prev.map((f) =>
        factorsToToggle.includes(f) ? { ...f, checked: check } : f
      )
    );
  };

  useEffect(() => {
    setFactorsState(factors.map((f) => ({ ...f, checked: true })));
  }, [factors]);

  // Get unique factor sources first
  const uniqueFactorSources = Array.from(
    new Set(factorsState.map((factor) => factor.source))
  ).sort();

  return (
    <div className="flex flex-col gap-2 h-full">
      <h2 className="text-2xl font-semibold mb-2">Add Requirements to Audit</h2>
      {onSubmit && (
        <div className="flex justify-between gap-2 mb-2">
          <Button variant="outline" onClick={onBack}>
            <ArrowLeft className="w-4 h-4 mr-2" /> Back - Edit Scope
          </Button>
          <Button onClick={handleSubmit}>Add Requirements to Audit</Button>
        </div>
      )}
      <div className="flex-1 overflow-y-auto">
        <div>
          {uniqueFactorSources.map((factorSource) => {
            const sourceFactors = factorsState.filter(
              (factor) => factor.source === factorSource
            );

            // Get unique functional areas for this source
            const sourceFunctionalAreas = Array.from(
              new Set(sourceFactors.map((factor) => factor.functional_area))
            ).sort();

            return (
              <Accordion
                key={factorSource}
                type="single"
                collapsible
                defaultValue={factorSource || "Unknown"}
                className="border rounded-md bg-white mb-2"
              >
                <AccordionItem
                  value={factorSource || "Unknown"}
                  className="border-b-0"
                >
                  <AccordionTrigger className="px-4 py-2">
                    <div className="flex justify-between w-full">
                      <span className="font-semibold">
                        {factorSource || "Unknown Source"}{" "}
                        <span className="text-gray-500">- source</span>
                      </span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    {sourceFunctionalAreas.map((functionalArea) => {
                      const faFactors = sourceFactors.filter(
                        (factor) => factor.functional_area === functionalArea
                      );
                      return (
                        <FunctionalAreaDisplay
                          key={`${factorSource}-${functionalArea}`}
                          functionalArea={functionalArea || "Unknown"}
                          factors={faFactors}
                          onToggleFactor={onSubmit ? onToggleFactor : () => {}}
                          onToggleAllFactors={
                            onSubmit ? onToggleAllFactors : () => {}
                          }
                        />
                      );
                    })}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// ~~~~~~~~~~~~~~~~~~~~~ FunctionalAreaDisplay ~~~~~~~~~~~~~~~~~~~~~
export const FunctionalAreaDisplay = ({
  functionalArea,
  factors,
  onToggleFactor,
  onToggleAllFactors,
}: {
  functionalArea: string;
  factors: FactorWithChecked[];
  onToggleFactor: (factor: FactorWithChecked) => void;
  onToggleAllFactors: (
    factorsToToggle: FactorWithChecked[],
    check: boolean
  ) => void;
}) => {
  const uniqueStandards = Array.from(
    new Set(factors.map((factor) => factor.standard_index))
  );

  // Determine the checkbox state for this Functional Area
  const allChecked = factors.length > 0 && factors.every((f) => f.checked);
  const noneChecked = factors.every((f) => !f.checked);
  const isIndeterminate = !allChecked && !noneChecked;
  const checkedState: boolean | "indeterminate" = allChecked
    ? true
    : isIndeterminate
      ? "indeterminate"
      : false;

  const handleToggleAll = () => {
    onToggleAllFactors(factors, !allChecked);
  };

  return (
    <Accordion
      type="single"
      collapsible
      defaultValue={functionalArea}
      className="bg-white"
    >
      <AccordionItem value={functionalArea} className="border-b-0">
        <AccordionTrigger className="flex items-center cursor-pointer select-none hover:bg-gray-50 rounded-md py-1.5 px-2 transition-colors">
          <div className="flex items-center w-full">
            <Checkbox
              aria-label={`Toggle all factors in ${functionalArea}`}
              className="ml-3 flex-shrink-0"
              onClick={(e) => e.stopPropagation()}
              checked={checkedState}
              onCheckedChange={handleToggleAll}
            />
            <span className="ml-2 font-medium text-gray-800 truncate">
              {functionalArea}{" "}
              <span className="text-gray-500">- Functional Area</span>
            </span>
          </div>
        </AccordionTrigger>

        <AccordionContent className="pl-6 pb-2">
          {/* Standards */}
          {uniqueStandards.map((standard) => {
            const standardFactors = factors.filter(
              (factor) => factor.standard_index === standard
            );
            return (
              <StandardDisplay
                key={standard}
                standard={standard || "NoStandard"}
                factors={standardFactors}
                onToggleFactor={onToggleFactor}
                onToggleAllFactors={onToggleAllFactors}
              />
            );
          })}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

// ~~~~~~~~~~~~~~~~~~~~~ StandardDisplay ~~~~~~~~~~~~~~~~~~~~~
const StandardDisplay = ({
  standard,
  factors,
  onToggleFactor,
  onToggleAllFactors,
}: {
  standard: string;
  factors: FactorWithChecked[];
  onToggleFactor: (factor: FactorWithChecked) => void;
  onToggleAllFactors: (
    factorsToToggle: FactorWithChecked[],
    check: boolean
  ) => void;
}) => {
  const uniqueElements = Array.from(
    new Set(factors.map((factor) => factor.element_index))
  );

  const allChecked = factors.length > 0 && factors.every((f) => f.checked);
  const noneChecked = factors.every((f) => !f.checked);
  const isIndeterminate = !allChecked && !noneChecked;
  const checkedState: boolean | "indeterminate" = allChecked
    ? true
    : isIndeterminate
      ? "indeterminate"
      : false;

  const handleToggleAll = () => {
    onToggleAllFactors(factors, !allChecked);
  };

  return (
    <Accordion
      type="single"
      collapsible
      defaultValue={standard}
      className="bg-white"
    >
      <AccordionItem value={standard} className="border-b-0">
        <AccordionTrigger
          className={cn(
            "flex items-center cursor-pointer select-none hover:bg-gray-50 rounded-md py-1.5 px-1.5 transition-colors",
            checkedState ? "" : ""
          )}
        >
          <div className="flex items-center w-full">
            <Checkbox
              aria-label={`Toggle all factors in standard ${standard}`}
              checked={checkedState}
              onClick={(e) => e.stopPropagation()}
              onCheckedChange={handleToggleAll}
              className="ml-3 flex-shrink-0"
            />
            <span className="ml-2 text-gray-800 truncate">
              {standard || "NoStandard"}{" "}
              <span className="text-gray-500">- Standard</span>
            </span>
          </div>
        </AccordionTrigger>

        <AccordionContent className="pl-6 pb-2">
          {/* Elements */}
          {uniqueElements.map((element) => {
            const elementFactors = factors.filter(
              (factor) => factor.element_index === element
            );
            return (
              <ElementDisplay
                key={element}
                element={element}
                factors={elementFactors}
                onToggleFactor={onToggleFactor}
                onToggleAllFactors={onToggleAllFactors}
              />
            );
          })}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

// ~~~~~~~~~~~~~~~~~~~~~ ElementDisplay ~~~~~~~~~~~~~~~~~~~~~
const ElementDisplay = ({
  element,
  factors,
  onToggleFactor,
  onToggleAllFactors,
}: {
  element: string;
  factors: FactorWithChecked[];
  onToggleFactor: (factor: FactorWithChecked) => void;
  onToggleAllFactors: (
    factorsToToggle: FactorWithChecked[],
    check: boolean
  ) => void;
}) => {
  const allChecked = factors.length > 0 && factors.every((f) => f.checked);
  const noneChecked = factors.every((f) => !f.checked);
  const isIndeterminate = !allChecked && !noneChecked;
  const checkedState: boolean | "indeterminate" = allChecked
    ? true
    : isIndeterminate
      ? "indeterminate"
      : false;

  const handleToggleAll = () => {
    onToggleAllFactors(factors, !allChecked);
  };
  return (
    <Accordion
      type="single"
      collapsible
      defaultValue={element}
      className="bg-white"
    >
      {/* {factors.map((factor, index) => {
        return <div key={index}>Hello</div>;
      })} */}
      <AccordionItem value={element || "NoElement"} className="border-b-0">
        <AccordionTrigger
          className={cn(
            "flex items-center cursor-pointer select-none hover:bg-gray-50 rounded-md py-1.5 px-1.5 transition-colors",
            checkedState ? "" : ""
          )}
        >
          <div className="flex items-center w-full">
            <Checkbox
              aria-label={`Toggle all factors in element ${element}`}
              checked={checkedState}
              onClick={(e) => e.stopPropagation()}
              onCheckedChange={handleToggleAll}
              className="ml-3 flex-shrink-0"
            />
            <span className="ml-2 text-gray-800 truncate">
              {element || "No Element"}{" "}
              <span className="text-gray-500">- Element</span>
            </span>
          </div>
        </AccordionTrigger>

        <AccordionContent className="pl-6 pb-2">
          {/* Individual factors */}
          {factors.map((factor, index) => (
            <FactorDisplay
              key={index}
              factor={factor}
              onToggleFactor={onToggleFactor}
            />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

// ~~~~~~~~~~~~~~~~~~~~~ FactorDisplay ~~~~~~~~~~~~~~~~~~~~~
export const FactorDisplay = ({
  factor,
  onToggleFactor,
}: {
  factor: FactorWithChecked;
  onToggleFactor: (factor: FactorWithChecked) => void;
}) => {
  return (
    <div
      className={cn(
        "flex items-center py-1.5 px-1.5 rounded-md transition-colors hover:bg-gray-50 pl-5",
        factor.checked ? "bg-blue-50 hover:bg-blue-100" : ""
      )}
      onClick={(e) => onToggleFactor(factor)}
    >
      <div className="flex items-center">
        <Checkbox
          aria-label="Select factor"
          checked={factor.checked}
          onCheckedChange={() => onToggleFactor(factor)}
          className="ml-2 flex-shrink-0"
        />
        <span className="ml-2.5 text-gray-800 truncate">
          {factor.factor_title || factor.factor_index || "Untitled"}{" "}
          <span className="text-gray-500">- Requirement</span>
        </span>
      </div>
    </div>
  );
};
