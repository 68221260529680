import { DatePicker } from "@mui/x-date-pickers";
import { useAuthInfo } from "@propelauth/react";
import dayjs from "dayjs";
import { PencilIcon } from "lucide-react";
import { useCallback, useContext, useState } from "react";
import { toast } from "sonner";
import { AuditToolContext } from "../../contexts/AuditToolContext";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import { updateAuditTool } from "../../utils/apiCalls";

export const DateSelector = () => {
  const { auditTool, setAuditTool, isOwner } = useContext(AuditToolContext);
  const { accessToken } = useAuthInfo();
  const [lookbackPopoverOpen, setLookbackPopoverOpen] = useState(false);
  const [auditPeriodPopoverOpen, setAuditPeriodPopoverOpen] = useState(false);
  const handleUpdateLookbackPeriod = useCallback(
    async (startDate: string, endDate: string) => {
      if (!auditTool?.id || !accessToken) {
        console.error("Missing required audit tool ID or access token");
        return;
      }

      try {
        const tool = await updateAuditTool(
          auditTool.id,
          {
            audit_start_date: startDate,
            audit_end_date: endDate,
          },
          accessToken
        );

        setAuditTool((prev) => {
          if (!prev) {
            return prev;
          }
          return {
            ...prev,
            ...tool,
          };
        });
        setLookbackPopoverOpen(false);
      } catch (error) {
        toast.error("Failed to update lookback period");
      }
    },
    [auditTool?.id, accessToken]
  );
  const handleUpdateAuditPeriod = useCallback(
    async (startDate: string, endDate: string) => {
      if (!auditTool?.id || !accessToken) {
        console.error("Missing required audit tool ID or access token");
        return;
      }

      try {
        const tool = await updateAuditTool(
          auditTool.id,
          {
            audit_work_start_date: startDate,
            audit_work_end_date: endDate,
          },
          accessToken
        );

        setAuditTool((prev) => {
          if (!prev) {
            return prev;
          }
          return {
            ...prev,
            ...tool,
          };
        });
        setAuditPeriodPopoverOpen(false);
      } catch (error) {
        toast.error("Failed to update audit period");
      }
    },
    [auditTool?.id, accessToken]
  );
  return (
    <div className="flex gap-4">
      <DateRangePopover
        label="Lookback Period"
        startDate={auditTool?.audit_start_date ?? ""}
        endDate={auditTool?.audit_end_date ?? ""}
        onSubmit={(startDate, endDate) => {
          handleUpdateLookbackPeriod(startDate, endDate);
        }}
        open={lookbackPopoverOpen}
        setOpen={setLookbackPopoverOpen}
        disabled={!isOwner}
      />
      <DateRangePopover
        label="Audit Date(s)"
        startDate={auditTool?.audit_work_start_date ?? ""}
        endDate={auditTool?.audit_work_end_date ?? ""}
        onSubmit={(startDate, endDate) => {
          handleUpdateAuditPeriod(startDate, endDate);
        }}
        open={auditPeriodPopoverOpen}
        setOpen={setAuditPeriodPopoverOpen}
      />
    </div>
  );
};

function DateRangePopover({
  startDate,
  endDate,
  onSubmit,
  label,
  open,
  setOpen,
  disabled,
}: {
  startDate: string;
  endDate: string;
  onSubmit: (startDate: string, endDate: string) => void;
  label: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  disabled?: boolean;
}) {
  const [workingStartDate, setWorkingStartDate] = useState(
    dayjs(startDate ? new Date(startDate) : undefined)
  );
  const [workingEndDate, setWorkingEndDate] = useState(
    dayjs(endDate ? new Date(endDate) : undefined)
  );
  return (
    <div className="relative flex flex-col bg-white rounded-md p-2 border border-gray-200">
      <div className="absolute -top-2.5 left-2 px-1 bg-white text-xs text-gray-400 font-medium">
        {label}
      </div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <button
            className={`flex items-center gap-2 rounded-md px-3 transition-colors ${
              disabled ? "cursor-not-allowed opacity-50" : "hover:bg-gray-100"
            }`}
            disabled={disabled}
          >
            <span className="text-sm text-gray-600 font-medium">
              {startDate ? new Date(startDate).toLocaleDateString() : "N/A"} -{" "}
              {endDate ? new Date(endDate).toLocaleDateString() : "N/A"}
            </span>
            <PencilIcon className="h-4 w-4 text-gray-500" />
          </button>
        </PopoverTrigger>
        <PopoverContent className="w-80 p-4 space-y-4">
          <DatePicker
            label="Start Date"
            value={workingStartDate}
            onChange={(newValue) => {
              if (newValue) {
                setWorkingStartDate(newValue);
              }
            }}
            className="w-full"
            disabled={disabled}
          />
          <DatePicker
            label="End Date"
            value={workingEndDate}
            onChange={(newValue) => {
              if (newValue) {
                setWorkingEndDate(newValue);
              }
            }}
            className="w-full"
            disabled={disabled}
          />
          <button
            className={`w-full text-white text-sm font-medium py-2 px-4 rounded-md transition-colors ${
              disabled
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700"
            }`}
            onClick={() => {
              if (!disabled) {
                onSubmit(
                  workingStartDate.toISOString(),
                  workingEndDate.toISOString()
                );
              }
            }}
            disabled={disabled}
          >
            Apply Date Range
          </button>
        </PopoverContent>
      </Popover>
    </div>
  );
}
