import {
  AlertCircle,
  Check,
  ChevronLeft,
  Download,
  FileText,
  Info,
  Save,
  Search,
  X,
} from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  CsvError,
  FixedCell,
} from "../../pages/cmsAudit/CMSAuditDashboard";
import { Button } from "../../shadcn/components/button";
import { Input } from "../../shadcn/components/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
interface CsvDisplayProps {
  data: { headers: string[]; data: string[][] };
  editableData: { headers: string[]; data: string[][] };
  setEditableData: React.Dispatch<
    React.SetStateAction<{ headers: string[]; data: string[][] }>
  >;
  universeCols: Record<string, Col>;
  errors?: CsvError;
  fixedCells?: FixedCell[];
  showFixedValues?: boolean;
  onSave?: (data: { headers: string[]; data: string[][] }) => void;
  onToggleFixedCell?: (
    rowIndex: number,
    colIndex: number,
    useFixed: boolean
  ) => void;
  fixAllInColumn: (colIndex: number) => void;
  confirmFixedCell: (
    rowIndex: number,
    colIndex: number,
    useFixed: boolean
  ) => void;
  readOnly?: boolean;
  className?: string;
  currentFixingCell: {
    rowIndex: number;
    colIndex: number;
  } | null;
  setCurrentFixingCell: (
    cell: { rowIndex: number; colIndex: number } | null
  ) => void;
}

export const CsvDisplay: React.FC<CsvDisplayProps> = ({
  data,
  editableData,
  setEditableData,
  universeCols,
  errors = {},
  fixedCells,
  showFixedValues,
  onSave,
  onToggleFixedCell,
  readOnly = false,
  className,
  fixAllInColumn,
  confirmFixedCell,
  currentFixingCell,
  setCurrentFixingCell,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [editingCell, setEditingCell] = useState<{
    row: number;
    col: string;
  } | null>(null);
  const [showErrors, setShowErrors] = useState(true);
  const [universeColIndexMap, setUniverseColIndexMap] = useState<
    Record<number, string>
  >({});
  const [universeColIndexReverseMap, setUniverseColIndexReverseMap] = useState<
    Record<string, number>
  >({});

  // Get column headers from the first row
  const columns = data.headers;

  useEffect(() => {
    const map: Record<number, string> = {};
    const reverseMap: Record<string, number> = {};

    if (Object.keys(universeCols).length > 0) {
      Object.entries(universeCols).forEach(([colIdx, col]: [string, Col]) => {
        const convertedNum = colIdx
          .split("")
          .reverse()
          .reduce(
            (acc: number, char: string, i: number) =>
              acc + (char.charCodeAt(0) - 64) * Math.pow(26, i),
            0
          );
        map[convertedNum] = col.col_id;
        reverseMap[col.col_id] = convertedNum;
      });
    }
    setUniverseColIndexMap(map);
    setUniverseColIndexReverseMap(reverseMap);
  }, [universeCols]);

  // Initialize editable data when data changes
  useEffect(() => {
    setEditableData({ ...editableData, data: [...data.data] });
  }, [data]);

  // Handle cell edit
  const handleCellEdit = (rowIndex: number, column: number, value: string) => {
    setEditableData((prevData) => {
      const updatedData = [...prevData.data];
      updatedData[rowIndex][column] = value;
      return { ...prevData, data: updatedData };
    });
  };

  // Handle cell click to start editing
  const handleCellClick = (rowIndex: number, column: string) => {
    if (readOnly) return;
    setEditingCell({ row: rowIndex, col: column });
  };

  // Check if a row has errors
  const hasErrors = (rowIndex: number | string): boolean => {
    const rowErrors = errors[String(rowIndex)];
    return (
      !!rowErrors?.error.length ||
      Object.values(rowErrors?.cell_errors || {}).some(
        (errors) => errors.length > 0
      )
    );
  };

  // Get row-level errors for a specific row
  const getRowErrors = (rowIndex: number | string): string[] => {
    const rowErrors = errors[String(rowIndex)];
    return rowErrors ? rowErrors.error : [];
  };

  // Check if a specific cell has errors
  const hasCellErrors = (
    rowIndex: number | string,
    universeColId: string
  ): boolean => {
    const rowErrors = errors[String(rowIndex)];
    return !!rowErrors?.cell_errors[universeColId]?.length;
  };

  // Get errors for a specific cell
  const getCellErrors = (
    rowIndex: number | string,
    universeColId: string
  ): string[] => {
    const rowErrors = errors[String(rowIndex)];
    if (!rowErrors || !rowErrors.cell_errors) return [];
    return rowErrors.cell_errors[universeColId] || [];
  };

  // Get total error count
  const getTotalErrorCount = (): number => {
    return Object.keys(errors).length;
  };

  // Handle save button click
  const handleSave = () => {
    if (onSave) {
      onSave(editableData);
    }
  };

  // Export data as CSV
  const exportCsv = useCallback(() => {
    const headers = columns.join(",");
    const rows = editableData.data.map((row: string[]) =>
      columns
        .map((col: string, index: number) => {
          // Handle values with commas by wrapping in quotes
          const value = String(row[index] || "");
          return value.includes(",") ? `"${value}"` : value;
        })
        .join(",")
    );

    const csvContent = [headers, ...rows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "export.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [editableData, columns]);

  const isCellFixed = (
    rowIndex: number,
    colIndex: number
  ): FixedCell | undefined => {
    if (!fixedCells) return undefined;
    return fixedCells.find(
      (cell) =>
        cell.rowIndex === rowIndex &&
        colIndex === cell.colIndex &&
        !(cell.isAccepted || cell.isRejected)
    );
  };

  return (
    <div className={className}>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <div className="relative">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
            <Input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) =>
                setSearchTerm((e.target as HTMLInputElement).value)
              }
              className="pl-8 pr-8 py-1 border border-gray-300 w-[250px]"
            />
            {searchTerm && (
              <Button
                onClick={() => setSearchTerm("")}
                className="absolute right-2 top-2.5"
              >
                <X className="h-4 w-4 text-gray-400" />
              </Button>
            )}
          </div>

          <Button
            onClick={() => setShowErrors(!showErrors)}
            className="text-sm"
          >
            {showErrors ? "Hide Errors" : "Show Errors"}
          </Button>
        </div>

        <div className="flex items-center space-x-2">
          {!readOnly && (
            <Button
              onClick={handleSave}
              disabled={!onSave}
              className="text-sm flex items-center"
            >
              <Save className="h-4 w-4 mr-2" />
              Save Changes
            </Button>
          )}

          <Button onClick={exportCsv} className="text-sm flex items-center">
            <Download className="h-4 w-4 mr-2" />
            Export CSV
          </Button>
        </div>
      </div>

      <div className="border rounded overflow-hidden">
        {editableData.data.length > 0 ? (
          <div className="overflow-auto max-h-[70vh]">
            <table className="w-full border-collapse">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th className="p-2 text-left font-medium text-gray-500 w-[80px] border-r border-gray-200"></th>
                  {columns.map((column, colIndex) => {
                    const universeColId = universeColIndexMap[colIndex + 1];
                    const universeCol = universeCols[universeColId];
                    const columnFixedCells =
                      fixedCells?.filter(
                        (cell) => cell.colIndex === colIndex
                      ) || [];

                    return (
                      <th
                        key={`${column}-id`}
                        className="p-2 text-center font-medium text-gray-500 border-r border-gray-200"
                      >
                        <div className="flex items-center justify-center">
                          <div
                            className="max-w-[150px] truncate flex-1"
                            title={universeCol?.field_name}
                          >
                            {universeColId || ""}
                          </div>
                          {universeCol && (
                            <Tooltip>
                              <TooltipTrigger>
                                <Info size={14} />
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="p-2 min-w-fit max-w-[350px] flex flex-col gap-2">
                                  {/* Tooltip Title */}
                                  <h3 className="font-bold text-base">
                                    CMS Field Requirement
                                  </h3>
                                  {/* Field Name */}
                                  <div className="flex items-center gap-2">
                                    <label className="text-sm font-medium text-gray-700">
                                      Field Name:
                                    </label>
                                    <h4 className="mt-1 font-semibold text-sm">
                                      {universeCol?.field_name}
                                    </h4>
                                  </div>
                                  {/* Description */}
                                  <label className="text-sm font-medium text-gray-700">
                                    FieldDescription:
                                  </label>
                                  <div className="border border-gray-200 p-2 rounded-md">
                                    <p className="break-words">
                                      {universeCol?.description}
                                    </p>
                                  </div>
                                  {/* Field Length */}
                                  <div>
                                    <p className="mt-2 text-sm text-gray-500">
                                      Maximum Field Length:{" "}
                                      {universeCol?.field_length}
                                    </p>
                                  </div>
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          )}
                          {columnFixedCells.length > 0 && (
                            <Tooltip>
                              <TooltipTrigger>
                                <div className="ml-1 text-yellow-500 cursor-help">
                                  <Info size={14} />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent className="p-0">
                                <div className="py-4 px-0 flex flex-col">
                                  <p className="font-bold text-lg mb-4 text-center">
                                    Fixable Cells:
                                  </p>
                                  <div className="flex flex-col space-y-4 max-h-48 overflow-y-auto">
                                    {columnFixedCells.map((cell, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center justify-between border-b border-gray-300 py-3 px-6 bg-gray-100"
                                      >
                                        <p className="font-semibold">
                                          Row {cell.rowIndex + 1}:
                                        </p>
                                        <div className="flex items-center space-x-2">
                                          <p className="font-bold px-3 py-1 bg-red-200 text-red-700 rounded">
                                            {cell.originalValue}
                                          </p>
                                          <ChevronLeft
                                            className="text-gray-500 rotate-180"
                                            size={14}
                                          />
                                          <p className="font-bold px-3 py-1 bg-green-200 text-green-700 rounded">
                                            {cell.fixedValue}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <Button
                                    size="sm"
                                    variant="outline"
                                    className="bg-green-500 text-white hover:bg-green-600 mx-6 mt-4"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      fixAllInColumn(colIndex);
                                    }}
                                  >
                                    Fix All
                                  </Button>
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
                <tr>
                  <th className="p-2 text-left font-medium text-gray-500 w-[80px] border-r border-gray-200">
                    Row
                  </th>
                  {columns.map((column, colIndex) => {
                    const universeColId = universeColIndexMap[colIndex + 1];
                    const universeCol = universeCols[universeColId];
                    return (
                      <th
                        key={column}
                        className="p-2 text-left font-medium text-gray-500 border-r border-gray-200 relative"
                      >
                        <div className="flex items-center">
                          <div
                            className="max-w-[150px] truncate"
                            title={column}
                          >
                            {column}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {editableData.data.map((row: string[], rowIndex: number) => {
                  const rowHasErrors = hasErrors(rowIndex);
                  const rowErrors = getRowErrors(rowIndex);
                  const rowCellErrorCount = Object.values(
                    errors[String(rowIndex)]?.cell_errors || {}
                  ).filter((errors) => errors.length > 0).length;

                  return (
                    <tr
                      key={rowIndex}
                      className={
                        rowHasErrors && showErrors ? "bg-red-50/30" : ""
                      }
                    >
                      <td className="p-2 border-t border-r border-gray-200 font-medium relative">
                        <div className="flex items-center">
                          <span className="mr-2">{rowIndex + 1}</span>
                          {rowHasErrors &&
                            showErrors &&
                            (rowErrors.length > 0 || rowCellErrorCount > 0) && (
                              <div
                                className="text-red-500 cursor-help"
                                title={rowErrors.join("\n")}
                              >
                                <Tooltip>
                                  <TooltipTrigger>
                                    <AlertCircle size={16} />
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    {rowHasErrors && (
                                      <p>{rowErrors.join("\n")}</p>
                                    )}
                                    {rowCellErrorCount > 0 && (
                                      <p>{rowCellErrorCount} cell errors</p>
                                    )}
                                  </TooltipContent>
                                </Tooltip>
                              </div>
                            )}
                        </div>
                      </td>

                      {columns.map((column: string, colIndex: number) => {
                        const universeColId = universeColIndexMap[colIndex + 1];
                        const cellHasError = hasCellErrors(
                          rowIndex,
                          universeColId
                        );
                        const cellErrorMessages = getCellErrors(
                          rowIndex,
                          universeColId
                        );

                        const fixedCell = isCellFixed(rowIndex, colIndex);
                        const isFixed = !!fixedCell;

                        if (isFixed) {
                          return (
                            <FixingCell
                              rowIndex={rowIndex}
                              column={column}
                              colIndex={colIndex}
                              fixedCell={fixedCell}
                              handleCellClick={handleCellClick}
                              currentlyFixingCell={
                                currentFixingCell?.rowIndex === rowIndex &&
                                currentFixingCell?.colIndex === colIndex
                              }
                              setCurrentlyFixingCell={(boolean) =>
                                setCurrentFixingCell(
                                  boolean ? { rowIndex, colIndex } : null
                                )
                              }
                              confirmFixedCell={(useFixed) =>
                                confirmFixedCell(rowIndex, colIndex, useFixed)
                              }
                            />
                          );
                        }
                        return (
                          <td
                            key={`${rowIndex}-${column}`}
                            id={`cell-${rowIndex}-${colIndex}`}
                            onClick={() => handleCellClick(rowIndex, column)}
                            className={`p-2 border-t border-r border-gray-200 ${
                              !readOnly ? "cursor-pointer hover:bg-gray-50" : ""
                            } ${cellHasError && showErrors ? "bg-red-100" : ""}`}
                          >
                            <div className="relative">
                              {editingCell?.row === rowIndex &&
                              editingCell?.col === column ? (
                                <input
                                  type="text"
                                  value={row[colIndex] || ""}
                                  onChange={(e) =>
                                    handleCellEdit(
                                      rowIndex,
                                      colIndex,
                                      (e.target as HTMLInputElement).value
                                    )
                                  }
                                  autoFocus
                                  onBlur={() => setEditingCell(null)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      handleCellEdit(
                                        rowIndex,
                                        colIndex,
                                        (e.target as HTMLInputElement).value
                                      );
                                    } else if (e.key === "Escape") {
                                      setEditingCell(null);
                                    }
                                  }}
                                  className="w-full p-1 border border-gray-300 rounded"
                                />
                              ) : (
                                <div className="flex items-center">
                                  <span>{row[colIndex] || ""}</span>
                                  {cellHasError && (
                                    <Tooltip>
                                      <TooltipTrigger
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <div className="ml-1 text-red-500 cursor-help">
                                          <AlertCircle size={14} />
                                        </div>
                                      </TooltipTrigger>
                                      <TooltipContent
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <p>{cellErrorMessages.join(", ")}</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  )}
                                </div>
                              )}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="h-24 flex flex-col items-center justify-center text-gray-500">
            <FileText className="h-8 w-8 mb-2" />
            <p>{searchTerm ? "No results found" : "No data available"}</p>
          </div>
        )}
      </div>

      {getTotalErrorCount() > 0 && (
        <div className="flex items-center mt-2 text-sm text-gray-500">
          <AlertCircle className="h-4 w-4 mr-2 text-red-500" />
          Found {getTotalErrorCount()}{" "}
          {getTotalErrorCount() === 1 ? "row" : "rows"} with errors
        </div>
      )}
    </div>
  );
};
const FixingCell = ({
  rowIndex,
  column,
  colIndex,
  fixedCell,
  handleCellClick,
  currentlyFixingCell,
  setCurrentlyFixingCell,
  confirmFixedCell,
}: {
  rowIndex: number;
  column: string;
  colIndex: number;
  fixedCell: FixedCell;
  handleCellClick: (rowIndex: number, column: string) => void;
  currentlyFixingCell: boolean;
  setCurrentlyFixingCell: (currentlyFixingCell: boolean) => void;
  confirmFixedCell: (useFixed: boolean) => void;
}) => {
  return (
    <Popover
      open={currentlyFixingCell}
      onOpenChange={(open) => {
        if (!open) {
          setCurrentlyFixingCell(false);
        }
      }}
    >
      <PopoverTrigger className="w-full h-full" asChild>
        <td
          key={`${rowIndex}-${column}`}
          onClick={() => setCurrentlyFixingCell(!currentlyFixingCell)}
          id={`cell-${rowIndex}-${colIndex}`}
          className={`p-2 relative border-t border-r border-gray-200 bg-yellow-100 cursor-pointer`}
        >
          <div
            id={`cellum`}
            className="flex items-center w-full h-full"
            // onClick={() => setCurrentlyFixingCell(!currentlyFixingCell)}
          >
            <span>{fixedCell.fixedValue}</span>
          </div>
        </td>
      </PopoverTrigger>
      <PopoverContent onClick={(e) => e.stopPropagation()} className="p-0">
        <div className="py-4 px-0">
          <p className="font-bold text-lg mb-4 text-center">Value was fixed</p>
          <div className="flex flex-col space-y-4">
            <div
              className="flex items-center justify-between border-b border-gray-300 py-3 bg-red-100 px-4 cursor-pointer hover:bg-red-200"
              onClick={() => confirmFixedCell(false)}
            >
              <p className="font-semibold">Original:</p>
              <p className="font-bold px-2 py-1 bg-red-200 rounded mx-2">
                {fixedCell.originalValue}
              </p>
              <Button
                size="sm"
                variant="outline"
                className="bg-red-500 text-white hover:bg-red-600"
                onClick={() => confirmFixedCell(false)}
              >
                <X size={14} />
              </Button>
            </div>
            <div
              className="flex items-center justify-between border-b border-gray-300 py-3 bg-green-100 px-4 cursor-pointer hover:bg-green-200"
              onClick={() => confirmFixedCell(true)}
            >
              <p className="font-semibold">Fixed:</p>
              <p className="font-bold px-2 py-1 bg-green-200 rounded mx-2">
                {fixedCell.fixedValue}
              </p>
              <Button
                size="sm"
                variant="outline"
                className="bg-green-500 text-white hover:bg-green-600"
                onClick={() => confirmFixedCell(true)}
              >
                <Check size={14} />
              </Button>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

const EditingCell = ({
  rowIndex,
  column,
  colIndex,
  value,
  handleCellClick,
  readOnly,
  cellHasError,
  showErrors,
  isFixed,
  showFixedValues,
  handleCellEdit,
  setEditingCell,
  clearEditingCell,
}: {
  rowIndex: number;
  column: string;
  colIndex: number;
  value: string;
  handleCellClick: (rowIndex: number, column: string) => void;
  readOnly: boolean;
  cellHasError: boolean;
  showErrors: boolean;
  isFixed: boolean;
  showFixedValues: boolean;
  handleCellEdit: (rowIndex: number, column: string, value: string) => void;
  setEditingCell: (rowIndex: number, column: string) => void;
  clearEditingCell: () => void;
}) => {
  return (
    <td
      key={`${rowIndex}-${column}`}
      id={`cell-${rowIndex}-${colIndex}`}
      onClick={() => handleCellClick(rowIndex, column)}
      className={`p-2 border-t border-r border-gray-200 ${
        !readOnly ? "cursor-pointer hover:bg-gray-50" : ""
      } ${cellHasError && showErrors ? "bg-red-100" : ""} ${
        isFixed && showFixedValues ? "bg-yellow-100" : ""
      }`}
    >
      <div className="relative">
        <input
          type="text"
          value={value}
          onChange={(e) =>
            handleCellEdit(
              rowIndex,
              column,
              (e.target as HTMLInputElement).value
            )
          }
          autoFocus
          onBlur={() => clearEditingCell()}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleCellEdit(
                rowIndex,
                column,
                (e.target as HTMLInputElement).value
              );
            } else if (e.key === "Escape") {
              clearEditingCell();
            }
          }}
          className="w-full p-1 border border-gray-300 rounded"
        />
      </div>
    </td>
  );
};

const CellDisplay = ({
  rowIndex,
  column,
  colIndex,
  value,
  handleCellClick,
  cellHasError,
  showErrors,
  cellErrorMessages,
}: {
  rowIndex: number;
  column: string;
  colIndex: number;
  value: string;
  handleCellClick: (rowIndex: number, column: string) => void;
  readOnly: boolean;
  cellHasError: boolean;
  showErrors: boolean;
  isFixed: boolean;
  showFixedValues: boolean;
  cellErrorMessages: string[];
}) => {
  return (
    <td
      key={`${rowIndex}-${column}`}
      id={`cell-${rowIndex}-${colIndex}`}
      onClick={() => handleCellClick(rowIndex, column)}
      className={`${cellHasError && showErrors ? "bg-red-100" : ""}`}
    >
      <div className="relative">
        {
          <div className="flex items-center">
            <span>{value}</span>
            {cellHasError && (
              <Tooltip>
                <TooltipTrigger onClick={(e) => e.stopPropagation()}>
                  <div className="ml-1 text-red-500 cursor-help">
                    <AlertCircle size={14} />
                  </div>
                </TooltipTrigger>
                <TooltipContent onClick={(e) => e.stopPropagation()}>
                  <p>{cellErrorMessages.join(", ")}</p>
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        }
      </div>
    </td>
  );
};

export default CsvDisplay;
