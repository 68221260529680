import { useState } from "react";
import { Separator } from "../shadcn/components/separator";
import { SideNav } from "./SideNav";

export const Layout = (props: {
  pageName?: string;
  pageHeader?: React.ReactNode;
  children: React.ReactNode;
}) => {
  const [mouseOver, setMouseOver] = useState<boolean>(false);

  const pageHeader = props.pageHeader || (
    <h2 className="text-2xl font-bold tracking-tight">{props.pageName}</h2>
  );

  return (
    <div className="flex h-screen bg-gray-100">
      <div
        className="min-w-[50px] max-w-[50px] transition-all duration-300 ease-in-out overflow-visible z-50"
        onMouseEnter={() => {
          setMouseOver(true);
        }}
        onMouseLeave={() => {
          setMouseOver(false);
        }}
      >
        <div
          className={
            mouseOver
              ? "w-[240px] transition-all duration-300 ease-in-out h-screen"
              : "w-[60px] transition-all duration-300 ease-in-out h-screen"
          }
        >
          <SideNav collapsed={!mouseOver} />
        </div>
      </div>
      <div id="main-panel" className="w-[calc(100%-50px)]">
        <div className="pl-2 w-[100%] h-screen overflow-y-scroll">
          <div className="px-8 py-3">{pageHeader}</div>
          <Separator />
          <div className="px-8 pt-6 space-y-8 w-[100%]">{props.children}</div>
        </div>
      </div>
    </div>
  );
};
