import { AuditFactorReview } from "../../contexts/AuditToolContext";
import { AuditTool, Factor, FileIndex } from "../../types";

export type UniqueFunctionalArea = {
  functional_area: string;
  factors: Factor[];
};

export const smartSort = (a: string, b: string) => {
  const aMatch = a.match(/\d+$/);
  const bMatch = b.match(/\d+$/);

  if (aMatch && bMatch) {
    return parseInt(aMatch[0]) - parseInt(bMatch[0]);
  }
  return a.localeCompare(b);
};

export const getUniqueFunctionalAreas = (
  factors: Factor[]
): UniqueFunctionalArea[] => {
  const uniqueFunctionalAreas = Array.from(
    new Set(factors.map((factor) => factor.functional_area))
  );
  return uniqueFunctionalAreas.map((functional_area) => {
    return {
      functional_area: functional_area,
      factors: factors.filter(
        (factor) => factor.functional_area === functional_area
      ),
    };
  });
};

export type UniqueStandard = {
  functional_area: string;
  standard_index: string;
  standard_title: string;
  factors: Factor[];
};
export const getUniqueStandards = (factors: Factor[]): UniqueStandard[] => {
  const uniqueStandards = Array.from(
    new Set(factors.map((factor) => factor.standard_index))
  );
  return uniqueStandards
    .map((standard_index) => {
      const standard = factors.find(
        (factor) => factor.standard_index === standard_index
      );
      return {
        functional_area: standard?.functional_area ?? "",
        standard_index: standard_index ?? "",
        standard_title: standard?.standard_title ?? "Untitled",
        factors: factors.filter(
          (factor) => factor.standard_index === standard_index
        ),
      };
    })
    .sort((a, b) => {
      return smartSort(a.standard_index ?? "", b.standard_index ?? "");
    });
};

export type UniqueElement = {
  functional_area: string;
  standard_index: string;
  standard_title: string;
  element_index: string;
  element_title: string;
  element_explanation: string;
  factors: Factor[];
  element_must_pass: boolean;
};
export const getUniqueElements = (factors: Factor[]): UniqueElement[] => {
  const uniqueElements = Array.from(
    new Set(factors.map((factor) => factor.element_index))
  );
  return uniqueElements
    .map((element_index) => {
      const element = factors.find(
        (factor) => factor.element_index === element_index
      );
      return {
        functional_area: element?.functional_area ?? "",
        standard_index: element?.standard_index ?? "",
        standard_title: element?.standard_title ?? "",
        element_index: element_index ?? "",
        element_title: element?.element_title ?? "Untitled",
        element_explanation: element?.element_explanation ?? "",
        element_must_pass: element?.element_must_pass ?? false,
        factors: factors.filter(
          (factor) => factor.element_index === element_index
        ),
      };
    })
    .sort((a, b) => {
      return smartSort(a.element_index ?? "", b.element_index ?? "");
    });
};

export const sortFactors = (factors: Factor[], functionalArea: string) => {
  const sorted = factors
    .filter((factor) => factor.functional_area === functionalArea)
    .sort((a, b) => {
      if (a.standard_index === b.standard_index) {
        if (a.element_index === b.element_index) {
          return smartSort(a.factor_index ?? "", b.factor_index ?? "");
        }
        return smartSort(a.element_index ?? "", b.element_index ?? "");
      }
      return smartSort(a.standard_index ?? "", b.standard_index ?? "");
    });
  const lite = sorted.map(
    (factor) =>
      `${factor.standard_index ?? ""} - ${factor.element_index ?? ""} - ${factor.factor_index ?? ""}`
  );
  return sorted;
};

export const createFileIndexFromAuditTool = (
  auditTool: AuditTool,
  selectedRelatedDocIds: string[],
  reviewedDocIds: string[] = []
): FileIndex => {
  // Create the root folder
  let fpath = auditTool.folder_path;
  if (!fpath) {
    if (auditTool.doc_folder_path) {
      const docFolderPath = auditTool.doc_folder_path;
      if ("sharepoint_folder_path" in docFolderPath) {
        fpath = docFolderPath.sharepoint_folder_path;
      } else if ("prefix" in docFolderPath) {
        fpath = docFolderPath.prefix;
      }
    }
  }
  const baseFolderName = fpath?.split("/").pop() || auditTool.name;

  const rootFolder: FileIndex = {
    type: "folder",
    name: baseFolderName,
    id: null,
    children: [],
    doc_type_name: "",
    regulatory_doc_id: null,
  };

  // Process each related document
  if (!auditTool.related_docs || auditTool.related_docs.length === 0) {
    return rootFolder;
  }
  auditTool.related_docs.forEach((doc) => {
    let currentLevel = rootFolder.children;
    const fullPath = doc.doc_url?.split("/").filter(Boolean) || [];
    const baseIndex = fullPath.indexOf(baseFolderName);
    const pathParts = baseIndex >= 0 ? fullPath.slice(baseIndex + 1) : [];
    // Create folder structure for each path segment except the last one (which is the file)
    for (let i = 0; i < pathParts.length - 1; i++) {
      const folderName = pathParts[i];
      let folder = currentLevel.find(
        (item) => item.type === "folder" && item.name === folderName
      );

      if (!folder) {
        folder = {
          type: "folder",
          name: folderName,
          id: null,
          children: [],
          doc_type_name: "",
          regulatory_doc_id: null,
        };
        currentLevel.push(folder);
      }

      currentLevel = folder.children;
    }
    // Add the file at the current level
    currentLevel.push({
      type: "file",
      name: doc.doc_name,
      id: doc.id,
      children: [],
      doc_type_name: doc.doc_type_name,
      regulatory_doc_id: doc.doc_id,
      isSelected: selectedRelatedDocIds.includes(doc.id),
      isReviewed: reviewedDocIds.includes(doc.id),
    });
  });

  return rootFolder;
};

// Regex arrays for policy keywords and file keywords.
// Note: using the 'i' flag makes matching case-insensitive.
const policyRegexes = [
  /\bpolic(?:y|ies)\b/i, // "policy" or "policies"
  /\bprocedur(?:e|es)\b/i, // "procedure" or "procedures"
  /\bmanuals?\b/i, // "manual" or "manuals"
  /\bprotocols?\b/i, // "protocol" or "protocols"
  /\bguidelin(?:e|es)\b/i, // "guideline" or "guidelines"
  /\btemplates?\b/i, // "template" or "templates"
  /\bstandards?\b/i, // "standard" or "standards"
  /\bcontracts?\b/i, // "contract" or "contracts"
  /\bagreements?\b/i, // "agreement" or "agreements"
  /\bmemorand(?:um|a)\b/i, // "memorandum" or "memoranda"
  /\bjob\s+descriptions?\b/i, // "job description" or "job descriptions"
  /\bdocumented\s+process(?:es)?\b/i, // "documented process" or "documented processes"
  /\bmaterials?\b/i, // "material" or "materials"
];

const fileRegexes = [
  /\bfiles?\b/i, // "file" or "files"
  /\brecords?\b/i, // "record" or "records"
  /\blogs?\b/i, // "log" or "logs"
  /\bsamples?\b/i, // "sample" or "samples"
  /\breports?\b/i, // "report" or "reports"
  /\bminutes?\b/i, // "minute" or "minutes"
  /\bdenials?\b/i, // "denial" or "denials"
  /\btracking\b/i, // "tracking"
  /\bevidenc(?:e|es)\b/i, // "evidence" or "evidences"
  /\bfollow[-\s]?up\b/i, // "follow-up" or "follow up"
  /\bmeetings?\b/i, // "meeting" or "meetings"
  /\baudit(s)?\b/i, // "audit" or "audits"
  /\bassess(?:ment|ments)\b/i, // "assessment" or "assessments"
  /\bmaterials?\b/i, // "material" or "materials" (added here too for ambiguous cases)
];

// Function to classify a source string
export function classifySource(sources: string[]) {
  // Check if any of the policy regexes match
  const isPolicy = sources.some((source) =>
    policyRegexes.some((regex) => regex.test(source))
  );
  // Check if any of the file regexes match
  const isFile = sources.some((source) =>
    fileRegexes.some((regex) => regex.test(source))
  );

  if (isPolicy && isFile) {
    return "both";
  } else if (isPolicy) {
    return "policy";
  } else if (isFile) {
    return "file";
  } else {
    return "both";
  }
}

export const escapeReviewMarkdown = (review: AuditFactorReview) => {
  const fileNames = Array.from(
    new Set(
      review.review_docs
        .sort((a, b) => (a.review_rank ?? 0) - (b.review_rank ?? 0))
        .map((doc) => doc.doc_name)
    )
  );
  let summary = review.review_summary;
  for (const name of fileNames) {
    const nameRegex = new RegExp(name, "g");
    const noExtension = fileNameWithoutExtension(name);
    const noExtensionRegex = new RegExp(noExtension, "g");

    if (nameRegex.test(summary)) {
      summary = summary.replaceAll(nameRegex, `\`doc:${name}\``);
    } else {
      summary = summary.replaceAll(noExtensionRegex, `\`doc:${noExtension}\``);
    }
  }
  return summary.trimEnd();
};

export const fileNameWithoutExtension = (fileName: string) => {
  return fileName?.replace(/\.[^.]+$/, "");
};
