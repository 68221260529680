import { useAuthInfo } from "@propelauth/react";
import { Loader2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import CmsRequirementTable from "../../components/CmsRequirementTable";
import CsvDisplay from "../../components/CsvDisplay";
import { Layout } from "../../components/Layout";
import { Button } from "../../shadcn/components/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../shadcn/components/card";
import { Input } from "../../shadcn/components/input";
import { Label } from "../../shadcn/components/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import { CmsRequirement, CmsTool } from "../../types";
import { auditUniverse, validateUniverse } from "../../utils/apiCalls";

export type CsvError = {
  [key: string]: {
    error: string[];
    cell_errors: { [key: string]: string[] };
  };
};

export type CsvData = {
  headers: string[];
  data: string[][];
};

enum FieldType {
  CHAR = "CHAR",
  DATE = "DATE",
  TIME = "TIME",
  BOOL = "BOOL",
  DETERMINATION = "DETERMINATION",
  MBI = "MBI",
  PROCESSED = "PROCESSED",
  REQUESTOR = "REQUESTOR",
  RECONSIDERATION = "RECONSIDERATION",
}

export type Col = {
  col_id: string;
  field_name: string;
  field_type: FieldType;
  field_length: number;
  description: string;
  nullable: boolean;
};

export type FixedCell = {
  rowIndex: number;
  colIndex: number;
  originalValue: string;
  fixedValue: string;
  isAccepted?: boolean;
  isRejected?: boolean;
};

export type ValidationResult = {
  cols: Record<string, Col>;
  errors: CsvError;
  modified_data: CsvData;
};

const splitUnescapedCommas = (str: string): string[] => {
  const regex = /(?:^|,)(?:"([^"]*)"|([^,]*))/g;
  const result: string[] = [];
  let match;
  while ((match = regex.exec(str)) !== null) {
    if (regex.lastIndex === match.index) {
      regex.lastIndex++;
    }
    result.push(match[1] !== undefined ? match[1] : match[2]);
  }
  return result;
};

const parseCsv = (csv: string) => {
  const rows = csv.split("\n");
  const headers = splitUnescapedCommas(rows[0]);
  const data = rows.slice(1).map((row) => splitUnescapedCommas(row));
  return { headers, data };
};

const convertDataToCsv = (data: CsvData) => {
  return [
    data.headers
      .map((header) => (header.includes(",") ? `"${header}"` : header))
      .join(","),
    ...data.data.map((row) =>
      row.map((cell) => (cell.includes(",") ? `"${cell}"` : cell)).join(",")
    ),
  ].join("\n");
};

export const CMSAuditDashboard = () => {
  const authInfo = useAuthInfo();
  const [universeFile, setUniverseFile] = useState<File | null>(null);
  const [cmsTool, setCmsTool] = useState<CmsTool>("ODAG");
  const [universeId, setUniverseId] = useState<string>("1");
  const [isValidating, setIsValidating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  //DATA VERSIONS
  const [fileData, setFileData] = useState<CsvData>({
    headers: [],
    data: [],
  });
  const [validationResult, setValidationResult] =
    useState<ValidationResult | null>(null);
  const [editableData, setEditableData] = useState<CsvData>({
    headers: [],
    data: [],
  });

  const [fixedCells, setFixedCells] = useState<FixedCell[]>([]);
  const [currentFixingCell, setCurrentFixingCell] = useState<{
    rowIndex: number;
    colIndex: number;
  } | null>(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showFixedData, setShowFixedData] = useState(false);

  // Add a state to track the current fixed cell index
  const [currentFixedCellIndex, setCurrentFixedCellIndex] = useState<number>(0);

  const [auditResults, setAuditResults] = useState<CmsRequirement[]>([]);

  // Add a state to track the D-SNP / AIP toggle
  const [isDsnp, setIsDsnp] = useState(false);

  // Ref for the audit results section
  const auditResultsRef = useRef<HTMLDivElement | null>(null);

  // Group fixed cells by row when they are set
  useEffect(() => {
    const grouped: Record<number, FixedCell[]> = {};
    fixedCells.forEach((cell) => {
      if (!grouped[cell.rowIndex]) {
        grouped[cell.rowIndex] = [];
      }
      grouped[cell.rowIndex].push(cell);
    });
    handleReviewFixedCells();
  }, [fixedCells]);

  // Scroll to audit results when they load
  useEffect(() => {
    if (auditResults.length > 0 && auditResultsRef.current) {
      auditResultsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [auditResults]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUniverseFile(e.target.files[0]);
      setFileUploaded(false);
      setValidationResult(null);
      setShowErrors(false);
      setShowFixedData(false);
      setFixedCells([]);
    }
  };

  const handleValidateUniverse = async (
    file: File,
    cmsTool: CmsTool,
    universeId: string,
    accessToken: string,
    fixValues: boolean
  ) => {
    setIsValidating(true);
    try {
      const result = await validateUniverse(
        file,
        cmsTool,
        universeId,
        accessToken,
        fixValues
      );

      const { headers, data } = parseCsv(result.modified_csv);

      setValidationResult({
        ...result,
        modified_data: { headers, data },
      });
      const modifiedData = parseCsv(result.modified_csv);

      setEditableData((prev) => ({
        ...prev,
        headers: modifiedData.headers,
        data: modifiedData.data,
      }));
      setShowErrors(true);

      // If we're fixing values, identify which cells were fixed
      if (fixValues && result.data) {
        const newFixedCells: FixedCell[] = [];
        const modifiedData = parseCsv(result.modified_csv);
        // Compare original data with modified data to find fixed cells
        editableData.data.forEach((originalRow, rowIndex) => {
          if (rowIndex < result.data!.length) {
            const modifiedRow = modifiedData.data[rowIndex];

            originalRow.forEach((colName, index) => {
              const originalValue = String(colName || "");
              const modifiedValue = String(modifiedRow[index] || "");

              if (originalValue !== modifiedValue) {
                console.log(
                  `Fixed cell found: ${originalValue} -> ${modifiedValue}`
                );
                newFixedCells.push({
                  rowIndex,
                  colIndex: index,
                  originalValue,
                  fixedValue: modifiedValue,
                });
              }
            });
          }
        });
        setFixedCells(newFixedCells);
        setShowFixedData(true);
      } else {
        setFileData({ headers, data });
      }
    } catch (error) {
      console.error("Error validating universe:", error);
    } finally {
      setIsValidating(false);
    }
  };

  const handleUploadFile = async () => {
    if (!universeFile || !authInfo?.accessToken) {
      return;
    }
    await handleValidateUniverse(
      universeFile,
      cmsTool,
      universeId,
      authInfo.accessToken,
      false
    );
    setFileUploaded(true);
  };

  // Function to re-validate edited data
  const handleRevalidateEdited = async () => {
    if (!authInfo?.accessToken || !editableData.data.length) {
      return;
    }

    setIsValidating(true);

    try {
      const csvContent = convertDataToCsv(editableData);
      // Create a new File object from the CSV content
      const blob = new Blob([csvContent], { type: "text/csv" });
      const editedFile = new File([blob], "edited_data.csv", {
        type: "text/csv",
      });

      // Validate the edited file
      handleValidateUniverse(
        editedFile,
        cmsTool,
        universeId,
        authInfo.accessToken,
        true
      );
    } catch (error) {
      console.error("Error revalidating edited data:", error);
    } finally {
      setIsValidating(false);
    }
  };

  const handleAuditUniverse = async () => {
    if (!universeFile || !authInfo?.accessToken) {
      return;
    }
    const editedData = convertDataToCsv(editableData);
    const blob = new Blob([editedData], { type: "text/csv" });
    const editedFile = new File([blob], "edited_data.csv", {
      type: "text/csv",
    });
    const results = await auditUniverse(
      editedFile,
      cmsTool,
      universeId,
      authInfo.accessToken,
      isDsnp
    );
    console.log(results);
    setAuditResults(results);
  };

  const toggleFixedValues = () => {
    setShowFixedData((prev) => !prev);
  };

  const scrollToCell = (rowIndex: number, colIndex: number) => {
    const cellId = `cell-${rowIndex}-${colIndex}`;
    const cell = document.getElementById(cellId);
    if (cell) {
      cell.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const handleToggleFixedCell = (
    rowIndex: number,
    colIndex: number,
    useFixed: boolean
  ) => {
    // Update the display data based on the toggle
    if (validationResult) {
      const newData = [...editableData.data];

      // Find the fixed cell
      const fixedCell = fixedCells.find(
        (cell) => cell.rowIndex === rowIndex && cell.colIndex === colIndex
      );

      if (fixedCell) {
        // Update the data with either the fixed or original value
        newData[rowIndex][colIndex] = useFixed
          ? fixedCell.fixedValue
          : fixedCell.originalValue;

        // Update the validation result
        setEditableData((prev) => ({
          ...prev,
          data: newData,
        }));
      }
    }
  };

  const getDisplayData = () => {
    if (!validationResult) return fileData;

    if (showFixedData && validationResult.modified_data) {
      return validationResult.modified_data;
    }

    return fileData;
  };

  // Function to handle reviewing fixed cells
  const handleReviewFixedCells = (
    rowIndex: number = -1,
    colIndex: number = -1
  ) => {
    if (rowIndex !== -1 && colIndex !== -1) {
      scrollToCell(rowIndex, colIndex);
      return;
    }
    if (fixedCells.length) {
      scrollToCell(fixedCells[0].rowIndex, fixedCells[0].colIndex);
      return;
    }
  };

  // Function to confirm keep/revert action
  const confirmKeepRevert = (
    rowIndex: number,
    colIndex: number,
    useFixed: boolean
  ) => {
    if (!fixedCells) return;
    const newData = [...editableData.data];
    const fixedCell = fixedCells.find(
      (cell: FixedCell) =>
        cell.rowIndex === rowIndex && cell.colIndex === colIndex
    );
    if (fixedCell) {
      newData[rowIndex][colIndex] = useFixed
        ? fixedCell.fixedValue
        : fixedCell.originalValue;
      setEditableData({ ...editableData, data: newData });

      // Remove the fixed cell from the list
      setFixedCells((prev: FixedCell[]) =>
        prev.filter((cell: FixedCell) => cell !== fixedCell)
      );
    }
  };

  // Function to fix all cells in a column
  const fixAllInColumn = (colIndex: number) => {
    const newData = [...editableData.data];
    fixedCells?.forEach((cell) => {
      if (cell.colIndex === colIndex) {
        newData[cell.rowIndex][colIndex] = cell.fixedValue;
      }
    });
    setEditableData({ ...editableData, data: newData });

    // Remove all fixed cells in the column from the list
    setFixedCells((prev: FixedCell[]) =>
      prev.filter((cell) => cell.colIndex !== colIndex)
    );
  };

  return (
    <Layout>
      <div className="container mx-auto py-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">CMS Audit Dashboard</h1>
        </div>

        <div className="grid grid-cols-1 gap-6">
          <Card className="w-full">
            <CardHeader>
              <CardTitle>Upload Universe</CardTitle>
              <CardDescription>
                Upload a universe file to validate, clean, and evaluate against
                CMS requirements.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="audit-area">Audit Area</Label>
                    <Select
                      value={cmsTool}
                      onValueChange={(value) => setCmsTool(value as CmsTool)}
                    >
                      <SelectTrigger id="audit-area">
                        <SelectValue placeholder="Select Audit Area" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="CDAG">CDAG</SelectItem>
                        <SelectItem value="CPE">CPE</SelectItem>
                        <SelectItem value="FA">FA</SelectItem>
                        <SelectItem value="ODAG">ODAG</SelectItem>
                        <SelectItem value="SNPCC">SNPCC</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="universe-id">Universe ID</Label>
                    <Select
                      value={universeId}
                      onValueChange={(value) => setUniverseId(value)}
                    >
                      <SelectTrigger id="universe-id">
                        <SelectValue placeholder="Select Universe ID" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="1">1</SelectItem>
                        <SelectItem value="2">2</SelectItem>
                        <SelectItem value="3">3</SelectItem>
                        <SelectItem value="4">4</SelectItem>
                        <SelectItem value="5">5</SelectItem>
                        <SelectItem value="6">6</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="universe-file">Universe File</Label>
                  <Input
                    id="universe-file"
                    type="file"
                    accept=".csv,.xlsx"
                    onChange={handleFileChange}
                  />
                </div>
                <Button
                  onClick={handleUploadFile}
                  disabled={!universeFile || fileUploaded || isUploading}
                  className="w-full mt-4"
                >
                  {isUploading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Uploading...
                    </>
                  ) : (
                    "Upload File"
                  )}
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>

        {fileUploaded && (
          <div className="mt-8">
            <Card>
              <CardHeader>
                <CardTitle>Validation Results</CardTitle>
                <CardDescription>
                  {Object.keys(validationResult?.errors || {}).length === 0
                    ? "All rows are valid!"
                    : `Found ${Object.keys(validationResult?.errors || {}).length} rows with errors.`}
                </CardDescription>
                {fixedCells.length > 0 ? (
                  <div className="flex justify-center mt-4">
                    <Button
                      onClick={() => {
                        const firstNonAddressedFixedCell = fixedCells.find(
                          (cell) => !cell.isAccepted && !cell.isRejected
                        );
                        if (firstNonAddressedFixedCell) {
                          handleReviewFixedCells(
                            firstNonAddressedFixedCell.rowIndex,
                            firstNonAddressedFixedCell.colIndex
                          );
                        }
                      }}
                      disabled={isValidating}
                      variant="default"
                      className="mx-2"
                    >
                      Review Next Fix
                    </Button>
                  </div>
                ) : (
                  <div className="flex justify-center items-center mt-4 space-x-4">
                    {Object.keys(validationResult?.errors || {}).length > 0 && (
                      <Button
                        onClick={handleRevalidateEdited}
                        disabled={isValidating}
                        variant="default"
                        className="mx-2"
                      >
                        {isValidating ? (
                          <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Cleaning Data...
                          </>
                        ) : (
                          "Clean Data"
                        )}
                      </Button>
                    )}
                    {Object.keys(validationResult?.errors || {}).length === 0 &&
                      fixedCells.length === 0 && (
                        <Card className="flex flex-col items-center p-4">
                          <div className="flex items-center space-x-2 mb-2">
                            <input
                              type="checkbox"
                              id="dsnp-toggle"
                              checked={isDsnp}
                              onChange={() => setIsDsnp(!isDsnp)}
                              className="form-checkbox h-5 w-5 text-blue-600"
                            />
                            <label htmlFor="dsnp-toggle" className="text-sm">
                              D-SNP / AIP
                            </label>
                          </div>
                          <Button
                            onClick={handleAuditUniverse}
                            className="w-full"
                          >
                            Audit Universe
                          </Button>
                        </Card>
                      )}
                  </div>
                )}
              </CardHeader>
              <CardContent>
                <CsvDisplay
                  universeCols={validationResult ? validationResult.cols : {}}
                  data={getDisplayData()}
                  editableData={editableData}
                  setEditableData={setEditableData}
                  errors={
                    showErrors && validationResult
                      ? validationResult.errors
                      : {}
                  }
                  fixedCells={fixedCells}
                  showFixedValues={showFixedData}
                  onToggleFixedCell={handleToggleFixedCell}
                  readOnly={!showErrors}
                  className="mt-2"
                  confirmFixedCell={confirmKeepRevert}
                  fixAllInColumn={fixAllInColumn}
                  currentFixingCell={currentFixingCell}
                  setCurrentFixingCell={setCurrentFixingCell}
                />
              </CardContent>
            </Card>
          </div>
        )}

        {auditResults?.length > 0 && (
          <div className="mt-8" ref={auditResultsRef}>
            <Card>
              <CardHeader>
                <CardTitle>Audit Results</CardTitle>
                <CardDescription>
                  Displaying the results of the universe audit.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <CmsRequirementTable requirements={auditResults} />
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CMSAuditDashboard;
