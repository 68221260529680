import { ListBulletIcon, TableIcon } from "@radix-ui/react-icons";
import { Button } from "../shadcn/components/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";

export const ListTableToggle = (props: {
  viewType: "list" | "table";
  setViewType: (viewType: "list" | "table") => void;
  title: string;
}) => {
  return (
    <div className="flex items-center space-x-1 bg-gray-200 p-1 rounded-md">
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <Button
            size="icon"
            variant={props.viewType === "list" ? "outline" : "ghost"}
            onClick={() => {
              if (props.viewType !== "list") {
                props.setViewType("list");
              }
            }}
          >
            <ListBulletIcon className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>View {props.title} as a List</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <Button
            size="icon"
            variant={props.viewType === "table" ? "outline" : "ghost"}
            onClick={() => {
              if (props.viewType !== "table") {
                props.setViewType("table");
              }
            }}
          >
            <TableIcon className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>View {props.title} as a Table</TooltipContent>
      </Tooltip>
    </div>
  );
};
