import { useAuthInfo } from "@propelauth/react";
import { createContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { PdfCitation } from "../components/PdfViewer/PdfHighlighter/types";
import { Requirement } from "../types";
import { generateRequirement, getRequirements } from "../utils/apiCalls";
import { citationSortOrder, loadAndFormatTime } from "../utils/format";

interface RequirementContextProps {
  requirements: Requirement[];
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  loadRequirements: (docId: string) => Promise<void>;
  addRequirement: (
    docId: string,
    pageNumber: number,
    citation?: PdfCitation
  ) => Promise<void>;
  generateRequirementLoading: boolean;
  requirementsLoading: boolean;
  viewType: "table" | "list";
  setViewType: React.Dispatch<React.SetStateAction<"table" | "list">>;
  activeRequirement: Requirement | undefined;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editingIndividualRequirementCitation: boolean;
  setEditingIndividualRequirementCitation: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  activeRequirementId: string | null;
  setActiveRequirementId: React.Dispatch<React.SetStateAction<string | null>>;
  individualRequirementActiveCitationIndex: number;
  setIndividualRequirementActiveCitationIndex: React.Dispatch<
    React.SetStateAction<number>
  >;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

export const RequirementContext = createContext<RequirementContextProps>({
  requirements: [],
  setRequirements: () => {},
  loadRequirements: async () => {},
  addRequirement: async () => {},
  generateRequirementLoading: false,
  requirementsLoading: false,
  viewType: "table",
  setViewType: () => {},
  activeRequirement: undefined,
  activeRequirementId: null,
  setActiveRequirementId: () => {},
  editing: false,
  setEditing: () => {},
  editingIndividualRequirementCitation: false,
  setEditingIndividualRequirementCitation: () => {},
  individualRequirementActiveCitationIndex: 0,
  setIndividualRequirementActiveCitationIndex: () => {},
  activeTab: "",
  setActiveTab: () => {},
});

export const RequirementProvider = (props: {
  children: React.ReactNode;
  defaultActiveTab: string;
}) => {
  const { children } = props;
  const authInfo = useAuthInfo();
  const [activeRequirementId, setActiveRequirementId] = useState<string | null>(
    null
  );
  const [editing, setEditing] = useState<boolean>(false);
  const [
    editingIndividualRequirementCitation,
    setEditingIndividualRequirementCitation,
  ] = useState<boolean>(false);
  const [requirementsLoading, setRequirementsLoading] =
    useState<boolean>(false);
  const [requirements, setRequirements] = useState<Requirement[]>([]);
  const [viewType, setViewType] = useState<"table" | "list">("table");
  const [generateRequirementLoading, setGenerateRequirementLoading] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [
    individualRequirementActiveCitationIndex,
    setIndividualRequirementActiveCitationIndex,
  ] = useState(0);
  const [activeTab, setActiveTab] = useState<string>(props.defaultActiveTab);
  const activeRequirement = requirements.find(
    (requirement) => requirement.id === activeRequirementId
  );

  useEffect(() => {
    const paramViewType = searchParams.get("viewType");
    if (paramViewType) {
      setViewType(paramViewType as "table" | "list");
      if (paramViewType === "list") {
        const paramRequirementId = searchParams.get("requirementId");
        if (paramRequirementId) {
          setActiveRequirementId(paramRequirementId);
        }
      }
    }
  }, []);

  const loadRequirements = async (docId: string) => {
    setRequirementsLoading(true);
    getRequirements(docId, authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        const newRequirements = response.requirements.sort(citationSortOrder);
        setRequirements(newRequirements);
        if (
          response.requirements.length > 0 &&
          searchParams.get("requirementId") === null
        ) {
          const mostRecentRequirement = response.requirements.reduce(
            (prev, current) => {
              const prevDate = new Date(prev.updated_at);
              const currentDate = new Date(current.updated_at);
              return currentDate > prevDate ? current : prev;
            }
          );

          const timestamp = loadAndFormatTime(mostRecentRequirement.updated_at);

          // toast.message(`Continue where you left off?`, {
          //   description: timestamp,
          //   action: {
          //     label: "View",
          //     onClick: () => {
          //       setActiveTab("requirements");
          //       setViewType("list");
          //       setActiveRequirementId(mostRecentRequirement.id!);
          //     },
          //   },
          //   duration: 15000, // 15 seconds
          //   position: "top-center",
          //   closeButton: true,
          // });
        }
      } else {
        toast.error("Failed to load requirements");
      }
      setRequirementsLoading(false);
    });
  };

  const addRequirement = async (
    docId: string,
    pageNumber: number,
    citation?: PdfCitation
  ) => {
    let response = null;

    // if there is no selection but existing requirements then generate a new one
    if (!citation && requirements.length > 0) {
      setGenerateRequirementLoading(true);
      response = await generateRequirement(
        docId,
        pageNumber,
        authInfo.accessToken ?? ""
      );
      // if (response !== null && response.length === 0) {
      //   toast.info("No requirement to suggest");
      // }
      setGenerateRequirementLoading(false);
    }
    let newRequirement: Requirement;

    // if the response is not null, use the first requirement in the response otherwise create a new one
    if (response !== null && response.length > 0) {
      newRequirement = response[0];
      newRequirement.id = null;
      newRequirement.citations[0].id = null;
    } else if (citation) {
      newRequirement = {
        id: null,
        text: citation.match,
        reference_doc_types: activeRequirement?.reference_doc_types ?? null,
        citations: [
          {
            id: null,
            page: pageNumber,
            text: citation.match,
            formatted_text: null,
            created_at: new Date().toISOString().slice(0, -1),
            user: null,
            start_index: null,
            match_index: null,
          },
        ],
        action_items: [],
        tag: null,
        impacted_documents: [],
        assignees: [],
        updated_at: new Date().toISOString().slice(0, -1),
        reference_documents: [],
        due_date: null,
        summary: null,
        analysis: null,
        is_gap: null,
      };
    } else {
      newRequirement = {
        id: null,
        text: "",
        reference_doc_types: activeRequirement?.reference_doc_types ?? null,
        citations: [],
        action_items: [],
        tag: null,
        impacted_documents: [],
        assignees: [],
        updated_at: new Date().toISOString().slice(0, -1),
        reference_documents: [],
        due_date: null,
        summary: null,
        analysis: null,
        is_gap: null,
      };
    }
    setRequirements((requirements) => [
      ...requirements.filter((requirement) => !!requirement.id),
      newRequirement,
    ]);
    setActiveRequirementId(null);
    setEditing(true);
  };

  return (
    <RequirementContext.Provider
      value={{
        requirements,
        setRequirements,
        loadRequirements,
        addRequirement,
        generateRequirementLoading,
        requirementsLoading,
        viewType,
        setViewType,
        activeRequirement,
        editing,
        setEditing,
        editingIndividualRequirementCitation,
        setEditingIndividualRequirementCitation,
        activeRequirementId,
        setActiveRequirementId,
        individualRequirementActiveCitationIndex,
        setIndividualRequirementActiveCitationIndex,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </RequirementContext.Provider>
  );
};

export const requirementCanBeAutoPopulated = (requirement: Requirement) => {
  // label is null, no citations, no assignees, no action items
  return (
    requirement.id !== null &&
    requirement.tag === null &&
    requirement.impacted_documents.length === 0 &&
    requirement.reference_documents.length === 0 &&
    requirement.assignees.length === 0 &&
    requirement.action_items.length === 0
  );
};
