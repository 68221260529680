import { useAuthInfo } from "@propelauth/react";
import { CopyIcon, ReloadIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import { Layout } from "../../components/Layout";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "../../shadcn/components/button";
import { Calendar } from "../../shadcn/components/calendar";
import { Input } from "../../shadcn/components/input";
import { Label } from "../../shadcn/components/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn/components/tabs";
import { cn } from "../../shadcn/lib/utils";
import { distributeReq, getRecentDocs, ingestApl } from "../../utils/apiCalls";
import { TimeAgo } from "../../utils/format";

export function RegulatorSelect({
  regulator,
  setRegulator,
}: {
  regulator: string;
  setRegulator: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <Select onValueChange={(value) => setRegulator(value)}>
      <SelectTrigger className="w-[200px] bg-white">
        <SelectValue placeholder="Select regulator" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="dhcs">DHCS APL</SelectItem>
        <SelectItem value="dhcs_bhin">DHCS BHIN</SelectItem>
        <SelectItem value="dmhc">DMHC APL</SelectItem>
      </SelectContent>
    </Select>
  );
}

export function YearSelect({
  year,
  setYear,
}: {
  year: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
}) {
  const years = Array.from({ length: 9 }, (_, i) => 2020 + i);

  return (
    <Select
      onValueChange={(value) => setYear(Number(value))}
      defaultValue="2025"
    >
      <SelectTrigger className="w-[200px] bg-white">
        <SelectValue placeholder="Select year" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {years.map((y) => (
            <SelectItem key={y} value={y.toString()}>
              {y}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export function DatePicker({
  date,
  setDate,
}: {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}

const AplIngestView = () => {
  const authInfo = useAuthInfo();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedAttachment0, setSelectedAttachment0] = useState<File | null>(
    null
  );
  const [selectedAttachment1, setSelectedAttachment1] = useState<File | null>(
    null
  );
  const [selectedAttachment2, setSelectedAttachment2] = useState<File | null>(
    null
  );
  const [selectedAttachment3, setSelectedAttachment3] = useState<File | null>(
    null
  );
  const [selectedAttachment4, setSelectedAttachment4] = useState<File | null>(
    null
  );
  const [selectedAttachment5, setSelectedAttachment5] = useState<File | null>(
    null
  );
  const [selectedAttachment6, setSelectedAttachment6] = useState<File | null>(
    null
  );
  const [selectedAttachment7, setSelectedAttachment7] = useState<File | null>(
    null
  );
  const [selectedAttachment8, setSelectedAttachment8] = useState<File | null>(
    null
  );
  const [selectedAttachment9, setSelectedAttachment9] = useState<File | null>(
    null
  );
  const [uploading, setUploading] = useState(false);
  const [regulator, setRegulator] = useState("");
  const [aplDate, setAplDate] = useState<Date | undefined>(undefined);
  const [aplIdentifier, setAplIdentifier] = useState("");
  const [aplSubject, setAplSubject] = useState("");
  const [supersededAplIdentifier, setSupersededAplIdentifier] = useState("");

  const isValidAplFormat = (aplId: string) => {
    const aplRegex = /^\d{2}-\d{3}$/;
    return aplRegex.test(aplId);
  };

  const handleFileUpload = async () => {
    if (!regulator) {
      toast.error("Please select a regulator");
      return;
    }
    if (!selectedFile) {
      toast.error("Please select a file");
      return;
    }
    if (!aplDate) {
      toast.error("Please select a APL date");
      return;
    }
    if (!aplIdentifier) {
      toast.error("Please enter an APL identifier");
      return;
    }
    if (!aplSubject) {
      toast.error("Please enter a subject");
      return;
    }

    setUploading(true);
    try {
      const result = await ingestApl(
        regulator,
        selectedFile,
        aplDate?.toISOString().split("T")[0] ?? "",
        aplIdentifier,
        aplSubject,
        supersededAplIdentifier,
        [
          selectedAttachment0,
          selectedAttachment1,
          selectedAttachment2,
          selectedAttachment3,
          selectedAttachment4,
          selectedAttachment5,
          selectedAttachment6,
          selectedAttachment7,
          selectedAttachment8,
          selectedAttachment9,
        ].filter((attachment) => !!attachment),
        authInfo.accessToken ?? null
      );

      if (result.success) {
        toast.success("APL ingested successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(result.error || "Failed to ingest APL");
      }
    } catch (error) {
      toast.error("Failed to ingest APL");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="pl-1 space-y-4">
      <div className="space-y-6">
        <div className="font-semibold text-lg pt-4">Upload APL</div>
        <div className="flex-column items-center space-y-6">
          <div className="flex flex-col items-start space-y-2">
            <Label>
              Regulator <span className="text-red-500">*</span>
            </Label>
            <div className="flex items-center space-x-2">
              <RegulatorSelect
                regulator={regulator}
                setRegulator={setRegulator}
              />
            </div>
          </div>
          <div className="flex flex-col items-start space-y-2">
            <Label>
              APL Date <span className="text-red-500">*</span>
            </Label>
            <div className="flex items-center space-x-2">
              <DatePicker date={aplDate} setDate={setAplDate} />
              {aplDate && (
                <p className="text-sm text-red-500">
                  {(() => {
                    const lowerBound = new Date();
                    lowerBound.setDate(lowerBound.getDate() - 120);
                    const today = new Date();
                    return aplDate >= lowerBound && aplDate <= today
                      ? "✅"
                      : "❌ Must be within the past 7 days.";
                  })()}
                </p>
              )}
            </div>
          </div>
          <div className="flex-col items-center space-y-1">
            <div className="flex-col items-center">
              <Label>
                APL Identifier <span className="text-red-500">*</span>
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <Input
                value={aplIdentifier}
                onChange={(e) => setAplIdentifier(e.target.value)}
                placeholder="e.g. 25-001"
                className="max-w-[250px]"
              />
              {aplIdentifier && (
                <p className="text-sm text-red-500">
                  {isValidAplFormat(aplIdentifier)
                    ? "✅"
                    : "❌ Format must be 'YY-XXX'"}
                </p>
              )}
            </div>
          </div>
          <div className="flex-column items-center space-y-1">
            <div className="flex-col items-center">
              <Label>
                Subject <span className="text-red-500">*</span>
              </Label>
              <p className="text-sm italic text-gray-400">
                exclude text in brackets
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <Input
                value={aplSubject}
                onChange={(e) => setAplSubject(e.target.value)}
                placeholder="e.g. Street Medicine Provider: Definitions and Participation in Managed Care"
                className="max-w-[700px]"
              />
              {aplSubject && (
                <p className="text-sm text-red-500">
                  {aplSubject.trim().length > 0
                    ? "✅"
                    : "❌ Subject cannot be empty"}
                </p>
              )}
            </div>
          </div>
          <div className="flex-col items-center space-y-1">
            <div className="flex-col items-center">
              <Label>Superseded APL Identifier</Label>
              <p className="text-sm italic text-gray-400">
                If the uploaded APL supersedes another APL, enter the superseded
                APL identifier here. Otherwise, leave blank.
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <Input
                value={supersededAplIdentifier}
                onChange={(e) => setSupersededAplIdentifier(e.target.value)}
                placeholder="e.g. 23-023"
                className="max-w-[250px]"
              />
              {supersededAplIdentifier && (
                <p className="text-sm text-red-500">
                  {isValidAplFormat(supersededAplIdentifier)
                    ? "✅"
                    : "❌ Format must be 'YY-XXX'"}
                </p>
              )}
            </div>
          </div>
          <div className="flex-column items-center space-y-1">
            <div className="flex-col items-center">
              <Label>
                APL File <span className="text-red-500">*</span>
              </Label>
              <p className="text-sm italic text-gray-400">
                If there is a redlined version against the <u>superseded</u>{" "}
                APL, upload it instead.
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <Input
                type="file"
                onChange={(e) => setSelectedFile(e.target.files?.[0] || null)}
                accept=".pdf,.doc,.docx"
                className="max-w-[500px]"
              />
              {selectedFile && <p>✅</p>}
            </div>
          </div>
          <div>
            <h3>Attachments</h3>
            <div className="flex items-center space-x-2">
              <Input
                type="file"
                onChange={(e) =>
                  setSelectedAttachment0(e.target.files?.[0] || null)
                }
                accept=".pdf,.doc,.docx"
                className="max-w-[500px]"
              />
              {selectedAttachment0 && <p>✅</p>}
            </div>
            <div className="flex items-center space-x-2">
              <Input
                type="file"
                onChange={(e) =>
                  setSelectedAttachment1(e.target.files?.[0] || null)
                }
                accept=".pdf,.doc,.docx"
                className="max-w-[500px]"
              />
              {selectedAttachment1 && <p>✅</p>}
            </div>
            <div className="flex items-center space-x-2">
              <Input
                type="file"
                onChange={(e) =>
                  setSelectedAttachment2(e.target.files?.[0] || null)
                }
                accept=".pdf,.doc,.docx"
                className="max-w-[500px]"
              />
              {selectedAttachment2 && <p>✅</p>}
            </div>
            <div className="flex items-center space-x-2">
              <Input
                type="file"
                onChange={(e) =>
                  setSelectedAttachment3(e.target.files?.[0] || null)
                }
                accept=".pdf,.doc,.docx"
                className="max-w-[500px]"
              />
              {selectedAttachment3 && <p>✅</p>}
            </div>
            <div className="flex items-center space-x-2">
              <Input
                type="file"
                onChange={(e) =>
                  setSelectedAttachment4(e.target.files?.[0] || null)
                }
                accept=".pdf,.doc,.docx"
                className="max-w-[500px]"
              />
              {selectedAttachment4 && <p>✅</p>}
            </div>
            <div className="flex items-center space-x-2">
              <Input
                type="file"
                onChange={(e) =>
                  setSelectedAttachment5(e.target.files?.[0] || null)
                }
                accept=".pdf,.doc,.docx"
                className="max-w-[500px]"
              />
              {selectedAttachment5 && <p>✅</p>}
            </div>
            <div className="flex items-center space-x-2">
              <Input
                type="file"
                onChange={(e) =>
                  setSelectedAttachment6(e.target.files?.[0] || null)
                }
                accept=".pdf,.doc,.docx"
                className="max-w-[500px]"
              />
              {selectedAttachment6 && <p>✅</p>}
            </div>
            <div className="flex items-center space-x-2">
              <Input
                type="file"
                onChange={(e) =>
                  setSelectedAttachment7(e.target.files?.[0] || null)
                }
                accept=".pdf,.doc,.docx"
                className="max-w-[500px]"
              />
              {selectedAttachment7 && <p>✅</p>}
            </div>
            <div className="flex items-center space-x-2">
              <Input
                type="file"
                onChange={(e) =>
                  setSelectedAttachment8(e.target.files?.[0] || null)
                }
                accept=".pdf,.doc,.docx"
                className="max-w-[500px]"
              />
              {selectedAttachment8 && <p>✅</p>}
            </div>
            <div className="flex items-center space-x-2">
              <Input
                type="file"
                onChange={(e) =>
                  setSelectedAttachment9(e.target.files?.[0] || null)
                }
                accept=".pdf,.doc,.docx"
                className="max-w-[500px]"
              />
              {selectedAttachment9 && <p>✅</p>}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <Button onClick={handleFileUpload} disabled={uploading}>
              {uploading && (
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
              )}
              Upload
            </Button>
            {uploading && (
              <p className="text-sm text-orange-500">
                Do not refresh page, upload may take up to 30 seconds.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ReqDistributionView = () => {
  const authInfo = useAuthInfo();
  const [documentId, setDocumentId] = useState("");
  const [updating, setUpdating] = useState(false);

  const handleUpdate = async () => {
    if (!documentId) {
      toast.error("Please enter a document ID");
      return;
    }

    setUpdating(true);
    try {
      const result = await distributeReq(
        documentId,
        authInfo.accessToken ?? null
      );

      if (result.success) {
        toast.success("Document distributed successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(result.error || "Failed to distribute document");
      }
    } catch (error) {
      toast.error("Failed to distribute document");
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="pl-1 space-y-4">
      <div className="space-y-6">
        <div className="font-semibold text-lg pt-4">
          Distribute Requirements
        </div>
        <div className="flex-column items-center space-y-6">
          <div className="flex flex-col items-start space-y-2">
            <Label>
              Regulatory / Document ID <span className="text-red-500">*</span>
            </Label>
            <div className="flex items-center space-x-2">
              <Input
                value={documentId}
                onChange={(e) => setDocumentId(e.target.value)}
                placeholder="e.g. 00000000-0000-0000-0000-000000000000"
                className="w-[400px]"
              />
              {documentId && (
                <p className="text-sm text-red-500">
                  {/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
                    documentId
                  )
                    ? "✅"
                    : "❌ Must be a valid 36-char UUID format (e.g. 123e4567-e89b-12d3-a456-426614174000)"}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <Button onClick={handleUpdate} disabled={updating}>
              {updating && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}
              Distribute
            </Button>
            {updating && (
              <p className="text-sm text-orange-500">
                Do not refresh page, distribution may take up to 30 seconds.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminView = () => {
  return (
    <div className="space-y-4 pl-1">
      <p className="text-md font-bold text-red-500">
        You are currently in admin mode, please proceed with caution.
      </p>
    </div>
  );
};

const RecentDocsView = () => {
  const authInfo = useAuthInfo();
  const [recentDocs, setRecentDocs] = useState<Record<string, any>[]>([]);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("ID copied to clipboard");
  };

  useEffect(() => {
    getRecentDocs(authInfo.accessToken ?? null).then((response) => {
      setRecentDocs(response);
    });
  }, []);

  return (
    <>
      <div className="font-semibold text-lg mt-6">Recently Added Documents</div>
      <table className="w-full mt-4 border-collapse">
        <thead>
          <tr className="bg-gray-400">
            <th className="text-left p-2 border">Document Type</th>
            <th className="text-left p-2 border">Document Name</th>
            <th className="text-left p-2 border">ID</th>
            <th className="text-left p-2 border">Created At</th>
          </tr>
        </thead>
        <tbody>
          {recentDocs.map((doc, index) => (
            <tr
              key={doc.id}
              className={`border-b hover:bg-gray-300 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-100"
              }`}
            >
              <td className="p-2 border">{doc.doc_type}</td>
              <td className="p-2 border">{doc.doc_name}</td>
              <td className="p-2 border">
                <div className="flex items-center space-x-2">
                  <span>{doc.id}</span>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => handleCopy(doc.id)}
                    className="h-6 w-6 p-0"
                  >
                    <CopyIcon className="h-4 w-4" />
                  </Button>
                </div>
              </td>
              <td className="p-2 border">
                <TimeAgo timestamp={doc.created_at} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export const AdminPage = () => {
  const { isAdmin, departments } = useContext(UserContext);

  return (
    <Layout pageName="Admin">
      <div className="pb-10">
        <Tabs defaultValue="admin">
          <TabsList>
            <TabsTrigger value="admin">Admin</TabsTrigger>
            {isAdmin && (
              <>
                <TabsTrigger value="apl-ingest">APL Ingest</TabsTrigger>
                <TabsTrigger value="req-distribute">
                  Requirement Distribution
                </TabsTrigger>
                <TabsTrigger value="recent">Recently Added Docs</TabsTrigger>
              </>
            )}
          </TabsList>
          <TabsContent value="admin">
            <AdminView />
          </TabsContent>
          {isAdmin && (
            <>
              <TabsContent value="apl-ingest">
                <AplIngestView />
              </TabsContent>
              <TabsContent value="req-distribute">
                <ReqDistributionView />
              </TabsContent>
              <TabsContent value="recent">
                <RecentDocsView />
              </TabsContent>
            </>
          )}
        </Tabs>
      </div>
    </Layout>
  );
};
