import { useAuthInfo } from "@propelauth/react";
import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import { useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import { toast } from "sonner";
import { SelectFilter } from "../../components/FilterUtils";
import { LoadingView } from "../../components/Loading";
import { DataTable, HeaderCell } from "../../components/Table";
import { Badge } from "../../shadcn/components/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import {
  Department,
  FeedDocType,
  PaginationOutput,
  RegulatoryDoc,
  Requirement,
  statusToLabelMap,
  TaskStatus,
} from "../../types";
import { allRegulatoryDocs } from "../../utils/apiCalls";
import { loadAndFormatDate } from "../../utils/format";
import { RegulatoryDocAgencyImage } from "./CustomDocFeedContent";

const getDepartmentsCount = (requirements: Requirement[]) => {
  const allDepartments = requirements
    .flatMap((requirement) => requirement.assignees)
    .concat(
      requirements.flatMap((requirement) =>
        requirement.action_items.flatMap((action_item) => action_item.assignees)
      )
    );

  const uniqueDepartments = [
    ...new Map(
      allDepartments
        .filter(Boolean)
        .map((department: Department) => [department.name, department])
    ).values(),
  ] as Department[];

  const departmentsToShow = uniqueDepartments
    .map((department: Department) => department.name)
    .sort((a, b) => a.localeCompare(b));

  const departmentCounts = new Map<string, number>(
    departmentsToShow.map((department) => [department, 0])
  );

  requirements.forEach((requirement) => {
    requirement.action_items.forEach((action_item) => {
      action_item.assignees.forEach((assignee) => {
        if (assignee && assignee.name) {
          departmentCounts.set(
            assignee.name,
            (departmentCounts.get(assignee.name) ?? 0) + 1
          );
        }
      });
    });
  });

  return departmentCounts;
};

const getActionItemsCount = (requirements: Requirement[]) => {
  let actionItemCount = 0;
  requirements.forEach((requirement) => {
    actionItemCount += requirement.action_items.length;
  });
  return actionItemCount;
};

export const RegulatoryDocTableView = (props: {
  feedDocTypes: FeedDocType[];
  agencyFilter: string[];
  setAgencyFilter: React.Dispatch<React.SetStateAction<string[]>>;
  statusFilters: string[];
  setStatusFilters: React.Dispatch<React.SetStateAction<string[]>>;
  relevantFilters: string[];
  setRelevantFilters: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const authInfo = useAuthInfo();
  const [regulatoryDocs, setRegulatoryDocs] = useState<{
    docs: RegulatoryDoc[];
    pagination_output: PaginationOutput | null;
  }>({ docs: [], pagination_output: null });
  const [regulatoryDocsLoading, setRegulatoryDocsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortingParams, setSortingParams] = useState<
    {
      id: string;
      desc: boolean;
    }[]
  >([]);

  useEffect(() => {
    setRegulatoryDocsLoading(true);
    allRegulatoryDocs(
      pageSize,
      pageIndex + 1,
      props.agencyFilter,
      props.statusFilters,
      props.relevantFilters,
      sortingParams,
      authInfo?.accessToken ?? null
    ).then((response) => {
      if (response !== null) {
        setRegulatoryDocs(response);
      } else {
        toast.error("Failed to fetch regulatory docs");
      }
      setRegulatoryDocsLoading(false);
    });
  }, [
    props.agencyFilter,
    props.relevantFilters,
    props.statusFilters,
    pageSize,
    pageIndex,
  ]);

  const columns: any[] = useMemo(
    () => [
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Released" />;
        },
        cell: ({ row }: any) => {
          return (
            <div className="text-center">
              {loadAndFormatDate(
                row.original.metadata_.original_date ?? row.original.date,
                false
              )}
            </div>
          );
        },
        id: "released-date",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Regulator" />;
        },
        cell: ({ row }: any) => {
          return (
            <RegulatoryDocAgencyImage agency={row.original.doc_type.agency} />
          );
        },
        id: "regulator",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Identifier" />;
        },
        cell: ({ row }: any) => {
          return (
            <div className="text-center">
              <a
                href={`/regulatory-doc/overview/${row.original.id}`}
                className="text-blue-500 underline"
              >
                <div className="text-left">{row.original.doc_identifier}</div>
              </a>
            </div>
          );
        },
        id: "doc-identifier",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Title" />;
        },
        cell: ({ row }: any) => {
          const summary_bullet_points = (row.original.summary ?? "")
            .split("|")
            .filter((point: string) => point.trim().length > 0)
            .map((point: string) => `- ${point.trim()}`)
            .join("\n");

          let markdown = `**${row.original.title}**`;
          if (summary_bullet_points.length > 0) {
            markdown += `\n\n${summary_bullet_points}`;
          }

          return (
            <Tooltip>
              <TooltipTrigger>
                <div className="text-left">
                  {row.original.title.slice(0, 50)}
                  {row.original.title.length > 50 && "..."}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <div className="max-h-[300px] overflow-y-auto">
                  <Markdown className="prose">{markdown}</Markdown>
                </div>
              </TooltipContent>
            </Tooltip>
          );
        },
        id: "title",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Relevant" />;
        },
        cell: ({ row }: any) => {
          const iconToShow = row.original.configuration.relevant ? (
            <CheckCircledIcon className="w-4 h-4 text-green-500" />
          ) : (
            <CrossCircledIcon className="w-4 h-4 text-red-500" />
          );
          return (
            <div className="flex items-center justify-center">{iconToShow}</div>
          );
        },
        id: "relevant",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Due Date" />;
        },
        cell: ({ row }: any) => {
          return (
            <>
              {row.original.configuration.implementation_date ? (
                <div className="text-center">
                  {loadAndFormatDate(
                    row.original.configuration.implementation_date,
                    false
                  )}
                </div>
              ) : null}
            </>
          );
        },
        id: "implementation-date",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Status" />;
        },
        cell: ({ row }: any) => {
          return (
            <div className="text-center">
              {row.original.configuration.status
                ? statusToLabelMap[
                    row.original.configuration.status as TaskStatus
                  ]
                : ""}
            </div>
          );
        },
        id: "status",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Departments" />;
        },
        cell: ({ row }: any) => {
          const departmentsToShow = getDepartmentsCount(
            row.original.requirements
          );

          return (
            <>
              {departmentsToShow.size > 0 ? (
                <div className="text-center">
                  <Tooltip>
                    <TooltipTrigger>
                      <Badge className="bg-gray-400">
                        {departmentsToShow.size}
                      </Badge>
                    </TooltipTrigger>
                    <TooltipContent>
                      <div className="space-y-1 w-[300px]">
                        {Array.from(departmentsToShow.entries()).map(
                          ([department, count]) => (
                            <div className="flex items-center justify-between w-full">
                              <div className="overflow-hidden text-ellipsis truncate w-[270px] text-left">
                                {department}
                              </div>
                              <div className="text-muted-foreground">
                                {count}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </div>
              ) : null}
            </>
          );
        },
        id: "impacted-departments",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Requirements" />;
        },
        cell: ({ row }: any) => {
          if (row.original.requirements.length === 0) {
            return null;
          }
          return (
            <a
              href={`/regulatory-doc/overview/${row.original.id}?activeTab=requirements`}
            >
              <div className="text-center">
                <Tooltip>
                  <TooltipTrigger>
                    <Badge className="bg-gray-400">
                      {row.original.requirements.length}
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent>Click to view requirements</TooltipContent>
                </Tooltip>
              </div>
            </a>
          );
        },
        id: "requirements",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Actions" />;
        },
        cell: ({ row }: any) => {
          const actionItemsCount = getActionItemsCount(
            row.original.requirements
          );
          if (actionItemsCount === 0) {
            return null;
          }
          return (
            <a
              href={`/regulatory-doc/overview/${row.original.id}?activeTab=actions`}
            >
              <div className="text-center">
                <Tooltip>
                  <TooltipTrigger>
                    <Badge className="bg-gray-400">{actionItemsCount}</Badge>
                  </TooltipTrigger>
                  <TooltipContent>Click to view action items</TooltipContent>
                </Tooltip>
              </div>
            </a>
          );
        },
        id: "action-items",
      },
    ],
    []
  );

  return (
    <>
      {regulatoryDocsLoading ? (
        <LoadingView />
      ) : (
        <DataTable
          tableWrapperClassName="overflow-y-auto h-[calc(100vh-230px)]"
          columns={columns}
          data={regulatoryDocs.docs}
          paginationAtTop={true}
          totalRecordCount={
            regulatoryDocs.pagination_output?.total_records ?? 0
          }
          headerChildren={
            <div className="flex items-center space-x-2">
              <SelectFilter
                title="Relevant"
                filterCounts={
                  regulatoryDocs.pagination_output?.record_metadata[
                    "relevant"
                  ] ?? {}
                }
                activeFilter={props.relevantFilters}
                setActiveFilter={props.setRelevantFilters}
                nameFormatter={(name: string) => {
                  return name === "relevant" ? "Relevant" : "Not Relevant";
                }}
              />
              <SelectFilter
                title="Regulator"
                filterCounts={
                  regulatoryDocs.pagination_output?.record_metadata[
                    "doc_type_ids"
                  ] ?? {}
                }
                activeFilter={props.agencyFilter}
                setActiveFilter={props.setAgencyFilter}
                nameFormatter={(name: string) => {
                  const docType = props.feedDocTypes.find(
                    (docType) => docType.id === name
                  );
                  return docType?.agency ?? name;
                }}
                disableClear={true}
              />
              <SelectFilter
                title="Status"
                filterCounts={
                  regulatoryDocs.pagination_output?.record_metadata["status"] ??
                  {}
                }
                activeFilter={props.statusFilters}
                setActiveFilter={props.setStatusFilters}
                nameFormatter={(name: string) => {
                  return name === "None"
                    ? "None"
                    : statusToLabelMap[name as TaskStatus];
                }}
              />
            </div>
          }
          setPaginationParams={(pagination, sorting) => {
            setPageIndex(pagination.pageIndex);
            setPageSize(pagination.pageSize);
            setSortingParams(sorting);
          }}
          paginationParams={{
            pageSize: pageSize,
            pageIndex: pageIndex,
          }}
        />
      )}
    </>
  );
};
